import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import { getAvatarAsync, getAvatarsAsync } from './avatar.types';
import { NormalizerResult } from '../../types/entity.types';

const getAvatar = (avatarId: number) => {
  return axios({
    method: 'get',
    url: `/u/avatar/${avatarId}`
  });
};

function* getAvatarHandler(action: ReturnType<typeof getAvatarAsync.request>): Generator {

  try {
    const avatarId: number = action.payload;
    const response: AxiosResponse = (yield call(getAvatar, avatarId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.avatars) as NormalizerResult;
    const { avatars } = entities;

    yield put(getAvatarAsync.success(avatars));
  } catch (error) {
    yield put(getAvatarAsync.failure(error));
  }
}

function* getAvatarWatcher() {
  yield takeLatest(getType(getAvatarAsync.request), getAvatarHandler);
}

const getAvatars = () => {
  return axios({
    method: 'get',
    url: '/u/avatar'
  });
};

function* getAvatarsHandler(action: ReturnType<typeof getAvatarsAsync.request>):Generator {
  try {
    const response: AxiosResponse = (yield call(getAvatars)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.avatars) as NormalizerResult;
    const { avatars } = entities;

    yield put(getAvatarsAsync.success(avatars));

  } catch (error) {
    yield put(getAvatarsAsync.failure(error));
  }
}

function* getAvatarsWatcher() {
  yield takeLatest(getType(getAvatarsAsync.request), getAvatarsHandler);
}

export default function* avatarSaga() {
  yield all([fork(getAvatarWatcher), fork(getAvatarsWatcher)]);
}
