import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { BadgeType } from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';

export const getBadgesAsync = createAsyncAction(
  'hmp/admin/GET_BADGES_REQUEST',
  'hmp/admin/GET_BADGES_SUCCESS',
  'hmp/admin/GET_BADGES_FAILURE'
)<void, Optional<NormalizedType<BadgeType>>, Error>();

const badgesActions = {
  getBadgesAsync
};

export type BadgesActionTypes = ActionType<typeof badgesActions>;
