import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { AvatarType } from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';

export const getAvatarAsync = createAsyncAction(
  'hmp/admin/GET_AVATAR_REQUEST',
  'hmp/admin/GET_AVATAR_SUCCESS',
  'hmp/admin/GET_AVATAR_FAILURE'
)<number, Optional<NormalizedType<AvatarType>>, Error>();

export const getAvatarsAsync = createAsyncAction(
  'hmp/admin/GET_AVATARS_REQUEST',
  'hmp/admin/GET_AVATARS_SUCCESS',
  'hmp/admin/GET_AVATARS_FAILURE'
)<void, Optional<NormalizedType<AvatarType>>, Error>();

export const updateAvatars = createStandardAction('hmp/admin/UPDATE_AVATARS')<Optional<NormalizedType<AvatarType>>>();

const avatarActions = {
  getAvatarAsync,
  getAvatarsAsync,
  updateAvatars
};

export type AvatarActionTypes = ActionType<typeof avatarActions>;
