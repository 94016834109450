import React, { Component } from 'react';
import { FlagType } from '../../types/entity.types';
import './newQnaPostForm.scss';
import { Icon, Popover } from 'antd';
import FlagResolveMenu from './FlagResolveMenu';
const IconText = ({ type, text }) => (
  <span className=''>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);

interface ComponentProps {
  participantId: number;
  id: number;
  isHidden: boolean;
  type: string;
  flags: FlagType[];
  style?: any;
  innerStyle?: any;
}

class FlagActionItem extends Component<ComponentProps, {}> {
  render() {
    const {
      participantId,
      id,
      isHidden,
      type,
      flags,
      style,
      innerStyle
    } = this.props;
    const unresolvedFlags = flags ? flags.filter(f => f.resolveDate === null && f.resolution === null) : [];
    return (unresolvedFlags.length > 0 || isHidden ?
    <Popover
      key={`popover-flag-action-item-${type}-${id}`}
      content={<FlagResolveMenu participantId={participantId} id={id} isHidden={isHidden} type={type} />}
      title='Resolve Flag'
      trigger='click'
      style={style}
    >
      <a style={innerStyle} className='hmp-flag-icon-wrapper red-flag'><IconText type="flag-o" text={unresolvedFlags.length} key="list-vertical-flag-o" /></a>
    </Popover> :
    <IconText type="flag-o" text={unresolvedFlags.length} key="list-vertical-flag-o" />);
  }
}

export default FlagActionItem;