import { Input } from 'antd';
import React, { Component } from 'react';
import './thread.scss';


interface ComponentProps {
  searchTerm: string;
  search: (term: string) => void
}

class ThreadSearch extends Component<ComponentProps, {}> {
  readonly state = {
  }

  render() {
    const { search, searchTerm } = this.props;
    return (
      <div className="thread-search">
        <Input
          allowClear={true}
          type="search"
          className="thread-search-input"
          placeholder="Search Threads"
          value={searchTerm}
          onChange={(e) => {
            search(e.target.value);
          }}
        />
      </div>
    );
  }
}

export default ThreadSearch;