import { ActionType, createAsyncAction } from 'typesafe-actions';
import { UserSurveyType } from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';

export const getParticipantUserSurveysAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANT_USER_SURVEYS_REQUEST',
  'hmp/admin/GET_PARTICIPANT_USER_SURVEYS_SUCCESS',
  'hmp/admin/GET_PARTICIPANT_USER_SURVEYS_FAILURE'
)<number, Optional<NormalizedType<UserSurveyType>>, Error>();

export const getUserSurveysAsync = createAsyncAction(
  'hmp/admin/GET_USER_SURVEYS_REQUEST',
  'hmp/admin/GET_USER_SURVEYS_SUCCESS',
  'hmp/admin/GET_USER_SURVEYS_FAILURE'
)<void, Optional<NormalizedType<UserSurveyType>>, Error>();

const surveyActions = {
  getParticipantUserSurveysAsync: getParticipantUserSurveysAsync,
  getUserSurveysAsync: getUserSurveysAsync,
};

export type UserSurveyActionTypes = ActionType<typeof surveyActions>;
