import React, { ChangeEvent } from 'react';
import {
  Input, InputNumber
} from 'antd';
import '../activity.scss';
import { ActivityQuestionType} from '../../../types/entity.types';

interface ComponentProps {
  form: any;
  creative: boolean;
  editable: boolean;
  question: ActivityQuestionType;
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  updateQuestion: (q: ActivityQuestionType) => void;
}

enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener
}

const FillInTheBlankQuestion = (props: ComponentProps) => {
  const { question, onTextChange, updateQuestion, creative, editable } = props;
  let { text, numBlanks } = question;
  const onNumBlanksChange = (value: number | undefined) => {
    if(value) {
      question.numBlanks = value;
      updateQuestion(question);
    }
  };
  return (
    <div>
      <Input spellCheck disabled={!creative && !editable} placeholder='Question text' defaultValue={text} onChange={onTextChange} />
      Number of Blanks: <InputNumber disabled={!creative && !editable} defaultValue={numBlanks} min={0} onChange={onNumBlanksChange}/>
    </div>
  );
};

export default FillInTheBlankQuestion;