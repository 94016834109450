import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  Select, Input, Checkbox
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import FormContextProvider from '../../form/FormContextProvider';
import { ActivityType, ActivityCategoryType, ActivityTypeType } from '../../../types/entity.types';
import * as selectors from '../../../redux/selectors';
const { Item } = Form;
const { Option } = Select;
import '../activity.scss';
import { getActivityCategoriesAsync, getActivityTypesAsync } from '../../../redux/activities/activities.types';
import IApplicationState from '../../../types/state.types';
import FroalaCustomUpload from '../FroalaCustomUpload';
import ActivityTooltip from '../ActivityTooltip';
import ActivityItemLabel from '../ActivityItemLabel';
import { updateCmsEditorBeforeSave } from '../../util/Util';

interface StateProps {
  categories: Optional<ActivityCategoryType[]>,
  types: Optional<ActivityTypeType[]>
}

interface DispatchProps {
  loadCategories: typeof getActivityCategoriesAsync.request,
  loadTypes: typeof getActivityTypesAsync.request
}

interface ComponentProps extends StateProps, DispatchProps {
  creative: boolean;
  editable: boolean;
  setStatus: (status: string) => void;
  setActivity: (props: any) => void;
  setActiveForm: (form: any) => void;
  activity: ActivityType;
}

const itemStyle = {
  width: '100%',
  marginBottom: '10px',
  marginTop: '10px'
};

class ActivityDetailForm extends Component<ComponentProps & FormComponentProps, {}> {
  componentDidMount() {
    const { setStatus, loadCategories, loadTypes, setActiveForm, form } = this.props;
    setStatus('process');
    setActiveForm(form);
    loadCategories();
    loadTypes();
  }
  componentDidUpdate() {
    const { setStatus, activity } = this.props;
    const { type, category, title, description, intro } = activity;
    if(type && category && title.length > 0 && description.length > 0 && intro.length > 0) {
      setStatus('finish');
    }
  }
  // Action Handlers
  onChange = (field: string, state: any) => {
    const { setActivity, form } = this.props;
    setActivity(state);
  };
  // Custom Field Validators
  validateIntro = (rule, value, cb) => {
    const { activity } = this.props;
    const { intro } = activity;
    try {
      if (intro.length === 0){
        throw new Error('Introduction is required.');
      }
      cb()
    } catch (err) {
      cb(err);
    }
  };
  // This validator is a hotfix to resolve an issue where
  // navigating to another form step and back causes the
  // description field in the form to be undefined.
  validateDescription = (rule, value, cb) => {
    const { activity } = this.props;
    const { description } = activity;
    try {
      if (description.length === 0){
        throw new Error('Description is required.');
      }
      cb()
    } catch (err) {
      cb(err);
    }
  };
  // Renderers
  renderType = (value: string) => {
    switch(value) {
      case 'quiz':
        return 'Quizzes';
      case 'category':
        return 'Break it down';
      case 'category no-answer':
        return 'Break it down (no answer)';
      case 'cyoa':
        return 'Call the shots';
      case 'fill in the blank':
        return 'Fill it in';
      case 'goals':
        return 'Goals';
      case 'ranking':
        return 'Sort it out';
      case 'screener':
        return 'Assessments';
    }
  };
  capitalize = (s: string): string => {
    return s ? s.split(' ').map(word => word[0].toUpperCase() + word.substring(1)).join(' ') : s;
  }
  characterLimit = (s: string, limit: number): number => {
    return s ? s.length < limit ? limit - s.length : 0 : 0;
  }
  render() {
    const { form, activity, categories, types, creative, editable } = this.props;
    const { title, description, prompt, intro } = activity;
    const { getFieldDecorator } = form;
    return (
      <div className='activity-form'>
        <FormContextProvider>
          <Form key="form" layout='horizontal' colon={false}>
            <Item key="type" label={<ActivityItemLabel label='Activity Type:' tooltip='Type determines what kind of activity you will create.' />} style={itemStyle}>
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Type is required.' }],
                initialValue: !creative ? activity.typeId : activity.type
              })(<Select
                disabled={!creative && !editable}
                onChange={(val) => this.onChange('type', { type: val })}
                placeholder='Type'
                style={{ width: 220 }}>
                {types?.map(t => <Option key={t.id} value={t.id}>{this.renderType(t.type)}</Option>)}
              </Select>)}
            </Item>
            <Item key="category" label="Activity Category:" style={itemStyle}>
              {getFieldDecorator('category', {
                rules: [{ required: true, message: 'Category is required.' }],
                initialValue: activity.category
              })(<Select
                disabled={!creative && !editable}
                onChange={(val) => this.onChange('category', { category: val })}
                placeholder='Category'
                style={{ width: 180 }}>
                {categories?.map(cat => <Option key={cat.id} value={cat.id}>{cat.category}</Option>)}
              </Select>)}
            </Item>
            <Item key="title" label="Title:" style={itemStyle}>
              {getFieldDecorator('title', {
                rules: [{ required: true, message: 'Title is required.' }],
                initialValue: title
              })(<div>
                  <Input
                    spellCheck
                    disabled={!creative && !editable}
                    placeholder='Name the activity'
                    maxLength={200}
                    defaultValue={title}
                    onChange={(e) => this.onChange('title', { title: e.target.value })}
                  />
                  <span>({this.characterLimit(title, 200)} characters left.)</span>
                </div>)}
            </Item>
            <Item key="description" label="Description:" style={itemStyle}>
              {getFieldDecorator('description', {
                rules: [{ required: true, validator: this.validateDescription }]
              })(<div>
                  <Input
                    spellCheck
                    disabled={!creative && !editable}
                    placeholder='Blurb shown on main list'
                    maxLength={400}
                    defaultValue={description}
                    onChange={(e) => this.onChange('description', { description: e.target.value })}
                  />
                  <span>({this.characterLimit(description, 400)} characters left.)</span>
                </div>)}
            </Item>
            <Item key="prompt" label={<ActivityItemLabel label='Prompt:' tooltip='The text that appears on the screen with each question.' />} style={itemStyle}>
              {getFieldDecorator('prompt', {
                rules: [{}]
              })(<div>
                  <Input
                    spellCheck
                    disabled={!creative && !editable}
                    placeholder='Prompt to appear above questions'
                    maxLength={200}
                    defaultValue={prompt}
                    onChange={(e) => this.onChange('prompt', { prompt: e.target.value })}
                  />
                  <span>({this.characterLimit(prompt, 200)} characters left.)</span>
                </div>)}
            </Item>
            <Item key="intro" label="Introduction:" style={itemStyle}>
              {getFieldDecorator('intro', {
                rules: [{ required: true, validator: this.validateIntro }]
              })(<FroalaCustomUpload
                  disabled={!creative && !editable}
                  model={intro}
                  onChange={(intro: string) => this.onChange('intro', { intro: updateCmsEditorBeforeSave(intro) })}
                />)}
            </Item>
          </Form>
        </FormContextProvider>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    categories: selectors.getCategories(state),
    types: selectors.getTypes(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadCategories: () => dispatch(getActivityCategoriesAsync.request()),
    loadTypes: () => dispatch(getActivityTypesAsync.request())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'form' })(ActivityDetailForm));