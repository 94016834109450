import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { ParticipantBadgeType } from '../../types/entity.types';
import IApplicationState, { NormalizedState } from '../../types/state.types';
import { entitySchema } from '../schema';
import { getRequestedStudyParticipant } from '../selectors';

export const selectParticipantBadges = (state: IApplicationState) => {
  return state.entities.participantBadges;
};


export const getParticipantBadges = createSelector(
  [selectParticipantBadges],
  (participantBadges:NormalizedState<ParticipantBadgeType>): Optional<ParticipantBadgeType[]> => {
    if (participantBadges?.allIds?.length) {
      const { participantBadges: denormalizedParticipantBadges } = denormalize({ participantBadges: participantBadges.allIds }, entitySchema, { participantBadges: participantBadges.byId });
      return denormalizedParticipantBadges;
    }
    return [];
  }
);


export default { getParticipantBadges };
