import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { CommentType, DashboardDataType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';

export const selectDashboard = (state: IApplicationState) => {
  return state.dashboard;
};

export const getDashboardData = createSelector(
  [selectDashboard],
  (dashboardData:DashboardDataType) => {
    return dashboardData;
  }
);

// export default { getDashboardData };
