import { faBan, faCheckCircle, faEye, faEyeSlash, faSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { _ } from 'lodash';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../redux/selectors';
import { NotificationType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { renderPopoverDate } from '../util/Util';

interface StateProps {
}

interface DispatchProps {
}

interface ComponentProps extends DispatchProps, StateProps {
  participantNotifications: NotificationType[]
}

const renderDateWithTime = (value: any, row: any) => {
  return moment(value).format('YYYY-MM-DD HH:SSa');
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a: any, b: any) => a.id - b.id
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    sorter: (a: any, b: any) => `${a.label}`.localeCompare(b.label),
    render: (value:any, row: any) => {
      if (row.label === 'Badge Earned') {
        return <Tooltip title={row.meta.body}>{row.label} ({row.meta.title})</Tooltip>
      }
      return row.label;
    }
  },
  {
    title: 'Create Date',
    dataIndex: 'createDate',
    key: 'createDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.createDate).unix() - moment(b.createDate).unix(),
    defaultSortOrder: 'descend'
  },
  {
    title: 'Results',
    dataIndex: 'result',
    key: 'result',
    render: (value:any, row: any) => {

      const spanStyle = {marginLeft: '5px'};
      const eye = row.viewDate ? faEye : faEyeSlash;
      const viewHover = <span key={row}>{row.meta.inAppTitle || row.meta.title}<br/>{row.meta.inAppBody || row.meta.body}<br/>{row.viewDate ? `Viewed ${moment(row.viewDate).format('YYYY-MM-DD HH:SSa')}` : 'Not Viewed' }</span>;
      const results:JSX.Element[] = [<Tooltip key={`inApp_${row.id}`} title={viewHover}>In-App: <FontAwesomeIcon icon={eye}/></Tooltip>];

      const channels = value.split('\n');
      _.forEach(channels, (channelResult:string) => {
        const parts = channelResult.split(':');
        const icon = _.endsWith(parts[1], 'Success') ? <FontAwesomeIcon icon={faCheckCircle}/> : <Tooltip title={parts[1]}><FontAwesomeIcon icon={faBan}/></Tooltip>
        if (_.startsWith(parts[0], 'Popup')) {
          results.push(<Tooltip key={`popup_${row.id}`} title={<span>{row.meta.inAppTitle || row.meta.title}<br/>{row.meta.inAppBody || row.meta.body}</span>}> <span style={spanStyle}>  PopUp: {icon}</span></Tooltip>);
        }
        else if (_.startsWith(parts[0], 'Push')) {
          results.push(<Tooltip key={`push_${row.id}`} title={<span>{row.meta.pushTitle}<br/>{row.meta.pushBodstyey}</span>}> <span style={spanStyle}>  Push: {icon}</span> </Tooltip>);
        }
        else if (_.startsWith(parts[0], 'Email')) {
          results.push(<Tooltip key={`email_${row.id}`} title={<span>{row.meta.emailSubject}<br/><br/><span dangerouslySetInnerHTML={{__html: row.meta.emailBody}}/></span>}> <span style={spanStyle}>  Email: {icon}</span></Tooltip>);
        }
        else if (_.startsWith(parts[0], 'SMS')) {
              results.push(<Tooltip key={`sms_${row.id}`} title={<span>{row.meta.smsBody}</span>}> <span style={spanStyle}>  SMS: {icon}</span></Tooltip>);
        }
      });
      return <span>{...results}</span>
    }
  }
];

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class NotificationLogTable extends PureComponent<ComponentProps, {}> {

  render() {
    const { participantNotifications } = this.props;
    return (
      <Table
        columns={columns}
        dataSource={participantNotifications}
        pagination={{pageSize: 20}}
        rowClassName={rowClassName}
        rowKey="id"
        // expandedRowRender={this.rowRender}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationLogTable);
