import Button from "antd/lib/button";
import Card from "antd/lib/card";
import { Tooltip } from 'antd';
import moment from "moment";
import React from "react";
import Config from "../../../../components/util/Config";
import { renderPopoverDate } from "../../../../components/util/Util";
import { ConsentStatusType, ParticipantType, StudyArmType } from "../../../../types/entity.types";

interface ComponentProps {
  studyArm: StudyArmType,
  participant: ParticipantType,
  editClickHandler: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  consentStatuses: ConsentStatusType[]
}

const ParticipantDemographicsCard = (props: ComponentProps) => {

  const {
    studyArm, participant, editClickHandler, consentStatuses
  } = props;

  const consentStatusDescription = consentStatuses?.length ? (consentStatuses.find(cs => cs.version === participant.consentStatusVersion)).description : "";

  const showHIVData = () => {
    return !Config.isHMPStyle();
  }
  const showReferrals = () => {
    return !Config.isHMPStyle();
  }
  const showQualtrics = () => {
    return !Config.isHMPStyle();
  }
  return (
    <Card key="view">
      <div className="profile">
        <div className="profile-container">
          <div className="demo" >
            <div className="demo-section">
              <div className="demo-row">
                <div className="label">
                  <label>First Name</label>
                </div>
                <div className="field">{participant.firstName}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Last Name</label>
                </div>
                <div className="field">{participant.lastName}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Username</label>
                </div>
                <div className="field">{participant.username}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Email</label>
                </div>
                <div className="field">{participant.email}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Mobile</label>
                </div>
                <div className="field">{participant.mobile}</div>
              </div>
              <div className="demo-row">
                <div className="label" style={{ alignSelf: 'flex-start' }}>
                  <label>Address</label>
                </div>
                <div className="field">
                  {participant.address1 ? participant.address1 : participant.address1}
                  <br />
                  {participant.address2 ? `${participant.address2}` : ''}
                  {participant.address2 ? <br /> : ''}
                  {`${participant.city ? participant.city : ''}, ${participant.state ? participant.state : ''} ${participant.postalCode ? participant.postalCode : ''}`}
                </div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Date of Birth</label>
                </div>
                <div className="field">{participant.dateOfBirth ? moment(participant.dateOfBirth).format('MM/DD/YYYY') : ''}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Age</label>
                </div>
                <div className="field">{participant.dateOfBirth ? moment().diff(participant.dateOfBirth, 'years') : ''}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Sex at Birth</label>
                </div>
                <div className="field">{participant.genderAtBirth}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Gender Identity</label>
                </div>
                <div className="field">{participant.genderIdentity}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Ethnicity</label>
                </div>
                <div className="field">{participant.ethnicity}</div>
              </div>
            </div>
            <div className="demo-section">
              <div className="demo-row">
                <div className="label">
                  <label>Create Date</label>
                </div>
                <div className="field">{renderPopoverDate(participant.createDate)}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Status</label>
                </div>
                <div className="field">{participant.status}</div>
              </div>
              {showHIVData() && <div className="demo-row">
                <div className="label">
                  <label>Actual HIV Status</label>
                </div>
                <div className="field">{participant.actualHivStatus}</div>
              </div>}
              {showHIVData() && <div className="demo-row">
                <div className="label">
                  <label>Reported HIV Status</label>
                </div>
                <div className="field">{participant.reportedHivStatus}</div>
              </div>}
              {/*<div className="demo-row">*/}
              {/*  <div className="label">*/}
              {/*    <label>Eligible</label>*/}
              {/*  </div>*/}
              {/*  <div className="field">{participant.isEligible?'YES':'NO'}</div>*/}
              {/*</div>*/}
              {/*<div className="demo-row">*/}
              {/*  <div className="label">*/}
              {/*    <label>Email Verified</label>*/}
              {/*  </div>*/}
              {/*  <div className="field">{participant.isEmailVerified?'YES':'NO'}</div>*/}
              {/*</div>*/}
              {/*<div className="demo-row">*/}
              {/*  <div className="label">*/}
              {/*    <label>Signed Consent</label>*/}
              {/*  </div>*/}
              {/*  <div className="field">{participant.hasSignedConsent?'YES':'NO'}</div>*/}
              {/*</div>*/}
              <div className="demo-row">
                <div className="label">
                  <label>Access Code</label>
                </div>
                <div className="field">{participant.accessCode}</div>
              </div>
              {showReferrals() && <div className="demo-row">
                <div className="label">
                  <label>Referral Code</label>
                </div>
                <div className="field">{participant.referralCode}</div>
              </div>}
              <div className="demo-row">
                <div className="label">
                  <label>Pin</label>
                </div>
                <div className="field">{participant.pin}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Mobile OS</label>
                </div>
                <div className="field">{participant.mobileOs}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Consent Version</label>
                </div>
                <div className="field"><Tooltip title={consentStatusDescription}><div>{participant.consentStatusVersion}</div></Tooltip></div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>PrEP Status</label>
                </div>
                <div className="field">{participant.prepStatus}</div>
              </div>
            </div>
            <div className="demo-section">
              <div className="demo-row">
                <div className="label">
                  <label>Applied Date</label>
                </div>
                <div className="field">{renderPopoverDate(participant.appliedDate)}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Accepted Date</label>
                </div>
                <div className="field">{renderPopoverDate(participant.acceptedDate)}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Start Date</label>
                </div>
                <div className="field">{renderPopoverDate(participant.startDate)}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>End Date</label>
                </div>
                <div className="field">{renderPopoverDate(participant.endDate)}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Mobile Enroll Date</label>
                </div>
                <div className="field">{renderPopoverDate(participant.mobileEnrollmentDate)}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Graduation Date</label>
                </div>
                <div className="field">{renderPopoverDate(participant.graduationDate)}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Disqualified Date</label>
                </div>
                <div className="field">{renderPopoverDate(participant.disqualifiedDate)}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Disqualified Reason</label>
                </div>
                <div className="field">{participant.disqualifiedReason}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Drop Date</label>
                </div>
                <div className="field">{renderPopoverDate(participant.dropDate)}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Drop Reason</label>
                </div>
                <div className="field">{participant.dropReason}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Dismissed Date</label>
                </div>
                <div className="field">{renderPopoverDate(participant.dismissedDate)}</div>
              </div>
              <div className="demo-row">
                <div className="label">
                  <label>Dismissed Reason</label>
                </div>
                <div className="field">{participant.dismissedReason}</div>
              </div>
            </div>
            <div className="demo-section">
              <div className="demo-row" style={{alignItems: "flex-start"}}>
                <div className="label">
                  <label>About Me</label>
                </div>
                <textarea rows={3} style={{width: "250px", overflowY: "visible"}} readOnly>{participant.aboutMe}</textarea>
              </div>
            </div>
           </div>
          <div className="actions">
            <Button onClick={editClickHandler}>Edit</Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ParticipantDemographicsCard;
