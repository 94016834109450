import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import {
  loadStudiesAsync, loadStudyAsync, loadStudyStatsAsync, updateStudies, updateStudyArms
} from './study.types';
import { NormalizerResult, StudyArmType, StudyType } from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';

// TODO The load functions should probably be at a higher level in the root saga or something, since it's coordinating
//  across concerns. However, right now I'm just going to import the participant handler from the participants sagas.
import { updateParticipantsHandler } from '../participants/participants.sagas';
import { getAvatarsAsync } from '../avatar/avatar.types';
import { getAdminAsync, getAdminRolesAsync } from '../user/user.types';
import { getForumDetailsByStudyIdAsync } from '../forum/forum.types';

function* updateStudiesHandler(studies: Optional<NormalizedType<StudyType>>) {
  if (studies) {
    yield put(updateStudies(studies));
  }
}

function* updateStudyArmsHandler(studyArms: Optional<NormalizedType<StudyArmType>>) {
  if (studyArms) {
    yield put(updateStudyArms(studyArms));
  }
}

/**
 * Orchestrates the loading of studies for the Admin portion of the portal. It allows us to monitor the storing of
 * Study and Study Arm data to make sure both actions have finished, which helps the UI know when the studies have
 * been fully loaded.
 *
 * @param action the request to load the study data for the Admin portion of the portal.
 */
function* loadStudiesHandler(action: ReturnType<typeof loadStudiesAsync.request>): Generator {

  try {
    const response: AxiosResponse = (yield call(getStudies)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.studies) as NormalizerResult;
    const { studies, studyArms } = entities;

    yield call(updateStudiesHandler, studies);
    yield call(updateStudyArmsHandler, studyArms);

    yield put(getAvatarsAsync.request());
    yield put(getAdminAsync.request());

    yield put(loadStudiesAsync.success());

  } catch (error) {
    yield put(loadStudiesAsync.failure(error));
  }
}

function* loadStudiesWatcher() {
  yield takeLatest(getType(loadStudiesAsync.request), loadStudiesHandler);
}

/**
 * Orchestrates the loading of a study for the Study portion of the portal. It allows us to monitor the storing of
 * Study, Study Arm, and Participant data to make sure all actions have finished, which helps the UI know when the study
 * has been fully loaded.
 *
 * @param action the request to load a study for the Study portion of the portal.
 */
function* loadStudyHandler(action: ReturnType<typeof loadStudyAsync.request>): Generator {

  try {
    const studyId: number = action.payload;
    const response: AxiosResponse = (yield call(getStudy, studyId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.studies) as NormalizerResult;
    const { studies, studyArms, participants } = entities;

    yield call(updateStudiesHandler, studies);
    yield call(updateStudyArmsHandler, studyArms);
    yield call(updateParticipantsHandler, participants);

    yield put(getAvatarsAsync.request());
    yield put(getAdminAsync.request());
    yield put(getAdminRolesAsync.request());
    yield put(getForumDetailsByStudyIdAsync.request(studyId));

    yield put(loadStudyAsync.success());

  } catch (error) {
    yield put(loadStudyAsync.failure(error));
  }
}

function* loadStudyWatcher() {
  yield takeLatest(getType(loadStudyAsync.request), loadStudyHandler);
}

/**
 * Makes the service call to get the studies for the Admin portion of the portal. The service returns both Study and
 * Study Arm information but not Participant information.
 */
const getStudies = () => {
  return axios({
    method: 'get',
    url: '/a/study'
  });
};


/**
 * Makes the service call to get the study for the Study portion of the portal. The service returns Study, Study Arm,
 * and Participant information.
 *
 * @param studyId the id of the study to get
 */
const getStudy = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/study/${studyId}`
  });
};

const loadStudyStats = (studyId: number) => {
  return axios({
      method: 'get',
      url: `/a/study/${studyId}/stats`
    }
  );
}

function* loadStudyStatsWatcher() {
  yield takeLatest(getType(loadStudyStatsAsync.request), loadStudyStatsHandler);
}


/**
 * Orchestrates the loading of a study for the Study portion of the portal. It allows us to monitor the storing of
 * Study, Study Arm, and Participant data to make sure all actions have finished, which helps the UI know when the study
 * has been fully loaded.
 *
 * @param action the request to load a study for the Study portion of the portal.
 */
function* loadStudyStatsHandler(action: ReturnType<typeof loadStudyStatsAsync.request>): Generator {

  try {
    const studyId: number = action.payload;
    const response: AxiosResponse = (yield call(loadStudyStats, studyId)) as AxiosResponse;
    yield put(loadStudyStatsAsync.success(response.data));

  } catch (error) {
    yield put(loadStudyStatsAsync.failure(error));
  }
}


export default function* studySaga() {
  yield all([
    fork(loadStudiesWatcher),
    fork(loadStudyWatcher),
    fork(loadStudyStatsWatcher)
  ]);
}
