import React, { Component } from 'react';import {
  Upload, Button, message
} from 'antd';
import './activity.scss';

interface ComponentProps {
  creative: boolean;
  editable: boolean;
  image: File;
  setStatus: (status: string) => void;
  uploadFile: (image: File) => Promise<void>;
  removeFile: () => Promise<void>;
}

class ActivityImageUpload extends Component<ComponentProps, {}> {
  readonly state = {
    fileList: this.props.image ? [this.props.image] : new Array(),
    uploading: false
  };
  onRemove = (file: any) => {
    const { removeFile, setStatus } = this.props;
    this.setState({
      fileList: []
    }, async () => {
      await removeFile().then(() => setStatus('process'));
    });
  };
  beforeUpload = (file: any) => {
    const { uploadFile, setStatus } = this.props;
    if(file.size <= 50000) {
      this.setState({
        fileList: [file],
      }, async () => {
        await uploadFile(file).then(() => {
          message.success('Uploaded successfully.');
          setStatus('finish');
        })
        .catch(err => {
          message.error(`Error uploading: ${err}`);
          setStatus('error');
        });
      });
    } else {
      message.error(`Upload failed. File exceeds 50KB limit.`);
      setStatus('error');
    }
    return false;
  }
  render() {
    const { creative, editable } = this.props;
    const { fileList } = this.state;
    return (
      <div className='activity-image-upload'>
        <Upload
          disabled={!creative && !editable}
          beforeUpload={this.beforeUpload}
          onRemove={this.onRemove}
          fileList={fileList}
          className="upload-list-inline">
          <Button style={{whiteSpace: 'pre-wrap'}}>
            <i className="fal fa-upload"></i> Upload
          </Button>
        </Upload>
      </div>
    );
  }
}

export default ActivityImageUpload;