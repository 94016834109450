import React, { Component } from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import Form, { FormComponentProps } from 'antd/lib/form';
import FormContextProvider from '../../form/FormContextProvider';
import { ActivityType, ActivityQuestionType, ActivityOptionType, ActivityTreeNodeType } from '../../../types/entity.types';
import '../activity.scss';
import DynamicQuestionItems from '../questions/DynamicQuestionItems';
import DynamicOptionItems from '../options/DynamicOptionItems';
import ChooseYourOwnAdventure from '../choose-your-own-adventure/ChooseYourOwnAdventure';
import { InputNumber } from 'antd';
const { Item } = Form;

interface ComponentProps {
  creative: boolean;
  editable: boolean;
  setStatus: (status: string) => void;
  setActivity: (props: any) => void;
  setActiveForm: (form: any) => void;
  activity: ActivityType;
}
enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener
}
const itemStyle = {
  width: '100%',
  marginBottom: '10px', 
  marginTop: '10px'
};
const defaultQuestion = {
  text: '',
  uuid: '',
  randomizeOptions: false,
  numBlanks: undefined,
  options: [],
  correctOptionId: undefined,
  feedback: []
};
const defaultOption = {
  text: '',
  uuid: '',
  points: 0,
  isCorrect: false,
  correctPosition: undefined,
  feedback: undefined
};

class ActivityQuestionForm extends Component<ComponentProps & FormComponentProps, {}> {
  componentDidMount() {
    const { setStatus, setActiveForm, form } = this.props;
    setStatus('process');
    setActiveForm(form);
  }
  // Action Handlers
  updateQuestions = (questions: ActivityQuestionType[]) => {
    const { setActivity, activity } = this.props;
    activity.questions = questions;
    setActivity({ questions });
  };
  updateOptions = (options: ActivityOptionType[]) => {
    const { setActivity, activity } = this.props;
    activity.options = options;
    if(activity.type === Type.ranking) {
      // If activity type is ranking, the correctPosition
      // is just the position in the array.
      for(let i = 0; i < options.length; i++) {
        options[i].correctPosition = i;
      }
    }
    setActivity({ options });
  };
  addQuestion = () => {
    const { activity } = this.props;
    const { questions } = activity;
    let updatedQuestions = questions ? _.cloneDeep(questions) : [];
    const newQuestion = _.cloneDeep(defaultQuestion);
    newQuestion.uuid = generateUUID();
    if(!questions) {
      updatedQuestions = [newQuestion]
    } else {
      updatedQuestions[updatedQuestions.length] = newQuestion;
    }
    this.updateQuestions(updatedQuestions);
  }
  removeQuestion = (index: number) => {
    const { activity } = this.props;
    const { questions } = activity;
    let i = 0;
    const update = questions ? questions.filter(q => { return index === i++ ? false : true; }) : [];
    this.updateQuestions(update);
  }
  updateQuestion = (q: ActivityQuestionType, index: number) => {
    const { activity } = this.props;
    const { questions } = activity;
    if(questions) {
      questions[index] = q;
      this.updateQuestions(questions);
    }
  }
  updateOption = (option: ActivityOptionType, index: number) => {
    const { activity } = this.props;
    const { options } = activity;
    if(options) {
      options[index] = _.cloneDeep(option);
      this.updateOptions(options);
    }
  }
  addOption = () => {
    const { activity } = this.props;
    const { options } = activity;
    let updatedOptions = options ? _.cloneDeep(options) : [];
    const newOption = _.cloneDeep(defaultOption);
    newOption.uuid = generateUUID();
    if(!options) {
      updatedOptions = [newOption]
    } else {
      updatedOptions[updatedOptions.length] = newOption;
    }
    this.updateOptions(updatedOptions);
  }
  removeOption = (index: number) => {
    const { activity } = this.props;
    let { options } = activity;
    let i = 0;
    options = options ? options.filter(o => { return index === i++ ? false : true; }) : [];
    this.updateOptions(options);
  }
  swapOptionPosition = (indexA: number, indexB: number) => {
    const { activity } = this.props;
    let { options } = activity;
    if(options && ((indexA < indexB && indexB < options.length) || (indexA > indexB && indexB >= 0))) {
      const temp = _.cloneDeep(options[indexA]);
      options[indexA] = _.cloneDeep(options[indexB]);
      options[indexB] = temp;
      this.updateOptions(options);
    }
  }
  onNumToSelectChange = (value: number | undefined) => {
    const { activity, setActivity } = this.props;
    if(value) {
      activity.numToSelect = value;
      setActivity({ numToSelect: value });
    }
  };
  validateNumToSelect = (rule, value, cb) => {
    const { activity } = this.props;
    const { numToSelect } = activity;
    try {
      if (!numToSelect){ 
        throw new Error('numToSelect is required.');
      }
      cb()
    } catch (err) {
      cb(err);
    }
  };
  updateTree = (tree: ActivityTreeNodeType) => {
    const { setActivity } = this.props;
    if(tree) {
      setActivity({ tree });
    }
  };
  // Custom Field Validators
  // Renderers
  render() {
    const { form, activity, setStatus, creative, editable } = this.props;
    const { questions, options, numToSelect, tree } = activity;
    let type = parseInt(activity.type);
    const displayActivityOptions = [Type.category, Type.category_no_answer, Type.ranking, Type.goals, Type.screener].indexOf(type) > -1;
    const displayQuestions = [Type.goals, Type.ranking, Type.cyoa].indexOf(type) === -1;
    const { getFieldDecorator } = form;
    return (
      <div className='activity-form'>
        <FormContextProvider>
          <Form key="form" layout='horizontal' colon={false}>
            {type === Type.goals && 
            <Item
              required={true}
              key='numToSelect'
              style={itemStyle}
            >
            {getFieldDecorator(`numToSelect`, {
              validateTrigger: ['onChange', 'onBlur', 'onFocus'],
              rules: [
                {
                  required: true,
                  message: 'Please set how many goals the participant is required to select.',
                  validator: this.validateNumToSelect
                }
              ]
            })(<span>Require user to select <InputNumber disabled={!creative && !editable} defaultValue={numToSelect} min={0} onChange={this.onNumToSelectChange}/> items.</span>)}
            </Item>}
            {displayActivityOptions && 
            <div style={{width: '100%'}}>
              <h4>Activity Options:</h4>
              <DynamicOptionItems
                type={type} 
                form={form} 
                creative={creative}
                editable={editable}
                options={options ? options : []} 
                setStatus={setStatus}
                updateOption={this.updateOption}
                addOption={this.addOption}
                removeOption={this.removeOption}
                swapOptionPosition={this.swapOptionPosition}
              />
            </div>}
            {displayQuestions &&
            <div style={{width: '100%'}}>
              <h4>Questions:</h4>
              <DynamicQuestionItems 
                type={type} 
                form={form} 
                creative={creative}
                editable={editable}
                questions={questions ? questions : []} 
                options={options ? options : []}
                setStatus={setStatus}
                updateQuestion={this.updateQuestion}
                addQuestion={this.addQuestion}
                removeQuestion={this.removeQuestion}
              />
            </div>}
            {type === Type.cyoa &&
            <ChooseYourOwnAdventure 
              form={form}
              creative={creative}
              editable={editable}
              tree={tree ? tree : {} as ActivityTreeNodeType}
              setStatus={setStatus}
              updateTree={this.updateTree}
            />}
          </Form>
        </FormContextProvider>
      </div>
    );
  }
}

export default Form.create({ name: 'form' })(ActivityQuestionForm);