import React, { Component } from 'react';
import Avatar from 'antd/lib/avatar';
import Badge from 'antd/lib/badge';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import AntdIcon from "../antdIcon/AntdIcon";
import * as selectors from '../../redux/selectors';
import { toggleSideBar } from '../../redux/sidebar/sidebar.types';
import { getUser } from '../../redux/selectors';
import { logout } from '../../redux/app/app.types';
import { UserType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import SideBarToggleButton from '../sidebar/SideBarToggleButton';

import {
  faBell,
  faChevronDown,
  faHorizontalRule,
  faSearch,
  faUser
} from '@fortawesome/pro-solid-svg-icons';

import './header.scss';

interface StateProps {
  collapsed: boolean,
  user: Optional<UserType>
}

interface DispatchProps {
  toggleCallback: () => void,
  logout: typeof logout
}

interface ComponentProps extends StateProps, DispatchProps {}

class Header extends Component<ComponentProps> {

  handleLogout = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    this.props.logout();
  };

  handleToggleButtonClick = () => {
    const { toggleCallback } = this.props;
    toggleCallback();
  };

  render() {

    const { Header: AntdHeader } = Layout;
    const { children, collapsed, user = { firstName: '' } } = this.props;

    const userMenu = (
      <Menu>
        <Menu.Item key="logout">
          <Button className="logout-btn" type="link" onClick={this.handleLogout}>
            <span>Logout</span>
          </Button>
        </Menu.Item>
      </Menu>
    );

    return (
      <AntdHeader id="header">
        <div id="header-left">
          <SideBarToggleButton collapsed={collapsed} toggleCallback={this.handleToggleButtonClick} />
          {children}
        </div>
        <div id="header-right">
          <span>
            <Avatar size="small" icon={<AntdIcon fontAwesomeIcon={faUser}/>} />
          </span>
          <Dropdown overlay={userMenu} trigger={['click']}>
            <a className="ant-dropdown-link" onClick={ e => e.preventDefault()}>
              {user.firstName} <AntdIcon fontAwesomeIcon={faChevronDown}/>
            </a>
          </Dropdown>
        </div>
      </AntdHeader>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  collapsed: selectors.isSidebarCollapsed,
  user: getUser
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    logout: () => dispatch(logout()),
    toggleCallback: () => dispatch(toggleSideBar())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
