import React, { ChangeEvent } from 'react';
import Cascader, { CascaderOptionType } from 'antd/lib/cascader';
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
import './resourceEditPage.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { RouteComponentProps, withRouter } from "react-router-dom";
import AntdIcon from '../../../components/antdIcon/AntdIcon';

import ResourceEditor from '../../../components/resources/editors/ResourceEditor';
import { getRequestedArticleAsync, loadArticlesAsync, saveArticleAsync } from '../../../redux/article/article.types';
import * as selectors from '../../../redux/selectors';
import { loadResourceTopicsAsync } from '../../../redux/topics/topic.types';
import { IApiRequestStatus } from '../../../types/api.types';
import { ArticleType, TopicType } from '../../../types/entity.types';
import IApplicationState from '../../../types/state.types';
import { clearStatus } from "../../../redux/api/api.types";
import { getType } from "typesafe-actions";

interface StateProps {
  resourceTopics: CascaderOptionType[];
  article: Optional<ArticleType>;
  loadResourceTopicsStatus: IApiRequestStatus;
  getRequestedEditResourceStatus: IApiRequestStatus;
  saveArticleStatus: IApiRequestStatus;
  requestedArticleId?: number;
  savedArticleId?: number;
  studyId: number;
}

interface DispatchProps {
  loadResourceTopics: typeof loadResourceTopicsAsync.request;
  getRequestedArticle: typeof getRequestedArticleAsync.request;
  saveArticle: typeof saveArticleAsync.request;
  clearStatus: typeof clearStatus;
}

interface ComponentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

class ResourceEditPage extends React.Component<ComponentProps, {}> {
  componentDidMount(): void {
    const {
      loadResourceTopics,
      getRequestedArticle,
      requestedArticleId,
    } = this.props;
    loadResourceTopics();

    if (requestedArticleId && requestedArticleId != -1) {
      getRequestedArticle();
    }
  }

  componentDidUpdate(prevProps: ComponentProps) {
    const {
      history,
      saveArticleStatus,
      studyId,
      clearStatus,
      savedArticleId,
      requestedArticleId,
    } = this.props;
    const { saveArticleStatus: prevStatus } = prevProps;
    if (
      !prevStatus.isSuccess &&
      saveArticleStatus.isSuccess &&
      savedArticleId &&
      requestedArticleId === -1
    ) {
      history.push(`/study/${studyId}/resources/edit/${savedArticleId}`);
      clearStatus(getType(saveArticleAsync.success));
    }
  }

  saveArticle = (article: ArticleType) => {
    this.props.saveArticle(article);
  };

  render() {
    const {
      resourceTopics,
      article,
      loadResourceTopicsStatus,
      getRequestedEditResourceStatus,
    } = this.props;

    // TODO change all of this to allow creating a new article or have a separate ResourceNewPage.

    if (loadResourceTopicsStatus.isLoading) {
      return <div>Loading Topics</div>;
    }

    if (getRequestedEditResourceStatus.isLoading) {
      return <div>Loading Article</div>;
    }

    if (loadResourceTopicsStatus.isError) {
      return (
        <div>Error loading topics: {loadResourceTopicsStatus.errorMessage}</div>
      );
    }

    if (getRequestedEditResourceStatus.isError) {
      return (
        <div>
          Error loading article: {getRequestedEditResourceStatus.errorMessage}
        </div>
      );
    }

    if (!resourceTopics) {
      return (
        <div>
          The topics did not load properly. No error message was returned from
          the server.
        </div>
      );
    }

    if (resourceTopics.length <= 0) {
      return (
        <div>
          No topics were loaded. You need to create topics first, so that
          articles can be categorized appropriately.
        </div>
      );
    }

    return (
      <div id="hmp-resource-edit-page">
        <ResourceEditor
          resourceTopics={resourceTopics}
          originalArticle={article}
          saveArticle={this.saveArticle}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    resourceTopics: selectors.getResourceTopicsCascader,
    article: selectors.getRequestedEditResource,
    loadResourceTopicsStatus: selectors.loadResourceTopicsStatus,
    getRequestedEditResourceStatus: selectors.getRequestedEditResourceStatus,
    saveArticleStatus: selectors.saveArticleStatus,
    savedArticleId: selectors.selectSavedArticleId,
    requestedArticleId: selectors.getRequestedEditResourceId,
    studyId: selectors.getRequestedStudyId,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadResourceTopics: () => dispatch(loadResourceTopicsAsync.request()),
    getRequestedArticle: () => dispatch(getRequestedArticleAsync.request()),
    saveArticle: (article: ArticleType) =>
      dispatch(saveArticleAsync.request(article)),
    clearStatus: (type: string) => dispatch(clearStatus(type)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourceEditPage)
);
