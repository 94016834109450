import React from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import {
  Input, Form
} from 'antd';
import '../activity.scss';
import { ActivityQuestionType, ActivityOptionType, ActivityFeedbackType } from '../../../types/entity.types';
import QuizQuestion from './QuizQuestion';
import FillInTheBlankQuestion from './FillInTheBlankQuestion';
import CategoryQuestion from './CategoryQuestion';
import DynamicFeedbackItems from '../feedback/DynamicFeedbackItems';
const { Item } = Form;

interface ComponentProps {
  form: any;
  type: number;
  creative: boolean;
  editable: boolean;
  question: ActivityQuestionType;
  options: ActivityOptionType[];
  setStatus: (status: string) => void;
  updateQuestion: (q: ActivityQuestionType) => void;
}

enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener,
  question
}
const defaultFeedback = {
  uuid: '',
  title: '',
  text: '',
  min: undefined,
  max: undefined,
  isOverallFeedback: false
};
const itemStyle = {
  width: '100%',
  marginBottom: '10px', 
  marginTop: '10px'
};

const QuestionItem = (props: ComponentProps) => {
  const { 
    question,
    setStatus,
    updateQuestion, 
    type, 
    form, 
    creative,
    editable,
    options 
  } = props;
  const { text, feedback, uuid, id } = question;
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    question.text = e.target.value;
    updateQuestion(question);
  }
  const updateFeedback = (fb: ActivityFeedbackType, index: number) => {
    if(question.feedback) {
      question.feedback[index] = fb;
      updateQuestion(question);
    }
  };
  const addFeedback = () => {
    let updatedQuestion = _.cloneDeep(question);
    const newFeedback = _.cloneDeep(defaultFeedback);
    newFeedback.uuid = generateUUID();
    if(!updatedQuestion.feedback) {
      updatedQuestion.feedback = [newFeedback]
    } else {
      updatedQuestion.feedback[updatedQuestion.feedback.length] = newFeedback;
    }
    updateQuestion(updatedQuestion);
  };
  const removeFeedback = (index: number) => {
    let i = 0;
    question.feedback = question.feedback ? question.feedback.filter(f => { return index === i++ ? false : true; }) : [];
    updateQuestion(question);
  };
  const validateQuestion = (rule, value, cb) => {
    try {
      if (question && question.text.length === 0){ 
        throw new Error('Question text is required.');
      }
      setStatus('finish');
      cb();
    } catch (err) {
      cb(err);
    }
  };
  let item;
  const { getFieldDecorator } = form;
  switch(type) {
    case Type.quiz:
      item = <QuizQuestion 
        form={form} 
        creative={creative}
        editable={editable}
        question={question} 
        setStatus={setStatus}
        onTextChange={onTextChange} 
        updateQuestion={updateQuestion} 
      />
    break;
    case Type.fill_in_the_blank:
      item = <FillInTheBlankQuestion 
        creative={creative} 
        editable={editable} 
        form={form} 
        question={question} 
        onTextChange={onTextChange} 
        updateQuestion={updateQuestion} 
      />
    break;  
    case Type.category:
      item = <CategoryQuestion 
        creative={creative} 
        editable={editable} 
        form={form} 
        question={question} 
        options={options} 
        onTextChange={onTextChange}
        updateQuestion={updateQuestion} 
      />;
    break;  
    default:
      item = <Input spellCheck disabled={!creative && !editable} placeholder='Question text' value={text} onChange={onTextChange}/>;
    break;  
  }
  const displayFeedback = [Type.category].indexOf(type) === -1;
  return (
  <Item
    required={false}
    key={id ? id : uuid}
    style={itemStyle}
  >
    {getFieldDecorator(`question-${id ? id : uuid}`, {
      validateTriggers: ['onChange', 'onBlur', 'onFocus'],
      rules: [
        {
          required: true,
          message: 'Please input question text or delete this question.',
          validator: validateQuestion
        }
      ]
    })(<div className='question-container'>
      {item}
      {displayFeedback && 
      <h4>Question Feedback:</h4> &&
      <DynamicFeedbackItems
        type={Type.question}
        form={form}
        creative={creative}
        editable={editable}
        feedback={feedback ? feedback : []}
        options={options ? options : []}
        setStatus={setStatus}
        updateFeedback={updateFeedback}
        addFeedback={addFeedback}
        removeFeedback={removeFeedback}
      />}
    </div>)}
  </Item>);
};

export default QuestionItem;