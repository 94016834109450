import { Popover, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { UserSurveyType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { renderPopoverDate } from '../util/Util';

interface StateProps {
}

interface DispatchProps {
}

interface ComponentProps extends DispatchProps, StateProps {
  userSurveys: UserSurveyType[]
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a: any, b: any) => a.id - b.id
  },
  {
    title: 'Survey',
    dataIndex: 'surveyName',
    key: 'surveyName',
    sorter: (a: any, b: any) => `${a.surveyName}`.localeCompare(b.surveyName)
  },
  {
    title: 'UniqueId/Link',
    dataIndex: 'followUpSurveyUniqueId',
    key: 'followUpSurveyUniqueId',
    render: (value: any, row: any) => {
      return <a href={row.url}>{value}</a>
    }
  },
  {
    title: 'Window Open Date',
    dataIndex: 'windowOpenDate',
    key: 'windowOpenDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.windowOpenDate).unix() - moment(b.windowOpenDate).unix()
  },
  {
    title: 'Window Close Date',
    dataIndex: 'windowCloseDate',
    key: 'windowCloseDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.windowCloseDate).unix() - moment(b.windowCloseDate).unix()
  },
  {
    title: 'Completed Date',
    dataIndex: 'completedDate',
    key: 'completedDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.completedDate).unix() - moment(b.completedDate).unix()
  }
];

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class NotificationLogTable extends PureComponent<ComponentProps, {}> {

  render() {
    const { userSurveys } = this.props;
    return (
      <Table
        columns={columns}
        dataSource={userSurveys}
        pagination={{pageSize: 20}}
        rowClassName={rowClassName}
        rowKey="id"
        // expandedRowRender={this.rowRender}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationLogTable);
