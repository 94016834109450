import { ActionType, createAction, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { CommentType, FormType } from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';
import {CreateCommentParam, SocialJsonParamType} from '../user/socialUtil';

export const getParticipantCommentsAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANT_COMMENTS_REQUEST',
  'hmp/admin/GET_PARTICIPANT_COMMENTS_SUCCESS',
  'hmp/admin/GET_PARTICIPANT_COMMENTS_FAILURE'
)<number, Optional<NormalizedType<CommentType>>, Error>();

export const getCommentsAsync = createAsyncAction(
  'hmp/admin/GET_COMMENTS_REQUEST',
  'hmp/admin/GET_COMMENTS_SUCCESS',
  'hmp/admin/GET_COMMENTS_FAILURE'
)<SocialJsonParamType[], Optional<NormalizedType<CommentType>>, Error>();

export const createCommentAsync = createAsyncAction(
  'hmp/admin/CREATE_COMMENT_REQUEST',
  'hmp/admin/CREATE_COMMENT_SUCCESS',
  'hmp/admin/CREATE_COMMENT_FAILURE'
)<CreateCommentParam, Optional<NormalizedType<CommentType>>, Error>();

export const updateComments = createStandardAction('hmp/admin/UPDATE_COMMENTS')<Optional<NormalizedType<CommentType>>>();

const commentsActions = {
  getParticipantCommentsAsync,
  getCommentsAsync,
  createCommentAsync,
  updateComments
};

export type CommentsActionTypes = ActionType<typeof commentsActions>;
