import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { AvatarType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { entitySchema } from '../schema';

export const selectAvatars = (state: IApplicationState) => {
  return state.entities.avatars;
};

export const getAvatars = createSelector(
  [selectAvatars],
  (avatars): Optional<AvatarType[]> => {
    if (avatars && avatars.allIds && avatars.allIds.length > 0) {
      const { avatars: denormalizedAvatars } = denormalize({ avatars: avatars.allIds }, entitySchema, { avatars: avatars.byId });
      return toArray(denormalizedAvatars);
    }
    return undefined;
  }
);

export default { getAvatars, selectAvatars };
