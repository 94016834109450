import React, { Component } from 'react';
import axios from '../../../redux/api';
import * as _ from 'lodash';
import {
  Select, Col, message
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import FormContextProvider from '../../form/FormContextProvider';
import ActivityImageUpload from '../ActivityImageUpload';
import ActivityGradientSelector from '../ActivityGradientSelector';
import { ActivityBackgroundType, ActivityType } from '../../../types/entity.types';
const { Item } = Form;
const { Option } = Select;
import '../activity.scss';
import ActivityPreview from '../ActivityPreview';

interface ComponentProps {
  creative: boolean;
  editable: boolean;
  setStatus: (status: string) => void;
  background: ActivityBackgroundType;
  backgroundType: string;
  setBackgroundType: (type: string) => void;
  setActivity: (props: any) => void;
  activity: ActivityType;
  previewImage: string;
  image: File;
  uploadFile: (image: File) => Promise<void>;
  removeFile: () => Promise<void>;
  setActiveForm: (form: any) => void;
}

class ActivityBackgroundForm extends Component<ComponentProps & FormComponentProps, {}> {
  componentDidMount() {
    const { setStatus, setActiveForm, form, backgroundType, background, previewImage, creative } = this.props;
    if(backgroundType === 'image' && previewImage.length === 0 || backgroundType === 'gradient' && !background.gradient) {
      setStatus('process');
    }
    setActiveForm(form);
  }
  componentWillReceiveProps(nextProps: ComponentProps) {
    if(!_.isEqual(nextProps, this.props) && nextProps.background) {
      const { setStatus } = this.props;
      const { backgroundType } = nextProps;
      const { gradient, filename } = nextProps.background;
      if(backgroundType === 'gradient' && gradient && gradient.start && gradient.end && gradient.start.length > 0 && gradient.end.length > 0) {
        setStatus('finish');
      } else if(backgroundType === 'image' && filename && filename.length > 0) {
        setStatus('finish');
      }
    }
  }
  onChange = (type: string) => {
    const { setBackgroundType } = this.props;
    setBackgroundType(type);
    this.setState({ type });
  };
  setGradient = (start: string | null, end: string | null) => {
    const { setActivity } = this.props;
    const background = {
      gradient: {
        start,
        end
      }
    };
    setActivity({ background });
    this.setState({ background });
  };
  validate = (rule, value, cb) => {
    const { backgroundType: type, previewImage, background, creative, editable } = this.props;
    const { gradient } = background;
    try {
      if(!creative && !editable) {
        cb();
        return;
      }
      if (type === 'image' && previewImage.length === 0){ 
        throw new Error('Image upload is required.');
      } else if (type === 'gradient' && !gradient) {
        throw new Error('Gradient is required.');
      }
      cb()
    } catch (err) {
      cb(err);
    }
  }
  // Renderers
  render() {
    const { 
      form, 
      setStatus, 
      image, 
      uploadFile, 
      removeFile, 
      previewImage, 
      activity, 
      backgroundType, 
      background,
      creative,
      editable
    } = this.props;
    const { getFieldDecorator } = form;
    const { gradient } = background
    return (
      <div className='activity-form'>
        <Col span={12}>
          <FormContextProvider>
            <Form key="background-form" layout="vertical" colon={false} onFocus={() => { this.props.form.resetFields() }}>
              <Item label="Please select the background type:">
                <div className='question-switch'>
                  <Select disabled={!creative && !editable} defaultValue={backgroundType} style={{width: 120}} onChange={this.onChange}>
                    <Option value='image'>Image</Option>
                    <Option value='gradient'>Gradient</Option>
                  </Select>
                </div>
                <span>{backgroundType === 'image' ? `Please choose an image to upload.
                The image ratio should be 16:9.` : 'Please select two colors for the gradient background.'}</span>
                {getFieldDecorator('background', {
                  rules: [{ required: true, validator: this.validate }],
                  validateTrigger: ['onChange', 'onSubmit', 'onBlur', 'onFocus']
                })(backgroundType === 'image' ? 
                  <ActivityImageUpload creative={creative} editable={editable} image={image} setStatus={setStatus} uploadFile={uploadFile} removeFile={removeFile}/>
                  : <ActivityGradientSelector creative={creative} editable={editable} gradient={gradient} setStatus={setStatus} setGradient={this.setGradient} />)}
              </Item>
            </Form>
          </FormContextProvider>
        </Col>
        <Col span={12}>
          <ActivityPreview creative={creative} editable={editable} backgroundType={backgroundType} imageFile={image} previewImage={previewImage} activity={activity} />
        </Col>
      </div>
    );
  }
}

export default Form.create({ name: 'background-form' })(ActivityBackgroundForm);