import { Card, message, Modal, Table } from 'antd';
import Tabs from 'antd/lib/tabs';
import * as _ from 'lodash';
import moment from 'moment';
import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import SwitchFilter from '../../../components/filter/SwitchFilter';
import TopicBadgeFilter from '../../../components/resources/topics/TopicBadgeFilter';
import SearchPanel from '../../../components/search/SearchPanel';
import TableTitle from '../../../components/table/TableTitle';
import { renderPopoverDate } from '../../../components/util/Util';
import {
  deleteArticleAsync,
  loadArticlesAsync,
  PublishArticleArguments,
  publishArticleAsync,
  unpublishArticleAsync
} from '../../../redux/article/article.types';
import * as selectors from '../../../redux/selectors';
import { loadResourceTopicsAsync } from '../../../redux/topics/topic.types';
import { IApiRequestStatus } from '../../../types/api.types';
import { ArticleType, TopicType } from '../../../types/entity.types';
import IApplicationState from '../../../types/state.types';
import './resourceLandingPage';

const { Column } = Table;
const { confirm } = Modal;

const tableStyle = {
  background: '#FFF'
};

const initialState = {
  selectedTopicId: -1 as number,
  selectedTopicIds: [] as number[],
  includeDeleted: false as boolean,
  isSearched: false as boolean
};
type ComponentState = Readonly<typeof initialState>;

interface StateProps {
  loadResourceTopicsStatus: IApiRequestStatus,
  studyId: number,
  resourceTopics: Optional<TopicType[]>,
  selectedArticles: Optional<ArticleType[]>
}

interface DispatchProps {
  loadResourceTopics: typeof loadResourceTopicsAsync.request,
  loadArticles: typeof loadArticlesAsync.request,
  deleteArticle: typeof deleteArticleAsync.request,
  publishArticle: typeof publishArticleAsync.request,
  unpublishArticle: typeof unpublishArticleAsync.request
}

interface ComponentProps extends StateProps, DispatchProps, RouteComponentProps {}

class ResourcesLandingPage extends Component<ComponentProps, {}> {
  readonly state: ComponentState = initialState;
  componentDidMount(): void {
    const { loadResourceTopics, loadArticles } = this.props;
    loadResourceTopics();
    loadArticles({
      includeDeleted: true,
      includeUnpublished: true
    });
  }
  handleSearchStringChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const { loadArticles } = this.props;
    if(event.target.value.length === 0) {
      this.setState({
        isSearched: false
      });
      loadArticles({});
    }
  };
  handleSearchSubmit = (value: string) => {
    const { loadArticles } = this.props;
    this.setState({
      isSearched: true
    });
    loadArticles({ query: value });
  };
  handleAddResourceClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const { history, studyId } = this.props;
    history.push(`/study/${studyId}/resources/new`);
  };
  handleSelectedTopicsChanged = (selectedTopicIds: number[]) => {
    this.setState({selectedTopicIds})
  };
  deleteArticle = (articleId: number) => {
    const { deleteArticle } = this.props;
    confirm({
      title: 'Are you sure you want to delete this activity?',
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        deleteArticle( articleId );
      },
      onCancel() {}
    });
  };
  copyDeepLink = (id: number) => {
    const deepLink = `hmp://resource/${id}`
    navigator.clipboard.writeText(deepLink).then(function() {
      message.success('Copied to clipboard!');
    }, function() {
      message.error('Failed to copy to clipboard.');
    });
  };
  publishArticle = (id: number) => {
    const { publishArticle } = this.props;
    publishArticle({ id });
  };
  unpublishArticle = (id: number) => {
    const { unpublishArticle } = this.props;
    unpublishArticle(id);
  };
  rowClassName = (record: any, index: number): string => {
    return (index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color') + ' hmp-clickable-row';
  };
  renderActions = (article: ArticleType) => {
    const displayDeleteAction = article.deleteDate === null;
    const displayPublish = article.publishDate === null;
    return <div className='resources-action-container'>
      {displayDeleteAction && <a title='Delete' className='resource-action' onClick={(e) => { e.stopPropagation(); this.deleteArticle(article.id); }}>
        <i className="fal fa-trash-alt fa-lg" />
      </a>}
      <a title='Copy deep link to clipboard' className='resource-action' onClick={(e) => { e.stopPropagation(); this.copyDeepLink(article.id); }}>
        <i className="far fa-link fa-lg"></i>
      </a>
      {displayPublish ?
      <a title='Publish' className='resource-action' onClick={(e) => { e.stopPropagation(); this.publishArticle(article.id); }}>
        <i className="fal fa-calendar-plus fa-lg" />
      </a> :
      <a title='Unpublish' className='resource-action' onClick={(e) => { e.stopPropagation(); this.unpublishArticle(article.id); }}>
        <i className="fal fa-calendar-times fa-lg" />
      </a>}
    </div>
  }
  toggleIncludeDeleted = (includeDeleted: boolean) => {
    this.setState({ includeDeleted });
  };
  stringAsDateSorter = (a: string | null, b: string | null) => {
    if (!a && !b) return 0;
    if (a === undefined) return -1;
    if (b === undefined) return 1;
    if (a === null) return -1;
    if (b === null) return 1;
    return moment(a).unix() - moment(b).unix();
  };
  onRow = (row: ArticleType) => {
    const { history, studyId } = this.props;
    return {
      onClick: (event: React.MouseEvent) => {
        event.stopPropagation();
        history.push(`/study/${studyId}/resources/edit/${row.id}`);
      }
    }
  };
  renderCover = (coverUrl: string, row: ArticleType) => {
    if (coverUrl){
      return <img alt={`${row.title} cover image`} src={coverUrl} width="100px" />
    }
  };
  renderTopicLabel = (topicId: number) => {
    const { resourceTopics } = this.props;
    if(!resourceTopics) {
      return 'UNKNOWN';
    }
    for (const topic of resourceTopics) {
      if (topic.id === topicId) {
        return topic.title;
      }
      if (topic.children && topic.children.length > 0) {
        for (const child of topic.children) {
          if (child.id === topicId) {
            return `${topic.title} : ${child.title}`;
          }
        }
      }
    }
  };
  caseInsensitiveTextSorter = (a: string, b: string) => {
    if (a === undefined) return 1;
    if (b === undefined) return -1;
    if (a === null) return 1;
    if (b === null) return -1;
    return a.toLowerCase().localeCompare(b.toLowerCase());
  };
  titleSorter = (a: ArticleType, b: ArticleType) => this.caseInsensitiveTextSorter(a.title, b.title);
  summarySorter = (a: ArticleType, b: ArticleType) => this.caseInsensitiveTextSorter(a.summary, b.summary);
  topicSorter = (a: ArticleType, b: ArticleType) => this.caseInsensitiveTextSorter(a.topic ? a.topic.title : null, b.topic ? b.topic.title : null);
  createDateSorter = (a: ArticleType, b: ArticleType) => this.stringAsDateSorter(a.createDate, b.createDate);
  publishDateSorter = (a: ArticleType, b: ArticleType) => this.stringAsDateSorter(a.publishDate, b.publishDate);
  lastUpdateDateSorter = (a: ArticleType, b: ArticleType) => this.stringAsDateSorter(a.lastUpdateDate, b.lastUpdateDate);
  deleteDateSorter = (a: ArticleType, b: ArticleType) => this.stringAsDateSorter(a.deleteDate, b.deleteDate);
  render() {
    const { loadResourceTopicsStatus, resourceTopics, selectedArticles } = this.props;
    const { selectedTopicIds, includeDeleted, isSearched } = this.state;
    if (loadResourceTopicsStatus.isLoading) {
      return <div>Loading Resources</div>;
    }
    if (loadResourceTopicsStatus.isError) {
      return <div>Error loading the resource topics: {loadResourceTopicsStatus.errorMessage}</div>
    }
    if (resourceTopics === undefined) {
      return <div>No Resource Topics are defined; by default UNKNOWN should at least be loaded.</div>
    }
    const filteredArticles: Optional<ArticleType[]> =
      _.filter(selectedArticles, (article: ArticleType) => {
        if (article.deleteDate) return false || includeDeleted;
        if (selectedTopicIds?.length) {
          return !!_.find(selectedTopicIds, (id:number) => article.topicId === id);
        }
        return true;
      });
    return (
      <div id="hmp-resources-landing-page">
        <div className="hmp-tab-container">
          <Tabs defaultActiveKey="resources">
            <Tabs.TabPane tab="Resources" key="resources">
              <Card
                title={<TableTitle icon='plus' buttonLabel='Add Resource' title='Resources' onClick={this.handleAddResourceClick} />}
              >
                <SearchPanel
                  filters={[
                    <TopicBadgeFilter topics={resourceTopics} selectedTopicsChanged={this.handleSelectedTopicsChanged} />,
                    <SwitchFilter value={includeDeleted} label='Show Deleted' onChange={this.toggleIncludeDeleted} />
                  ]}
                  searchChangeHandler={this.handleSearchStringChange}
                  searchSubmitHandler={this.handleSearchSubmit}
                />
                <Table
                  pagination={false}
                  dataSource={filteredArticles}
                  rowKey={row => `row-${row.id}`}
                  style={tableStyle}
                  onRow={this.onRow}
                  rowClassName={this.rowClassName}
                  scroll={{ y: 500 }}
                  key={`resource-table-${isSearched}`}
                >
                  <Column title="ID" dataIndex="id" key="id" />
                  <Column title="Cover" dataIndex="cover" key="cover" render={this.renderCover} width="100" />
                  <Column title="Title" dataIndex="title" key="title" sorter={this.titleSorter} />
                  <Column title="Summary" dataIndex="summary" key="summary" sorter={this.summarySorter} />
                  <Column title="Topic" dataIndex="topicId" key="topicId" render={this.renderTopicLabel} width="240px" sorter={this.topicSorter} />
                  <Column title="Create Date" dataIndex="createDate" key="createDate" render={renderPopoverDate} width="120px" sorter={this.createDateSorter} />
                  <Column title="Publish Date" dataIndex="publishDate" key="publishDate" render={renderPopoverDate} width="120px" sorter={this.publishDateSorter} />
                  <Column title="Date Modified" dataIndex="lastUpdateDate" key="lastUpdateDate" render={renderPopoverDate} width="120px"
                          defaultSortOrder={isSearched ? undefined : 'descend'}
                          sorter={this.lastUpdateDateSorter} />
                  {
                    includeDeleted ?
                      <Column title="Delete Date" dataIndex="deleteDate" key="deleteDate" render={renderPopoverDate}
                              width="120px"
                              sorter={this.deleteDateSorter}/>
                      : undefined
                  }
                  <Column title="Actions" render={this.renderActions} />
                </Table>
              </Card>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Topics" key="topics">
              <div>
                <div>
                  Placeholder for Topic management.
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Images" key="images">
              <div>
                <div>
                  Placeholder for Image management.
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>

      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>( {
  loadResourceTopicsStatus: selectors.loadResourceTopicsStatus,
  studyId: selectors.getRequestedStudyId,
  resourceTopics: selectors.getResourceTopics,
  selectedArticles: selectors.getSelectedArticles,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadResourceTopics: () => dispatch(loadResourceTopicsAsync.request()),
    loadArticles: (param) => dispatch(loadArticlesAsync.request(param)),
    deleteArticle: (resourceId: number) => dispatch(deleteArticleAsync.request(resourceId)),
    publishArticle: (args: PublishArticleArguments) => dispatch(publishArticleAsync.request(args)),
    unpublishArticle: (id: number) => dispatch(unpublishArticleAsync.request(id))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResourcesLandingPage));
