import axios from "../api";
import {AxiosResponse} from "axios";
import {all, call, fork, put, takeEvery} from "@redux-saga/core/effects";
import {normalize} from "normalizr";
import { getParticipantCommentsAsync, updateComments } from '../comments/comments.types';
import { loadDashboardDataAsync } from '../dashboard/dashboard.types';
import { getFlaggedPostsAsync, updatePosts } from '../posts/posts.types';
import {entitySchema} from "../schema";
import {NormalizerResult} from "../../types/entity.types";
import { SocialJsonParamType } from '../user/socialUtil';
import {
  getFlagsAsync,
  hideContentAsync,
  HideContentPayloadType,
  resolveFlagsAsync,
  unhideContentAsync
} from "./flags.types";
import {getType} from "typesafe-actions";

const getFlags = (jsonFlags:SocialJsonParamType[]) => {
  const param = JSON.stringify(jsonFlags);
  return axios({
    method: 'get',
    url: `/a/social/flags?flagsJson=${encodeURIComponent(param)}`
  });
};

function* getFlagsHandler(action: ReturnType<typeof getFlagsAsync.request>): Generator {

  try {
    const { payload } = action;
    const response: AxiosResponse = <AxiosResponse> (yield call(getFlags, payload));
    const { entities } = normalize(response.data, entitySchema.flags) as NormalizerResult;
    const { flags } = entities;

    yield put(getFlagsAsync.success(flags));
  } catch (error) {
    yield put(getFlagsAsync.failure(error));
  }
}

function* getFlagsWatcher() {
  yield takeEvery(getType(getFlagsAsync.request), getFlagsHandler);
}

const resolveFlags = (flagResolution: {ids: number[], resolution: string}) => {
  return axios({
    method: 'post',
    url: `/a/social/flag/resolve`,
    data: flagResolution
  });
};

function* resolveFlagsHandler(action: ReturnType<typeof resolveFlagsAsync.request>): Generator {

  try {
    const flagResolution: any = action.payload;
    const response: AxiosResponse = <AxiosResponse> (yield call(resolveFlags, flagResolution));
    const { entities } = normalize(response.data, entitySchema.flags) as NormalizerResult;
    const { flags } = entities;

    yield put(loadDashboardDataAsync.request());
    yield put(getFlaggedPostsAsync.request());

    yield put(resolveFlagsAsync.success(flags));
  } catch (error) {
    yield put(resolveFlagsAsync.failure(error));
  }
}

function* resolveFlagsWatcher() {
  yield takeEvery(getType(resolveFlagsAsync.request), resolveFlagsHandler);
}

const hideContent = (payload:HideContentPayloadType) => {
  return axios({
    method: 'put',
    url: `/a/forum/hide/${payload.type}/${payload.typeId}`
  });
};

function* hideContentHandler(action: ReturnType<typeof hideContentAsync.request>): Generator {

  try {
    const payload:any = action.payload;
    const response: AxiosResponse = <AxiosResponse> (yield call(hideContent, payload));
    if ('comment' === payload.type) {
      const { entities } = normalize([response.data], entitySchema.comments) as NormalizerResult;
      const { comments } = entities;
      yield put(updateComments(comments));
    }
    else if ('post' === payload.type) {
      const { entities } = normalize(response.data, entitySchema.posts) as NormalizerResult;
      const { posts } = entities;
      yield put(updatePosts(posts));
    }
    yield put(loadDashboardDataAsync.request());
  } catch (error) {
    yield put(hideContentAsync.failure(error));
  }
}

function* hideContentWatcher() {
  yield takeEvery(getType(hideContentAsync.request), hideContentHandler);
}

const unhideContent = (payload:HideContentPayloadType) => {
  return axios({
    method: 'put',
    url: `/a/forum/unhide/${payload.type}/${payload.typeId}`
  });
};

function* unhideContentHandler(action: ReturnType<typeof unhideContentAsync.request>): Generator {

  try {
    const payload:any = action.payload;
    const response: AxiosResponse = <AxiosResponse> (yield call(unhideContent, payload));
    if ('comment' === payload.type) {
      const { entities } = normalize([response.data], entitySchema.comments) as NormalizerResult;
      const { comments } = entities;
      yield put(updateComments(comments));
    }
    else if ('post' === payload.type) {
      const { entities } = normalize(response.data, entitySchema.posts) as NormalizerResult;
      const { posts } = entities;
      yield put(updatePosts(posts));
    }
  } catch (error) {
    yield put(unhideContentAsync.failure(error));
  }
}

function* unhideContentWatcher() {
  yield takeEvery(getType(unhideContentAsync.request), unhideContentHandler);
}

export default function* flagSaga() {
  yield all([fork(getFlagsWatcher)]);
  yield all([fork(resolveFlagsWatcher)]);
  yield all([fork(hideContentWatcher)]);
  yield all([fork(unhideContentWatcher)]);
}
