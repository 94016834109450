import { Button, Col, Icon, List, message, Modal, Select, Switch, Typography } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import React, { Component, ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import uuid from 'uuid';
import Toolbar from '../../../components/inbox/Toolbar';
import QnaDetailCard from '../../../components/qna/QnaDetailCard';
import QnaFormContainer from '../../../components/qna/QnaFormContainer';
import SearchInput from '../../../components/search/SearchInput';
import Username from '../../../components/username/Username';
import { renderPopoverDate, renderPopoverDateFromNow } from '../../../components/util/Util';
import { clearStatus } from '../../../redux/api/api.types';
import { resetRecentActivitySelectedQnaPostId } from '../../../redux/forum/forum.types';
import {
  clearQnaPosts,
  deleteQnaPostAsync,
  getFlaggedQnaContentAsync,
  getQnaPostAsync,
  GetQnaPostsArguments,
  getQnaPostsAsync
} from '../../../redux/qna/qna.types';
import * as selectors from '../../../redux/selectors';
import { loadQnaTopicsAsync } from '../../../redux/topics/topic.types';
import { IApiRequestStatus } from '../../../types/api.types';
import { AdminUserType, QnaPostType, StudyArmType } from '../../../types/entity.types';
import IApplicationState from '../../../types/state.types';
import './qna.scss';

const { Option } = Select;
const { Item } = List;
const { confirm } = Modal;
const { Paragraph } = Typography;

const IconText = ({ type, text }) => (
  <span className='hmp-qna-icon'>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);

const previewLength: number = Number.MAX_SAFE_INTEGER;

interface StateProps {
  studyId: number,
  qnaPosts: Optional<QnaPostType[]>,
  arms: Optional<StudyArmType[]>,
  loadQnaPostsStatus: IApiRequestStatus,
  recentActivitySelectedQnaPostId: Optional<number>;
  experts: Optional<AdminUserType[]>;
}

interface DispatchProps {
  clearQnaPosts: typeof clearQnaPosts,
  loadQnaPosts: typeof getQnaPostsAsync.request,
  loadQnaPostById: typeof getQnaPostAsync.request,
  loadQnaTopics: typeof loadQnaTopicsAsync.request,
  loadFlaggedQnaContent: typeof getFlaggedQnaContentAsync.request,
  deleteQnaPost: typeof deleteQnaPostAsync.request,
  clearLoadStatus: typeof clearStatus,
  resetActivitySelectedQnaPostId: typeof resetRecentActivitySelectedQnaPostId
}

interface ComponentProps extends StateProps, DispatchProps {}

class QnaLandingPage extends Component<ComponentProps, {}> {
  readonly state = {
    currentId: -1,
    searchTerm: '',
    showDeleted: false,
    showOnlyUnanswered: false,
    showOnlyFlagged: false,
    pageSize: 10,
    pageNumber: 0,
    viewForm: false,
    selectedExpertId: undefined as number
  };

  private infiniteScrollContainerKey:string = '';

  constructor(props: ComponentProps) {
    super(props);
  }

  componentDidMount() {
    const { studyId, loadQnaPosts, loadQnaTopics} = this.props;
    const { pageSize } = this.state;
    this.reinitializeAndSearch();
    loadQnaTopics();
  }

  componentWillReceiveProps(prevProps: ComponentProps) {
    const { recentActivitySelectedQnaPostId, loadQnaPostById } = this.props;
    if(recentActivitySelectedQnaPostId) {
      loadQnaPostById(recentActivitySelectedQnaPostId);
      this.setState({ currentId: recentActivitySelectedQnaPostId });
    }
  }

  allcaps = (s: string): string => s.toUpperCase();

  search = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: e.target.value });
  };

  debouncedHandleSearchChange:any = undefined;
  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.debouncedHandleSearchChange) {
      this.debouncedHandleSearchChange = _.debounce(() => {
          this.reinitializeAndSearch();
        } , 600);
    }

    this.setState({ searchTerm: event.target.value, showOnlyFlagged: false });
    this.debouncedHandleSearchChange();
  };

  reinitializeAndSearch = () => {
    const { studyId, loadQnaPosts, clearQnaPosts } = this.props;
    const { pageSize, searchTerm, showDeleted, showOnlyUnanswered } = this.state;
    this.infiniteScrollContainerKey = uuid();
    clearQnaPosts();
    loadQnaPosts({ studyId, pageNumber:0, query: searchTerm, pageSize, previewLength, includeDeleted: showDeleted, answers: showOnlyUnanswered ? 'unansweredOnly' : 'all' });
    this.setState({ loading: true, pageNumber: 0});
  }

  onShowDeletedChange = (checked: boolean) => {
    this.setState({ showDeleted: checked, showOnlyFlagged: false });
    setTimeout( this.reinitializeAndSearch, 5);
  };

  handleInfiniteLoad = (pageNumber: number) => {
    const { studyId, loadQnaPosts } = this.props;
    const { pageSize, searchTerm, showOnlyUnanswered, showDeleted } = this.state;

    this.setState({ pageNumber });
    loadQnaPosts({ studyId, query: searchTerm, pageNumber, pageSize, previewLength, includeDeleted: showDeleted, answers: showOnlyUnanswered ? 'unansweredOnly' : 'all' });
  };

  copyDeepLink = (id: number) => {
    const deepLink = `hmp://qna/${id}`;
    navigator.clipboard.writeText(deepLink).then(function() {
      message.success('Copied to clipboard!');
    }, function() {
      message.error('Failed to copy to clipboard.');
    });
  };

  displayQnaPost = (currentId: number) => {
    const { loadQnaPostById, resetActivitySelectedQnaPostId } = this.props;
    if(this.state.currentId !== currentId) {
      resetActivitySelectedQnaPostId();
      loadQnaPostById(currentId);
      this.setState({ currentId });
    }
  };

  onShowOnlyFlaggedChange = (checked: boolean) => {
    this.setState({ showOnlyFlagged: checked, });
    if(checked) {
      const { loadFlaggedQnaContent, clearQnaPosts, studyId } = this.props;
      this.infiniteScrollContainerKey = uuid();
      clearQnaPosts();
      loadFlaggedQnaContent(studyId);
      this.setState( { showOnlyUnanswered: false, showDeleted: false, searchTerm: '' });
    }
    else {
      this.reinitializeAndSearch();
    }
  };

  onShowOnlyUnansweredChange = (checked: boolean) => {
    this.setState({ showOnlyUnanswered: checked, showOnlyFlagged: false });
    setTimeout( this.reinitializeAndSearch, 5);
  };

  showQnaForm = () => {
    this.setState({ viewForm: true });
  };

  closeQnaForm = () => {
    this.setState({ viewForm: false });
  };

  deletePost = (id: number) => {
    const { currentId } = this.state;
    const { deleteQnaPost } = this.props;
    const setState = this.setState.bind(this);
    confirm({
      title: `Are you sure you want to delete this post?`,
      content: '',
      okText: 'Confirm',
      onOk() {
        if(currentId === id) {
          setState({ currentId: -1 });
        }
        deleteQnaPost(id);
      },
      onCancel() {}
    });
  };

  onSelectedExpertIdChange = (id: number) => {
    this.setState({
      selectedExpertId: id
    });
  };

  render() {
    const {
      currentId,
      searchTerm,
      showDeleted,
      showOnlyUnanswered,
      showOnlyFlagged,
      pageNumber,
      pageSize,
      viewForm,
      selectedExpertId
    } = this.state;
    const {
      qnaPosts,
      loadQnaPostsStatus,
      experts
    } = this.props;

    let sortedQnaPosts = (qnaPosts || []).sort((a: QnaPostType, b: QnaPostType) => moment(a.createDate).unix() - moment(b.createDate).unix()).reverse();
    if(selectedExpertId && selectedExpertId > -1) {
      sortedQnaPosts = sortedQnaPosts.filter(post => post.adHocNotifications.some(notifications => notifications.userId === selectedExpertId));
    }

    let currentPost = undefined;
    if(currentId > -1) {
      currentPost = _.find(sortedQnaPosts, post => post.id === currentId);
    }

    const hasMore = (1+pageNumber) * pageSize === sortedQnaPosts.length;

    return <div id="hmp-forum-page">
      {viewForm && <QnaFormContainer visible={viewForm} closeHandler={this.closeQnaForm} />}
      <div className="hmp-qna-page-tab" key={this.infiniteScrollContainerKey}>
        <Col span={11} className='hmp-qna-col'>
          <div className='hmp-qna-list-header'>
            <div className="hmp-qna-list-toolbar">
              <Toolbar
                title='Ask an Expert'
                leftItems={[
                ]}
                rightItems={[
                  <Button key="createNewButton" type='primary' onClick={(e) => { e.stopPropagation(); this.showQnaForm(); }}>+ Add Post</Button>
                ]} />
            </div>
            <SearchInput
              className='search-bar'
              id="search-input"
              onChangeHandler={this.handleSearchChange}
              placeholder="Search Posts"
              value={searchTerm}
            />
            <div className='filter-items'>
              <span className='filter'>Show Only Flagged <Switch checked={showOnlyFlagged} onChange={this.onShowOnlyFlaggedChange} /></span>
              <span className='filter'>Show Deleted <Switch checked={showDeleted} onChange={this.onShowDeletedChange} /></span>
              <span className='filter'>Show Only Unanswered <Switch checked={showOnlyUnanswered} onChange={this.onShowOnlyUnansweredChange} /></span>
            </div>
            <div className='dropdown-container'>
              <span className='dropdown'>Assigned To: <Select allowClear className='dropdown-box' placeholder='Select an expert' value={selectedExpertId} onChange={this.onSelectedExpertIdChange}>{experts?.map(expert => <Option key={`expert-option-${expert.id}`} value={expert.id}>{expert.firstName + " " + expert.lastName}</Option>)}</Select></span>
            </div>
          </div>
          <div id='infinite-scroll-container'>
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={this.handleInfiniteLoad}
              hasMore={hasMore}
              useWindow={false}
            >
              <List
                bordered
                itemLayout='vertical'
                size='large'
                dataSource={sortedQnaPosts}
                renderItem={(item: QnaPostType) => {
                  const {
                    id,
                    favorites,
                    thumbsups,
                    comments,
                    topic,
                    question,
                    answer,
                    createDate,
                    username,
                    createdByParticipantId,
                    answeredByUsername,
                    deleteDate
                  } = item;

                  const commentCount: number = comments ? comments.length + comments.map(c => c.comments.length).reduce((sum, c) => sum + c, 0) : 0;

                  let actions: ReactNode[] = [];
                  // if (username) {
                  //   actions.push(<Username participantId={createdByParticipantId} />)
                  // }



                  actions = actions.concat([
                    <span className='hmp-qna-username'><Username participantId={createdByParticipantId}/></span>,
                    <span className='hmp-qna-date'>{renderPopoverDateFromNow(createDate)}</span>,
                    <IconText type="message" text={commentCount} key="list-vertical-message" />,
                    <IconText type="like-o" text={thumbsups ? thumbsups.length : 0} key="list-vertical-like-o" />,
                    <IconText type="star-o" text={favorites ? favorites.length : 0} key="list-vertical-star-o" />,
                    <a title='Copy deep link to clipboard' className='qna-action' onClick={(e) => { e.stopPropagation(); this.copyDeepLink(id); }}>
                      <i className="far fa-link"></i>
                    </a>
                  ]);
                  let tags = [];
                  if(deleteDate) {
                    tags.push(<span title={renderPopoverDate(deleteDate)} className='post-tag-bubble deleted'>DELETED</span>);
                  }
                  return (<Item
                    key={`qna-post-${id}`}
                    onClick={(e) => this.displayQnaPost(id)}
                    actions={actions}
                  >
                    <Item.Meta
                      title={ <Paragraph style={{color: 'black'}} ellipsis={{rows: 2, expandable: false}}>{"Q: " + question}</Paragraph>}
                    />
                      <Paragraph className='qna-post-answer'
                        ellipsis={{rows: 2, expandable: false}}>
                        {answer ?
                          <div className="answerContainer">
                            A: {answer}
                            <div className="signatureContainer">
                              <p className="signature">- {answeredByUsername}</p>
                            </div>
                          </div>
                          :
                          "(Unanswered)"
                        }
                      </Paragraph>
                    <div className='hmp-qna-topic-container'>
                      {tags}
                      <span className='hmp-qna-topic'>{this.allcaps(topic)}</span>
                    </div>
                  </Item>);
              }}
              />
            </InfiniteScroll>
          </div>
        </Col>
        <Col span={11} className={currentId === -1 ? 'hmp-qna-col-empty' : 'hmp-qna-col-post'}>
          {currentPost && <QnaDetailCard post={currentPost} deleteQnaPost={this.deletePost} />}
        </Col>
      </div>
    </div>;
  }


}

const mapStateToProps = (state: IApplicationState) => {
  return {
    qnaPosts: selectors.getQnaPosts(state),
    studyId: selectors.getRequestedStudyId(state),
    arms: selectors.getRequestedStudyStudyArms(state),
    loadQnaPostsStatus: selectors.loadQnaPostsStatus(state),
    // postCount: selectors.getQnaPostCount(state),
    recentActivitySelectedQnaPostId: state.ui.forums.recentActivity.selectedQnaPostId,
    experts: selectors.getExpertAdmins(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadQnaPosts: (args: GetQnaPostsArguments) => dispatch(getQnaPostsAsync.request(args)),
    // loadQnaPostCount: (studyId: number) => dispatch(getQnaPostCountAsync.request(studyId)),
    loadQnaPostById: (id: number) => dispatch(getQnaPostAsync.request(id)),
    loadQnaTopics: () => dispatch(loadQnaTopicsAsync.request()),
    loadFlaggedQnaContent: (studyId: number) => dispatch(getFlaggedQnaContentAsync.request(studyId)),
    deleteQnaPost: (id: number) => dispatch(deleteQnaPostAsync.request(id)),
    clearLoadStatus: (type: string) => dispatch(clearStatus(type)),
    clearQnaPosts: () => dispatch(clearQnaPosts()),
    resetActivitySelectedQnaPostId: () => dispatch(resetRecentActivitySelectedQnaPostId())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QnaLandingPage);