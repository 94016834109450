import toArray from "lodash/toArray";
import { denormalize } from "normalizr";
import { createSelector } from "reselect";
import { ConsentStatusType, ParticipantType } from "../../types/entity.types";
import IApplicationState from "../../types/state.types";
import entitySchema from "../schema";

export const selectParticipants = (state: IApplicationState) => {
  return state.entities.participants;
};

export const getParticipants = createSelector(
  [selectParticipants],
  (participants): Optional<ParticipantType[]> => {
    if(!participants) {
      return undefined;
    }
    const { participants: denormalizedParticipants } = denormalize({ participants: participants.allIds }, entitySchema, { participants: participants.byId });
    return toArray(denormalizedParticipants);
  }
)

export const selectConsentStatuses = (state: IApplicationState) => {
  return state.entities.consentStatuses;
}

export const getConsentStatuses = createSelector(
  [selectConsentStatuses],
  (consentStatuses): Optional<ConsentStatusType[]> => {
    if (!consentStatuses) {
      return undefined
    }
    const { consentStatuses: denormalizedConsentStatuses } = denormalize({ consentStatuses: consentStatuses.allIds }, entitySchema, { consentStatuses: consentStatuses.byId });
    return toArray(denormalizedConsentStatuses);
  }
)

