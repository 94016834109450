import { Spin } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { Component, RefObject } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { SendMessageArguments, sendMessageAsync } from '../../redux/messages/messages.types';
import * as selectors from '../../redux/selectors';
import { IApiRequestState } from '../../types/api.types';
import { MessageResponseType, MessageThreadType, MessageType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import Username from '../username/Username';
import ComposeMessage from './ComposeMessage';
import EmptyMessageList from './EmptyMessageList';
import Message from './Message';
import './thread.scss';
import Toolbar from './Toolbar';

interface StateProps {
  getMessagesStatus: IApiRequestState;
  threads: Optional<MessageThreadType[]>;
}

interface DispatchProps {
  sendMessage: typeof sendMessageAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  userId: number;
  response: MessageResponseType;
  getMessages: (pageSize: number, pageNumber: number) => void;
}

class MessageList extends Component<ComponentProps, {}> {
  readonly state = {
    hasMore: false,
    loading: false,
    pageSize: 16,
    pageNumber: 0,
    scrollToBottom: false
  };
  private messageListBottomRef: RefObject<any>;
  constructor(props: ComponentProps) {
    super(props);
    this.messageListBottomRef = React.createRef();
  }
  componentDidMount() {
    const {
      response
    } = this.props;
    this.scrollToBottomMessageList();
    if(response && response.messages.length < response.messageCount) {
      this.setState({ hasMore: true });
    }
  }
  componentDidUpdate(prevProps: ComponentProps) {
    const { scrollToBottom } = this.state;
    const { response } = this.props;
    const { response: prevResponse } = prevProps;
    if(scrollToBottom && response.messages.length > prevResponse.messages.length) {
      this.scrollToBottomMessageList(true);
    }
  }
  componentWillReceiveProps(prevProps: ComponentProps) {
    const {
      hasMore,
      pageNumber,
      pageSize
    } = this.state;
    const {
      getMessagesStatus,
      response
    } = this.props;
    const {
      messages,
      messageCount
    } = response;
    if(hasMore) {
      if(getMessagesStatus.isSuccess) {
        this.setState({ loading: false });
      }
      if(messages.length === messageCount) {
        this.setState({ hasMore: false, loading: false });
      }
      if(pageNumber > Math.ceil(messageCount / pageSize)) {
        this.setState({ pageNumber: 0 });
      }
    } else {
      if(messages && messages.length < messageCount) {
        this.setState({ hasMore: true, loading: false });
      }
    }
  }

  renderMessages = () => {
    const { response: { messages }, userId } = this.props;
    let processedMessages = _.clone(messages);
    let i = 0;
    let messageCount = processedMessages.length;
    let tempMessages = [];
    processedMessages = processedMessages.sort((a: MessageType, b: MessageType) => moment(a.createDate).unix() - moment(b.createDate).unix());

    while (i < messageCount) {
      let previous = processedMessages[i - 1];
      let current = processedMessages[i];
      let next = processedMessages[i + 1];
      let isMine = current.userId === userId || current.fromAdmin;
      let currentMoment = moment(current.createDate);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.createDate);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.userId === current.userId;

        if (prevBySameAuthor && previousDuration.as('hours') < 0.5) {
          startsSequence = false;
        }

        if (previousDuration.as('hours') < 0.5) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.createDate);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.userId === current.userId;

        if (nextBySameAuthor && nextDuration.as('hours') < 0.5) {
          endsSequence = false;
        }
      }
      tempMessages.push(
        <Message
          key={i}
          message={current}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
        />
      );
      i++;
    }
    return tempMessages;
  }

  handleInfiniteLoadMessages = (page: number) => {
    const { getMessagesStatus, getMessages } = this.props;
    const { hasMore, pageSize, pageNumber } = this.state;
    this.setState({ loading: true });
    if(getMessagesStatus.isError || !hasMore) {
      this.setState({
        hasMore: false,
        loading: false
      });
      return;
    }
    getMessages(pageSize, pageNumber);
    this.setState({ pageNumber: pageNumber + 1 });
  };

  scrollToBottomMessageList = (smooth: boolean = false) => {
    if(this.messageListBottomRef.current) {
      this.messageListBottomRef.current.scrollIntoView(!smooth ? { block: "end", inline: "nearest" } : { behavior: "smooth", block: "end", inline: "nearest" });
    }
    this.setState({ scrollToBottom: false });
  };

  handleSubmit = (input: string) => {
    const { response, sendMessage, getMessages } = this.props;
    const { pageSize } = this.state;
    const { threadId } = response;
    sendMessage({ threadId, body: input });
    getMessages(pageSize, 0);
    this.setState({ scrollToBottom: true });
  };

  getMessages = () => {
    const { pageSize } = this.state;
    this.props.getMessages(pageSize, 0)
  }

  render() {
    const {
      response,
      getMessagesStatus,
      threads
    } = this.props;
    const {
      loading,
      hasMore
    } = this.state;
    const { title } = response;

    const thread = _.find(threads, t => t.id === response.threadId);

    return (
      <div className='message-column'>
        <div className="hmp-message-column-toolbar">
          <Toolbar title={title} rightItems={[
            <span style={{marginRight: '25px'}} key={thread?.participantId}><Username participantId={thread?.participantId} /> </span>
          ]} />
        </div>
        <div className='message-list'>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={this.handleInfiniteLoadMessages}
            hasMore={hasMore && !loading && !getMessagesStatus.isLoading}
            useWindow={false}
            isReverse={true}
            threshold={100}
          >
            {hasMore && loading &&
            <div className='hmp-inbox-loading-container'>
              <Spin />
            </div>}
            <div className='message-list-container'>
              { response ? this.renderMessages() : <EmptyMessageList />}
              <div ref={this.messageListBottomRef} />
            </div>
          </InfiniteScroll>
        </div>
        <ComposeMessage sendMessage={this.handleSubmit} getMessages={this.getMessages} threadId={response.threadId} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    sendMessage: (args: SendMessageArguments) => dispatch(sendMessageAsync.request(args))
  };
};

const mapStateToProps = (state: IApplicationState): StateProps => {
  return {
    getMessagesStatus: selectors.getMessagesStatus(state),
    threads: selectors.getMessageThreads(state)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);