import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { IApiRequestState } from '../types/api.types';
import { UserType } from '../types/entity.types';
import IApplicationState from '../types/state.types';
import * as selectors from '../redux/selectors';
import { getUserAsync } from '../redux/user/user.types';
import LandingPage from '../pages/auth/LandingPage';
import AdminRouter from './AdminRouter';
import StudyRouter from './StudyRouter';

interface StateProps {
  authenticatedUserId: Optional<number>,
  userLoadingStatus: IApiRequestState,
  user: Optional<UserType>
}

interface DispatchProps {
  getUser: typeof getUserAsync.request
}

interface ComponentProps extends StateProps, DispatchProps {}

class Router extends React.Component<ComponentProps, {}> {

  componentDidMount() {

    const { authenticatedUserId, user, getUser } = this.props;
    if (authenticatedUserId && !user) {
      getUser(authenticatedUserId);
    }
  }

  render() {

    const { userLoadingStatus, user } = this.props;

    if (userLoadingStatus.isLoading) {
      return <div>Loading...</div>;
    }

    if (userLoadingStatus.isError) {
      if (userLoadingStatus.errorStatus === 403) {
        return <div>Forbidden</div>;
      }
      return <div>Error</div>;
    }

    if (!user) {
      return <div>The user could not be loaded.</div>;
    }

    return (
      <Switch>
        <Route path="/admin" component={AdminRouter} />
        <Route path="/study" component={StudyRouter} />
        <Route component={LandingPage} />
      </Switch>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  authenticatedUserId: selectors.getAuthenticatedUserId,
  userLoadingStatus: selectors.getUserLoadingStatus,
  user: selectors.getUser
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getUser: (id: number) => dispatch(getUserAsync.request(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
