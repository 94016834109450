import { Button, Dropdown, Icon, List, Menu, message, Popover, Tooltip } from 'antd';
import React, { Component, ReactNode, RefObject } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { sendAdHocAdminQnaNotificationAsync } from '../../redux/notification/notification.types';
import * as selectors from '../../redux/selectors';
import { AdminUserType, QnaPostType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import Username from '../username/Username';
import { renderDateWithTime, renderPopoverDateFromNow } from '../util/Util';
import './newQnaPostForm.scss';
import QnaCommentList from './QnaCommentList';
import QnaFormContainer from './QnaFormContainer';

const { Item } = List;
const IconText = ({ type, text }) => (
  <span className='hmp-qna-icon'>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);

interface StateProps {
  experts: AdminUserType[];
  studyId: number;
}

interface DispatchProps {
  sendAdHocAdminQnaNotification: typeof sendAdHocAdminQnaNotificationAsync.request
}

interface ComponentProps extends StateProps, DispatchProps {
  post: QnaPostType;
  deleteQnaPost: (id: number) => void;
}

class QnaDetailCard extends Component<ComponentProps, {}> {
  private froalaRef: RefObject<any>;
  readonly state = {
    viewForm: false,
    isNotificationPopupVisible: false
  };
  constructor(props: ComponentProps) {
    super(props);
    this.froalaRef = React.createRef();
  }

  allcaps = (s: string): string => s.toUpperCase();
  copyDeepLink = (id: number) => {
    const deepLink = `hmp://qna/${id}`;
    navigator.clipboard.writeText(deepLink).then(function() {
      message.success('Copied to clipboard!');
    }, function() {
      message.error('Failed to copy to clipboard.');
    });
  };
  deletePost = () => {
    const { post, deleteQnaPost } = this.props;
    if(post.id) {
      deleteQnaPost(post.id);
    }
  };
  showQnaForm = () => {
    this.setState({ viewForm: true });
  };
  closeQnaForm = () => {
    this.setState({ viewForm: false });
  };
  private handleNotificationPopoverVisibleChange(visible: boolean) {
    this.setState({isNotificationPopupVisible: visible });
  }
  private sendAdHocNotification(userId: number) {
    const { id:qnaPostId } = this.props.post;
    const { sendAdHocAdminQnaNotification } = this.props;

    if (qnaPostId) {
      sendAdHocAdminQnaNotification({userId, qnaPostId});
    }

    this.setState({isNotificationPopupVisible: false });
  }
  showAdHocNotificationPopover = () => {
    this.setState({
      isNotificationPopupVisible: true
    });
  }
  handleActionsDropdownVisibleChange = (flag: boolean) => {
    if(!flag) {
      this.setState({
        isNotificationPopupVisible: flag
      });
    }
  };

  render() {
    const { viewForm } = this.state;
    const { post, experts } = this.props;

    return <div id="hmp-qna-detail-card">
      {viewForm && <QnaFormContainer visible={viewForm} closeHandler={this.closeQnaForm} post={post} />}
      <List
        bordered
        itemLayout='vertical'
        size='large'
        dataSource={[post]}
        renderItem={(item: QnaPostType) => {
          const {
            id,
            favorites,
            thumbsups,
            comments,
            topic,
            question,
            answer,
            answerHtml,
            answeredByUsername,
            createdByParticipantId,
            createDate,
            adHocNotifications,
            deleteDate
          } = item;
          const commentCount: number = comments ? comments.length + comments.map(c => c.comments.length).reduce((sum, c) => sum + c, 0) : 0;
          const sendAdHocNotificationPopoverContent =
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', marginBottom: '10px'}}>
              {...experts.map(e => {
                return <div key={e.id} style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', marginTop: '10px'}}>
                  <Button style={{width: '90%'}} onClick={() => this.sendAdHocNotification(e.id)}><Icon type="mail" /> {e.username} </Button>
                </div>})
              }
            </div>;
          let actions:ReactNode[] = [];
          const sendAdHocNotificationPopover = (
            <Popover
              content={sendAdHocNotificationPopoverContent}
              title="Notify Expert of this post"
              trigger="click"
              visible={this.state.isNotificationPopupVisible}
              onVisibleChange={this.handleNotificationPopoverVisibleChange.bind(this)}
            >
              <Tooltip placement="bottom" title={() => {
                return <div>{...adHocNotifications.map(n => <div><b>{n.username}</b> {renderPopoverDateFromNow(n.createDate)} <br/> <b>Email:</b> {n.emailMeta.success ? 'success': n.emailMeta.err} <br/><b>SMS:</b> {n.smsMeta.success ? 'success' : n.smsMeta.err}<br/><br/></div>)}</div>
              }}>
                <IconText type="mail" text={adHocNotifications?.length ? adHocNotifications[0].username : 'Notify' } key="list-vertical-message" />
              </Tooltip>
            </Popover>
          );
          const dropdownMenu = (
            <Menu>
              <Menu.Item
                key='delete'
                title='Delete post'
                className='qna-action'
                onClick={(e) => this.deletePost()}
              ><i className="fal fa-trash-alt"></i> Delete</Menu.Item>
              <Menu.Item
                key='edit'
                title='Edit'
                className='qna-action'
                onClick={(e) => this.showQnaForm()}
              ><i className="far fa-edit"></i> Edit</Menu.Item>
              <Menu.Item
                key='notify'
                title='Notify expert'
                className='qna-action'
                onClick={(e) => this.showAdHocNotificationPopover()}
              >{sendAdHocNotificationPopover}</Menu.Item>
            </Menu>
          );
          actions = actions.concat([
            <span className='hmp-qna-username'><Username participantId={createdByParticipantId}/></span>,
            <span className='hmp-qna-date'>{renderPopoverDateFromNow(createDate)}</span>,
            <IconText type="message" text={commentCount} key="list-vertical-message" />,
            <IconText type="like-o" text={thumbsups ? thumbsups.length : 0} key="list-vertical-like-o" />,
            <IconText type="star-o" text={favorites ? favorites.length : 0} key="list-vertical-star-o" />,
            <a title='Copy deep link to clipboard' className='qna-action' onClick={(e) => { e.stopPropagation(); this.copyDeepLink(id); }}>
              <i className="far fa-link"></i>
            </a>,
            <Dropdown
              trigger={['click']}
              overlay={dropdownMenu}
              onVisibleChange={this.handleActionsDropdownVisibleChange}
            >
              <a className='hmp-qna-icon'><i className="far fa-ellipsis-h fa-lg"></i></a>
            </Dropdown>
          ]);

          let tags = [];
          if(deleteDate !== undefined && deleteDate !== null) {
            tags.push(<span title={renderDateWithTime(deleteDate)} className='post-tag-bubble deleted'>DELETED</span>);
          }
          // Below we use dangeroueslySetInnerHTML to display the qna answer
          return (<div>
            <Item
              key={`qna-post-${id}`}
              actions={actions}
            >
              <Item.Meta
                title={"Q: " + question}
              />
              {answerHtml ?
                <div className="answerContainer">
                  <div>Answer:</div>
                  <p dangerouslySetInnerHTML={{ __html: answerHtml }}></p>
                </div>
                : "(Unanswered)"}
                {answer ?
                  <div className="answerContainer">
                    <br/>
                    <p> Answer (Plain Text):</p>
                    <p className='qna-post-answer'>{answer}</p>
                    <div className="signatureContainer">
                      <p className="signature">~ {answeredByUsername}</p>
                    </div>
                  </div>
                  :
                  ""
                }
              <div className='hmp-qna-topic-container'>
                {tags}
                <span className='hmp-qna-topic'>{this.allcaps(topic)}</span>
              </div>
            </Item>
            <QnaCommentList level={0} comments={comments} />
        </div>);
      }}
      />
    </div>;
  }
}


const mapStateToProps = (state: IApplicationState) => {
  return {
    experts: selectors.getExpertAdmins(state),
    studyId: selectors.getRequestedStudyId(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    sendAdHocAdminQnaNotification: (val: {qnaPostId: number, userId: number}) => dispatch(sendAdHocAdminQnaNotificationAsync.request(val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (QnaDetailCard);