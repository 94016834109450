import React, { Component } from 'react';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import generateUUID from 'uuid';
import {
  Checkbox, DatePicker, Button, Icon
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import FormContextProvider from '../../form/FormContextProvider';
import { ActivityType, ActivityFeedbackType } from '../../../types/entity.types';
import DynamicFeedbackItems from '../feedback/DynamicFeedbackItems';
import FeedbackItem from '../feedback/FeedbackItem';
const { Item } = Form;
import '../activity.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import ActivityItemLabel from '../ActivityItemLabel';

interface ComponentProps {
  creative: boolean;
  editable: boolean;
  setStatus: (status: string) => void;
  setActivity: (props: any) => void;
  setActiveForm: (form: any) => void;
  activity: ActivityType;
}
const itemStyle = {
  width: '100%',
  marginBottom: '10px',
  marginTop: '10px'
};
const defaultFeedback = {
  uuid: '',
  title: '',
  text: '',
  min: undefined,
  max: undefined,
  isOverallFeedback: false
};
enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener
}

class ActivityReviewForm extends Component<ComponentProps & FormComponentProps, {}> {
  componentDidMount() {
    const { setStatus, setActiveForm, form } = this.props;
    setStatus('finish');
    setActiveForm(form);
  }
  updateActivityFeedback = (feedback: ActivityFeedbackType[]) => {
    const { setActivity } = this.props;
    setActivity({ feedback });
  };
  updateFeedback = (fb: ActivityFeedbackType, index: number) => {
    const { activity, setActivity } = this.props;
    const { feedback } = activity;
    if(feedback) {
      feedback[index] = fb;
      if(feedback[index].isOverallFeedback) {
        setActivity({ overallFeedback: feedback[index] });
      }
      this.updateActivityFeedback(feedback);
    }
  };
  addFeedback = () => {
    const { activity } = this.props;
    const { feedback } = activity;
    let updatedFeedback = feedback ? _.cloneDeep(feedback) : [];
    const newFeedback = _.cloneDeep(defaultFeedback);
    newFeedback.uuid = generateUUID();
    if(!activity.feedback) {
      updatedFeedback = [newFeedback]
    } else {
      updatedFeedback[updatedFeedback.length] = newFeedback;
    }
    this.updateActivityFeedback(updatedFeedback);
  };
  removeFeedback = (index: number) => {
    const { activity } = this.props;
    let i = 0;
    activity.feedback = activity.feedback ? activity.feedback.filter(f => { return index === i++ ? false : true; }) : [];
    this.updateActivityFeedback(activity.feedback);
  };
  onRandomizeQuestionChange = (e: CheckboxChangeEvent) => {
    const { setActivity } = this.props;
    setActivity({ randomizeQuestions: e.target.checked })
  }
  onHealthAssessmentChange = (e: CheckboxChangeEvent) => {
    const { setActivity } = this.props;
    setActivity({ isHealthAssessment: e.target.checked })
  }
  onPublishDateChange = (date: Moment | null, dateString: string) => {
    const { setActivity } = this.props;
    setActivity({ publishDate: dateString })
  }
  render () {
    const {
      creative,
      editable,
      activity,
      form,
      setStatus
    } = this.props;
    const {
      randomizeQuestions,
      isHealthAssessment,
      publishDate,
      feedback,
      options,
      overallFeedback,
      type
    } = activity;
    const { getFieldDecorator } = form;
    const displayRandomizeQuestions = [Type.goals, Type.ranking, Type.cyoa].indexOf(parseInt(type)) === -1;
    return (
      <div className='activity-form'>
        <FormContextProvider>
          <Form key="form" layout='horizontal' colon={false}>
            {displayRandomizeQuestions && <Item style={itemStyle}>
              {getFieldDecorator('randomizeQuestions', {
                rules: [{ required: false }],
                initialValue: randomizeQuestions
              })(<Checkbox disabled={!creative && !editable} checked={randomizeQuestions} onChange={this.onRandomizeQuestionChange}>Randomize Questions</Checkbox>)}
            </Item>}
            <Item label={<ActivityItemLabel label='Publish Date:' tooltip='This is when this article will be available to the participants.  The exact moment it will be published is the first second of the day in Eastern Standard Time'/>} style={itemStyle}>
              {getFieldDecorator('publishDate', {
                rules: [{ required: false }],
                initialValue: publishDate ? moment(publishDate) : undefined
              })(<DatePicker disabled={!creative && !editable} onChange={this.onPublishDateChange} />)}
            </Item>
            <h4>Activity Feedback:</h4>
            <DynamicFeedbackItems
              type={0}
              form={form}
              creative={creative}
              editable={editable}
              options={options ? options : []}
              feedback={feedback ? feedback : []}
              setStatus={setStatus}
              updateFeedback={this.updateFeedback}
              addFeedback={this.addFeedback}
              removeFeedback={this.removeFeedback}
            />
          </Form>
        </FormContextProvider>
      </div>
    );
  }
}
export default Form.create({ name: 'form' })(ActivityReviewForm);