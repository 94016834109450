import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { OidcProvider } from 'redux-oidc';
import App from './App';
import configureStore from './redux/configureStore';
import userManager from './oidc/UserManager';
import Callback from './pages/auth/Callback';
import Logout from './pages/auth/Logout';
import SilentRenew from './pages/auth/SilentRenew';
import './index.less';
import './index.scss';
import MobilePageNotFound from './pages/404/MobilePageNotFound';


const root = document.getElementById('root');

const history: History<any> = createBrowserHistory();

const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider userManager={userManager} store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/auth/callback" component={Callback} />
          <Route exact path="/auth/logout" component={Logout} />
          <Route exact path="/auth/renew" component={SilentRenew} />
          <Route path="/hmpmobile" component={MobilePageNotFound} />
          <Route path='/' component={App} />
        </Switch>
      </ConnectedRouter>
    </OidcProvider>
  </Provider>,
  root
);
