import "@fortawesome/fontawesome-pro/css/all.min.css";
import Button from "antd/lib/button";
import Cascader from "antd/lib/cascader";
import Input from "antd/lib/input";

// import * as html2pdf from 'html2pdf.js';
import axios from "axios";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/plugins/char_counter.min.css";
import "froala-editor/css/plugins/code_view.min.css";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/css/plugins/draggable.min.css";
import "froala-editor/css/plugins/emoticons.min.css";
import "froala-editor/css/plugins/fullscreen.min.css";
import "froala-editor/css/plugins/help.min.css";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/special_characters.min.css";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/css/plugins/video.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/align.min";
import "froala-editor/js/plugins/char_counter.min";
import "froala-editor/js/plugins/code_beautifier.min";
import "froala-editor/js/plugins/code_view.min";
import "froala-editor/js/plugins/colors.min";
import "froala-editor/js/plugins/draggable.min";
import "froala-editor/js/plugins/emoticons.min";
import "froala-editor/js/plugins/font_family.min";
import "froala-editor/js/plugins/font_size.min";
import "froala-editor/js/plugins/fullscreen.min";
import "froala-editor/js/plugins/help.min";
import "froala-editor/js/plugins/image.min";
import "froala-editor/js/plugins/inline_class.min";
import "froala-editor/js/plugins/inline_style.min";
import "froala-editor/js/plugins/link.min";
import "froala-editor/js/plugins/lists.min";
import "froala-editor/js/plugins/paragraph_format.min";
import "froala-editor/js/plugins/paragraph_style.min";
import "froala-editor/js/plugins/print.min";
import "froala-editor/js/plugins/quote.min";
import "froala-editor/js/plugins/save.min";
import "froala-editor/js/plugins/special_characters.min";
import "froala-editor/js/plugins/table.min";
import "froala-editor/js/plugins/url.min";
import "froala-editor/js/plugins/video.min";
import "froala-editor/js/plugins/word_paste.min";
import moment from "moment";
import React, { ChangeEvent } from "react";

import FroalaEditor from "react-froala-wysiwyg";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { UserState } from "redux-oidc";
import { createStructuredSelector } from "reselect";

import { transliterate as tr } from "transliteration";

import IApplicationState from "types/state.types";
import { updateCmsEditorBeforeSave } from "../../../components/util/Util";
import * as selectors from "../../../redux/selectors";
import { loadResourceTopicsAsync } from "../../../redux/topics/topic.types";
import { TopicType } from "../../../types/entity.types";

const AxiosInstance = (access_token: any) =>
  axios.create({
    baseURL: "/",
    timeout: 2000,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`
    }
  });

const save = async (access_token: string, data: any) => await AxiosInstance(access_token).post('/api/a/cms/save', data).then(res => res);

const config = {
  autoSave: true,
  charCounterCount: true,
  fontFamilySelection: true,
  fontSizeSelection: true,
  iconsTemplate: 'font_awesome_5r',
  imageMaxSize: 16 * 1024 * 1024, // 16MB
  imageUploadParam: 'file',
  imageUploadMethod: 'POST',
  imageUploadURL: '/api/u/upload/',
  placeholderText: 'Edit Your Content Here!',
  pluginsEnabled: [
    'align',
    'charCounter',
    'codeBeautifier',
    'codeView',
    'colors',
    'draggable',
    'emoticons',
    'entities',
    // 'file',
    'fontFamily',
    'fontSize',
    'fullscreen',
    'help',
    'image',
    // 'imageManager',
    'inlineClass',
    'inlineStyle',
    // 'lineBreaker',
    'link',
    'lists',
    'paragraphFormat',
    'paragraphStyle',
    'print',
    // 'quickInsert',
    'quote',
    'save',
    'specialCharacters',
    'table',
    'url',
    'video',
    'wordPaste'
  ],
  toolbarButtons: {
    moreText: {
      buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
      buttonsVisible: 3
    },
    moreParagraph: {
      buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'outdent', 'indent', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'quote'],
      buttonsVisible: 6
    },
    moreRich: {
      buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'specialCharacters', 'insertHR'],
      buttonsVisible: 4
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'selectAll', 'html', 'help', 'save'],
      align: 'right',
      buttonsVisible: 2
    }
  },
  events: {
  },
  linkAutoPrefix: ''
};


const cleanstring = (dirty: string) => {
  if (dirty) {
    return tr(dirty
      .replace(/‘/g, "'")
      .replace(/’/g, "'")
      .replace(/“/g, '"')
      .replace(/”/g, '"')
      .replace(/'/g, "''") // escape single quotes, so the INSERT statement will be syntactically correct.
    );

  }
  return '';
};

interface ComponentProps {
  oidc: UserState,
  resourceTopics: Optional<TopicType[]>
}

interface DispatchProps {
  loadResourceTopics: typeof loadResourceTopicsAsync.request
}

class CmsEditPage extends React.Component<ComponentProps & DispatchProps, {}> {

  readonly state = {
    model: '',
    topic: -1,
    subTopic: -1,
    title: '',
    hook: '',
    saved: false,
    content: '',
    articleId: -1,
    oidc: this.props.oidc
  };

  componentDidMount() {
    const { loadResourceTopics } = this.props;
    loadResourceTopics();
  }

  handleTopicChange = (values: string[]) => {
    const topic = values[0];
    const subTopic = values[1];
    this.setState({ topic, subTopic, saved: false });
  };

  handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ title: value, saved: false });
  };

  handleHookChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    this.setState({ hook: value, saved: false });
  };

  handleModelChange = (model: any) => {
    this.setState({
      model,
      saved: false
    });
  };

  handleResetClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    this.setState({
      model: '',
      title: '',
      hook: '',
      saved: false,
      articleId: -1
    });
  };

  handleSave = (requestBody: any) => {
    const {
      topicId, title, summary, oidc, body, articleId
    } = requestBody;
    if (topicId !== -1 && title.length > 0 && summary.length > 0) {
      const response = save(oidc.user.access_token, {
        articleId: articleId !== -1 ? articleId : null,
        topicId,
        title,
        summary,
        createdByUserId: oidc.user.profile.sub,
        body: updateCmsEditorBeforeSave(body)
      });
      response.then(res => {
        console.log(res.data);
        this.setState({ saved: true, articleId: res.data.id });
      });
    }
  };

  render() {

    const { TextArea } = Input;

    const { resourceTopics } = this.props;

    const {
      topic, subTopic, title, hook, model, saved, oidc, articleId
    } = this.state;
    const requestBody = {
      title: cleanstring(title),
      topicId: subTopic,
      summary: cleanstring(hook),
      oidc,
      body: cleanstring(model),
      articleId
    };
    const sql = `INSERT INTO article (topic_id, title, summary, created_by_user_id, body) VALUES (${subTopic}, '${cleanstring(title)}', '${cleanstring(hook)}', '1001', '${cleanstring(model)}');`;

    return (
      <div style={{ margin: 'auto', width: '80%' }}>
        <div>
          <label>Topic</label>
          <Cascader
            fieldNames={{ label: 'title', value: 'id', children: 'children' }}
            // style={{width: '400px', margin: "20px"}}
            size="large"
            defaultValue={[`${topic}`, `${subTopic}`]}
            options={resourceTopics}
            onChange={this.handleTopicChange}
          />
        </div>
        <div>
          <label>Title</label>
          <Input style={{ width: '400px', margin: '20px' }} value={title} onChange={this.handleTitleChange} />
        </div>
        <div>
          <div>
            <label>Hook</label>
            <TextArea style={{ width: '600px', margin: '20px' }} rows={3} value={hook} maxLength={300} onChange={this.handleHookChange} />
          </div>
          <div>
            <Button onClick={this.handleResetClicked}>New Article</Button>
            <Button onClick={e => this.handleSave(requestBody)}>Save</Button>
            <span>{saved ? `Saved! ${moment(new Date()).format('hh:mm:ss')}` : 'There are unsaved changes...'}</span>
          </div>
        </div>
        <div>
          <FroalaEditor
            tag="textarea"
            config={config}
            model={model}
            onModelChange={this.handleModelChange}
          />
        </div>
        <div>
          <div>{sql}</div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, ComponentProps>({
  oidc: selectors.selectOidc,
  resourceTopics: selectors.getResourceTopics
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadResourceTopics: () => dispatch(loadResourceTopicsAsync.request())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CmsEditPage);
