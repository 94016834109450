import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ConsentStatusType, ParticipantType } from "../../types/entity.types";
import { NormalizedType } from '../../types/state.types';

export const loadParticipantAsync = createAsyncAction(
  'hmp/admin/LOAD_PARTICIPANT_REQUEST',
  'hmp/admin/LOAD_PARTICIPANT_SUCCESS',
  'hmp/admin/LOAD_PARTICIPANT_FAILURE'
)<number, void, Error>();

export const loadConsentStatusesAsync = createAsyncAction(
  'hmp/admin/LOAD_CONSENT_STATUSES_REQUEST',
  'hmp/admin/LOAD_CONSENT_STATUSES_SUCCESS',
  'hmp/admin/LOAD_CONSENT_STATUSES_FAILURE'
)<void, Optional<NormalizedType<ConsentStatusType>>, Error>();


export const loadRequestedParticipantAsync = createAsyncAction(
  'hmp/admin/LOAD_REQUESTED_PARTICIPANT_REQUEST',
  'hmp/admin/LOAD_REQUESTED_PARTICIPANT_SUCCESS',
  'hmp/admin/LOAD_REQUESTED_PARTICIPANT_FAILURE'
)<void, void, Error>();

export const saveParticipantAsync = createAsyncAction(
  'hmp/admin/SAVE_PARTICIPANT_REQUEST',
  'hmp/admin/SAVE_PARTICIPANT_SUCCESS',
  'hmp/admin/SAVE_PARTICIPANT_FAILURE'
)<ParticipantType, Optional<NormalizedType<ParticipantType>>, Error>();

export const saveParticipantActualHivStatusAsync = createAsyncAction(
  'hmp/admin/SAVE_PARTICIPANT_STATUS_REQUEST',
  'hmp/admin/SAVE_PARTICIPANT_STATUS_SUCCESS',
  'hmp/admin/SAVE_PARTICIPANT_STATUS_FAILURE',
)<{participantId: number, status: string}, Optional<NormalizedType<ParticipantType>>, Error>();

export const saveParticipantInterviewSelectedAsync = createAsyncAction(
  'hmp/admin/SAVE_PARTICIPANT_INTERVIEW_SELECTED_REQUEST',
  'hmp/admin/SAVE_PARTICIPANT_INTERVIEW_SELECTED_SUCCESS',
  'hmp/admin/SAVE_PARTICIPANT_INTERVIEW_SELECTED_FAILURE',
)<{participantId: number, interviewSelected: Boolean}, Optional<NormalizedType<ParticipantType>>, Error>();


export const updateParticipants = createStandardAction('hmp/admin/UPDATE_PARTICIPANTS')<Optional<NormalizedType<ParticipantType>>>();

const participantActions = {
  loadParticipantAsync,
  loadConsentStatusesAsync,
  loadRequestedParticipantAsync,
  saveParticipantAsync,
  saveParticipantActualHivStatusAsync,
  saveParticipantInterviewSelectedAsync,
  updateParticipants
};

export type ParticipantActionTypes = ActionType<typeof participantActions>;
