import React, { Component } from 'react';
import _ from 'lodash';
import { Modal, Input, Cascader } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import IApplicationState from '../../types/state.types';
import { CreateThreadArguments } from '../../redux/messages/messages.types';
import { ParticipantType } from '../../types/entity.types';
import * as selectors from '../../redux/selectors';
import FormContextProvider from '../../components/form/FormContextProvider';
import './thread.scss';
const { TextArea } = Input;
const { confirm } = Modal;
const { Item } = Form;

interface StateProps {
  participants: ParticipantType[]
}

interface ComponentProps extends StateProps, FormComponentProps {
  createThread: (thread: CreateThreadArguments) => Promise<void>;
  close: () => void;
  visible: boolean;
}

function filter(inputValue: any, path: any): boolean {
  return path.some((option: any) => option.label ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 : false);
}

class NewThreadListItem extends Component<ComponentProps, {}> {
  readonly state = {
    title: '',
    body: '',
    participantId: '',
    confirmLoading: false
  }
  handleOk = async () => {
    const { createThread } = this.props;
    const { title, body, participantId } = this.state;
    if(title && body && participantId) {
      this.setState({
        confirmLoading: true
      });
      await createThread({ title, body, participantId: parseInt(participantId) }).then(() => {
        this.setState({
          confirmLoading: false
        });
        this.props.close();
      });
    }
  }
  handleCancel = () => {
    const { close } = this.props;
    confirm({
      title: 'Are you sure you want to delete this message?',
      content: '',
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        close();
      },
      onCancel() {}
    });
  }
  handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: e.target.value });
  }
  handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ body: e.target.value });
  }
  handleParticipantChange = (value: string[]) => {
    this.setState({participantId: value[0]});
  }
  render() {
    const { visible, participants, form } = this.props;
    const { title, body, confirmLoading } = this.state;
    const { getFieldDecorator } = form;
    const options = _.map(participants, participant => _.pick(participant, ['username', 'id']))
                     .map(option => { return { label: option.username, value: option.id.toString() }; });
    return (
      <Modal
        destroyOnClose={true}
        closable={false}
        centered
        title='Compose Message'
        visible={visible}
        okText='Send Message'
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        confirmLoading={confirmLoading}
      >
        <FormContextProvider>
          <Form key="thread-form" layout="vertical" colon={false}>
            <Item label="Participant:">
              {getFieldDecorator('participant', {
                rules: [{ required: true, message: 'Participant is required.' }]
              })(<Cascader
                placeholder="Enter participant's username"
                options={options}
                onChange={this.handleParticipantChange}
                showSearch={{ filter }}
              />)}
            </Item>
            <Item label="Title:">
              {getFieldDecorator('title', {
                rules: [
                  { required: true, message: 'Title is required.' },
                  { max: 50, message: `Exceeds 50 character limit.` },
                ],
                initialValue: title
              })(<Input
                placeholder="Enter thread title"
                onChange={this.handleTitleChange}
              />)}
            </Item>
            <Item label="Message:">
              {getFieldDecorator('body', {
                rules: [{ required: true, message: 'Message body is required.' }],
                initialValue: body
              })(<TextArea
                  placeholder='Type a message'
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  onChange={this.handleBodyChange}
                />)}
            </Item>
          </Form>
        </FormContextProvider>
      </Modal>
    );
  }
}

const mapStateToProps =  createStructuredSelector<IApplicationState, StateProps>({
  participants: selectors.getRequestedStudyParticipantsWithUsernames
});

export default connect(mapStateToProps)(Form.create({ name: 'thread-form' })(NewThreadListItem));