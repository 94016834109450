export default class Config
{
    static isHMPStyle = () => {
        return process.env.CLIENT_ID === "hmp-style-admin";
    };
      
    static isHMPStigma = () => {
        return process.env.CLIENT_ID === "hmp-stigma-admin";
    };
      
    static isMultiStudyArm = () => {
        if(Config.isHMPStyle()) return false;
        if(Config.isHMPStigma()) return true;
        return true;
    }
}