import {Alert, Button, Cascader, Checkbox, Dropdown, Input, Menu, message, Modal, Radio, Tabs, Upload} from 'antd';
import _, {clone, cloneDeep, filter, find, flatten, groupBy, isEmpty, keys, map} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import uuid from 'uuid';
import {resetRecentActivityPostIds} from '../../redux/forum/forum.types';
import {clearPosts, savePostAsync} from '../../redux/posts/posts.types';
import axios from '../../redux/api';
import * as selectors from '../../redux/selectors';
import {
  ForumDetailType,
  ParticipantType,
  PostPollOption,
  PostType,
  StudyArmType,
  TopicType
} from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import './forumPostCreationModal.scss';
import {CascaderOptionType} from "antd/es/cascader";

const {TextArea} = Input;
const {TabPane} = Tabs;
const {confirm} = Modal;

interface StateProps {
  hasCareNavigatorRole: Optional<Boolean>;
  topics: Optional<TopicType[]>;
  arms: Optional<StudyArmType[]>;
  studyId: number;
  forums: Optional<ForumDetailType[]>;
  pseudoParticipants: Optional<ParticipantType[]>;
  participants: Optional<ParticipantType[]>;
}

interface DispatchProps {
  savePost: typeof savePostAsync.request,
  clearPosts: typeof clearPosts
  resetRecentActivityPostIds: typeof resetRecentActivityPostIds
}

interface ComponentProps extends StateProps, DispatchProps {
  visible: boolean;
  closeHandler: () => {};
}

const initialState = {
  selectedPost: undefined as Optional<PostType>,
  selectedFlaggedPost: undefined as Optional<PostType>,
  isEditing: false as boolean,
  newPostTitle: '' as string,
  newPostBody: '' as string,
  newPostPollOptions: [] as Array<PostPollOption>,
  addPostError: false as boolean,
  addPostErrorMessage: '' as string,
  selectedArmIds: [] as number[],
  selectedTopicIdsMap: new Map() as Map<number, number>,
  fileList:[] as Array<any>,
  postLinkType: 'none' as Optional<String>,
  previewImage: '' as string,
  previewVisible: false as boolean,
  videoWebLink: '' as string,
  image: undefined as Optional<File>,
  selectedParticipantMap: new Map<string, string>()
};

type ComponentState = typeof initialState;

function selectedParticipantFilter(inputValue: any, path: any): boolean {
  return path.some((option: any) => option.label ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 : false);
}

class ForumPostCreationModal extends Component<ComponentProps, ComponentState> {
  readonly state = initialState;

  onRemove = (file: any) => {
    this.setState({
      fileList: []
    });
  };

  beforeUpload = (file: any) => {
    if(file.size > 50000) {
      message.error(`Upload failed. File exceeds 50KB limit.`);
      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        if(reader.result) {
          this.setState({previewImage: reader.result as string});
          this.setState({ image: file });
          this.setState({ fileList: [
              {
                uid: '-1',
                name: 'name',
                status: 'done',
                url: reader.result
              }
            ]});
          resolve(true);
        }
        resolve(false);
      };
    });
    return true;
  }

  handleOk = async () => {
    const {
      newPostTitle,
      newPostBody,
      newPostPollOptions,
      selectedTopicIdsMap,
      selectedArmIds,
      postLinkType,
      image,
      videoWebLink,
      selectedParticipantMap
    } = this.state

    const {
      savePost,
      clearPosts,
      resetRecentActivityPostIds,
      closeHandler,
      hasCareNavigatorRole,
    } = this.props;

    this.setState({
      addPostError: false
    });

    // Begin by checking that the form items have valid responses
    if (isEmpty(selectedArmIds)) {
      this.setState({
        addPostError: true,
        addPostErrorMessage: 'Please select at least one study arm to post to'
      });
    } else if (isEmpty(newPostTitle)) {
      this.setState({
        addPostError: true,
        addPostErrorMessage: 'Please enter a post title'
      });
    } else if (isEmpty(newPostBody)) {
      this.setState({
        addPostError: true,
        addPostErrorMessage: 'Please enter a post body'
      });
    } else if (find(newPostPollOptions, o => o.option.trim() === '')) {
      this.setState({
        addPostError: true,
        addPostErrorMessage: 'One of your options is empty'
      });
    } else if (isEmpty(selectedTopicIdsMap)) {
      this.setState({
        addPostError: true,
        addPostErrorMessage: 'Please select a post topic'
      });
    } else {
      const { forums, pseudoParticipants } = this.props;
      const pseudoParticipantsGroupedByStudyArmId = groupBy(pseudoParticipants, p => p.studyArmId);
      // Check that all the selected arms have a corresponding topic selected
      const currentForums = filter(forums, f => _.includes(selectedArmIds, f.studyArmId));

      if (selectedTopicIdsMap && selectedArmIds) {
        await Promise.all(map(selectedArmIds, async armId => {
          const currentTopicCollectionId: number | undefined = currentForums.find(f => f.studyArmId === armId)?.topicCollectionIds[ 0 ];
          const actualTopicCollectionIds: number[] = [...selectedTopicIdsMap.keys()];
          // Compare the selected topicCollectionIds with the corresponding
          // study arm topic collection ids that have been selected.
          if (currentTopicCollectionId && actualTopicCollectionIds.indexOf(currentTopicCollectionId) === -1) {
            this.setState({
              addPostError: true,
              addPostErrorMessage: 'The number of topics selected doesn\'t match with the number of study arms selected.'
            });
          } else {
            // Create new post in all the selected arms
            let newPost: any = {
              title: newPostTitle,
              body: newPostBody
            };

            if (currentTopicCollectionId) {
              // @ts-ignore
              const currentForum = find(currentForums, f => f.topicCollectionIds.includes(currentTopicCollectionId));
              const currentArmParticipantId = hasCareNavigatorRole || pseudoParticipantsGroupedByStudyArmId[armId].length > 1 ?
                // @ts-ignore
                selectedParticipantMap.get(currentForum?.studyArmId.toString())
                // @ts-ignore
                : find(pseudoParticipants, p => p.studyArmId === currentForum?.studyArmId)?.id;

              if (!currentArmParticipantId) {
                this.setState( {
                  addPostError: true,
                  addPostErrorMessage: 'You must select a participant to post as'
                });
                return false;
              }
              else {
                if (postLinkType === 'poll') {
                  if (newPostPollOptions.length < 2) {
                    this.setState({
                      addPostError: true,
                      addPostErrorMessage: 'You must include at least two poll options'
                    });
                    return false;
                  }
                  Object.assign(newPost, {
                    poll: {
                      options: newPostPollOptions
                    }
                  })
                } else if (postLinkType !== "none") {
                  let url;
                  if (postLinkType === 'image') {
                    let response;
                    try {
                      const formData = new FormData();
                      // @ts-ignore
                      formData.append('file', image);
                      response = await axios(
                        {
                          method: 'post',
                          url: `/a/forum/upload?participantId=${currentArmParticipantId}`,
                          data: formData
                        }
                      );
                      url = `${window.location.protocol}//${window.location.host}/api${response.data.link}`;
                    } catch (err) {
                      console.log(JSON.stringify(err));
                      this.setState({
                        addPostError: true,
                        addPostErrorMessage: 'Image upload failed. Please try again or contact administrator'
                      });
                      return false;
                    }
                  } else {
                    if (isEmpty(videoWebLink)) {
                      this.setState({
                        addPostError: true,
                        addPostErrorMessage: 'Link field cannot be empty'
                      });
                      return false;
                    }
                    else {
                      if (/^(ftp|http|https):\/\/[^ "]+$/.test(videoWebLink)) {
                        url = videoWebLink;
                      }
                      else {
                        this.setState({
                          addPostError: true,
                          addPostErrorMessage: `The ${postLinkType} link is invalid`
                        });
                        return false;
                      }
                    }
                  }

                  Object.assign(newPost, {
                    postLinks: [ {
                      type: postLinkType,
                      url
                    }]
                  });
                }

                const post: PostType = {
                  ...newPost,
                  topicId: selectedTopicIdsMap.get(currentTopicCollectionId),
                  createdByParticipantId: currentArmParticipantId,
                  // @ts-ignore
                  studyArmId: currentForum?.studyArmId
                };
                resetRecentActivityPostIds();
                clearPosts();
                savePost(post);
              }
              this.resetState();
              closeHandler();
            }
          }
        }));
      }
    }
  }

  onCancel = () => {
    const {
      closeHandler
    } = this.props;
    const reset = this.resetState.bind(this);
    confirm({
      title: 'Are you sure you want to leave this post?',
      content: 'You will lose all changes.',
      okText: 'Leave',
      okType: 'danger',
      onOk() {
        reset();
        closeHandler();
      },
      onCancel() {
      }
    });
  }

  resetState = () => {
    const resetState = cloneDeep(initialState);
    this.setState(resetState);
  }

  handleArmCheckedChange = (checked: boolean, id: number) => {
    const {
      selectedArmIds
    } = this.state;
    // @ts-ignore
    if (checked && !selectedArmIds.includes(id)) {
      // Add arm to selectedArmIds
      this.setState({
        selectedArmIds: [...selectedArmIds, id]
      });
      // @ts-ignore
    } else if (!checked && selectedArmIds.includes(id)) {
      // Remove arm from selectedArmIds
      this.setState({
        selectedArmIds: [...selectedArmIds.filter(i => i !== id)]
      });
    }
  }

  handleTopicIdChange = (topicId: number, topicCollectionId: number) => {
    const {selectedTopicIdsMap} = this.state;
    selectedTopicIdsMap.set(topicCollectionId, topicId);
    this.setState({
      selectedTopicIdsMap
    });
  }

  handleAddOptionClick = () => {
    const {
      newPostPollOptions
    } = this.state;
    const updatedNewPostPollOptions = clone(newPostPollOptions);
    // @ts-ignore
    updatedNewPostPollOptions.push({option: '', optionId: uuid()});
    this.setState({
      newPostPollOptions: updatedNewPostPollOptions
    });
  };

  handleRemoveOption = (index: number) => {
    const {
      newPostPollOptions
    } = this.state;
    const updatedNewPostPollOptions = clone(newPostPollOptions);
    updatedNewPostPollOptions.splice(index, 1);
    this.setState({
      newPostPollOptions: updatedNewPostPollOptions
    });
  }

  handleOptionTextChange = (index: number, value: string) => {
    if (value === '') {
      this.handleRemoveOption(index);
    } else {
      const {
        newPostPollOptions
      } = this.state;
      newPostPollOptions[ index ].option = value;
      this.setState({
        newPostPollOptions: newPostPollOptions
      });
    }
  }

  handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newPostTitle: e.target.value
    });
  };

  handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      newPostBody: e.target.value
    });
  }

  handlePostLinkChange = (e: { target: { value: any; }; }) => { this.setState({postLinkType: e.target.value}); }

  handlePreview = () => { this.setState({ previewVisible: true }); }

  handleCancelPreview = () => { this.setState({ previewVisible: false })};

  updateVideoWebLink = (e: { target: { value: any; }; }) => { this.setState({ videoWebLink: e.target.value })};

  handleSelectedArmParticipantChange = (armId:number, value: string[]) => {
    let selectedParticipantMap = new Map(this.state.selectedParticipantMap);
    selectedParticipantMap.set(armId.toString(), value[0]);
    this.setState({selectedParticipantMap});
  }

  render() {
    const {
      topics,
      visible,
      forums,
      arms,
      pseudoParticipants,
      hasCareNavigatorRole,
      participants
    } = this.props;
    const {
      fileList,
      newPostPollOptions,
      selectedArmIds,
      selectedTopicIdsMap,
      addPostError,
      addPostErrorMessage,
      postLinkType,
      previewImage,
      previewVisible,
      videoWebLink,
      newPostBody,
      newPostTitle
    } = this.state;

    // Only display arms that have forums
    const displayedArms = forums ? filter(arms, arm => forums.some(f => f.studyArmId === arm.id)) : [];
    // Filter by forums linked to selectedArmIds, map forums to topicCollectionIds (number[]), flatten to simple number[]
    // @ts-ignore
    const selectedTopicCollectionIds: number[] = flatten(map(filter(forums, f => selectedArmIds.includes(f.studyArmId)), f => f.topicCollectionIds));
    // Union topics to remove duplicates
    // @ts-ignore
    const displayedTopics = filter(topics, topic => selectedTopicCollectionIds.includes(topic.topicCollectionId)).sort((a, b) => a.title.localeCompare(b.title));
    // @ts-ignore
    const displayedTopicsGroupedByArm = groupBy(displayedTopics, t => t.topicCollectionId);
    const pseudoParticipantsGroupedByStudyArmId = groupBy(filter(pseudoParticipants, p => !!p.username), p => p.studyArmId);

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    const participantSelectorOptions:Map<string, CascaderOptionType[]> = new Map();
    displayedArms.forEach((arm:StudyArmType) => {
      participantSelectorOptions.set(arm.id.toString(),
        _.map(_.filter(participants, p => p.studyArmId === arm.id && p.type === 'PSEUDO PARTICIPANT'), participant => {
          return {label: participant.username, value: participant.id.toString()};
        }));
    });

    return (
      <Modal
        className="addPostModal"
        title="Add Post"
        width="45%"
        visible={visible}
        onCancel={this.onCancel}
        onOk={this.handleOk}
        destroyOnClose={true}
      >
        { addPostError && addPostErrorMessage &&
          <Alert message={addPostErrorMessage} type='error' showIcon/>
        }
        { pseudoParticipants && pseudoParticipants.length === 0 &&
          <Alert
            message="You have no pseudo participants configured. You cannot create content. Contact an administrator."
            type='error' showIcon/>
        }

        <div id='formContainer'>
          <div className='formRow'>
            <span className='label'>Where would you like to post this content?</span>
            <div id='studyArmCheckboxes'>
              {displayedArms && displayedArms.length && displayedArms.map(arm => {
                if (hasCareNavigatorRole || pseudoParticipantsGroupedByStudyArmId[ arm.id ].length > 1)
                {
                 return   <Checkbox
                   key={`arm-${arm.id}`}
                   // @ts-ignore
                   checked={selectedArmIds.includes(arm.id)}
                   onChange={(e) => this.handleArmCheckedChange(e.target.checked, arm.id)}
                   ><Cascader
                     placeholder="Enter participant's username"
                     // @ts-ignore
                     disabled={!selectedArmIds.includes(arm.id)}
                     options={participantSelectorOptions.get(arm.id.toString())}
                     // @ts-ignore
                     onChange={(v:any) => this.handleSelectedArmParticipantChange(arm.id, v)}
                     showSearch={{ filter:selectedParticipantFilter }}
                   />
                 </Checkbox>
                }
                else {
                  return (
                    <Checkbox
                      key={`arm-${arm.id}`}
                      // @ts-ignore
                      checked={selectedArmIds.includes(arm.id)}
                      onChange={(e) => this.handleArmCheckedChange(e.target.checked, arm.id)}
                    >{`${arm.name} (${pseudoParticipantsGroupedByStudyArmId[ arm.id ] ? pseudoParticipantsGroupedByStudyArmId[ arm.id ][ 0 ].username : ""})`}</Checkbox>
                  );
                }
              })}
            </div>
          </div>
          {map(keys(displayedTopicsGroupedByArm), topicCollectionId => {
            const selectedTopic = topics?.find(t => t.id === selectedTopicIdsMap.get(parseInt(topicCollectionId)));
            const currentArm = filter(displayedArms, arm => {
              // @ts-ignore
              const currentForum = forums?.find(f => f.topicCollectionIds.includes(parseInt(topicCollectionId)));
              return arm.id === currentForum?.studyArmId;
            })[ 0 ];
            return (
              <div key={`topics-for-${topicCollectionId}`} className='formRow'>
                <span className='label'>Topic ({currentArm.name})</span><br/>
                <Dropdown  overlay={
                  // @ts-ignore
                  <Menu onClick={(e) => this.handleTopicIdChange(parseInt(e.key), parseInt(topicCollectionId))}>
                    {map(displayedTopicsGroupedByArm[ topicCollectionId ], (topic: TopicType) => {
                      return <Menu.Item key={topic.id}>{topic.title}</Menu.Item>
                    })}
                  </Menu>
                } placement="bottomLeft">
                  <Button>{selectedTopic ? selectedTopic.title : 'Select a topic'}</Button>
                </Dropdown>
              </div> );
          })}
          <div className='formRow'>
            <span className='label'>Title</span><br/>
            <Input className="input" value={newPostTitle} placeholder="Enter poll title" onChange={this.handleTitleChange}/>
          </div>
          <div className='formRow'>
            <span className='label'>Body</span><br/>
            <TextArea style={{height: '100px'}} value={newPostBody} className="input" placeholder="Enter poll body"
                      onChange={this.handleBodyChange}/>
          </div>
        </div>
        <div className='postLinks'>
          <Radio.Group onChange={(e) => this.handlePostLinkChange(e)} value={postLinkType}>
            <Radio key="none" style={radioStyle} value="none"> None </Radio>
            <Radio key="image" style={radioStyle} value="image"> Upload </Radio>
            <Radio key="video" style={radioStyle} value="video"> Video </Radio>
            <Radio key="web" style={radioStyle} value="web"> Web </Radio>
            <Radio key="poll" style={radioStyle} value="poll"> Poll </Radio>
          </Radio.Group>
          {postLinkType === 'image' &&
          <div className='upload'>
            <Upload
              className="upload-list-inline"
              // @ts-ignore
              beforeUpload={this.beforeUpload}
              onRemove={this.onRemove}
              listType="picture-card"
              onPreview={this.handlePreview}
              fileList={fileList}
            >
              {fileList.length === 0 &&
              <Button style={{whiteSpace: 'pre-wrap'}}>
                <i className="fal fa-upload"/>
              </Button>
              }
            </Upload>
            {fileList.length === 0 &&
              <Alert message="Aspect ration should be 3 x 2 and file size should be less than 50k" type='warning' />
            }
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancelPreview}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </div>
          }
          { postLinkType === "video" &&
          <div className="video">
            <Input className="video-web-link" placeholder="Must be a valid youtube url like https://youtu.be/..." value={videoWebLink} onChange={this.updateVideoWebLink} />
          </div>
          }
          { postLinkType === "web" &&
          <div className="video">
            <Input className="video-web-link" placeholder="Must be a valid web url beginning with http(s)://" value={videoWebLink} onChange={this.updateVideoWebLink} />
          </div>
          }
          { postLinkType === 'poll' &&
          <div className='formRow'>
            <span className='label'>Options</span><br/>
            <div>
              <div className='options-container'>
                {newPostPollOptions.map((option, index) =>
                  <Input
                    key={`${option.optionId}`}
                    className="optionInput"
                    placeholder="Enter option text"
                    defaultValue={option.option}
                    onChange={(e) => this.handleOptionTextChange(index, e.target.value)}
                    allowClear
                  />)}
              </div>
              <Button
                className="addOptionButton"
                onClick={this.handleAddOptionClick}
              >Add Option</Button>
            </div>
          </div>
          }
        </div>
      </Modal> );
  }
}

const mapStateToProps = (state: IApplicationState): StateProps => {
  return {
    hasCareNavigatorRole: selectors.hasCareNavigatorRole(state),
    topics: selectors.getForumTopics(state),
    arms: selectors.getRequestedStudyStudyArms(state),
    studyId: selectors.getRequestedStudyId(state),
    forums: selectors.getForums(state),
    pseudoParticipants: selectors.getCurrentUserPseudoParticipants(state),
    participants: selectors.getParticipants(state)
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    savePost: (newPost: PostType) => dispatch(savePostAsync.request(newPost)),
    clearPosts: () => dispatch(clearPosts()),
    resetRecentActivityPostIds: () => dispatch(resetRecentActivityPostIds())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForumPostCreationModal);
