import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { FormType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';

export const selectForms = (state: IApplicationState) => {
  return state.entities.forms;
};

export const getForms = createSelector(
  [selectForms],
  (forms): Optional<FormType[]> => {
    if (forms && forms.allIds && forms.allIds.length > 0) {
      const { forms: denormalizedForms } = denormalize({ forms: forms.allIds }, entitySchema, { forms: forms.byId });
      return toArray(denormalizedForms);
    }
    return undefined;
  }
);

export default { getForms };
