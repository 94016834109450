import { Card, Col, Collapse, Row, Table, Tabs } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import Username from '../../../components/username/Username';
import { renderDateWithTime, renderPopoverDate } from '../../../components/util/Util';
import * as selectors from '../../../redux/selectors';
import { getUserSurveysAsync } from '../../../redux/userSurvey/userSurvey.types';
import { UserSurveyType } from '../../../types/entity.types';
import IApplicationState from '../../../types/state.types';
import './surveys.scss';

const { TabPane } = Tabs;

interface StateProps {
  userSurveys: Optional<UserSurveyType[]>;
  studyId: number;
}

interface DispatchProps {
  getUserSurveys: typeof getUserSurveysAsync.request
}

interface ComponentProps extends DispatchProps, StateProps {};

class SurveysLandingPage extends Component<ComponentProps, StateProps> {

  readonly state = {
    userSurveys: undefined,
    studyId: -1,
  };

  componentDidMount() {
    this.props.getUserSurveys();
  }

  rowClassName = (record: any, index: number): string => {
    return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  };

  render() {
    const { userSurveys, studyId } = this.props

    const getStatus = (us:UserSurveyType) => {
      if (us.completedDate) return "Completed";

      const now = moment();
      const windowOpenDate = moment(us.windowOpen).startOf('day');
      const windowCloseDate = moment(us.windowClose).endOf('day');

      if (now.isBetween(windowOpenDate, windowCloseDate)) {
        return "Open";
      }

      if (now.isAfter(windowCloseDate)) {
        return "Missed";
      }

      if (now.isBefore(windowOpenDate)) {
        return "Upcoming";
      }

      return "BAD STATUS";
    }

    const columns = [
      {
        title: 'Participant ID',
        dataIndex: 'participant.id',
        key: 'participant.id',
        sorter: (a: any, b: any) => a.participant.id - b.participant.id
      },
      {
        width: '250px',
        title: 'Survey',
        dataIndex: 'surveyName',
        key: 'surveyName',
        sorter: (a: any, b: any) => `${a.surveyName}`.localeCompare(b.surveyName),
        filters: [
          {text: 'HMP Stigma-Post-BL Participant Verification', value: 'HMP Stigma-Post-BL Participant Verification'},
          {text: 'HMP Stigma-3-Month FUP', value:'HMP Stigma-3-Month FUP'},
          {text: 'HMP Stigma-6-Month FUP', value: 'HMP Stigma-6-Month FUP'},
          {text: 'HMP Stigma-9-Month FUP', value: 'HMP Stigma-9-Month FUP'},
          {text: 'HMP Stigma-12-Month FUP', value: 'HMP Stigma-12-Month FUP'} ],
        defaultFilteredValue: ['HMP Stigma-3-Month FUP', 'HMP Stigma-6-Month FUP', 'HMP Stigma-9-Month FUP', 'HMP Stigma-12-Month FUP' ],
        onFilter: (value:string, record:UserSurveyType) => record.surveyName === value,
        render: (value: any, row: any) => <a href={row.link}> {value}</a>
      },
      {
        title: 'Username',
        dataIndex: 'participant.id',
        key: 'participant.username',
        sorter: (a: any, b: any) => a.participant && b.participant && `${a.participant.username}`.localeCompare(b.participant.username),
        filters: _.map(_.sortedUniq(_.uniq(_.sortBy(_.compact(_.map(userSurveys, 'participant.username'))))), x => {
          return {text: x, value: x}
        }),
        onFilter: (value:string, record:UserSurveyType) => record.participant.username === value,
        render: (value: any, row: any) => <Username participantId={value} />,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (id:number, us:UserSurveyType) => getStatus(us),
        filters: [{text: 'Open', value: 'Open'}, {text: 'Upcoming', value: 'Upcoming'}, {text: 'Missed', value: 'Missed'}, {text: 'Completed', value: 'Completed'}],
        onFilter: (value:string, record:UserSurveyType) => getStatus(record) === value,
        sorter: (a: any, b: any) => {
          return getStatus(a).localeCompare(b.getStatus());
        },
      },
      {
        title: 'Window Open',
        dataIndex: 'windowOpen',
        key: 'windowOpen',
        render: renderPopoverDate,
        sorter: (a: any, b: any) =>  moment(a.windowOpen || 0).unix() - moment(b.windowOpen || 0).unix()
      },
      {
        title: 'Window Close',
        dataIndex: 'windowClose',
        key: 'windowClose',
        render: renderPopoverDate,
        sorter: (a: any, b: any) =>  moment(a.windowClose || 0).unix() - moment(b.windowClose || 0).unix()
      },
      {
        title: 'Days Remaining',
        dataIndex: 'daysRemaining',
        key: 'daysRemaining',
        sorter: (a: any, b: any) => a.daysRemaining - b.daysRemaining,
        defaultSortOrder: 'ascend'
      },
      {
        title: 'Completed',
        dataIndex: 'completedDate',
        key: 'completedDate',
        sorter: (a: any, b: any) => moment(a.completedDate || 0).unix() - moment(b.completedDate || 0).unix(),
        defaultSortOrder: 'ascend',
        render: renderDateWithTime
      },
    ]

    const cols = [
      { title: "Status", dataIndex: 'status'}, {title: "Participants", dataIndex: "participants"}
    ]

    const data = _.reduce(userSurveys, (data, us) => {
      // @ts-ignore
      data[us.surveyName][getStatus(us)]++;
      return data;
    },
      {
        "HMP Stigma-Post-BL Participant Verification": {
          Upcoming: 0, Open: 0, Missed: 0, Completed: 0
        },
        "HMP Stigma-3-Month FUP": {
          Upcoming: 0, Open: 0, Missed: 0, Completed: 0
        },
        "HMP Stigma-6-Month FUP": {
          Upcoming: 0, Open: 0, Missed: 0, Completed: 0
        },
        "HMP Stigma-9-Month FUP": {
          Upcoming: 0, Open: 0, Missed: 0, Completed: 0
        },
        "HMP Stigma-12-Month FUP": {
          Upcoming: 0, Open: 0, Missed: 0, Completed: 0
        }
      }
    );

    const columnizeData = (survey: string) => {
      // @ts-ignore
      return _.map(_.keys(data[ survey ]), (status: string) => {
        // @ts-ignore
        return {status: status, participants: parseInt(data[ survey ][ status ])} // @ts-ignore
      });
    }

    const month3data = columnizeData('HMP Stigma-3-Month FUP');
    const month6data = columnizeData('HMP Stigma-6-Month FUP');
    const month9data = columnizeData('HMP Stigma-9-Month FUP');
    const month12data = columnizeData('HMP Stigma-12-Month FUP');

    return (
      <div>
        <Tabs defaultActiveKey="overview">
          <TabPane key="overview" tab="Overview">
            <Row>
              <Col span={6}>
                <div className="padded">
                  <Card title="3-month FUP">
                    <Table columns={cols} dataSource={month3data} pagination={false}/>
                  </Card>
                </div>
              </Col>
              <Col span={6}>
                <div className="padded">
                  <Card title="6-month FUP">
                    <Table columns={cols} dataSource={month6data} pagination={false}/>
                  </Card>
                </div>
              </Col>
              <Col span={6}>
                <div className="padded">
                  <Card title="9-month FUP">
                    <Table columns={cols} dataSource={month9data} pagination={false}/>
                  </Card>
                </div>
              </Col>
              <Col span={6} >
                <div className="padded">
                  <Card title="12-month FUP">
                    <Table columns={cols} dataSource={month12data} pagination={false}/>
                  </Card>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane key="surveys" tab="Surveys">
            <div style={{flex: '0 0 auto', height: '100%'}}>
              <Table
                columns={columns}
                dataSource={userSurveys}
                scroll={{y: `calc(100vh - 150px)`}}
                pagination={false}
                rowClassName={this.rowClassName}
                rowKey="id"
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  };
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>( {
  userSurveys: selectors.getUserSurveys,
  studyId: selectors.getRequestedStudyId
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getUserSurveys: () => dispatch(getUserSurveysAsync.request())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (SurveysLandingPage));