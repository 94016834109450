import {Alert, Button, Checkbox, Input, message, Switch} from 'antd';
import * as _ from 'lodash';
import React, {ChangeEvent, Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Dispatch} from 'redux';
import {createStructuredSelector} from 'reselect';
import {getStudyArmLabelFromId} from '../../../components/util/Util';
import {updateNotificationSubscriptionAsync} from '../../../redux/notification/notification.types';
import * as selectors from '../../../redux/selectors';
import {
  PasswordArguments,
  updateEmailAsync,
  updateMobileAsync,
  updatePasswordAsync,
} from '../../../redux/user/user.types';
import {NotificationSubscription, ParticipantType, StudyArmType, UserType} from '../../../types/entity.types';
import IApplicationState from '../../../types/state.types';
import {IApiRequestStatus} from '../../../types/api.types';
import {clearStatus} from '../../../redux/api/api.types';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import './settings.scss';
import {getType} from 'typesafe-actions';


interface StateProps {
  user: Optional<UserType>;
  pseudoParticipants: Optional<ParticipantType[]>;
  studyArms: Optional<StudyArmType[]>;
  studyId: number;
  updatePasswordStatus: IApiRequestStatus;
  isNonYAB: boolean;
}

interface DispatchProps {
  updateNotificationSubscription: typeof updateNotificationSubscriptionAsync.request;
  updatePassword: typeof updatePasswordAsync.request;
  updateEmail: typeof updateEmailAsync.request;
  updateMobile: typeof updateMobileAsync.request;
  clearStatus: typeof clearStatus;
}

interface ComponentProps extends DispatchProps, StateProps { }

const initialState = {
  user: undefined as Optional<UserType>,
  pseudoParticipants: [] as Optional<ParticipantType[]>,
  editMobileVisible: false as boolean,
  editEmailVisible: false as boolean,
  editPasswordVisible: false as boolean,
  mobile: '' as Optional<string>,
  email: '' as Optional<string>,
  password: '' as Optional<string>,
  password2x: '' as Optional<string>,
  currentPassword: '' as Optional<string>,
  updatePseudoParticipants: false as boolean,
  passwordMessage: 'Password is required ' as Optional<string>
}

type ComponentState = typeof initialState;

class SettingsLandingPage extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = initialState;

  componentDidMount() {
    const mobile = this.props.user?.mobile ? this.props.user.mobile : '';
    const nonE164 =  _.startsWith(mobile, '+1') ? mobile.replace('+1', '') : mobile;
    this.setState({mobile: this.formatToPhone(nonE164), email: this.props.user?.email});
  }

  formatToPhone = (input:string) => {
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);

    if(input.length > 6) {
      input = `(${zip}) ${middle} - ${last}`;
    }
    else if(input.length > 3) {
      input = `(${zip}) ${middle}`;
    }
    else if(input.length > 0) {
      input = `(${zip}`;
    }
    return input;
  }

  rowClassName = (record: any, index: number): string => {
    return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  };

  updateMobileInState = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({mobile: event.target.value});
  }

  cancelSaveMobile = () => {
    const mobile = this.props.user?.mobile ? this.props.user.mobile : '';
    const nonE164 =  _.startsWith(mobile, '+1') ? mobile.replace('+1', '') : mobile;
    this.setState({mobile: this.formatToPhone(nonE164)});
    this.setState({editMobileVisible: false});
  }

  saveMobile = () => {
    const { mobile } = this.state;
    const formattedMobile = mobile ? '+1' + mobile.replace(/\D/g, '')
      : '+10000000000';
    this.props.updateMobile(formattedMobile);
    this.setState({editMobileVisible: false});
  }

  updateEmailInState = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({email: event.target.value});
  }

  cancelSaveEmail = () => {
    this.setState({editEmailVisible: false, email: this.props.user?.email});
  }

  saveEmail = () => {
    this.props.updateEmail(this.state.email);
    this.setState({editEmailVisible: false});
  }

  updatePasswordInState = (event: ChangeEvent<HTMLInputElement>) => {
    const { password2x } = this.state;
    let passwordMessage = undefined;
    if(!event.target.value) {
      passwordMessage = 'Password is required'
    } else if(event.target.value.length < 8) {
      passwordMessage = 'Password must be at least 8 characters'
    } else if(event.target.value !== password2x) {
      passwordMessage = 'Passwords should match'
    }
    this.setState({ password: event.target.value, passwordMessage });
  }

  updatePassword2xInState = (event: ChangeEvent<HTMLInputElement>) => {
    const { password } = this.state;
    let passwordMessage = undefined;
    if(!event.target.value) {
      passwordMessage = 'Password is required'
    } else if(event.target.value !== password) {
      passwordMessage = 'Passwords should match'
    }
    this.setState({ password2x: event.target.value, passwordMessage });
  }

  onCurrentPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentPassword: event.target.value });
  }

  onUpdatePseudoParticipantsChange = (event: CheckboxChangeEvent) => {
    this.setState({ updatePseudoParticipants: event.target.checked });
  }

  cancelSavePassword = () => {
    this.setState({password: '', password2x: '', passwordMessage: undefined, editPasswordVisible: false});
  }

  savePassword = () => {
    const {
      password,
      password2x,
      currentPassword,
      updatePseudoParticipants
    } = this.state;
    const { updatePassword } = this.props;
    if (password && password === password2x && currentPassword) {
      updatePassword({ password, currentPassword, updatePseudoParticipants });
      this.setState({ editPasswordVisible: false, password: '', currentPassword: '', password2x: '', updatePseudoParticipants: false });
    }
  }

  notificationOrder = ['NEW_MESSAGE', 'NEW_MESSAGE_IN_MY_THREAD', 'THREAD_ASSIGNED_TO_ME', 'NEW_ASK_THE_EXPERT'];

  render() {
    const {
      user,
      pseudoParticipants,
      studyArms,
      studyId,
      updatePasswordStatus,
      clearStatus,
      isNonYAB
    } = this.props
    const {
      editMobileVisible,
      mobile,
      editEmailVisible,
      email,
      editPasswordVisible,
      password,
      password2x,
      passwordMessage,
      currentPassword,
      updatePseudoParticipants
    } = this.state;

    if (!user) {
      return <div> loading</div>
    }

    if(updatePasswordStatus.isSuccess) {
      message.success('Password updated successfully.');
      clearStatus(getType(updatePasswordAsync.success));
    } else if(updatePasswordStatus.isError) {
      if(updatePasswordStatus.errorStatus === 401) {
        message.error('Current password was incorrect.', 3);
      } else {
        message.error(updatePasswordStatus.errorMessage);
      }
      clearStatus(getType(updatePasswordAsync.failure));
    }

    let {notificationSubscriptions} = user;

    notificationSubscriptions = _.sortBy(notificationSubscriptions, ns => _.indexOf(this.notificationOrder, ns.label));

    const ateNewQuestionSub = _.find(notificationSubscriptions, ns => ns.label === 'NEW_ASK_THE_EXPERT');
    const ateAdHocSub = _.find(notificationSubscriptions, ns => ns.label === 'ASK_THE_EXPERT_AD_HOC');
    const inboxNewMessageSub = _.find(notificationSubscriptions, ns => ns.label === 'NEW_MESSAGE');
    const inboxNewMessageInMyThreadSub = _.find(notificationSubscriptions, ns => ns.label === 'NEW_MESSAGE_IN_MY_THREAD');
    const inboxThreadAssignedToMeSub = _.find(notificationSubscriptions, ns => ns.label === 'THREAD_ASSIGNED_TO_ME');

    const showMobileInputField = () => {
      this.setState({editMobileVisible: true});
    }

    const showEmailInputField = () => {
      this.setState({editEmailVisible: true});
    }

    const showPasswordInputField = () => {
      this.setState({editPasswordVisible: true});
    }

    const isNumericInput = (event: KeyboardEvent) => {
      const key = event.keyCode;
      return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
      );
    };

    const isModifierKey = (event:KeyboardEvent) => {
      const key = event.keyCode;
      return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
          // Allow Ctrl/Command + A,C,V,X,Z
          (event.ctrlKey === true || event.metaKey === true) &&
          (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
    };

    const enforceFormat = (event: KeyboardEvent) => {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if(!isNumericInput(event) && !isModifierKey(event)){
        event.preventDefault();
      }
    };

    const formatToPhoneHandler = (event:KeyboardEvent) => {
      if(isModifierKey(event)) {return;}

      // I am lazy and don't like to type things more than once
      const target = event.target;
      const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
      const zip = input.substring(0,3);
      const middle = input.substring(3,6);
      const last = input.substring(6,10);

      if(input.length > 6){target.value = `(${zip}) ${middle} - ${last}`;}
      else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
      else if(input.length > 0){target.value = `(${zip}`;}
    };

    return (
      <div id="settings">
        <div className="titleRow">
          <h3>Details</h3>
        </div>
        <div className="details indent">
          <div className="settings-column">
            <p>Id: {user?.id}</p>
            {editMobileVisible ?
              <span className="editable-field">
                <Input key="mobile" style={{width: '200px'}} onChange={this.updateMobileInState} onKeyDown={enforceFormat} onKeyUp={formatToPhoneHandler} value={mobile} />
                <Button className="settings-button" onClick={this.saveMobile}>Save</Button>
                <Button className="settings-button" onClick={this.cancelSaveMobile}>Cancel</Button>
              </span>
              : <span className="editable-field">
                  <p>Mobile: {mobile ? mobile : '(none)'}</p>
                  <Button className="settings-button" onClick={showMobileInputField}>Edit</Button>
                </span>
            }
            {editEmailVisible ?
              <span className="editable-field">
                <Input key="email" onChange={this.updateEmailInState} value={email} />
                <Button className="settings-button" onClick={this.saveEmail}>Save</Button>
                <Button className="settings-button" onClick={this.cancelSaveEmail}>Cancel</Button>
              </span>
              : <span className="editable-field">
                <p>Email: {email ? email : '(none)'}</p>
                <Button className="settings-button" onClick={showEmailInputField}>Edit</Button>
              </span>
            }
            {editPasswordVisible ?
              <span className="editable-field active">
                Current Password: <Input.Password visibilityToggle key="current-password" onChange={this.onCurrentPasswordChange} value={currentPassword}/>
                New Password: <Input.Password visibilityToggle={true} key="pwd1"
                                          onChange={this.updatePasswordInState}
                                          value={password} />
                Confirm New Password: <Input.Password visibilityToggle={true} key="pwd2" onChange={this.updatePassword2xInState} value={password2x} />
                <Checkbox onChange={this.onUpdatePseudoParticipantsChange} checked={updatePseudoParticipants}>Also update pseudo-participant passwords associated with your user?</Checkbox>
                <div style={{display: 'flex', flexDirection: 'row', paddingTop: '5px', alignContent: 'space-between', width: '100%' }}>
                  {passwordMessage ?
                    <Alert type="error" message={passwordMessage}/>
                    :
                    <span>
                      <Button className="settings-button" onClick={this.savePassword}>Save</Button>
                    </span>
                  }
                  <Button className="settings-button" onClick={this.cancelSavePassword}>Cancel</Button>

                </div>
              </span>
              : <span className="editable-field">
                  <p>Password: ****************** </p>
                  <Button className="settings-button" onClick={showPasswordInputField}>Edit</Button>
                </span>
            }
          </div>

        </div>
        <div>
        <div className="titleRow">
          <h3>Roles</h3>
        </div>
          <div>
            {_.map(user.roles, role => {
              return (
                <div className="details indent">
                  <div className="settings-column">
                    <p>{role.role.role} : {role.role.description} </p>
                  </div>
                </div>
              )
            })
            }
          </div>
        </div>


        { pseudoParticipants?.length ?
          <div>
            <div className="titleRow">
              <h3>Pseudo Participants</h3>
            </div>
            <div className="tableRow">
              <table id="pseudos-table">
                <thead>
                <tr style={{borderBottom: '1px solid #8185B3'}}>
                  <th>Username</th>
                  <th>Study Arm</th>
                  <th>Id</th>
                </tr>
                </thead>
                <tbody>
                {_.map(pseudoParticipants, p => {
                  return p ? <tr key={p.id}>
                    <td><a href={`/study/${studyId}/participants/${p.id}`}>{p.username}</a></td>
                    <td>{getStudyArmLabelFromId(studyArms, p.studyArmId)}</td>
                    <td>{p.id}</td>
                  </tr> : undefined
                })}
                </tbody>
              </table>
            </div>
          </div>
          : undefined
        }

        {isNonYAB ?
          <div>
            <div className="titleRow">
              <h3>Notification Settings</h3>
            </div>
            <div className="titleRow indent">
              <h4>Ask the Expert</h4>
              <p style={{paddingLeft: '145px'}}>Email</p>
              <p style={{paddingLeft: '20px'}}>Sms</p>
            </div>
            <div className="subRow">
              <p>New Question</p> &nbsp; &nbsp;
              <Switch key="switch-email-new-question" checked={ateNewQuestionSub.emailOn === 1 ? true : false}
                     onChange={(checked) => { this.updateNotificationSubscription({...ateNewQuestionSub, emailOn: checked ? 1 : 0, userId: user.id}) }}/>
              <Switch key="switch-email-new-question" checked={ateNewQuestionSub.smsOn === 1 ? true : false}
                      onChange={(checked) => { this.updateNotificationSubscription({...ateNewQuestionSub, smsOn: checked ? 1 : 0, userId: user.id}) }}/>
            </div>
            <div className="subRow">
              <p>Question Assigned To Me</p> &nbsp; &nbsp;
              <Switch key="switch-email-new-question" checked={ateAdHocSub.emailOn === 1 ? true : false}
                      onChange={(checked) => { this.updateNotificationSubscription({...ateAdHocSub, emailOn: checked ? 1 : 0, userId: user.id}) }}/>
              <Switch key="switch-email-new-question" checked={ateAdHocSub.smsOn === 1 ? true : false}
                      onChange={(checked) => { this.updateNotificationSubscription({...ateAdHocSub, smsOn: checked ? 1 : 0, userId: user.id}) }}/>
            </div>
            <div className="titleRow indent">
              <h4>Inbox</h4>
              <p style={{paddingLeft: '203px'}}>Email</p>
              <p style={{paddingLeft: '20px'}}>Sms</p>
            </div>
            <div className="subRow">
              <p>New Message</p> &nbsp; &nbsp;
              <Switch key="switch-email-new-question" checked={inboxNewMessageSub.emailOn === 1 ? true : false}
                      onChange={(checked) => {
                        this.updateNotificationSubscription({...inboxNewMessageSub, emailOn: checked ? 1 : 0, userId: user.id})
                        if (checked) {
                          this.updateNotificationSubscription({...inboxNewMessageInMyThreadSub, emailOn: 0, userId: user.id});
                        }
                      }}/>
              <Switch key="switch-email-new-question" checked={inboxNewMessageSub.smsOn === 1 ? true : false}
                      onChange={(checked) => {
                        this.updateNotificationSubscription({...inboxNewMessageSub, smsOn: checked ? 1 : 0, userId: user.id})
                        if (checked) {
                          this.updateNotificationSubscription({...inboxNewMessageInMyThreadSub, smsOn: 0, userId: user.id});
                        }
                      }}/>
            </div>
            <div className="subRow">
              <p>New Message In My Thread</p> &nbsp; &nbsp;
              <Switch key="switch-email-new-question" disabled={inboxNewMessageSub.emailOn === 1 ? true : false} checked={inboxNewMessageInMyThreadSub.emailOn === 1 ? true : false}
                      onChange={(checked) => { this.updateNotificationSubscription({...inboxNewMessageInMyThreadSub, emailOn: checked ? 1 : 0, userId: user.id}) }}/>
              <Switch key="switch-email-new-question" disabled={inboxNewMessageSub.smsOn === 1 ? true : false} checked={inboxNewMessageInMyThreadSub.smsOn === 1 ? true : false}
                      onChange={(checked) => { this.updateNotificationSubscription({...inboxNewMessageInMyThreadSub, smsOn: checked ? 1 : 0, userId: user.id}); }}/>
            </div>
            <div className="subRow">
              <p>Thread Assigned To Me</p> &nbsp; &nbsp;
              <Switch key="switch-email-new-question" checked={inboxThreadAssignedToMeSub.emailOn === 1 ? true : false}
                      onChange={(checked) => { this.updateNotificationSubscription({...inboxThreadAssignedToMeSub, emailOn: checked ? 1 : 0, userId: user.id}) }}/>
              <Switch key="switch-email-new-question" checked={inboxThreadAssignedToMeSub.smsOn === 1 ? true : false}
                      onChange={(checked) => { this.updateNotificationSubscription({...inboxThreadAssignedToMeSub, smsOn: checked ? 1 : 0, userId: user.id}) }}/>
            </div>

          </div>

          : null }
      </div>
    );
  };

  private updateNotificationSubscription(subscription: NotificationSubscription) {
    console.log(subscription);
    this.props.updateNotificationSubscription(subscription);
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  user: selectors.getUser,
  pseudoParticipants: selectors.getCurrentUserPseudoParticipants,
  studyArms: selectors.getStudyArms,
  studyId: selectors.getRequestedStudyId,
  updatePasswordStatus: selectors.updatePasswordStatus,
  isNonYAB: selectors.isNonYAB
});


const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    updatePassword: (args: PasswordArguments) => dispatch(updatePasswordAsync.request(args)),
    updateMobile: (mobile: string) => dispatch(updateMobileAsync.request(mobile)),
    updateEmail: (email: string) => dispatch(updateEmailAsync.request(email)),
    updateNotificationSubscription: (subscription: NotificationSubscription) => dispatch(updateNotificationSubscriptionAsync.request(subscription)),
    clearStatus: (type: string) => dispatch(clearStatus(type))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsLandingPage));