import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import { MessageThreadType } from '../../types/entity.types';
import {
  getParticipantThreadsAsync,
  getMessageInboxAsync,
  MessagesActionTypes,
  createThreadAsync,
  archiveThreadAsync,
  unarchiveThreadAsync,
  markThreadImportantAsync,
  unmarkThreadImportantAsync,
  markThreadUnreadAsync,
  assignThreadAsync,
  unassignThreadAsync,
  clearMessages
} from './messages.types';

const updateThreadsReducer = produce((draft, threads) => {
  if (!threads) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(threads), Number));
  const ids = Object.keys(threads);
  for (const id of ids) {
    draft.byId[id] = threads[id];
  }
});

export const threadsReducer = (state: NormalizedState<MessageThreadType> = initialNormalizedState, action: MessagesActionTypes) => {
  switch (action.type) {
    case getType(getParticipantThreadsAsync.success):
      return updateThreadsReducer(state, action.payload);
    case getType(getMessageInboxAsync.success):
      return updateThreadsReducer(state, action.payload);
    case getType(createThreadAsync.success):
      return updateThreadsReducer(state, action.payload);
    case getType(archiveThreadAsync.success):
      return updateThreadsReducer(state, action.payload);
    case getType(unarchiveThreadAsync.success):
      return updateThreadsReducer(state, action.payload);
    case getType(markThreadImportantAsync.success):
      return updateThreadsReducer(state, action.payload);
    case getType(unmarkThreadImportantAsync.success):
      return updateThreadsReducer(state, action.payload);
    case getType(markThreadUnreadAsync.success):
      return updateThreadsReducer(state, action.payload);
    case getType(assignThreadAsync.success):
      return updateThreadsReducer(state, action.payload);
    case getType(unassignThreadAsync.success):
      return updateThreadsReducer(state, action.payload);
    case getType(clearMessages):
      return { byId: {}, allIds: [] };
    default:
      return state;
  }
};

export default threadsReducer;
