import React, { Component } from "react"
import moment from 'moment';
import { MessageType } from "../../types/entity.types";
import { CSSTransition } from "react-transition-group";

interface ComponentProps {
  message: MessageType;
  isMine: boolean;
  startsSequence: boolean;
  endsSequence: boolean;
  showTimestamp: boolean;
}

class Message extends Component<ComponentProps,{}> {
  readonly state = {
    showMessageTimestamp: false
  };
  private messageTimestampRef: any;
  constructor(props: ComponentProps) {
    super(props);
    this.messageTimestampRef = React.createRef();
  }
  toggleShowTimestamp = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const { showMessageTimestamp } = this.state;
    this.setState({ showMessageTimestamp: !showMessageTimestamp });
  };

  render() {
    const {
      message,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp
    } = this.props;
    const { showMessageTimestamp } = this.state;
    const friendlyTimestamp = moment(message.createDate).format('LLLL');
    return (
      <div className={[
        'message',
        `${isMine ? 'mine' : ''}`,
        `${startsSequence ? 'start' : ''}`,
        `${endsSequence ? 'end' : ''}`
      ].join(' ')}>
        { showTimestamp &&
          <div className="timestamp">
            { friendlyTimestamp }
          </div> 
        }
        <div className="bubble-container">
          <div className="bubble" title={friendlyTimestamp} onClick={this.toggleShowTimestamp}>
            { message.body }
          </div>
        </div>
        <CSSTransition
          in={showMessageTimestamp}
          appear
          timeout={400}
          classNames="message-timestamp-transition"
          unmountOnExit
        >
          <div ref={this.messageTimestampRef} className={"message-timestamp"}>
            { friendlyTimestamp }
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default Message;