import React, { ChangeEvent } from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import {
  Input, Checkbox
} from 'antd';
import '../activity.scss';
import { ActivityQuestionType, ActivityOptionType } from '../../../types/entity.types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import DynamicOptionItems from '../options/DynamicOptionItems';

interface ComponentProps {
  form: any;
  creative: boolean;
  editable: boolean;
  question: ActivityQuestionType;
  setStatus: (status: string) => void;
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  updateQuestion: (q: ActivityQuestionType) => void;
}

enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener
}
const defaultOption = {
  text: '',
  uuid: '',
  points: 0,
  isCorrect: false,
  correctPosition: undefined,
  feedback: undefined
};

const QuizQuestion = (props: ComponentProps) => {
  const { 
    question, 
    onTextChange, 
    updateQuestion, 
    form, 
    creative,
    editable,
    setStatus 
  } = props;
  let { text, options, randomizeOptions, uuid } = question;
  const onRandomizeOptions = (e: CheckboxChangeEvent) => {
    question.randomizeOptions = e.target.checked;
    updateQuestion(question);
  }
  const updateOption = (option: ActivityOptionType, index: number) => {
    if(options) {
      options[index] = option;
      updateQuestion(question);
    }
  }
  const addOption = () => {
    let updatedQuestion = _.cloneDeep(question);
    const newOption = _.cloneDeep(defaultOption);
    newOption.uuid = generateUUID();
    if(!updatedQuestion.options) {
      updatedQuestion.options = [newOption]
    } else {
      updatedQuestion.options[updatedQuestion.options.length] = newOption;
    }
    updateQuestion(updatedQuestion);
  }
  const removeOption = (index: number) => {
    let i = 0;
    question.options = options ? options.filter(o => { return index === i++ ? false : true; }) : [];
    updateQuestion(question);
  }
  return (
    <div>
      <Input spellCheck disabled={!creative && !editable} placeholder='Question text' defaultValue={text} onChange={onTextChange} />
      <Checkbox disabled={!creative && !editable} checked={randomizeOptions} onChange={onRandomizeOptions}>Randomize answer options</Checkbox>
      <DynamicOptionItems 
      form={form}
      creative={creative}
      editable={editable}
      options={options ? options : []} 
      type={Type.quiz} 
      setStatus={setStatus}
      updateOption={updateOption} 
      addOption={addOption} 
      removeOption={removeOption}
    />
    </div>
  );
};

export default QuizQuestion;