import { ActionType, createAsyncAction } from 'typesafe-actions';
import {NoteType, ParticipantType} from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';

export const getParticipantNotesAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANT_NOTES_REQUEST',
  'hmp/admin/GET_PARTICIPANT_NOTES_SUCCESS',
  'hmp/admin/GET_PARTICIPANT_NOTES_FAILURE'
)<number, Optional<NormalizedType<NoteType>>, Error>();

export const saveParticipantNoteAsync = createAsyncAction(
  'hmp/admin/SAVE_PARTICIPANT_NOTE_REQUEST',
  'hmp/admin/SAVE_PARTICIPANT_NOTE_SUCCESS',
  'hmp/admin/SAVE_PARTICIPANT_NOTE_FAILURE'
)<NoteType, Optional<NormalizedType<NoteType>>, Error>();


const notesActions = {
  getParticipantNotesAsync,
  saveParticipantNoteAsync
};

export type NotesActionTypes = ActionType<typeof notesActions>;
