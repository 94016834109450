import { _ } from 'lodash';
import { CSVLink } from 'react-csv';
import { Button, Spin, Table } from 'antd';
import Column from 'antd/es/table/Column';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { renderDateWithoutTime, renderDateWithTime } from '../../components/util/Util';
import { getPaymentsAsync, getPaymentsReportAsync } from '../../redux/payment/payment.types';
import * as selectors from '../../redux/selectors';
import './report.scss';
import { IApiRequestState } from '../../types/api.types';

import { PaymentType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';

interface StateProps {
  payments: Optional<PaymentType[]>
  getPaymentsReportStatus: IApiRequestState,
}

interface DispatchProps {
  getPaymentsReport: typeof getPaymentsReportAsync.request
}

interface ComponentProps extends DispatchProps, StateProps { }

const initialState = {
}

type ComponentState = typeof initialState;

class IncentivesReport extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = initialState;

  componentDidMount() {
    this.props.getPaymentsReport();
  }

  rowClassName = (record: any, index: number): string => {
    return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  };

  render() {
    const { payments, getPaymentsReportStatus } = this.props

    if (getPaymentsReportStatus.isLoading) {
      return <Spin/>;
    }
    const headers = [
      { label: "Study ID", key: "studyId" },
      { label: "Amazon Order #", key: "trackingInfo" },
      { label: "Milestone", key: "label" },
      { label: "Amount", key: "amount" },
      { label: "paid", key: "paidDate" },
    ];

    return (
      <div>
        <CSVLink data={payments} headers={headers} filename={`Incentives-Report-${renderDateWithoutTime(new Date())}`}><Button>Export as CSV</Button></CSVLink>
        <Table {...this.state}
               style={{paddingTop: '10px'}}
               dataSource={payments}
               rowClassName={this.rowClassName}
               rowKey={"id"}
               scroll={{y: `calc(100vh - 200px)`}}
               pagination={false}
        >
          <Column title='StudyID' dataIndex='studyId' key='studyId'
                  sorter={(a: any, b: any) => a.studyId - b.studyId} />
          <Column title='Amazon Order #' dataIndex="trackingInfo" key="trackingInfo"
                  sorter={(a:any, b: any) =>  `${a.trackingInfo}`.localeCompare(b.trackingInfo)} />
          <Column title='Milestone' dataIndex='label' key='label'
                  sorter={(a: any, b: any) => `${a.label}`.localeCompare(b.label)} />
          <Column title='Amount' dataIndex='amount' key='amount'
                  render={(amount:any) => `$${amount}` }
                  sorter={(a: any, b: any) => a.amount - b.amount} />
          <Column title='Paid' dataIndex='paidDate' key='paidDate'
                  render={(paidDate:any) => `${paidDate ? renderDateWithTime(paidDate) : ''}` }
                  defaultSortOrder='descend'
                  sorter={(a: any, b: any) => {
                    if (!a.paidDate && !b.paidDate) return 0;
                    if (!a.paidDate && b.paidDate) return 1;
                    if (a.paidDate && !b.paidDate) return -1;
                    else return moment(a.paidDate).unix() - moment(b.paidDate).unix()
                  }} />

        </Table>
      </div>
    );
  };

}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  payments: selectors.getPayments,
  getPaymentsReportStatus: selectors.getPaymentsReportStatus
});


const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getPaymentsReport: () => dispatch(getPaymentsReportAsync.request()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IncentivesReport));