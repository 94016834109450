import { Alert, Button, DatePicker, Descriptions, Input } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { fulfillTestkitAsync } from '../../redux/testkit/testkit.types';
import { TestkitType } from '../../types/entity.types';

const { Item } = Descriptions;

interface StateProps {
}

interface DispatchProps {
  fulfillTestkit: typeof fulfillTestkitAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  testkit: TestkitType
}

const inputStyle = {
  marginTop: '5px',
  marginBottom: '5px'
}

class TestkitRequestedCard extends Component<ComponentProps, {}> {
  readonly state = {
    trackingNumber: '',
    returnTrackingNumber: '',
    estimatedDeliveryDate: '',
    fulfillmentError: false
  };

  componentDidMount() {
  }

  onChange(state: any) {
    this.setState(state);
  }

  handleFulfillment = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { fulfillTestkit, testkit } = this.props;
    const { id } = testkit;
    const { trackingNumber, returnTrackingNumber, estimatedDeliveryDate } = this.state;
    if(trackingNumber.length > 0 && estimatedDeliveryDate.length > 0) {
      const testkitArg = {
        id,
        trackingNumber,
        returnTrackingNumber,
        estimatedDeliveryDate
      };
      fulfillTestkit(testkitArg);
      this.setState({ fulfillmentError: false });
    } else {
      this.setState({ fulfillmentError: true });
    }
  };

  render() {
    const { testkit } = this.props;
    const { participant } = testkit;

    if(!participant) {
      return <div>Participant Not Found.</div>
    }

    return (
      <div>
        <Descriptions layout='vertical' column={testkit.type.match(/hemaspot/i) ? 6 : 5} size='small' colon={false}>
          <Item label='Fulfillment:' span={1}>
            <span>{`${testkit.firstName} ${testkit.lastName}`}</span><br />
            <span>{`${testkit.address1}`}</span><br />
            {testkit.address2 ? <span>{`${testkit.address2}`}<br /></span> : ''}
            <span>{`${testkit.city}, ${testkit.state} ${testkit.postalCode}`}</span><br />
          </Item>
          <Item label="Type">
            <span style={{fontSize: '18px', fontWeight: 'bold'}}>{`${testkit.type}`}</span>
          </Item>
          <Item label='Tracking ID:'><Input placeholder='Tracking ID' style={inputStyle} onChange={(event) => this.onChange({ trackingNumber: event.target.value })}></Input></Item>
          <Item label='Estimated Delivery Date:'><DatePicker style={inputStyle} onChange={(date, dateString) => this.onChange({ estimatedDeliveryDate: dateString })}></DatePicker></Item>
          {testkit.type.match(/hemaspot/i) &&
          <Item label='Return Tracking ID:'><Input placeholder='Return Tracking ID' style={inputStyle} onChange={(event) => this.onChange({ returnTrackingNumber: event.target.value })}></Input></Item>
          }
          <Item><Button type='primary' onClick={this.handleFulfillment} style={inputStyle}>Submit</Button></Item>
        </Descriptions>
        <Alert style={this.state.fulfillmentError ? {visibility: 'visible'} : {visibility: 'hidden'}} message='Must provide Tracking ID and Estimated Delivery Date.' type='error' showIcon />
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fulfillTestkit: (testkit) => dispatch(fulfillTestkitAsync.request(testkit))
  };
};

export default connect(null, mapDispatchToProps)(TestkitRequestedCard);
