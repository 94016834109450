import * as _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import VirtualList from 'react-tiny-virtual-list';
import { PostType } from '../../../types/entity.types';
import './forumPage.scss';
import Post from './Post';


interface StateProps {
  posts: PostType[],
  height: number,
  scrollTop: number,
  onScroll: Function,
  selectedPostId: Optional<number>,
  onSelectPost: Function,
  currentPage: number
}

interface DispatchProps {
}

interface ComponentProps extends StateProps, DispatchProps {}

const initialState = {
  selectedPostId: undefined as Optional<number>,
  currentPage: 0 as number,
};

type ComponentState = typeof initialState;


class PostList extends Component<ComponentProps, ComponentState> {

  readonly state = initialState;

  renderPostItem = ({ index, style }: {index: number, style: any}) => {

    const { posts } = this.props;

    if (!posts || (posts && !posts[index])) {
      return <div>Unknown Error</div>;
    }

    const post = posts[index];
    return <div key={post.id} style={style} onClick={() => this.props.onSelectPost(post)}><Post mode="partial" key={post.id} post={post} /></div>;
  };

  render() {

    const {  posts = [], scrollTop, selectedPostId } = this.props;
    const height = this.props.height ? this.props.height : '100%';
    const selectedPost = _.find(posts, p => p.id === selectedPostId);

    const listItemSize: number = 220;

    return (
      <div className="hmp-forum-page-tab-container">
        <div className="hmp-forum-page-tab-content">
          <div id="postListLeft" className="postList">
            <div className="centerList">
              <VirtualList
                      width='100%'
                      style={{backgroundColor: 'black'}}
                      height={height}
                      itemCount={posts.length}
                      itemSize={listItemSize}
                      scrollOffset={scrollTop}
                      renderItem={this.renderPostItem}
                      onScroll={this.props.onScroll}
                    />
            </div>
          </div>
          <div id="selectedPostRight" className="postList">
            <div className="centerList" style={{height: height}}>
               {selectedPost && <Post mode="full" post={selectedPost} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(PostList);
