import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import _ from 'lodash';
import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { NormalizerResult, QnaPostType } from '../../types/entity.types';
import axios from '../api';
import { loadDashboardDataAsync } from '../dashboard/dashboard.types';
import { entitySchema } from '../schema';
import {
  getQnaPostsAsync,
  getQnaPostAsync,
  deleteQnaPostAsync,
  createQnaPostAsync,
  updateQnaPostAsync,
  getFlaggedQnaContentAsync,
  GetQnaPostsArguments,
  getQnaPostCountAsync
} from './qna.types';
import { createJsonParam }   from '../../redux/user/socialUtil';
import { getCommentsAsync }  from '../../redux/comments/comments.types';
import { getThumbsupsAsync } from '../../redux/thumbsups/thumbsups.types';
import { getFavoritesAsync } from '../../redux/favorites/favorites.types';

const getQnaPosts = (args: GetQnaPostsArguments) => {
  let { studyId, pageNumber, pageSize, previewLength, query, answers, includeDeleted } = args;
  if(!pageNumber) {
    pageNumber = 0;
  }
  if(!pageSize) {
    pageSize = 25;
  }

  const includeDeletedParam = includeDeleted ? 'true' : 'false';

  let url = `/a/qna/${studyId}/posts?pageSize=${pageSize}&pageNumber=${pageNumber}&includeDeleted=${includeDeletedParam}&answers=${answers}`;

  if (previewLength) {
    url += `&previewLength=${previewLength}`;
  }

  if (query) {
    url += `&searchText=${query}`;
  }

  return axios({
    method: 'get',
    url
  });
};

const getQnaPost = (id: number) => {
  return axios({
    method: 'get',
    url: `/a/qna/post/${id}/`
  });
};

const getQnaPostCount = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/qna/${studyId}/postCount`
  });
};

const deleteQnaPost = (id: number) => {
  return axios({
    method: 'delete',
    url: `/a/qna/post/${id}`
  });
};

const createQnaPost = (post: QnaPostType) => {
  return axios({
    method: 'put',
    url: `/a/qna`,
    data: post
  });
};

const updateQnaPost = (post: QnaPostType) => {
  return axios({
    method: 'put',
    url: `/a/qna/${post.id}/update`,
    data: post
  });
};

const getFlaggedQnaContent = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/qna/${studyId}/flagged`
  });
};

function* getQnaPostsHandler(action: ReturnType<typeof getQnaPostsAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getQnaPosts, action.payload)) as AxiosResponse;
    const { entities, result } = normalize(response.data, entitySchema.qnaPosts) as NormalizerResult;
    const { qnaPosts } = entities;

    if (result?.length) {
      const jsonParam = [createJsonParam('qna', result)];
      yield put(getCommentsAsync.request(jsonParam));
      yield put(getThumbsupsAsync.request(jsonParam));
      yield put(getFavoritesAsync.request(jsonParam));
    }

    yield put(getQnaPostsAsync.success(qnaPosts));
  } catch (error) {
    yield put(getQnaPostsAsync.failure(error));
  }
}

function* getQnaPostHandler(action: ReturnType<typeof getQnaPostAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getQnaPost, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.qnaPosts) as NormalizerResult;
    const { qnaPosts } = entities;
    yield put(getQnaPostAsync.success(qnaPosts));
  } catch (error) {
    yield put(getQnaPostAsync.failure(error));
  }
}

function* deleteQnaPostHandler(action: ReturnType<typeof deleteQnaPostAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(deleteQnaPost, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.qnaPosts) as NormalizerResult;
    const { qnaPosts } = entities;
    yield put(deleteQnaPostAsync.success(qnaPosts));
    yield put(loadDashboardDataAsync.request());
  } catch (error) {
    yield put(deleteQnaPostAsync.failure(error));
  }
}

function* createQnaPostHandler(action: ReturnType<typeof createQnaPostAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(createQnaPost, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.qnaPosts) as NormalizerResult;
    const { qnaPosts } = entities;
    yield put(createQnaPostAsync.success(qnaPosts));
    yield put(loadDashboardDataAsync.request());
  } catch (error) {
    yield put(createQnaPostAsync.failure(error));
  }
}

function* updateQnaPostHandler(action: ReturnType<typeof updateQnaPostAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(updateQnaPost, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.qnaPosts) as NormalizerResult;
    const { qnaPosts } = entities;
    yield put(updateQnaPostAsync.success(qnaPosts));
    yield put(loadDashboardDataAsync.request());
  } catch (error) {
    yield put(updateQnaPostAsync.failure(error));
  }
}

function* getFlaggedQnaContentHandler(action: ReturnType<typeof getFlaggedQnaContentAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getFlaggedQnaContent, action.payload)) as AxiosResponse;
    const { entities, result } = normalize(response.data, entitySchema.qnaPosts) as NormalizerResult;
    const { qnaPosts } = entities;

    if (result?.length) {
      const jsonParam = [createJsonParam('qna', result)];
      yield put(getCommentsAsync.request(jsonParam));
      yield put(getThumbsupsAsync.request(jsonParam));
      yield put(getFavoritesAsync.request(jsonParam));
    }

    yield put(getFlaggedQnaContentAsync.success(qnaPosts));
  } catch (error) {
    yield put(getFlaggedQnaContentAsync.failure(error));
  }
}

function* getQnaPostCountHandler(action: ReturnType<typeof getQnaPostCountAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getQnaPostCount, action.payload)) as AxiosResponse;
    yield put(getQnaPostCountAsync.success(response.data));
  } catch (error) {
    yield put(getQnaPostCountAsync.failure(error));
  }
}

function* getQnaPostsWatcher() {
  yield takeLatest(getType(getQnaPostsAsync.request), getQnaPostsHandler);
}

function* getQnaPostWatcher() {
  yield takeLatest(getType(getQnaPostAsync.request), getQnaPostHandler);
}

function* deleteQnaPostWatcher() {
  yield takeLatest(getType(deleteQnaPostAsync.request), deleteQnaPostHandler);
}

function* createQnaPostWatcher() {
  yield takeLatest(getType(createQnaPostAsync.request), createQnaPostHandler);
}

function* updateQnaPostWatcher() {
  yield takeLatest(getType(updateQnaPostAsync.request), updateQnaPostHandler);
}

function* getFlaggedQnaContentWatcher() {
  yield takeLatest(getType(getFlaggedQnaContentAsync.request), getFlaggedQnaContentHandler);
}

function* getQnaPostCountWatcher() {
  yield takeLatest(getType(getQnaPostCountAsync.request), getQnaPostCountHandler);
}
export default function* qnaSaga() {
  yield all([
    fork(getQnaPostsWatcher),
    fork(getQnaPostWatcher),
    fork(deleteQnaPostWatcher),
    fork(createQnaPostWatcher),
    fork(updateQnaPostWatcher),
    fork(getFlaggedQnaContentWatcher),
    fork(getQnaPostCountWatcher),
  ]);
}
