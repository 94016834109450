import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { StudyActionTypes, updateStudies } from './study.types';
import { StudyType } from '../../types/entity.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';

const updateStudiesReducer = produce((draft, studies) => {
  if (!studies) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(studies), Number));
  const ids = Object.keys(studies);
  for (const id of ids) {
    draft.byId[id] = studies[id];
  }
});

export const studyReducer = (state: NormalizedState<StudyType> = initialNormalizedState, action: StudyActionTypes) => {

  switch (action.type) {
    case getType(updateStudies): return updateStudiesReducer(state, action.payload);
    default: return state;
  }
};

export default studyReducer;
