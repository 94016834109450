import Collapse from 'antd/lib/collapse';
import Card from 'antd/lib/card';
import Icon from 'antd/lib/icon';
import React, { Component } from 'react';
import NotesTable from "../../../../components/note/NotesTable";
import {NoteType, ParticipantType} from "../../../../types/entity.types";
import {saveParticipantNoteAsync} from "../../../../redux/notes/notes.types";
import './participant.scss';
import Button from "antd/lib/button";
import FormContextProvider from '../../../../components/form/FormContextProvider';
import Form, { FormComponentProps } from 'antd/lib/form';
import Input from "antd/lib/input";
import PageHeader from 'antd/lib/page-header';

interface ComponentProps extends FormComponentProps{
  participant: ParticipantType,
  notes: Optional<NoteType[]>,
  saveClickHandler: (note: NoteType) => void
}

const initialState = {
  inAddMode: false as boolean,
  addedNote: {} as NoteType
};
type ComponentState = Readonly<typeof initialState>;

class ParticipantNoteCard extends Component<ComponentProps, ComponentState> {

  readonly state: ComponentState = initialState;

  handleSubmit = (e: any )=> {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.state.addedNote.participantId = this.props.participant.id;
        this.props.saveClickHandler(this.state.addedNote);
        this.setState({inAddMode: false});
      }
    });
  };

  handleAddClick = () => {
    this.setState({inAddMode: true})
  };

  handleCancelClick = () => {
    this.setState({ inAddMode: false });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      participant,
      notes
    } = this.props;

    const { addedNote } = this.state;

    const {Panel} = Collapse;

    const genExtra = () => (
      // <Icon type="plus-circle-o" onClick={this.handleAddClick}>
        <Button size="small" onClick={this.handleAddClick}>Add Note</Button>
    );

    return (
      <div id="participant-notes-container">
          {this.state.inAddMode
            ? <Card key="add">
              <PageHeader title="Add Note"/>
                <FormContextProvider>
                  <Form layout="inline" key={participant.id} onSubmit={this.handleSubmit}>
                    <Form.Item style={{alignItems: "flex-start", marginLeft: "20px"}}>
                      {getFieldDecorator('body', {
                        rules: [{required: true, message: 'Note is required!'}],
                        initialValue: ''
                      })(<Input onChange={(v) => { addedNote.body = v.target.value; }} style={{width: "750px"}}/>)}
                    </Form.Item>
                    <Form.Item><Button htmlType="submit">Save</Button></Form.Item>
                    <Form.Item><Button onClick={this.handleCancelClick}>Cancel</Button></Form.Item>
                  </Form>
                </FormContextProvider>
            </Card>
            : <Collapse expandIconPosition="left">
                <Panel key="1" header={`Notes (${notes ? notes.length : 0})`} extra={genExtra()}>
                  <NotesTable notes={notes}/>
                </Panel>
              </Collapse>
          }
      </div>
    );
  }
}

export default (Form.create<ComponentProps>())(ParticipantNoteCard);