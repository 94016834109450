import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import { CommentType } from '../../types/entity.types';
import {
  CommentsActionTypes,
  createCommentAsync,
  getCommentsAsync,
  getParticipantCommentsAsync,
  updateComments
} from './comments.types';

const updateCommentsReducer = produce((draft, comments) => {
  if (!comments) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(comments), Number));
  const ids = Object.keys(comments);
  for (const id of ids) {
    draft.byId[id] = comments[id];
  }
});

export const commentsReducer = (state: NormalizedState<CommentType> = initialNormalizedState, action: CommentsActionTypes) => {

  switch (action.type) {

    case getType(getParticipantCommentsAsync.success):
    case getType(getCommentsAsync.success):
    case getType(createCommentAsync.success):
    case getType(updateComments):
      return updateCommentsReducer(state, action.payload);

    default: return state;
  }

};

export default commentsReducer;
