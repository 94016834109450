import {
  faBookMedical,
  faBooks,
  faCertificate,
  faCog,
  faCogs,
  faCommentAltLines,
  faCommentsAlt,
  faDatabase, faDollarSign,
  faFileChartLine,
  faFileSignature,
  faGamepadAlt,
  faInbox,
  faMegaphone,
  faMicroscope,
  faMoneyBillAlt,
  faTachometerAltFastest,
  faTasks,
  faUser,
  faUserPlus,
  faUsers,
  faUsersClass,
  faVial
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'antd';
import Menu from 'antd/lib/menu';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { loadDashboardDataAsync } from '../../redux/dashboard/dashboard.types';
import { hasDataAnalystRole } from '../../redux/selectors';
import * as selectors from '../../redux/selectors';
import { isNonYAB } from '../../redux/user/user.selectors';
import IApplicationState from '../../types/state.types';
import AntdIcon from "../antdIcon/AntdIcon";
import SideBar from './SideBar';
import './sidebar.scss';
import Config from '../../components/util/Config';

const { SubMenu } = Menu;

interface DispatchProps {
  loadDashboardData: typeof loadDashboardDataAsync.request
}

interface ComponentProps extends DispatchProps {
  collapsed: boolean,
  currentPath: string,
  isNonYAB: boolean,
  hasDataAnalystRole: boolean,
  dashboardData: any
}

class StudySideBar extends Component<ComponentProps, {}> {

  componentDidMount(): void {
    this.props.loadDashboardData();
  }

  render() {

    const {collapsed, currentPath, isNonYAB, hasDataAnalystRole } = this.props;
    const studyId = currentPath.replace(/^\/study\/(\d+)\/?.*$/, '$1');
    const selectedKey = currentPath.replace(/^\/study\/\d+\/?(.*)$/, '/study/$1');
    const iconTachometer = (): any => {
      return <FontAwesomeIcon icon={faTachometerAltFastest}/>;
    };

    const iconMicroscope = (): any => {
      return <FontAwesomeIcon icon={faMicroscope}/>;
    };

    const iconUsersClass = (): any => {
      return <FontAwesomeIcon icon={faUsersClass}/>;
    };

    const iconCommentAltLines = (): any => {
      return <FontAwesomeIcon icon={faCommentAltLines}/>;
    };

    const iconWpForms = (): any => {
      return <i className="fab fa-wpforms"/>;
    };

    const iconUsers = (): any => {
      return <FontAwesomeIcon icon={faUsers}/>;
    };

    const iconTestkits = (): any => {
      return <FontAwesomeIcon icon={faVial}/>;
    };

    const iconUser = (): any => {
      return <FontAwesomeIcon icon={faUser}/>;
    };

    const iconCertificate = (): any => {
      return <FontAwesomeIcon icon={faCertificate}/>;
    };

    const iconInbox = (): any => {
      return <FontAwesomeIcon icon={faInbox}/>;
    }

    const iconAskAnExpert = (): any => {
      return <FontAwesomeIcon icon={faBookMedical}/>;
    }

    const menuItems: JSX.Element[] = [];

    const showTestKits = () => {
    return !Config.isHMPStyle();
    }
    const showIncentives = () => {
      return !Config.isHMPStyle();
    }
    const showForms = () => {
      return !Config.isHMPStyle();
    }
    const showSurvey = () => {
      return !Config.isHMPStyle();
    }

    if (isNonYAB) {
      menuItems.push(
        <Menu.Item key="/study/" className="sidebar-menu-item">
          <Link to={`/study/${studyId}`}>
            <AntdIcon fontAwesomeIcon={faTachometerAltFastest}/>
            <span>Dashboard</span>
          </Link>
        </Menu.Item>
      );
      menuItems.push(
        <Menu.Item key="/study/study" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/study`}>
            <AntdIcon fontAwesomeIcon={faMicroscope}/>
            <span>Study</span>
          </Link>
        </Menu.Item>
      );
      {/*<Menu.Item key="/study/arms" className="sidebar-menu-item">*/
      }
      {/*  <Link to={`/study/${studyId}/arms`}>*/
      }
      {/*    <AntdIcon fontAwesomeIcon={faUsersClass}/>*/
      }
      {/*    <span>Arms</span>*/
      }
      {/*  </Link>*/
      }
      {/*</Menu.Item>*/
      }
      menuItems.push(
        <Menu.Item key="/study/participants" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/participants`}>
            <AntdIcon fontAwesomeIcon={faUsers}/>
            <span>Participants</span>
          </Link>
        </Menu.Item>
      );
      menuItems.push(
        <Menu.Item key="/study/inbox" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/inbox`}>
            <Icon component={iconInbox}/>
            <span>Inbox ({this.props.dashboardData.inbox.unreadThreads})</span>
          </Link>
        </Menu.Item>
      );
    }
    menuItems.push(
      <Menu.Item key="/study/forums" className="sidebar-menu-item">
        <Link to={`/study/${studyId}/forums`}>
          <AntdIcon fontAwesomeIcon={faCommentsAlt}/>
          <span>Forums</span>
        </Link>
      </Menu.Item>
    );

    if (isNonYAB) {
      menuItems.push(
        <Menu.Item key="/study/activities" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/activities`}>
            <AntdIcon fontAwesomeIcon={faGamepadAlt}/>
            <span>Activities</span>
          </Link>
        </Menu.Item>
      );
      menuItems.push(
        <Menu.Item key="/study/resources" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/resources`}>
            <AntdIcon fontAwesomeIcon={faBooks}/>
            <span>Resources</span>
          </Link>
        </Menu.Item>
      );

      if (showTestKits()) {
        menuItems.push(
          <Menu.Item key="/study/testkits" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/testkits`}>
              <Icon component={iconTestkits}/>
              <span>Test Kits</span>
            </Link>
          </Menu.Item>
        );
      }

      if (showIncentives()) {
        menuItems.push(
          <Menu.Item key="/study/payments" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/payments`}>
              <AntdIcon fontAwesomeIcon={faMoneyBillAlt}/>
              <span>Incentives</span>
            </Link>
          </Menu.Item>
        );
      }

      menuItems.push(
        <Menu.Item key="/study/qna" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/qna`}>
            <AntdIcon fontAwesomeIcon={faBookMedical}/>
            <span>Ask an Expert ({this.props.dashboardData.qnas.unansweredCount})</span>
          </Link>
        </Menu.Item>
      );

      menuItems.push(
        <Menu.Item key="/study/announcements" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/announcements`}>
            <AntdIcon fontAwesomeIcon={faMegaphone}/>
            <span>Announcements</span>
          </Link>
        </Menu.Item>
      );
    }

    if (isNonYAB) {
      if (showSurvey()) {
        menuItems.push(
          <Menu.Item key="/study/surveys" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/surveys`}>
              <AntdIcon fontAwesomeIcon={faTasks}/>
              <span>Surveys</span>
            </Link>
          </Menu.Item>
        );
      }

      if (showForms()) {
        menuItems.push(
          <Menu.Item key="/study/form" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/form`}>
              <AntdIcon fontAwesomeIcon={faFileSignature}/>
              <span>Forms</span>
            </Link>
          </Menu.Item>
        );
      }
    }

      if (hasDataAnalystRole) {

        menuItems.push(
          <SubMenu
            key="/study/analytics"
            className="sidebar-menu-item"
            title={
              <span>
                <AntdIcon fontAwesomeIcon={faDatabase}/>
                <span>Data Analytics</span>
              </span>
            }
          >
            <Menu.Item key="/study/reports/incentives" className="sidebar-menu-item">
              <Link to={`/study/${studyId}/reports/incentives`}>
                <AntdIcon fontAwesomeIcon={faDollarSign}/>
                <span> Incentives</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/study/adHocQuery" className="sidebar-menu-item">
              <Link to={`/study/${studyId}/adHocQuery`}>
                <AntdIcon fontAwesomeIcon={faFileChartLine}/>
                <span> Ad Hoc Query Page</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/study/testkitReport" className="sidebar-menu-item">
              <Link to={`/study/${studyId}/testkitReport`}>
                <AntdIcon fontAwesomeIcon={faVial}/>
                <span>Test Kit Report</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        )
      }
      // menuItems.push(
      //   <SubMenu
      //     key="/study/analytics"
      //     className="sidebar-menu-item"
      //     title={
      //       <span>
      //         <AntdIcon fontAwesomeIcon={faDatabase}/>
      //         <span>Data Analytics</span>
      //       </span>
      //     }
      //   >
      //     <Menu.Item key="/study/analytics/dashboard" className="sidebar-menu-item">
      //       <Link to={`/study/${studyId}/analytics/dashboard`}>
      //         <AntdIcon fontAwesomeIcon={faChartPie}/>
      //         <span>Data Dashboard</span>
      //       </Link>
      //     </Menu.Item>
      //     <SubMenu
      //       key="/study/reports"
      //       className="sidebar-menu-item"
      //       title={
      //         <span>
      //         <AntdIcon fontAwesomeIcon={faFileChartLine}/>
      //         <span>Reports</span>
      //       </span>
      //       }
      //     >
      //       <Menu.Item key="/study/reports/analytics" className="sidebar-menu-item">
      //         <Link to={`/study/${studyId}/reports/analytics`}>
      //           <AntdIcon fontAwesomeIcon={faFileChartLine}/>
      //           <span> Analytics</span>
      //         </Link>
      //       </Menu.Item>
      //     </SubMenu>
      //   </SubMenu>
      // );
    // }

    if (isNonYAB) {
      menuItems.push(
        <SubMenu
          key="/study/configuration"
          className="sidebar-menu-item"
          title={
            <span>
              <AntdIcon fontAwesomeIcon={faCogs}/>
              <span>Configuration</span>
            </span>
          }
        >
          <Menu.Item key="/study/avatars" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/avatars`}>
              <AntdIcon fontAwesomeIcon={faUser}/>
              <span>Avatars</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/study/badges" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/badges`}>
              <AntdIcon fontAwesomeIcon={faCertificate}/>
              <span>Badges</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/study/settings" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/settings`}>
              <AntdIcon fontAwesomeIcon={faCog}/>
              <span>Settings</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/study/1/user" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/user`}>
            <AntdIcon fontAwesomeIcon={faUserPlus}/>
            <span>User Creation</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      );
    }
    else {
      menuItems.push(
        <SubMenu
          key="/study/configuration"
          className="sidebar-menu-item"
          title={
            <span>
              <AntdIcon fontAwesomeIcon={faCogs}/>
              <span>Configuration</span>
            </span>
          }
        >
          <Menu.Item key="/study/settings" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/settings`}>
              <AntdIcon fontAwesomeIcon={faCog}/>
              <span>Settings</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      );
    }
    return (
      <SideBar collapsed={collapsed}>
        <Menu
          id="sidebar-menu"
          selectedKeys={[selectedKey]}
          mode="inline"
          defaultSelectedKeys={['/home']}
        >

          {...menuItems}


        </Menu>
      </SideBar>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { pathname } = state.router.location;
  const { sidebar } = state.ui;

  return {
    collapsed: sidebar.collapsed,
    currentPath: pathname,
    isNonYAB: isNonYAB(state),
    hasDataAnalystRole: hasDataAnalystRole(state),
    dashboardData: selectors.selectDashboard(state)
  };
};


const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadDashboardData: () => dispatch(loadDashboardDataAsync.request())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudySideBar);
