import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { BadgeType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { entitySchema } from '../schema';

export const selectBadges = (state: IApplicationState) => {
  return state.entities.badges;
};

export const getBadges = createSelector(
  [selectBadges],
  (badges): Optional<BadgeType[]> => {
    if (badges && badges.allIds && badges.allIds.length > 0) {
      const { badges: denormalizedBadges } = denormalize({ badges: badges.allIds }, entitySchema, { badges: badges.byId });
      return toArray(denormalizedBadges);
    }
    return undefined;
  }
);

export default { getBadges };
