import { Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import Username from '../../components/username/Username';
import * as selectors from '../../redux/selectors';
import {
  getAwaitingResultTestkitsAsync,
  getCompletedTestkitsAsync,
  getNeedsReviewTestkitsAsync, getRequestedTestkitsAsync
} from "../../redux/testkit/testkit.types";
import { TestkitType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { renderPopoverDate } from '../util/Util';
import TestkitCompletedCard from './TestkitCompletedCard';

interface StateProps {
  testkits: Optional<TestkitType[]>
}

interface DispatchProps {
  loadCompletedTestkits: typeof getCompletedTestkitsAsync.request
  loadNeedsReviewTestkits: typeof getNeedsReviewTestkitsAsync.request
  loadAwaitingResultsTestkits: typeof getAwaitingResultTestkitsAsync.request
  loadRequestedTestkits: typeof getRequestedTestkitsAsync.request
}

interface ComponentProps extends DispatchProps, StateProps {
}

const columns = [
  {
    title: '',
    dataIndex: 'kitType',
    key: 'kitType',
  },
  {
    title: 'Requested',
    dataIndex: 'requested',
    key: 'requested',
  },
  {
    title: 'Completed',
    dataIndex: 'completed',
    key: 'completed',
  },
];

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class TestkitCompletedReport extends Component<ComponentProps, {}> {
  readonly state = {
    studyId: 1
  }

  componentDidMount() {
    const { studyId } = this.state;
    const { loadCompletedTestkits, loadAwaitingResultsTestkits, loadNeedsReviewTestkits, loadRequestedTestkits } = this.props;
    loadCompletedTestkits({ studyId });
    loadAwaitingResultsTestkits({ studyId });
    loadNeedsReviewTestkits({ studyId });
    loadRequestedTestkits({ studyId });
  }

  render() {
    const { testkits } = this.props;

    const data = [
      {kitType: 'HEMASPOT', requested: '0', completed: '0' },
      {kitType: 'OraQuick', requested: '0', completed: '0' },
    ];

    if (testkits) {
      testkits.forEach(testkit => {
        if (testkit.type === 'HEMASPOT') {
          // @ts-ignore
          data[0].requested++;
          if (testkit.completedDate)
          {
            // @ts-ignore
            data[0].completed++;
          }
        }
        else {
          // @ts-ignore
          data[1].requested++;
          if (testkit.completedDate) {
            // @ts-ignore
            data[ 1 ].completed++;
          }
        }
      });
    }

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowClassName={rowClassName}
        rowKey="kitType"
      />
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  testkits: selectors.getTestkits
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadCompletedTestkits: (args: {studyId: number}) => dispatch(getCompletedTestkitsAsync.request(args)),
    loadAwaitingResultsTestkits: (args: {studyId: number}) => dispatch(getAwaitingResultTestkitsAsync.request(args)),
    loadNeedsReviewTestkits: (args: {studyId: number}) => dispatch(getNeedsReviewTestkitsAsync.request(args)),
    loadRequestedTestkits: (args: {studyId: number}) => dispatch(getRequestedTestkitsAsync.request(args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestkitCompletedReport);
