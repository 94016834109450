import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  BadgesActionTypes, getBadgesAsync
} from './badges.types';
import { BadgeType } from '../../types/entity.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';

const updateBadgesReducer = produce((draft, badges) => {
  if (!badges) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(badges), Number));
  const ids = Object.keys(badges);
  for (const id of ids) {
    draft.byId[id] = badges[id];
  }
});

export const badgesReducer = (state: NormalizedState<BadgeType> = initialNormalizedState, action: BadgesActionTypes) => {

  switch (action.type) {
    case getType(getBadgesAsync.success): return updateBadgesReducer(state, action.payload);
    default: return state;
  }
};

export default badgesReducer;
