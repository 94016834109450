import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { NormalizerResult } from '../../types/entity.types';
import axios from '../api';
import { entitySchema } from '../schema';
import {
  getUserAsync,
  getAdminAsync,
  updatePasswordAsync,
  updateMobileAsync,
  updateEmailAsync,
  createUserAsync,
  getAdminRolesAsync,
  PasswordArguments
} from './user.types';
import { UserType, NewUserType } from '../../types/entity.types';
import { loadStudyAsync } from '../study/study.types';

const updatePassword = (args: PasswordArguments) => {
  const { password, currentPassword, updatePseudoParticipants } = args;
  return axios({
    method: 'put',
    url: `/a/usmg/updatePassword?updatePseudoParticipants=${updatePseudoParticipants}`,
    data: { password, currentPassword }
  });
};

const updateMobile = (mobile: string) => {
  return axios({
    method: 'put',
    url: `/a/usmg/updateMobile/`,
    data: { mobile }
  });
};

const updateEmail = (email: string) => {
  return axios({
    method: 'put',
    url: `/a/usmg/updateEmail/`,
    data: { email }
  });
};

const getUser = (id: number) => {
  return axios({
    method: 'get',
    url: `/a/usmg/user/${id}`
  });
};

const getAdmin = () => {
  return axios({
    method: 'get',
    url: `/a/usmg/admin`
  });
};

const createUser = (user: NewUserType) => {
  return axios({
    method: 'put',
    url: `a/usmg/user`,
    data: user
  });
};

const getAdminRoles = () => {
  return axios({
    method: 'get',
    url: `/a/usmg/admin/roles`
  });
};

function* updatePasswordHandler(action: ReturnType<typeof updatePasswordAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(updatePassword, action.payload)) as AxiosResponse;
    yield put(updatePasswordAsync.success(response.data as Optional<UserType>));
  } catch (error) {
    yield put(updatePasswordAsync.failure(error));
  }
}

function* updateMobileHandler(action: ReturnType<typeof updateMobileAsync.request>): Generator {
  const mobile = action.payload;
  try {
    const response: AxiosResponse = (yield call(updateMobile, mobile)) as AxiosResponse;
    yield put(updateMobileAsync.success(response.data as Optional<UserType>));
  } catch (error) {
    yield put(updateMobileAsync.failure(error));
  }
}

function* updateEmailHandler(action: ReturnType<typeof updateEmailAsync.request>): Generator {
  const password = action.payload;
  try {
    const response: AxiosResponse = (yield call(updateEmail, password)) as AxiosResponse;
    yield put(updateEmailAsync.success(response.data as Optional<UserType>));
  } catch (error) {
    yield put(updateEmailAsync.failure(error));
  }
}

function* userHandler(action: ReturnType<typeof getUserAsync.request>): Generator {
  const id: number = action.payload;
  try {
    const response: AxiosResponse = (yield call(getUser, id)) as AxiosResponse;
    yield put(getUserAsync.success(response.data as Optional<UserType>));
  } catch (error) {
    yield put(getUserAsync.failure(error));
  }
}

function* getAdminHandler(action: ReturnType<typeof getAdminAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getAdmin)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.admin) as NormalizerResult;
    const { admin } = entities;

    yield put(getAdminAsync.success(admin));
  } catch (error) {
    yield put(getAdminAsync.failure(error));
  }
}

function* createUserHandler(action: ReturnType<typeof createUserAsync.request>): Generator {
  try {
    const user = action.payload;
    const response: AxiosResponse = (yield call(createUser, user)) as AxiosResponse;
    const { studyId } = user;
    yield put(createUserAsync.success());
  } catch (error) {
    yield put(createUserAsync.failure(error));
  }
}

function* getAdminRolesHandler(action: ReturnType<typeof getAdminRolesAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getAdminRoles)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.adminRoles) as NormalizerResult;
    const { adminRoles } = entities;

    yield put(getAdminRolesAsync.success(adminRoles));
  } catch (error) {
    yield put(getAdminRolesAsync.failure(error));
  }
}

function* updatePasswordWatcher() {
  yield takeLatest(getType(updatePasswordAsync.request), updatePasswordHandler);
}

function* updateMobileWatcher() {
  yield takeLatest(getType(updateMobileAsync.request), updateMobileHandler);
}

function* updateEmailWatcher() {
  yield takeLatest(getType(updateEmailAsync.request), updateEmailHandler);
}

function* userWatcher() {
  yield takeLatest(getType(getUserAsync.request), userHandler);
}

function* getAdminWatcher() {
  yield takeLatest(getType(getAdminAsync.request), getAdminHandler);
}

function* createUserWatcher() {
  yield takeLatest(getType(createUserAsync.request), createUserHandler);
}

function* getAdminRolesWatcher() {
  yield takeLatest(getType(getAdminRolesAsync.request), getAdminRolesHandler);
}

export default function* userSaga() {
  yield all([
    fork(userWatcher),
    fork(getAdminWatcher),
    fork(updatePasswordWatcher),
    fork(updateEmailWatcher),
    fork(updateMobileWatcher),
    fork(createUserWatcher),
    fork(getAdminRolesWatcher)
  ]);
}
