import { Layout, message, Spin } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { clearStatus } from '../../../redux/api/api.types';
import MessageList from '../../../components/inbox/MessageList';
import NoThreadSelected from '../../../components/inbox/NoThreadSelected';
import ThreadList from '../../../components/inbox/ThreadList';
import {
  clearMessages,
  createThreadAsync,
  GetInboxArguments,
  getMessageInboxAsync,
  GetMessagesArguments,
  getMessagesAsync,
} from '../../../redux/messages/messages.types';
import * as selectors from '../../../redux/selectors';
import { IApiRequestStatus } from '../../../types/api.types';
import { MessageResponseType, MessageThreadType, MessageType } from '../../../types/entity.types';
import IApplicationState from '../../../types/state.types';
import './inbox.scss';
import { getType } from 'typesafe-actions';
const { Sider, Content } = Layout;

interface StateProps {
  userId: Optional<number>;
  responses: Optional<MessageResponseType[]>;
  threads: Optional<MessageThreadType[]>;
  messages: Optional<MessageType[]>;
  loadInboxStatus: IApiRequestStatus;
  createMessageThreadStatus: IApiRequestStatus;
  threadCount: number;
}

interface DispatchProps {
  loadInbox: typeof getMessageInboxAsync.request;
  getMessages: typeof getMessagesAsync.request;
  clearMessages: typeof clearMessages;
  clearStatus: typeof clearStatus;
}

interface ComponentProps extends StateProps, DispatchProps {
}

interface ComponentState {
  activeThreadId?: number;
  pageNumber: number;
  previewLength: number;
  pageSize: number;
  hasMore: boolean;
  loading: boolean;
  searchTerm?: string;
  filter: string;
  sortBy: string;
}

class Inbox extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = {
    activeThreadId: undefined,
    pageNumber: 0,
    previewLength: 22,
    pageSize: 10,
    hasMore: true,
    loading: false,
    searchTerm: '',
    filter: 'inbox',
    sortBy: 'newest'
  }
  componentDidMount() {
    const {loadInbox, getMessages,} = this.props;
    const {activeThreadId, pageSize, previewLength, filter, sortBy,} = this.state;
    if (!!activeThreadId) {
      getMessages({threadId: activeThreadId});
    }
  }

  componentDidUpdate(prevProps: ComponentProps) {
    const {
      hasMore
    } = this.state;
    const {
      loadInboxStatus,
      threads,
      threadCount,
      createMessageThreadStatus,
      clearStatus,
    } = this.props;
    if (hasMore) {
      if (
        !prevProps.loadInboxStatus.isSuccess &&
        loadInboxStatus.isSuccess
      ) {
        // If the activeThreadId isn't in the current threads, reset it.
        if (!this.props.threads?.filter(t => t.id === this.state.activeThreadId).length) {
          this.setState({
            activeThreadId: undefined,
            loading: false,
          });
        } else {
          this.setState({
            loading: false
          });
        }
      }
      if (threads && threadCount === threads.length) {
        this.setState({hasMore: false, loading: false});
      }
    } else if (threads && threadCount > threads.length) {
      this.setState({
        hasMore: true
      });
    }
    // Check create thread async status
    if (
      !prevProps.createMessageThreadStatus.isError &&
      createMessageThreadStatus.isError
    ) {
      message.error(`Error: ${createMessageThreadStatus.errorMessage}`);
      clearStatus(getType(createThreadAsync.failure));
    }
    if (
      !prevProps.createMessageThreadStatus.isSuccess &&
      createMessageThreadStatus.isSuccess
    ) {
      message.success('Thread created successfully.');
      clearStatus(getType(createThreadAsync.success));
    }
  }

  setActiveThread = (threadId: number) => {
    const {getMessages} = this.props;
    const {activeThreadId} = this.state;
    getMessages({pageSize: 16, threadId});
    if (activeThreadId !== threadId) {
      this.setState({
        activeThreadId: threadId
      });
    }
  }

  refreshThreads = () => {
    const { sortBy, filter, searchTerm, previewLength } = this.state;
    const { threads, loadInbox } = this.props;
    loadInbox({ previewLength, pageNumber: 0, pageSize: threads?.length ? threads.length : 10, sortBy, filter, searchTerm });
  };

  getMessages = (pageSize: number, pageNumber: number) => {
    const {getMessages} = this.props;
    const {activeThreadId} = this.state;
    if(!!activeThreadId) {
      getMessages({threadId: activeThreadId, pageSize, pageNumber});
    }
  };

  handleInfiniteLoad = (pageNumberParamFromInfiniteLoader: number) => {
    const {
      loadInboxStatus,
      loadInbox,
    } = this.props;
    const {
      previewLength,
      pageNumber,
      pageSize,
      hasMore,
      searchTerm,
      filter,
      sortBy
    } = this.state;
    if (loadInboxStatus.isError || !hasMore) {
      this.setState({
        hasMore: false,
        loading: false
      });
    } else {
      loadInbox({
        previewLength,
        pageNumber,
        pageSize,
        searchTerm,
        filter,
        sortBy
      });
      this.setState({
        pageNumber: pageNumber + 1,
        loading: true,
      });
    }
  };

  debouncedSearch = _.debounce(this.handleInfiniteLoad, 500);

  handleSearch = (searchTerm: string) => {
    const { clearMessages } = this.props;
    this.setState({
      pageNumber: 0,
      searchTerm,
      filter: "inbox",
      hasMore: true,
      pageSize: 10,
      loading: true
    }, () => {
      clearMessages();
      this.debouncedSearch(-1);
    });
  };

  setSortBy = (sortBy: string) => {
    const { threads } = this.props;
    const {
      pageSize,
    } = this.state;
    // We want to retain the same number of records when we sort
    let newPageSize = pageSize;
    if(threads && threads.length && threads.length > pageSize) {
      newPageSize = threads.length;
    }
    this.setState({
      pageNumber: 0,
      sortBy,
      searchTerm: undefined,
      pageSize: newPageSize,
    }, () => {
      clearMessages();
      this.handleInfiniteLoad(-1);
    });
  };

  setFilter = (filter: string) => {
    const { clearMessages } = this.props;
    this.setState({
      pageNumber: 0,
      filter,
      searchTerm: '',
      pageSize: 10,
      hasMore: true,
    }, () => {
      clearMessages();
      this.handleInfiniteLoad(-1);
    });
  };

  render() {
    const {
      activeThreadId,
      hasMore,
      searchTerm,
      loading,
      filter,
      sortBy,
      pageNumber
    } = this.state;
    const {
      responses,
      threads,
      loadInboxStatus
    } = this.props;
    const {userId} = this.props;
    const response: MessageResponseType = _.filter(responses, response => response.threadId === activeThreadId)[ 0 ];
    return (
      <Layout className='inbox'>
        <Sider
          collapsible={false}
          style={{backgroundColor: 'white'}}
          width={400}
        >
          <div className='scrollable sidebar'>
            <InfiniteScroll
              initialLoad={!threads && !loadInboxStatus.isSuccess}
              pageStart={0}
              loadMore={this.handleInfiniteLoad}
              hasMore={hasMore && !loading && !loadInboxStatus.isLoading}
              useWindow={false}
            >
              <ThreadList
                setActiveThread={this.setActiveThread}
                activeThreadId={activeThreadId}
                userId={userId}
                handleSearch={this.handleSearch}
                setSortBy={this.setSortBy}
                setFilter={this.setFilter}
                refreshThreads={this.refreshThreads}
                searchTerm={searchTerm}
                filter={filter}
                sortBy={sortBy}
              />
            </InfiniteScroll>
          </div>
        </Sider>
        <Content className='scrollable content'>
          {activeThreadId !== -1 && response ?
            <MessageList
              userId={userId ? userId : -1}
              response={response}
              getMessages={this.getMessages}
            />
            : <NoThreadSelected/>}
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state: IApplicationState): StateProps => {
  return {
    userId: state.auth.user ? state.auth.user.id : -1,
    responses: selectors.getMessageResponses(state),
    messages: selectors.getMessages(state),
    loadInboxStatus: selectors.loadMessageInboxStatus(state),
    createMessageThreadStatus: selectors.createMessageThreadStatus(state),
    threadCount: selectors.getMessageThreadCount(state),
    threads: selectors.getMessageThreads(state)
  }
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadInbox: (args: GetInboxArguments) => dispatch(getMessageInboxAsync.request(args)),
    getMessages: (args: GetMessagesArguments) => dispatch(getMessagesAsync.request(args)),
    clearMessages: () => dispatch(clearMessages()),
    clearStatus: (type: string) => dispatch(clearStatus(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);