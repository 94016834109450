import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { NotificationType } from '../../types/entity.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import { NotificationsActionTypes, getParticipantNotificationsAsync } from './notification.types';

const updateNotificationsReducer = produce((draft, notifications) => {
  if (!notifications) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(notifications), Number));
  const ids = Object.keys(notifications);
  for (const id of ids) {
    draft.byId[id] = notifications[id];
  }
});

export const notificationsReducer = (state: NormalizedState<NotificationType> = initialNormalizedState, action: NotificationsActionTypes) => {

  switch (action.type) {
    case getType(getParticipantNotificationsAsync.success): return updateNotificationsReducer(state, action.payload);
    default: return state;
  }

};

export default notificationsReducer;
