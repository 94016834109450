import { schema } from 'normalizr';

/**
 * Define the entities schema...
 *
 * {
 *   studies: {
 *     "1": StudyType,
 *     "2": StudyType
 *   },
 *   studyArms: {
 *     "1": StudyArmType,
 *     "2": StudyArmType
 *   },
 *   participants: {
 *     "1": ParticipantType,
 *     "2": ParticipantType
 *   },
 *   posts: {
 *     "1": PostType,
 *     "2": PostType
 *   }
 * }
 *
 */

export const avatarSchema = new schema.Entity('avatars');
export const avatarListSchema = new schema.Array(avatarSchema);

export const adminSchema = new schema.Entity('admin');
export const adminListSchema = new schema.Array(adminSchema);

export const participantSchema = new schema.Entity('participants',
{ avatar: avatarSchema },
{
  processStrategy: (value, parent, key) => {
    return {
      ...value,
      avatar: { id: value.avatarId }
    }
  }
});
export const participantListSchema = new schema.Array(participantSchema);

export const studyArmSchema = new schema.Entity('studyArms', {
  participants: participantListSchema
});

export const studyArmListSchema = new schema.Array(studyArmSchema);

export const studySchema = new schema.Entity('studies', {
  studyArms: studyArmListSchema
});
export const studyListSchema = new schema.Array(studySchema);

export const noteSchema = new schema.Entity('notes');
export const noteListSchema = new schema.Array(noteSchema);

export const notificationSchema = new schema.Entity('notifications',
  { participant: participantSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: { id: value.participantId }
      }
    }
  }
);
export const notificationListSchema = new schema.Array(notificationSchema);

export const userSurveySchema = new schema.Entity('userSurveys',
  { participant: participantSchema },
  {
    processStrategy: (value, parent, key ) => {
      return {
        ...value,
        participant: { id: value.participantId }
      }
    }
  }
  );
export const userSurveyListSchema = new schema.Array(userSurveySchema);

export const postSchema = new schema.Entity('posts',
  {participant: participantSchema},
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: {id: value.createdByParticipantId}
      }
    }
  });
export const postListSchema = new schema.Array(postSchema);

export const topicSchema = new schema.Entity('topics');
topicSchema.define({parentTopic: [topicSchema]});
export const topicListSchema = new schema.Array(topicSchema);

export const formSchema = new schema.Entity('forms');
export const formListSchema = new schema.Array(formSchema);

export const commentSchema = new schema.Entity('comments',
  {participant: participantSchema},
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: {id: value.participantId}
      }
    }
  });
export const commentListSchema = new schema.Array(commentSchema);

export const messageSchema = new schema.Entity('messages', {}, { idAttribute: 'messageId' });
export const messageListSchema = new schema.Array(messageSchema);
export const messageResponseSchema = new schema.Entity('messageResponses',
{ messages: messageListSchema },
{
  idAttribute: 'threadId'
});
export const messageResponseListSchema = new schema.Array(messageResponseSchema);

export const threadSchema = new schema.Entity('threads',
{ participant: participantSchema },
{
  processStrategy: (value, parent, key) => {
    return {
      ...value,
      participant: { id: value.participantId }
    }
  }
});
export const threadListSchema = new schema.Array(threadSchema);

export const participantBadgeSchema = new schema.Entity('participantBadges');
export const participantBadgeListSchema = new schema.Array(participantBadgeSchema);

export const badgeSchema = new schema.Entity('badges', {}, { idAttribute: 'badgeCollectionId' });
export const badgeListSchema = new schema.Array(badgeSchema);

export const paymentSchema = new schema.Entity('payments', {}, { idAttribute: 'incentivePaymentId'});
export const paymentListSchema = new schema.Array(paymentSchema);

export const testkitSchema = new schema.Entity('testkits',
{ participant: participantSchema },
{
  processStrategy: (value, parent, key) => {
    return {
      ...value,
      participant: { id: value.participantId }
    }
  }
}
);
export const testkitListSchema = new schema.Array(testkitSchema);

export const flagSchema = new schema.Entity('flags',
  {admin: adminSchema, participant: participantSchema, flaggedParticipant: participantSchema},
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: {id: value.participantId},
        flaggedParticipant: {id: value.flaggedParticipantId},
        admin: {id: value.resolveUserId}
      }
    }
   });
export const flagListSchema = new schema.Array(flagSchema);

export const thumbsupSchema = new schema.Entity('thumbsups',
  {participant: participantSchema},
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: {id: value.participantId}
      }
    }
  });
export const thumbsupListSchema = new schema.Array(thumbsupSchema);

export const favoriteSchema = new schema.Entity('favorites',
  {participant: participantSchema},
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: {id: value.participantId}
      }
    }
  });
export const favoriteListSchema = new schema.Array(favoriteSchema);

export const activitySchema = new schema.Entity('activities');
export const activityListSchema = new schema.Array(activitySchema);

export const activityCategorySchema = new schema.Entity('activityCategories');
export const activityCategoryListSchema = new schema.Array(activityCategorySchema);

export const activityTypeSchema = new schema.Entity('activityTypes');
export const activityTypeListSchema = new schema.Array(activityTypeSchema);

export const articleSchema = new schema.Entity('articles', {
  topic: topicSchema },{
  processStrategy: (value, parent, key) => {
    return {
      ...value,
      topic: { id: value.topicId }
    }
  }
});
export const articleListSchema = new schema.Array(articleSchema);

export const announcementSchema = new schema.Entity('announcements');
export const announcementListSchema = new schema.Array(announcementSchema);

export const qnaPostSchema = new schema.Entity('qnaPosts');
export const qnaPostListSchema = new schema.Array(qnaPostSchema);

export const adminRoleSchema = new schema.Entity('adminRoles');
export const adminRoleListSchema = new schema.Array(adminRoleSchema);

export const forumSchema = new schema.Entity('forums');
export const forumListSchema = new schema.Array(forumSchema);

export const consentStatusSchema = new schema.Entity("consentStatuses");
export const consentStatusList = new schema.Array(consentStatusSchema);

export const entitySchema = {
  admin: adminListSchema,
  articles: articleListSchema,
  avatars: avatarListSchema,
  comments: commentListSchema,
  messages: messageListSchema,
  messageResponses: messageResponseListSchema,
  threads: threadListSchema,
  participants: participantListSchema,
  topics: topicListSchema,
  notes: noteListSchema,
  notifications: notificationListSchema,
  posts: postListSchema,
  studies: studyListSchema,
  studyArms: studyArmListSchema,
  forms: formListSchema,
  badges: badgeListSchema,
  participantBadges: participantBadgeListSchema,
  testkits: testkitListSchema,
  flags: flagListSchema,
  thumbsups: thumbsupListSchema,
  favorites: favoriteListSchema,
  activities: activityListSchema,
  activityCategories: activityCategoryListSchema,
  activityTypes: activityTypeListSchema,
  payments: paymentListSchema,
  announcements: announcementListSchema,
  userSurveys: userSurveyListSchema,
  qnaPosts: qnaPostListSchema,
  adminRoles: adminRoleListSchema,
  forums: forumListSchema,
  consentStatuses: consentStatusList
};

export default entitySchema;
