import React, { Component } from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import {
  Form, Tree
} from 'antd';
import '../activity.scss';
import { ActivityTreeNodeType, ActivityTreeOptionType } from '../../../types/entity.types';
import ActivityTreeNode from './ActivityTreeNode';
const { Item } = Form;

interface ComponentProps {
  form: any;
  creative: boolean;
  editable: boolean;
  tree: ActivityTreeNodeType;
  setStatus: (status: string) => void;
  updateTree: (tree: ActivityTreeNodeType) => void;
}
const defaultNode = {
  uuid: '',
  text: '',
  options: []
};
const defaultOption = {
  uuid: '',
  text: '',
  question: undefined
};
const itemStyle = {
  width: '100%',
  marginBottom: '10px', 
  marginTop: '10px'
};

class ChooseYourOwnAdventure extends Component<ComponentProps, {}> {
  updateNode = (node: ActivityTreeNodeType) => {
    const { updateTree } = this.props;
    updateTree(_.cloneDeep(node));
  };
  removeNode = () => {
    const { updateTree } = this.props;
    updateTree(_.cloneDeep(defaultNode));
  };
  validateTree = (rule, value, cb) => {
    const { tree, setStatus } = this.props;
    const { text, options } = tree;
    try {
      if(!this.traverseTree(tree)) {
        throw new Error('All nodes of a tree must have text.');
      }
      setStatus('finish');
      cb();
    } catch (err) {
      cb(err);
    }
  };
  traverseTree = (node: any): boolean => {
    if(node) {
      if(node.options && node.options.length > 0) {
        return node.text.length > 0 && node.options.map(o => this.traverseTree(o));
      } else if (node.question) {
        return node.text.length > 0 && this.traverseTree(node.question);
      }
    }
    return true;
  }
  render () {
    const { 
      form,
      creative,
      editable,
      tree,
      setStatus
    } = this.props;
    const { getFieldDecorator } = form;
    return (<div className='tree-container'>
      <h4>Choose Your Own Adventure:</h4>
      <Item
          required={false}
          key={`tree-${creative ? tree.uuid : tree.id}`}
          style={itemStyle}
        >
          {getFieldDecorator(`tree-${creative ? tree.uuid : tree.id}`, {
            validateTriggers: ['onChange', 'onBlur', 'onFocus'],
            rules: [
              {
                required: true,
                message: 'Please input question text or delete this question.',
                validator: this.validateTree
              }
            ]
          })(
            <ActivityTreeNode
              form={form}
              creative={creative}
              editable={editable}
              node={tree}
              level={0}
              setStatus={setStatus}
              updateNode={this.updateNode}
              removeNode={this.removeNode} 
            />
          )}
        </Item>
    </div>);
  } 
}; 

export default ChooseYourOwnAdventure;