import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';
import generateUUID from "uuid";
import axios from "../../redux/api";
import IApplicationState from "../../types/state.types";
import {
  upsertActivityAsync,
  publishActivityAsync,
} from "../../redux/activities/activities.types";
import {
  ActivityType,
  ActivityTreeNodeType,
  ActivityTreeOptionType,
  ActivityFeedbackType,
  ActivityOptionType,
  ActivityQuestionType,
} from "../../types/entity.types";
import { Steps, Modal, Button, Row, Col, message, Switch, Tooltip } from "antd";
import ActivityBackgroundForm from "./forms/ActivityBackgroundForm";
import ActivityDetailsForm from "./forms/ActivityDetailsForm";
import ActivityQuestionForm from "./forms/ActivityQuestionForm";
import ActivityReviewForm from "./forms/ActivityReviewForm";
const { Step } = Steps;
const { confirm } = Modal;

interface StateProps {}

interface DispatchProps {
  upsertActivity: typeof upsertActivityAsync.request;
  publishActivity: typeof publishActivityAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  activity: ActivityType;
  closeHandler: () => {};
  visible: boolean;
  creative: boolean;
  copied: boolean;
}

enum Status {
  WAIT = "wait",
  PROCESS = "process",
  FINISH = "finish",
  ERROR = "error",
}

enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener,
}

const defaultState = {
  activity: {
    title: "",
    description: "",
    prompt: "",
    intro: "",
    randomizeQuestions: false,
    isHealthAssessment: false,
    background: {
      gradient: {},
      filename: "",
    },
    publishDate: "",
    feedback: [] as ActivityFeedbackType[],
    options: [] as ActivityOptionType[],
    questions: [] as ActivityQuestionType[],
    tree: {
      text: "",
      uuid: "0",
      options: [] as ActivityTreeOptionType[],
    },
    upload: undefined,
  },
  current: 0,
  statuses: [Status.PROCESS, Status.WAIT, Status.WAIT, Status.WAIT],
  visible: true,
  backgroundType: "image",
  previewImage: "",
  image: "",
  form: undefined,
  creative: false,
  editable: false,
};
const cleanstring = (dirty: string) => {
  if (dirty) {
    return dirty
      .replace(/‘/g, "'")
      .replace(/’/g, "'")
      .replace(/“/g, '"')
      .replace(/”/g, '"');
  }
  return "";
};

class ActivityFormContainer extends Component<ComponentProps, {}> {
  readonly state = _.cloneDeep(defaultState);
  componentDidMount() {
    const { creative, activity } = this.props;
    if (activity) {
      const { categoryId, typeId } = activity;
      const mergedActivity = _.merge(
        _.cloneDeep(defaultState.activity),
        activity
      );
      if (categoryId) {
        mergedActivity.category = categoryId;
      }
      if (typeId) {
        mergedActivity.type = typeId;
      }
      this.setState({ creative, activity: mergedActivity });
    } else {
      this.setState({ creative });
    }
  }
  componentDidUpdate(nextProps: any, nextState: any) {
    if (!_.isEqual(nextState, this.state)) {
      if (!this.props.creative && !nextState.creative && !nextState.editable) {
        this.setStatus(Status.FINISH);
      }
    }
  }
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.activity) {
      const { activity, creative, copied } = nextProps;
      const { background, typeId, tree, firstId, categoryId } = activity;
      const mergedActivity = _.merge(
        _.cloneDeep(defaultState.activity),
        activity
      );
      let state = _.cloneDeep(defaultState);
      if (categoryId) {
        mergedActivity.category = categoryId;
      }
      if (typeId) {
        mergedActivity.type = typeId;
      }
      if (background) {
        if (
          background.gradient &&
          background.gradient.start &&
          background.gradient.start.length > 0
        ) {
          state.backgroundType = "gradient";
        } else if (background.filename && background.filename.length > 0) {
          state.backgroundType = "image";
        }
      }
      if (typeId === Type.cyoa && (!creative || copied) && tree) {
        mergedActivity.tree = this.mapTree(tree, firstId);
      }
      this.setState({
        activity: mergedActivity,
        backgroundType: state.backgroundType,
      });
    }
  }
  mapTree = (tree: any, firstId: number): ActivityTreeNodeType => {
    const { questions, options } = tree;
    return this.buildTree(
      options,
      questions,
      questions[firstId],
      "node"
    ) as ActivityTreeNodeType;
  };
  buildTree = (
    options: any[],
    questions: any[],
    node: any,
    type: string
  ): ActivityTreeOptionType | ActivityTreeNodeType => {
    if (type === "node") {
      return {
        id: node.id,
        text: node.text,
        uuid: generateUUID(),
        options: node.optionIds.map((id: number) =>
          this.buildTree(options, questions, options[id], "option")
        ),
      } as ActivityTreeNodeType;
    } else {
      return {
        id: node.id,
        text: node.text,
        uuid: generateUUID(),
        question: node.questionId
          ? this.buildTree(
              options,
              questions,
              questions[node.questionId],
              "node"
            )
          : undefined,
      };
    }
  };
  handleSave = (e: any) => {
    const { current, statuses, form } = this.state;
    if (form) {
      form.validateFields((err: any, values: any) => {
        if (!err && statuses[current] === Status.FINISH) {
          const handleSaveActivity = this.handleSaveActivity.bind(this);
          confirm({
            title: "Are you sure you want to create this activity?",
            content: "",
            okText: "Confirm",
            onOk() {
              handleSaveActivity();
            },
            onCancel() {},
          });
        } else if (err) {
          this.setStatus(Status.ERROR);
        }
      });
    }
  };
  handlePublish = (e: any) => {
    const { current, statuses, form } = this.state;
    if (form) {
      form.validateFields((err: any, values: any) => {
        if (!err && statuses[current] === Status.FINISH) {
          const handleSaveAndPublishActivity =
            this.handleSaveAndPublishActivity.bind(this);
          confirm({
            title: "Are you sure you want to create this activity?",
            content: "",
            okText: "Confirm",
            onOk() {
              handleSaveAndPublishActivity();
            },
            onCancel() {},
          });
        } else if (err) {
          this.setStatus(Status.ERROR);
        }
      });
    }
  };
  handleSaveActivity = async () => {
    const { closeHandler, upsertActivity } = this.props;
    const { activity, backgroundType, image } = this.state;
    // validate and save
    if (activity) {
      activity.intro = cleanstring(activity.intro);
      activity.description = cleanstring(activity.description);
      if (backgroundType === "image") {
        activity.background.gradient = undefined;
        if (image) {
          activity.upload = image;
        }
      } else if (backgroundType === "gradient") {
        activity.background.filename = undefined;
      }
      upsertActivity(activity);
      message.success(`Activity created successfully!`);
    }
    this.resetState();
    closeHandler();
  };
  handleSaveAndPublishActivity = async () => {
    const { closeHandler, upsertActivity } = this.props;
    const { activity, backgroundType, image } = this.state;
    // validate and save
    if (activity) {
      activity.intro = cleanstring(activity.intro);
      activity.description = cleanstring(activity.description);
      if (backgroundType === "image") {
        activity.background.gradient = undefined;
        if (image) {
          activity.upload = image;
        }
      } else if (backgroundType === "gradient") {
        activity.background.filename = undefined;
      }
      if (!activity.publishDate) {
        activity.publishDate = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      upsertActivity(activity);
      message.success(`Activity created successfully!`);
    }
    this.resetState();
    closeHandler();
  };
  handleCancel = (e: any) => {
    const { closeHandler, creative } = this.props;
    const { editable } = this.state;
    const resetState = this.resetState.bind(this);
    if (creative || editable) {
      confirm({
        title: "Are you sure you want to leave this activity?",
        content: "You will lose all changes.",
        okText: "Leave",
        okType: "danger",
        onOk() {
          closeHandler();
          resetState();
        },
        onCancel() {},
      });
    } else {
      closeHandler();
      resetState();
    }
  };
  handleClose = (e: any) => {
    const { closeHandler } = this.props;
    closeHandler();
    this.resetState();
  };
  next = () => {
    const { current, statuses, form } = this.state;
    if (form) {
      form.validateFields((err: any, values: any) => {
        if (!err && statuses[current] === Status.FINISH) {
          this.setState({ current: current + 1 });
        } else if (err) {
          this.setStatus(Status.ERROR);
        }
      });
    }
  };
  previous = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };
  setStatus = (status: Status) => {
    const { current, statuses } = this.state;
    let updated = [...statuses];
    updated[current] = status;
    this.setState({
      statuses: updated,
    });
  };
  setBackgroundType = (type: string) => {
    const { previewImage, activity } = this.state;
    if (type === "image" && previewImage.length === 0) {
      this.setStatus(Status.PROCESS);
    } else if (
      type === "gradient" &&
      activity.background &&
      !activity.background.gradient
    ) {
      this.setStatus(Status.PROCESS);
    }
    this.setState({
      backgroundType: type,
    });
  };
  setPreviewImage = (base64: string | undefined) => {
    if (base64) {
      this.setState(
        {
          previewImage: base64,
        },
        () => {
          this.setStatus(Status.FINISH);
        }
      );
    }
  };
  uploadFile = (file: File): Promise<boolean> => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        if (reader.result) {
          this.setPreviewImage(reader.result as string);
          this.setStatus(Status.FINISH);
          this.setState({
            image: file,
          });
          resolve(true);
        }
        resolve(false);
      };
    });
  };
  removeFile = () => {
    this.setState({
      previewImage: "",
      image: undefined,
    });
  };
  resetState = () => {
    const resetState = _.cloneDeep(defaultState);
    this.setState(resetState);
  };
  setActivity = (props: any) => {
    if (props) {
      const { activity } = this.state;
      _.assign(activity, props);
      this.setState({
        activity: _.cloneDeep(activity),
      });
    }
  };
  setActiveForm = (form: any) => {
    this.setState({ form });
  };
  onEditChange = (checked: boolean) => {
    const { activity } = this.props;
    if (
      checked &&
      activity.id &&
      moment(activity.publishDate).isSameOrBefore()
    ) {
      message.error(
        "You cannot edit a published activity, if you need to update or fix a small detail or typo contact the OCS team."
      );
      // message.warning("You are editing a published post, be aware that any changes you make to this activity may influence the data you are collecting.");
    } else {
      this.setState({ editable: checked });
    }
  };
  renderTitle = () => {
    const { creative, editable } = this.state;
    let text;
    if (creative) {
      text = "Create New Activity";
    } else if (editable) {
      text = "Activity: Edit Mode";
    } else {
      text = "Activity: View Mode";
    }
    return (
      <div className="activity-form-title">
        <span className="activity-title-text">{text}</span>
        {!creative && (
          <Tooltip title="Click the switch to toggle view/edit mode.">
            <Switch checked={editable} onChange={this.onEditChange} />
          </Tooltip>
        )}
      </div>
    );
  };
  renderSteps = () => {
    const {
      backgroundType,
      activity,
      previewImage,
      image,
      creative,
      editable,
    } = this.state;
    const { background } = activity;
    return [
      {
        title: "Background",
        content: (
          <ActivityBackgroundForm
            creative={creative}
            editable={editable}
            setStatus={this.setStatus}
            background={background}
            backgroundType={backgroundType}
            setActivity={this.setActivity}
            uploadFile={this.uploadFile}
            removeFile={this.removeFile}
            setBackgroundType={this.setBackgroundType}
            activity={activity}
            image={image}
            previewImage={previewImage}
            setActiveForm={this.setActiveForm}
          />
        ),
      },
      {
        title: "Details",
        content: (
          <ActivityDetailsForm
            creative={creative}
            editable={editable}
            activity={activity}
            setStatus={this.setStatus}
            setActivity={this.setActivity}
            setActiveForm={this.setActiveForm}
          />
        ),
      },
      {
        title: "Questions",
        content: (
          <ActivityQuestionForm
            creative={creative}
            editable={editable}
            activity={activity}
            setStatus={this.setStatus}
            setActivity={this.setActivity}
            setActiveForm={this.setActiveForm}
          />
        ),
      },
      {
        title: "Save",
        content: (
          <ActivityReviewForm
            creative={creative}
            editable={editable}
            activity={activity}
            setStatus={this.setStatus}
            setActivity={this.setActivity}
            setActiveForm={this.setActiveForm}
          />
        ),
      },
    ];
  };
  renderActions = (length: number) => {
    const { current, statuses, editable, creative } = this.state;
    const footerActions = [];
    if (current < length - 1) {
      footerActions.push(
        <Button key="next" type="primary" onClick={this.next}>
          Next
        </Button>
      );
    }
    if (current === length - 1) {
      if (creative || editable) {
        footerActions.push(
          <Button key="save" type="primary" onClick={this.handleSave}>
            Save
          </Button>
        );
        footerActions.push(
          <Button
            key="saveAndPublish"
            type="primary"
            onClick={this.handlePublish}
          >
            Save & Publish
          </Button>
        );
      } else {
        footerActions.push(
          <Button key="close" type="primary" onClick={this.handleClose}>
            Close
          </Button>
        );
      }
    }
    if (current > 0) {
      footerActions.push(
        <Button key="previous" onClick={this.previous}>
          Previous
        </Button>
      );
    }
    return footerActions;
  };

  render() {
    const { current, statuses } = this.state;
    const { visible } = this.props;
    const steps = this.renderSteps();
    const footerActions = this.renderActions(steps.length);
    return (
      <Modal
        title={this.renderTitle()}
        visible={visible}
        onOk={this.handleSave}
        onCancel={this.handleCancel}
        footer={footerActions}
        closable={false}
        destroyOnClose={true}
        width="60%"
        style={{ minWidth: "800px" }}
      >
        <div className="activity-form-container">
          <Steps current={current} status={statuses[current]}>
            {steps.map((s) => (
              <Step key={s.title} title={s.title}></Step>
            ))}
          </Steps>
          <div className="activity-form-content">
            <Row>
              <Col span={24}>{steps[current].content}</Col>
            </Row>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    upsertActivity: (activity: ActivityType) => dispatch(upsertActivityAsync.request(activity)),
    publishActivity: (id: number) => dispatch(publishActivityAsync.request(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityFormContainer);