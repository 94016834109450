import React, { Component } from 'react';
import { Button } from 'antd';
import './table.scss';

interface ComponentProps {
  icon: string;
  buttonLabel: string;
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

class TableTitle extends Component<ComponentProps, {}> {
  render() {
    const { icon, buttonLabel, title, onClick } = this.props;
    return (<div className='table-title'>
      <h1>{title}</h1>
      <Button type="primary" icon={icon} onClick={onClick} >
        {buttonLabel}
      </Button>
    </div>);
  }
}

export default TableTitle;
                  