import React, { ChangeEvent } from 'react';
import {
  Input
} from 'antd';
import '../activity.scss';
import { ActivityOptionType } from '../../../types/entity.types';

interface ComponentProps {
  creative: boolean;
  editable: boolean;
  option: ActivityOptionType;
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setPoints: (value: number) => void;
}

const CategoryOption = (props: ComponentProps) => {
  const { creative, editable, option, onTextChange } = props;
  const { text } = option;
  return <Input spellCheck disabled={!creative && !editable} placeholder='Option text' value={text} onChange={onTextChange} style={{width: '100%'}}/>;
};

export default CategoryOption;