import {
  DatePicker, Radio, Button, Collapse, Popover, Cascader
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import * as _ from 'lodash';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import FormContextProvider from './FormContextProvider';
import { FormType, ParticipantType } from '../../types/entity.types';
import { saveFormAsync, updateFormAsync } from '../../redux/form/form.types';

import './editForm.scss';

const { TextArea } = Input;
const { confirm } = Modal;
const { Panel } = Collapse;

interface DispatchProps {
  saveForm: typeof saveFormAsync.request;
  editForm: typeof updateFormAsync.request;
}

interface StateProps {
  participants: ParticipantType[]
}

interface ComponentProps extends DispatchProps, StateProps {
  closeHandler: () => {},
  visible: boolean,
  editable: boolean,
  data: FormType,
  editing: boolean
}

const textAreaRows = 4;
const footerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%'
};
const footerActionStyle = {
  textAlign: 'center',
  width: '50%'
};
const radioStyle = {
  display: 'block',
  height: 'auto',
  lineHeight: '30px'
};

function filter(inputValue: any, path: any): boolean {
  return path.some((option: any) => option.label ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 : false);
}

const requiredText = 'This response is required.';

const reasonOptions = ['End of study', 'Death (complete AE CRF)', 'Voluntary withdrawal', 'Inappropriate app conduct', 'Incarceration', 'Inappropriate enrollment', 'Other, specify:'];
const withdrawalReasons = ['Did not like the app', 'Time commitment', 'Uncomfortable with study procedures', 'Had a bad experience', 'Other, specify:'];
const associationOptions = ['Yes', 'No', 'Don\'t know'];
const assessmentOptions = ['Baseline', 'Month 3', 'Month 6', 'Month 9', 'Month 12'];

class AdverseEventForm extends Component<ComponentProps & FormComponentProps, {}> {

  readonly state = {
    participantId: -1,
    formType: 'EARLY_TERMINATION',
    Q3R: new Date(),
    Q4R: -1,
    Q4aR: undefined,
    Q4bR: undefined,
    Q4cR: undefined,
    Q4dR: undefined,
    Q4eR: undefined,
    Q5R: undefined,
    Q6R: undefined,
    Q7R: undefined,
    Q8R: undefined
  };

  componentDidMount() {
    const { editing, data } = this.props;
    if(editing && data.id) {
      this.setState({
        participantId: data.participantId,
        ...JSON.parse(data.formData)
      });
    }
  }
  questionMap = {
    Q1: 'Q1R', // adminId
    Q2: 'Q2R', // participantId
    Q3: 'Q3R', // date
    Q4: 'Q4R', // number [0-6]
    Q4a: 'Q4aR', // if Q4R = 1, date or null
    Q4b: 'Q4bR', // if Q4R = 1, string or null
    Q4c: 'Q4cR', // if Q4R = 2, number [0-4]
    Q4d: 'Q4dR', // string (other)
    Q4e: 'Q4eR', // string (other) nested under Q4c
    Q5: 'Q5R', // number [0-2]
    Q6: 'Q6R', // number [0-4]
    Q7: 'Q7R', // date
    Q8: 'Q8R' // string
  };

  formatDate = (d: Date | undefined) => {
    return moment(d).calendar();
  }

  onChange = (state: any) => {
    this.setState(state);
  }

  showCancel = () => {
    const { closeHandler, editable } = this.props;
    if (editable) {
      confirm({
        title: 'Are you sure you want to leave this form?',
        content: 'You will lose all changes.',
        okText: 'Leave',
        okType: 'danger',
        onOk() {
          closeHandler();
        },
        onCancel() { }
      });
    } else {
      closeHandler();
    }
  }

  handleSubmit = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.showSave();
      }
    });
  }

  showSave = () => {
    const { editable, editing } = this.props;
    if (editable || editing) {
      const handleSaveForm = this.handleSaveForm.bind(this);
      confirm({
        title: 'Are you sure you want to submit this form?',
        content: '',
        okText: 'Save',
        onOk() {
          handleSaveForm();
        },
        onCancel() { }
      });
    }
  }

  handleSaveForm = () => {
    const { saveForm, closeHandler, editable, data, editing, editForm } = this.props;
    const { participantId, formType } = this.state;
    const form = {
      participantId,
      formType,
      formData: _.omit(this.state, ['participantId', 'formType']),
      isResolved: true
    };
    if (editable || editing) {
      const { formData } = form;
      if ((formData.Q4aR || formData.Q4bR) && formData.Q4R !== 1) {
        formData.Q4aR = undefined;
        formData.Q4bR = undefined;
      }
      if (formData.Q4cR && formData.Q4R !== 2) {
        formData.Q4cR = undefined;
      }
      if (formData.Q4dR && formData.Q4R !== 6) {
        formData.Q4dR = undefined;
      }
      if (formData.Q4eR && (formData.Q4R !== 2 || formData.Q4cR !== 4)) {
        formData.Q4eR = undefined;
      }
      if(editing && data.id) {
        form.id = data.id;
        editForm(form)
      } else {
        saveForm(form);
      }
    }
    closeHandler();
  }


  render() {
    const {
      form, visible, editable, data, editing, participants
    } = this.props;
    const { getFieldDecorator } = form;
    const { formType } = this.state;
    let formData: any;
    if (data) formData = JSON.parse(data.formData);
    const dateFormat = 'MM/DD/YYYY';
    const displayDate = this.formatDate(new Date());
    const type = formType.split('_').map((s: string) => s.charAt(0) + s.substring(1).toLowerCase()).join(' ');

    const options = _.map(participants, participant => _.pick(participant, ['username', 'id']))
      .map(option => { return { label: option.username, value: option.id.toString() }; });

    return (
      <Modal
        key={data?.id ? `form-${data.id}` : 'form-termination'}
        title={editable ? `${type} started: ${displayDate}` : `${type} created: ${this.formatDate(data.createDate)}`}
        visible={visible}
        onCancel={this.showCancel}
        width="45%"
        style={{ minWidth: '45vw' }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button type={editable || editing ? 'danger' : 'default'} onClick={e => { e.stopPropagation(); this.showCancel(); }}>Close</Button>,
          <Button type="default" disabled={!editable && !editing} onClick={e => { e.stopPropagation(); this.handleSubmit(); }}>Save</Button>,
        ]}
        destroyOnClose
      >
        <FormContextProvider>
          <Form key="form" layout="vertical" colon={false}>
            <Form.Item label="Participant ID:">
              {getFieldDecorator('participantId', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : data.participantId
              })(<Cascader style={{marginBottom: '5px'}}
                           placeholder="Enter participant's username"
                           options={options}
                           onChange={(value) => this.setState({participantId: value[0]})}
                           showSearch={{ filter }}/>)}
            </Form.Item>
            <Form.Item label="Termination Date:">
              {getFieldDecorator('Q3', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : moment(formData[this.questionMap.Q3])
              })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q3R: dateString })} format={dateFormat} />)}
            </Form.Item>
            <Form.Item label="Primary reason for early termination">
              {getFieldDecorator('Q4', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : formData[this.questionMap.Q4]
              })(<Radio.Group disabled={!editable && !editing} onChange={(e) => this.onChange({ Q4R: e.target.value })}>
                {reasonOptions.map(opt => {
                  switch (reasonOptions.indexOf(opt)) {
                    case 1:
                      return (
                        <Collapse style={radioStyle} bordered={false} activeKey={editable ? this.state.Q4R : formData[this.questionMap.Q4]}>
                          <Panel showArrow={false} id="collapse-header" header={<Radio value={reasonOptions.indexOf(opt)} style={radioStyle}>{opt}</Radio>} key="1">
                            <Form.Item label="Date of death:">
                              {getFieldDecorator('Q4a', {
                                rules: [{ required: this.state.Q4R === 1, message: requiredText }],
                                initialValue: editable ? undefined : (formData[this.questionMap.Q4a] ? 0 : 1)
                              })(<Radio.Group disabled={!editable && !editing}>
                                <Radio style={radioStyle} value={0}>
                                  <DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q4aR: dateString })} format={dateFormat} defaultValue={editable ? undefined : moment(formData[this.questionMap.Q4a])} />
                                </Radio>
                                <Radio style={radioStyle} value={1} onChange={e => this.onChange({ Q4aR: undefined })}>Unknown</Radio>
                                 </Radio.Group>)}
                            </Form.Item>
                            <Form.Item label="Cause of death:">
                              <Input disabled={!editable && !editing} onChange={(e) => this.onChange({ Q4bR: e.target.value })} defaultValue={editable ? '' : formData[this.questionMap.Q4b]} />
                            </Form.Item>
                          </Panel>
                        </Collapse>
                      );
                    case 2:
                      return (
                        <Collapse style={radioStyle} bordered={false} activeKey={editable ? this.state.Q4R : formData[this.questionMap.Q4]}>
                          <Panel showArrow={false} id="collapse-header" header={<Radio value={reasonOptions.indexOf(opt)} style={radioStyle}>{opt}</Radio>} key="2">
                            <Form.Item label="Primary reason for voluntary withdrawal:">
                              {getFieldDecorator('Q4c', {
                                rules: [{ required: this.state.Q4R === 2, message: requiredText }],
                                initialValue: editable ? undefined : formData[this.questionMap.Q4c]
                              })(<Radio.Group disabled={!editable && !editing} onChange={(e) => this.onChange({ Q4cR: e.target.value })}>
                                {withdrawalReasons.map(opt => {
                                  switch (withdrawalReasons.indexOf(opt)) {
                                    case 4:
                                      return (
                                        <div style={radioStyle}>
                                          <Radio value={withdrawalReasons.indexOf(opt)}>{opt}</Radio>
                                          <Input disabled={!editable && !editing} onChange={(e) => this.onChange({ Q4eR: e.target.value })} style={{ width: 100, marginLeft: 10 }} defaultValue={editable ? '' : formData[this.questionMap.Q4e]} />
                                        </div>
                                      );
                                    default:
                                      return <Radio style={radioStyle} value={withdrawalReasons.indexOf(opt)}>{opt}</Radio>;
                                  }
                                })}
                                 </Radio.Group>)}
                            </Form.Item>
                          </Panel>
                        </Collapse>
                      );
                    case 6:
                      return (
                        <div style={radioStyle}>
                          <Radio value={reasonOptions.indexOf(opt)}>{opt}</Radio>
                          <Input disabled={!editable && !editing} onChange={(e) => this.onChange({ Q4dR: e.target.value })} style={{ width: 100, marginLeft: 10 }} defaultValue={editable ? '' : formData[this.questionMap.Q4d]} />
                        </div>
                      );
                    default:
                      return <Radio style={radioStyle} value={reasonOptions.indexOf(opt)}>{opt}</Radio>;
                  }
                })}
              </Radio.Group>)}
            </Form.Item>
            <Form.Item label="Was early termination associated with an adverse event?">
              {getFieldDecorator('Q5', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? this.state.Q5R : parseInt(formData[this.questionMap.Q5])
              })(<Radio.Group disabled={!editable && !editing} onChange={e => this.onChange({ Q5R: e.target.value })}>
                {associationOptions.map(opt => {
                  switch (associationOptions.indexOf(opt)) {
                    case 0:
                      return (
                        <Popover placement="leftTop" content="Be sure to complete Adverse Event CRF." trigger="click">
                          <Radio value={associationOptions.indexOf(opt)}>{opt}</Radio>
                        </Popover>
                      );
                    default:
                      return <Radio value={associationOptions.indexOf(opt)}>{opt}</Radio>;
                  }
                })}
              </Radio.Group>)}
            </Form.Item>
            <Form.Item label="Last assessment started:">
              {getFieldDecorator('Q6', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? this.state.Q6R : formData[this.questionMap.Q6]
              })(<Radio.Group disabled={!editable && !editing} onChange={e => this.onChange({ Q6R: e.target.value })}>
                {assessmentOptions.map(opt => {
                  return <Radio style={radioStyle} value={assessmentOptions.indexOf(opt)}>{opt}</Radio>;
                })}
              </Radio.Group>)}
            </Form.Item>
            <Form.Item label="Date of last completed assessment:">
              {getFieldDecorator('Q7', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : moment(formData[this.questionMap.Q7])
              })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q7R: dateString })} format={dateFormat} />)}
            </Form.Item>
            <Form.Item label="Additional information regarding the reason for participant's early termination:">
              {getFieldDecorator('Q8', {
                rules: [{ required: false, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q8]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q8R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
          </Form>
        </FormContextProvider>
      </Modal>
    );
  }
}

const mapStateToProps =  createStructuredSelector<IApplicationState, StateProps>({
  participants: selectors.getRequestedStudyParticipantsWithUsernames
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    saveForm: (form: FormType) => dispatch(saveFormAsync.request(form)),
    editForm: (form: FormType) => dispatch(updateFormAsync.request(form))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'form' })(AdverseEventForm));
