import React, { Component } from 'react';
import './thread.scss';
import { Button } from 'antd';

interface ComponentProps {
  type: string;
  action: () => void;
  animation?: string;
}

class ToolbarButton extends Component<ComponentProps,{}> {

  renderIcon = () => {
    const { type, action, animation } = this.props;
    switch(type) {
      case 'add':
        return (<Button type='primary' onClick={(e) => { e.stopPropagation(); action(); }} className='toolbar-button'>
          <i className={`toolbar-button-icon far fa-plus ${animation}`} /> Compose
        </Button>);
      case 'refresh':
        return (<Button type='primary' onClick={(e) => { e.stopPropagation(); action(); }} className='toolbar-button'>
          <i className={`toolbar-button-icon fas fa-sync-alt ${animation}`} /> Refresh
        </Button>);
      default: 
      return <div></div>;
    }
  }

  render() {
    return this.renderIcon();
  }
}

export default ToolbarButton;