import React from 'react';
import {
  Popover, Icon
} from 'antd';
import './activity.scss';

interface ComponentProps {
  text: string;
}
const ActivityTooltip = (props: ComponentProps) => {
  const { text } = props;
  const content = <p className='activity-popover-content'>{text}</p>;
  return <Popover placement="right" content={content} trigger="click">
    <Icon type="info-circle" />
  </Popover>;
};

export default ActivityTooltip;