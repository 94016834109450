import React, { Component, RefObject } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { connect } from 'react-redux';
import { UserState } from "redux-oidc";
import { createStructuredSelector } from 'reselect';
import uuid from 'uuid';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';

interface StateProps {
  oidc: UserState
}

interface ComponentProps extends StateProps {
  model: string;
  onChange: (model: string) => void;
  disabled?: boolean;
  charLimit: number | undefined;
}

class FroalaCustomUpload extends Component<ComponentProps, {}> {
  private froalaRef: RefObject<any>;
  private key: string;
  constructor(props: ComponentProps) {
    super(props);
    this.froalaRef = React.createRef();
    this.key = uuid();
  }
  componentWillReceiveProps(nextProps: ComponentProps) {
    if(!nextProps.disabled) {
      const editor = this.froalaRef.current;
      if(editor && editor.getEditor() && editor.getEditor().edit) {
        editor.getEditor().edit.on();
      }
    } else if(nextProps.disabled) {
      const editor = this.froalaRef.current;
      if(editor && editor.getEditor() && editor.getEditor().edit) {
        editor.getEditor().edit.off();
      }
    }
  }
  onModelChange = (model: string) => {
    const { onChange, disabled } = this.props;
    if(!disabled) {
      onChange(model);
    }
  };
  render() {
    const { model, disabled, oidc, charLimit=-1 } = this.props;
    const config = {
      autoSave: true,
      saveInterval: 1000,
      immediateReactModelUpdate: true,
      charCounterCount: true,
      charCounterMax: charLimit,
      fontFamilySelection: true,
      fontSizeSelection: true,
      iconsTemplate: 'font_awesome_5r',
      imageMaxSize: 16 * 1024 * 1024, // 16MB
      imageUploadParam: 'file',
      imageUploadMethod: 'POST',
      imageUploadURL: '/api/a/cms/uploads/image',
      requestHeaders: {
        "Authorization": `Bearer ${oidc.user?.access_token}`,
      },
      pluginsEnabled: [
        'align',
        'charCounter',
        'codeBeautifier',
        'codeView',
        'colors',
        'draggable',
        'emoticons',
        'entities',
        'fontFamily',
        'fontSize',
        'fullscreen',
        'help',
        'image',
        'inlineClass',
        'inlineStyle',
        'link',
        'lists',
        'paragraphFormat',
        'paragraphStyle',
        'print',
        'quote',
        'save',
        'specialCharacters',
        'spellChecker',
        'table',
        'url',
        'video',
        'wordPaste',
      ],
      events : {
        'initialized': async function () {
          if(disabled) {
            await new Promise(resolve => setTimeout(resolve, 1));
            (this as any).edit.off();
          }
        }
      },
      linkAutoPrefix: ''
    };
    return <FroalaEditor
      ref={this.froalaRef}
      key={this.key}
      tag="textarea"
      config={config}
      model={model}
      onModelChange={this.onModelChange}
    />
  }
};

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  oidc: selectors.selectOidc
});

export default connect(mapStateToProps, null) ( FroalaCustomUpload );