import React, { Component } from 'react';
import moment from 'moment';
import { CommentType } from '../../types/entity.types';
import './newQnaPostForm.scss';
import { List, Icon, Comment } from 'antd';
import Username from '../username/Username';
import QnaCommentList from './QnaCommentList';
import FlagActionItem from './FlagActionItem';
const { Item } = List;
const IconText = ({ type, text }) => (
  <span className=''>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);

interface ComponentProps {
  level: number;
  comment: CommentType;
}

class QnaComment extends Component<ComponentProps, {}> {
  renderDate = (value: string | Date) => {
    if(!value) {
      return 'N/a'
    }
    return moment(value).calendar()
  };
  render() {
    const { comment, level } = this.props;
    const {
      id,
      participant,
      body,
      createDate,
      comments: childComments,
      thumbsups: commentThumbsups,
      favorites: commentFavorites,
      flags,
      isHidden
    } = comment;
    if(participant) {
      const {
        id: participantId,
        avatar,
        avatarBackground
      } = participant;
      let tags = [];
      if(isHidden) {
        tags.push(<span title='hidden' className='tag-bubble hidden'>HIDDEN</span>);
      }
      return (<div>
        <Comment
          style={level > 0 ? {marginLeft: `${level * 10 + 10}px`, backgroundColor: 'white'} : { backgroundColor: 'white' }}
          className='qna-post-comment'
          actions={[
            <IconText type="message" text={childComments ? childComments.length : 0} key="list-vertical-message" />,
            <IconText type="like-o" text={commentThumbsups ? commentThumbsups.length : 0} key="list-vertical-like-o" />,
            <IconText type="star-o" text={commentFavorites ? commentFavorites.length : 0} key="list-vertical-star-o" />,
            <FlagActionItem participantId={participantId} id={id} isHidden={isHidden} type={'comment'} flags={flags} />,
          ]}
          author={<span><Username participantId={participantId} style={{color: '#8185B3'}} />{tags}</span>}
          avatar={<img className='qna-avatar' alt='avatar' src={`data:image/jpeg;base64, ${avatar? avatar.avatar : ''}`} style={{ backgroundColor: avatarBackground ? `#${avatarBackground}` : '#FFB100' }} />}
          content={<p>{body}</p>}
          datetime={moment(createDate).calendar()}
        />
        {childComments && childComments.length > 0 && <QnaCommentList level={level+1} comments={childComments} />}
      </div>)
    }
    return <div />;
  }
}

export default QnaComment;