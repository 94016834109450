import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { updateStudyArms, StudyActionTypes } from './study.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import { StudyArmType } from '../../types/entity.types';

const updateStudyArmsReducer = produce((draft, studyArms) => {
  if (!studyArms) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(studyArms), Number));
  const ids = Object.keys(studyArms);
  for (const id of ids) {
    draft.byId[id] = studyArms[id];
  }
});

export const studyArmReducer = (state: NormalizedState<StudyArmType> = initialNormalizedState, action: StudyActionTypes) => {

  switch (action.type) {
    case getType(updateStudyArms): return updateStudyArmsReducer(state, action.payload);
    default: return state;
  }
};

export default studyArmReducer;
