import { AxiosResponse } from 'axios';
import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import { NormalizerResult } from '../../types/entity.types';
import { getBadgesAsync } from './badges.types';

const getBadges = () => {
  return axios({
    method: 'get',
    url: '/v/badge'
  });
};

function* getBadgesHandler(action: ReturnType<typeof getBadgesAsync.request>): Generator {

  try {
    const response: AxiosResponse = (yield call(getBadges)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.badges) as NormalizerResult;
    const { badges } = entities;

    yield put(getBadgesAsync.success(badges));
  } catch (error) {
    yield put(getBadgesAsync.failure(error));
  }
}

function* getBadgesWatcher() {
  yield takeLatest(getType(getBadgesAsync.request), getBadgesHandler);
}

export default function* badgeSaga() {
  yield all([fork(getBadgesWatcher)]);
}
