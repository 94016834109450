import * as React from 'react';
import { _ } from 'lodash';
import { Popover } from 'antd';
import moment from 'moment';
import { StudyArmType } from '../../types/entity.types';

export function renderDateWithTime(date:any) {
  return (date ? moment(date).format('YYYY-MM-DD h:mm:ss a') : 'N/A');
}

export function renderDateWithoutTime(date:any) {
  return (date ? moment(date).format('YYYY-MM-DD') : 'N/A');
}

export function renderPopoverDate(date:any) {
  return (
    <Popover content={renderDateWithTime(date)}>
      {renderDateWithoutTime(date)}
    </Popover>
  );
}

export function renderDateCalendar(date:any) {
  return moment(date).local().calendar();
}

export function renderPopoverDateCalendar(date:any) {
  return (
    <Popover content={renderDateWithTime(date)}>
      {renderDateCalendar(date)}
    </Popover>
  );
}

export function renderDateWithTimeTwelveHourPeriod(date: any) {
  return ( date ? moment(date).format('YYYY-MM-DD hh:mma') : 'N/A');
}

export function renderDateFromNow(date:any) {
  return moment(date).fromNow();
}

export function renderPopoverDateFromNow(date:any) {
  return (
    <Popover content={renderDateWithTime(date)}>
      {renderDateFromNow(date)}
    </Popover>
  );
}

/**
 * Adjusts body text before it is saved to database
 * @param body String for the editor content
 */
export function updateCmsEditorBeforeSave(body: string) {
  if(body){
    const updatedBody = body.replace(/hmp%3A/g, "hmp:");
    return updatedBody;
  }

  return body;
}

export function getStudyArmLabelFromId(studyArms: Optional<StudyArmType[]>, studyArmId: number|string): string {
  if (!studyArms) return '';
  const studyArm = _.find(studyArms, (sa:StudyArmType) => sa.id == studyArmId);
  return studyArm.name;
}
