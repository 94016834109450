import Collapse from 'antd/lib/collapse';
import Table from 'antd/lib/table';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MessageThreadType } from '../../types/entity.types';
import { renderDateWithTime, renderPopoverDate } from '../util/Util';

const initialState = {
};

type ComponentState = Readonly<typeof initialState>;

interface ComponentProps {
  threads: Optional<MessageThreadType[]>
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a: any, b: any) => a.id - b.id
  },
  {
    title: 'Date',
    dataIndex: 'lastMessageDate',
    key: 'lastMessageDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => {
      return moment(a.lastMessageDate || 0).unix() - moment(b.lastMessageDate || 0).unix();
    },
    defaultSortOrder: 'descend'
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    // render: renderParticipantLink,
    sorter: (a: any, b: any) => `${a.title}`.localeCompare(b.title)
  },
  {
    title: 'Preview',
    dataIndex: 'preview',
    key: 'preview',
    // render: renderParticipantLink,
    sorter: (a: any, b: any) => `${a.preview}`.localeCompare(b.preview)
  },
  {
    title: 'Unread messages',
    dataIndex: 'unreadMessageCount',
    sorter: (a: any, b: any) => a.unreadMessageCount - b.unreadMessageCount
  }

];

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class ThreadsTable extends Component<ComponentProps, ComponentState> {
  static defaultProps = { };

  readonly state: ComponentState = initialState;

  render() {
    const { Panel } = Collapse;

    return (
      <div id="search-results-container">
        <Table
          columns={columns}
          dataSource={this.props.threads}
          pagination={false}
          rowClassName={rowClassName}
        />
      </div>
    );
  }
}

export default connect(null, null)(ThreadsTable);
