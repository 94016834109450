import { _ } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import includes from 'lodash/includes';
import Collapse from 'antd/lib/collapse';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Table from 'antd/lib/table';
import { IApiRequestState } from 'types/api.types';
import StudyArmFilter from '../../../../components/studyArmFilter/StudyArmFilter';
import { renderDateWithoutTime, renderDateWithTime } from '../../../../components/util/Util';
import * as selectors from '../../../../redux/selectors';
import { ParticipantType, StudyArmType, StudyType } from '../../../../types/entity.types';
import IApplicationState from '../../../../types/state.types';
import SearchInput from '../../../../components/search/SearchInput';
import './participantSearch.scss';
import { Card, message, Popconfirm, Tabs } from 'antd';
import Username from '../../../../components/username/Username';
import axios from '../../../../redux/api';

const { TabPane } = Tabs;

interface StateProps {
  loadStudyStatus: IApiRequestState,
  study: Optional<StudyType>,
  participants: ParticipantType[],
  studyId: number
}

interface DispatchProps {
}

interface ComponentProps extends StateProps, DispatchProps, RouteComponentProps {}

const initialState = {
  searchString: '' as string,
  armFilterId: 0 as number,
  filtersCollapsed: false as boolean,
  participantSubpage: 'participants',
  flaggedParticipants: []
};

type ComponentState = Readonly<typeof initialState>

const renderParticipantLink = (value: any, row: any) => {
  return <Username participantId={row.id} />
};

const renderPhoneNumber = (value: any) => {
  if (!value) {
    return <span />;
  }
  const formattedPhoneNumber = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return <span>{formattedPhoneNumber}</span>;
};


const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class ParticipantSearch extends Component<ComponentProps, ComponentState> {

  readonly state: ComponentState = initialState;  

  handleSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value.toString();
    this.setState({
      searchString: value
    });
  };
  handleArmFilterChange = (event: RadioChangeEvent) => {
    const value = event.target.value as number;
    this.setState({
      armFilterId: value
    });
  };
  renderStudyArm = (value: number) => {
    const { study } = this.props;
    if(value && study) {
      const arm = study.studyArms.find(sa => sa.id === value);
      return arm ? arm.name : value;
    }
  };
  studyArmFilters = () => {
    const { study } = this.props;
    if(study) {
      return study.studyArms.map(arm => {
        return {
          text: arm.name,
          value: arm.id
        };
      });
    }
    return [];
  };
  handleRowClick = (record: ParticipantType, index: number, event: Event) => {
    if(record && record.id) {
      const { studyId } = this.props;
      this.props.history.push(`/study/${studyId}/participants/${record.id}`);
    }
  };
  handleFlaggedRowClick = (record: any) => {
    if(record && record.id) {
      const { studyId } = this.props;
      this.props.history.push(`/study/${studyId}/participants/${record.typeId}`);
    }
  };
  renderFirstNameLink = (value: any, row: any) => {
    const { studyId } = this.props;
    return <Link to={`/study/${studyId}/participants/${row.id}`}>{value}</Link>
  };
  getFlaggedParticipants = async () => {
    const result = await axios({
      method: 'get',
      url: `/a/social/flaggedParticipants`
    });
    this.setState({flaggedParticipants: result.data})
  }
  handleTabChange = async (activeKey:string) => {
    const { history, studyId } = this.props;
    switch (activeKey) {
      case 'participants':
        break;
      case 'flaggedParticipants':
        await this.getFlaggedParticipants()
        break;
    }
    this.setState({participantSubpage: activeKey})
  }

  updateFlag = async (record: any, resolution: string) => {
    const data = {
      ids: [record.id],
      resolution
    }
    const result = await axios({
      method: 'post',
      url: `/a/social/flag/resolve`,
      data
    });
    await this.getFlaggedParticipants()
  }
  resolveFlag = async (record: any) => {
    try{
    await this.updateFlag(record, "Confirmed");
    message.success('Updated successfully');
  } catch (e) {
      message.error('Error occurred while updating: '+e);
    } 
  }

  ignoreFlag = async (record: any) => {
    try{
      await this.updateFlag(record, "Ignored");
    message.success('Updated successfully');
  } catch (e) {
      message.error('Error occurred while updating: '+e);
  } 
  }

  flaggedColumns = [
    {
      title: 'Flagged Participant',
      dataIndex: 'username',
      key: 'username',
      render: (username: any, record: any) => (
        <a onClick={(e) => this.handleFlaggedRowClick(record)}>{username}</a>
      ),
    },
    {
      title: 'Flagged On',
      dataIndex: 'createDate',
      key: 'createDate',
      render: renderDateWithTime,
    },
    {
      title: 'Resolution',
      dataIndex: 'resolution',
      key: 'resolution',
      sorter: (a: any, b: any) => a.id - b.id,
      render: (feature: any, record: any) => (
        <Popconfirm
    title="Select to resolution:"
    onConfirm={(e) => this.resolveFlag(record)}
    onCancel={(e) => this.ignoreFlag(record)}
    okText="Confirm"
    cancelText="Ignore"
  >
        <a>{record.resolution?? "<Click to Update>"}</a>
        </Popconfirm>
      ),
    },
    {
      title: 'Resolve Date',
      dataIndex: 'resolveDate',
      key: 'resolveDate',
      render: renderDateWithTime,
    },
  ]

  render() {
    const studyArmFilters = this.studyArmFilters();
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id - b.id
      }, {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        render: renderParticipantLink,
        sorter: (a: any, b: any) => `${a.username}`.localeCompare(b.username),
        filters: [
          {
            text: 'Unenrolled',
            value: 'Unenrolled'
          }
        ],
        onFilter: (value, record) => value === 'Unenrolled' && _.isEmpty(record.username)
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        render: this.renderFirstNameLink,
        sorter: (a: any, b: any) => `${a.firstName}`.localeCompare(b.firstName)
      }, {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a: any, b: any) => `${a.email}`.localeCompare(b.email)
      }, {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        render: renderPhoneNumber,
        sorter: (a: any, b: any) => `${a.mobile}`.localeCompare(b.mobile)
      }, {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: renderDateWithoutTime,
        sorter: (a: any, b: any) => moment(a.startDate).unix() - moment(b.startDate).unix(),
        defaultSortOrder: 'descend'
      }, {
        title: 'Study Arm',
        dataIndex: 'studyArmId',
        key: 'studyArmId',
        render: this.renderStudyArm,
        filters: studyArmFilters,
        onFilter: (value, record) => record.studyArmId === value
      }
    ];

    const { searchString, armFilterId, filtersCollapsed } = this.state;

    const { loadStudyStatus, study, participants } = this.props;

    if (loadStudyStatus.isLoading) {
      return <div>Loading</div>;
    }

    if (loadStudyStatus.isError) {
      return <div>Error Loading the Study</div>;
    }

    if (!study) {
      return <div>Study was not loaded</div>;
    }

    const searchParticipants = searchString === '' ? participants : participants.filter(participant => {
      return Object.values(participant).some(property => includes(property ? property.toString().toLowerCase() : '', searchString.toLowerCase()));
    });

    const filteredParticipants = armFilterId === 0 ? searchParticipants : searchParticipants.filter(participant => {
      return participant.studyArmId === armFilterId;
    });

    const filterHeight = filtersCollapsed ? 275 : 350;
    return (
      <Tabs defaultActiveKey="participants" onChange={this.handleTabChange}>
          <TabPane key="participants" tab="Participants">
      <Card
        id="participant-search"
      >
        <div className='table-filter-search'>
          <SearchInput
            className='search-bar'
            id="search-input"
            onChangeHandler={this.handleSearchStringChange}
            placeholder="Search Participants"
            value={searchString}
          />
        </div>
        <div id="search-results-container">
          <div style={{flex: '0 0 auto', height: '100%'}}>
            <Table
              scroll={{y: `calc(100vh - ${filterHeight}px)`}}
              columns={columns}
              dataSource={filteredParticipants}
              rowClassName={rowClassName}
              pagination={false}
              tableLayout='fixed'
              rowKey="id"
              onRowClick={this.handleRowClick}
            />
          </div>
        </div>
      </Card>
          </TabPane>
          <TabPane key="flaggedParticipants" tab="Flagged Participants">
          <Table              
              columns={this.flaggedColumns}
              dataSource={this.state.flaggedParticipants}
              rowClassName={rowClassName}
              pagination={false}
              tableLayout='fixed'
              rowKey="id"
            />
            </TabPane>
          </Tabs>

    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  loadStudyStatus: selectors.loadStudyStatus,
  study: selectors.getRequestedStudy,
  participants: selectors.getRequestedStudyParticipants,
  studyId: selectors.getRequestedStudyId
});

export default withRouter(connect(mapStateToProps)(ParticipantSearch));
