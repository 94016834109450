import React, { useState, Component } from 'react';
import * as _ from 'lodash';
import {
  Icon, Button, Col, Row
} from 'antd';
import Form from 'antd/lib/form';
import { ActivityOptionType, ActivityTreeOptionType } from '../../../types/entity.types';
const { Item } = Form;
import '../activity.scss';
import TreeOptionItem from './TreeOptionItem';

interface ComponentProps {
  form: any;
  creative: boolean;
  editable: boolean;
  options: ActivityOptionType[];
  level: number;
  setStatus: (status: string) => void;
  updateOptions: (options: ActivityOptionType[]) => void;
  addOption: () => void;
  removeOption: (index: number) => void;
  swapOptionPosition?: (indexA: number, indexB: number) => void;
}

class TreeOptionItems extends Component<ComponentProps, {}> {
  onAddClick = (e: React.MouseEvent<HTMLElement>) => {
    const { addOption } = this.props;
    e.preventDefault();
    addOption();
  };
  updateOption = (option: ActivityTreeOptionType, index: number) => {
    const { options, updateOptions } = this.props;
    if(index < options.length) {
      options[index] = option;
      updateOptions(options);
    }
  };

  render() {
    const { 
      form, 
      level, 
      options, 
      setStatus, 
      removeOption, 
      creative,
      editable
    } = this.props;
    const items = options.map((option: ActivityTreeOptionType, index: number) => {
      return (<li className='tree-option-item' key={`option-item-${creative ? option.uuid : option.id}`} >
        <div className='tree-option-item-content'>
          <TreeOptionItem
            form={form}
            creative={creative}
            editable={editable}
            option={option}
            level={level+1}
            setStatus={setStatus}
            updateOption={(o: ActivityTreeOptionType) => this.updateOption(o, index)}
          />
          {(creative || editable) && <div className='tree-option-icon'>
            <Icon type='minus-circle-o' onClick={() => removeOption(index)} />
          </div>}
        </div>
      </li>);
    });
    return (
      <div className='tree-options-container'>
        <div className='tree-option-items'>
          {items}
        </div>
        <Item>
          <Button disabled={!creative && !editable} type='dashed' onClick={this.onAddClick} style={{width: '100%'}}>
            <Icon type='plus' /> Add option
          </Button>
        </Item>
      </div>
    );
  }
};

export default TreeOptionItems;