import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { ReactNode } from 'react';
import { FormContext } from './FormContext';

type FormContextProviderProps = { children?: ReactNode } & FormComponentProps;

// This strategy for dealing with Antd Form decorators came from...
// https://medium.com/@joshuaavalon/use-ant-design-form-with-react-context-api-a9db1bdd539
function FormContextProviderImpl(props: FormContextProviderProps): JSX.Element {
  const { form, children } = props;
  return <FormContext.Provider value={form}>{children}</FormContext.Provider>;
}

export const FormContextProvider = Form.create()(FormContextProviderImpl);
export default FormContextProvider;
