import React, { Component } from 'react';
import {
  Icon, Button
} from 'antd';
import Form from 'antd/lib/form';
import { ActivityFeedbackType, ActivityOptionType } from '../../../types/entity.types';
const { Item } = Form;
import FeedbackItem from './FeedbackItem';
import '../activity.scss';

interface ComponentProps {
  form: any;
  type: number;
  creative: boolean;
  editable: boolean;
  feedback?: ActivityFeedbackType;
  option: ActivityOptionType;
  setStatus: (status: string) => void;
  updateFeedback: (feedback: ActivityFeedbackType) => void;
  addFeedback: () => void;
  removeFeedback: (e: React.MouseEvent<HTMLElement>) => void;
}
const itemStyle = {
  width: '100%',
  marginBottom: '10px', 
  marginTop: '10px'
};

class OptionFeedbackItem extends Component<ComponentProps, {}> {
  onAddClick = (e: React.MouseEvent<HTMLElement>) => {
    const { addFeedback } = this.props;
    e.preventDefault();
    addFeedback();
  };
  render() {
    const { 
      form, 
      type, 
      creative,
      editable,
      feedback, 
      setStatus, 
      updateFeedback,
      removeFeedback, 
      option 
    } = this.props;
    return (
      <div className='feedback-item'>
        {feedback ? <ul className='feedback-list-item-container'>
            <FeedbackItem 
              form={form} 
              type={type} 
              creative={creative}
              editable={editable}
              feedback={feedback} 
              options={[option]} 
              setStatus={setStatus}
              updateFeedback={updateFeedback}
            />
            {(creative || editable) && 
            <button disabled={!creative && !editable} className='dashed-danger-button' onClick={removeFeedback}>
              <Icon type='minus' /> Remove Option Feedback
            </button>}
          </ul>
        : <Item style={itemStyle}>
          <Button disabled={!creative && !editable} type='dashed' onClick={this.onAddClick} style={{width: '100%'}}>
            <Icon type='plus' /> Add feedback
          </Button>
        </Item>}
      </div>
    );
  }
};

export default OptionFeedbackItem;