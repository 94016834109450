import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import { transliterate as tr } from 'transliteration';
import TextArea from 'react-textarea-autosize'
import { ParticipantType, QnaPostType } from '../../types/entity.types';
import './newQnaPostForm.scss';
import Form, { FormComponentProps } from 'antd/lib/form';
import FormContextProvider from '../form/FormContextProvider';
import { Modal, Select, Button, Cascader, Input } from 'antd';
import { createQnaPostAsync, updateQnaPostAsync } from '../../redux/qna/qna.types';
import { TopicType } from '../../types/entity.types';
import HMPTextArea from '../../components/textarea/HMPTextArea';
import FroalaCustomUpload from '../activity/FroalaCustomUpload';
import { updateCmsEditorBeforeSave } from '../util/Util';

const { confirm } = Modal;
const { Item } = Form;
const { Option } = Select;

interface StateProps {
  participants: ParticipantType[];
  topics: TopicType[];
}

interface DispatchProps {
  createQnaPost: typeof createQnaPostAsync.request;
  updateQnaPost: typeof updateQnaPostAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps, FormComponentProps {
  visible: boolean;
  closeHandler: () => void;
  post?: QnaPostType;
}

const defaultPost: QnaPostType = {
  id: undefined,
  topicId: undefined,
  question: '',
  answer: '',
  answerHtml: '',
  topic: '',
  isAnswered: true,
  isPrivate: false,
  answeredByUserId: -1,
  createDate: new Date(),
  lastUpdateDate: new Date(),
  username: '',
  answeredByUsername: '',
  deleteDate: null,
  threadId: null,
  tags: '',
  thumbsups: [],
  favorites: [],
  comments: [],
  adHocNotifications: []
};
const itemStyle = {
  marginBottom: '10px',
  marginTop: '10px'
}
const questionCharLimit = 5000;
const answerCharLimit = 25000;
const MEDIUMTEXT = 16777215;
const cleanstring = (dirty: string) => {
  if (dirty) {
    return tr(dirty
      .replace(/‘/g, "'")
      .replace(/’/g, "'")
      .replace(/“/g, '"')
      .replace(/”/g, '"')
    );
  }
  return '';
};

class QnaFormContainer extends Component<ComponentProps, {}> {
  readonly state = {
    post: defaultPost
  };
  componentDidMount() {
    const { post } = this.props;
    if(post && post.id) {
      this.setState({ post });
    }
  }
  renderTitle= () => {
    return <div className='qna-form-title'>
      <span className='qna-title-text'>Create/Edit Post</span>
    </div>
  }
  handleSave = () => {
    const { form } = this.props;
    const { post } = this.state;
    if(form) {
      form.validateFields((err: any, values: any) => {
        if (!err) {
          const handleSaveQnaPost = this.handleSaveQnaPost.bind(this);
          confirm({
            title: `Are you sure you want to post this?`,
            content: '',
            okText: 'Confirm',
            onOk() {
              handleSaveQnaPost();
            },
            onCancel() {}
          });
        }
      });
    }
  };
  handleSaveQnaPost = async () => {
    const {
      closeHandler,
      createQnaPost,
      updateQnaPost
    } = this.props;
    const {
      post
    } = this.state;
    const {
      question,
      answer
    } = post;
    post.question = cleanstring(question);
    post.answer = answer ? cleanstring(answer) : '';
    if(post.id) {
      updateQnaPost(post);
    } else {
      createQnaPost(post);
    }
    this.resetState();
    closeHandler();
  };
  resetState = () => {
    const resetState = _.cloneDeep({ post: defaultPost });
    this.setState(resetState);
  };
  handleCancel = () => {
    const { closeHandler } = this.props;
    const reset = this.resetState.bind(this);
    confirm({
      title: 'Are you sure you want to leave this post?',
      content: 'You will lose all changes.',
      okText: 'Leave',
      okType: 'danger',
      onOk() {
        reset();
        closeHandler();
      },
      onCancel() {}
    });
  }
  onTopicIdChange = (id: number) => {
    const { post } = this.state;
    post.topicId = id;
    this.setState({ post });
  };
  onQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { post } = this.state;
    post.question = e.target.value;
    this.setState({ post });
  };
  onAnswerHtmlChange = (value: string) => {
    const { post } = this.state;
    const answerHtml = updateCmsEditorBeforeSave(value);
    post.answerHtml = answerHtml
    this.setState({ post });
  };

  onAnswerChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { post } = this.state;
    post.answer = e.target.value;
    this.setState({ post });
  };
  characterLimit = (s: string | null, limit: number): string => {
    if(s) {
      if(s.length <= limit) {
        return `(${limit - s.length} characters left.)`
      }
      return `(${s.length - limit} characters over character limit.)`
    }
    return `(${limit} characters left)`;
  };
  render() {
    const {
      visible,
      form,
      participants,
      topics,
    } = this.props;
    const { getFieldDecorator } = form;
    const { post } = this.state;
    const {
      topicId,
      question,
      answer,
      answerHtml,
    } = post;
    const footerActions = [
      <Button key="cancel" type='default' onClick={this.handleCancel}>Cancel</Button>,
      <Button key="primary" type='primary' onClick={this.handleSave}>Post</Button>
    ];
    return (<Modal
      title={this.renderTitle()}
      visible={visible}
      onOk={this.handleSave}
      onCancel={this.handleCancel}
      footer={footerActions}
      closable={false}
      destroyOnClose={true}
      width='50%'
      style={{minWidth: '800px'}}>
      <FormContextProvider>
        <Form key="post-form" className="new-qna-post-form" layout="horizontal" colon={false}>
          <Item key="post-form-topic" label="Topic:" style={itemStyle} >
            {getFieldDecorator('topic', {
              rules: [{ required: true, message: 'Type is required.' }],
              initialValue: topicId
            })(
              <Select
                showSearch
                onChange={this.onTopicIdChange}
                style={{ width: 'auto', minWidth: 200 }}
                placeholder="Choose a topic"
                optionFilterProp='value'
                filterOption={(input, option) => {
                  const current = topics.find(t => t.id === option.props.value);
                  if(current) {
                    return current.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  return false;
                }}
              >
                {topics && _.map(_.sortBy(topics, 'title'), topic => <Option key={`option-${topic.id}`} value={topic.id}>{topic.title}</Option>)}
              </Select>
            )}
          </Item>
          <Item key="post-form-label" label="Question:" style={itemStyle} >
            {getFieldDecorator('question', {
              rules: [
                { required: true, message: `Question is required.` },
                { max: questionCharLimit, message: `${question.length - questionCharLimit} characters over`}
              ],
              initialValue: question ? question : '',
            })(<HMPTextArea
              spellCheck={true}
              maxRows={8}
              onChange={this.onQuestionChange}
              value={question ? question : ''}
              placeholder='Question text'
              characterLimit={questionCharLimit}
            />)}
          </Item>
          <Item key="post-form-answerhtml" label="Answer:" style={itemStyle} >
              {getFieldDecorator('answerHtml', {
                rules: [
                  { required: true, message: `Answer is required.` },
                  { max: MEDIUMTEXT, message: `${answerHtml ? answerHtml.length - MEDIUMTEXT : 0} characters over. Consider using less stylized text or mark up.`}
                ],
                initialValue: answerHtml ? answerHtml: ''
              })(<FroalaCustomUpload
                model={answerHtml ? answerHtml : ''}
                onChange={this.onAnswerHtmlChange}
                charLimit={answerCharLimit}
              />)}
            </Item>
          <Item key="post-form-answer" label="Answer (Plain Text): Please make sure this content is same as Answer above!" style={itemStyle} >
            {getFieldDecorator('answer', {
              rules: [
                { required: true, message: `Answer (Plain Text) is required.` },
                { max: answerCharLimit, message: `${answer ? answer.length - answerCharLimit : 0} characters over`}
              ],
              initialValue: answer ? answer : '',
            })(<HMPTextArea
              className='qna-input'
              spellCheck={true}
              maxRows={8}
              onChange={this.onAnswerChange}
              value={answer ? answer : ''}
              placeholder='Answer text'
              characterLimit={answerCharLimit}
            />)}
          </Item>
        </Form>
      </FormContextProvider>
    </Modal>);
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    participants: selectors.getRequestedStudyParticipantsWithUsernames(state),
    topics: selectors.getQnaTopics(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    createQnaPost: (post: QnaPostType) => dispatch(createQnaPostAsync.request(post)),
    updateQnaPost: (post: QnaPostType) => dispatch(updateQnaPostAsync.request(post))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'qna-form' })(QnaFormContainer));