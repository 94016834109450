import { message } from 'antd';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Tabs from 'antd/lib/tabs';
import { _ } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import PostList from '../../pages/study/forum/PostList';
import { clearStatus } from '../../redux/api/api.types';
import { loadDashboardDataAsync } from '../../redux/dashboard/dashboard.types';
import {
  resetRecentActivityPostIds,
  updateFlaggedScroll, updateFlaggedSelectedPostId, updateRecentActivityScroll, updateRecentActivitySelectedPostId,
  updateUncategorizedScroll,
  updateUncategorizedSelectedPostId
} from '../../redux/forum/forum.types';
import {
  getFlaggedPostsAsync,
  getPostsAsync,
  GetPostsSearchType,
  getUncategorizedPostsAsync,
  initialGetPostsSearch,
  savePostAsync
} from '../../redux/posts/posts.types';
import { IApiRequestState } from '../../types/api.types';
import {
  CommentType,
  DashboardDataType,
  FavoriteType,
  FlagType, ParticipantType, PostPollOption,
  PostType, StudyArmType,
  StudyType,
  ThumbsupType, TopicType
} from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import SearchInput from '../search/SearchInput';
import Config from '../util/Config';
import ForumPostCreationModal from './ForumPostCreationModal';
import * as selectors from '../../redux/selectors';

const { TabPane } = Tabs;
const { Panel } = Collapse;

interface StateProps {
  study: Optional<StudyType>,
  getPostsStatus: IApiRequestState,
  posts: Optional<PostType[]>,
  uncategorizedPosts: Optional<PostType[]>,
  flaggedPosts: Optional<PostType[]>,
  flags: Optional<FlagType[]>,
  comments: Optional<CommentType[]>,
  thumbsups: Optional<ThumbsupType[]>,
  favorites: Optional<FavoriteType[]>,
  loadDashboardDataStatus: IApiRequestState,
  dashboardData: Optional<DashboardDataType>,
  forumsSubpage: Optional<string>,
  recentActivityScrollTop: number;
  flaggedPostsScrollTop: number;
  uncategorizedPostsScrollTop: number;
  recentActivitySelectedPostId: Optional<number>;
  flaggedPostsSelectedPostId: Optional<number>;
  uncategorizedPostsSelectedPostId: Optional<number>;
  pseudoParticipants: Optional<ParticipantType[]>,
  topics: Optional<TopicType[]>;
  arms: Optional<StudyArmType[]>;
  studyId: number;
  saveForumPostStatus: IApiRequestState,
}
interface DispatchProps {
  savePost: typeof savePostAsync.request,
  getPosts: typeof getPostsAsync.request,
  getUncategorizedPosts: typeof getUncategorizedPostsAsync.request,
  loadDashboardData: typeof loadDashboardDataAsync.request,
  getFlaggedPosts: typeof getFlaggedPostsAsync.request,
  updateRecentActivityScroll: typeof updateRecentActivityScroll
  updateFlaggedScroll: typeof updateFlaggedScroll
  updateUncategorizedScroll: typeof updateUncategorizedScroll,
  updateRecentActivitySelectedPostId: typeof updateRecentActivitySelectedPostId,
  updateFlaggedSelectedPostId: typeof updateFlaggedSelectedPostId,
  updateUncategorizedSelectedPostId: typeof updateUncategorizedSelectedPostId,
  resetRecentActivityPostIds: typeof resetRecentActivityPostIds,
  clearStatus: typeof clearStatus
}
interface ComponentProps extends StateProps, DispatchProps, RouteComponentProps {}
const initialState = {
  selectedPost: undefined as Optional<PostType>,
  selectedFlaggedPost: undefined as Optional<PostType>,
  isEditing: false as boolean,
  editedPost: undefined as Optional<PostType>,
  armFilterId: 0 as number,
  getPostsSearch: Object.assign( initialGetPostsSearch, {studyArmIds: Config.isMultiStudyArm()? [2, 3]: [1]}),
  searchIsCollapsed: false as boolean,
  postCreationModalVisible: false as boolean,
  newPostTitle: '' as string,
  newPostBody: '' as string,
  newPostPollOptions: [{option: ''}] as Array<PostPollOption>,
  addPostError: false as boolean,
  addPostErrorMessage: '' as string,
  newPostArm2Selected: true as boolean,
  newPostArm3Selected: true as boolean,
  newPostTopic: 'Select a topic' as string,
  showDeleted: true as boolean
};
type ComponentState = typeof initialState;

class ForumPage extends Component<ComponentProps, ComponentState> {
  readonly state = initialState;
  async componentDidMount() {
    const { study, getPosts, getUncategorizedPosts, getFlaggedPosts, loadDashboardData, pseudoParticipants } = this.props;
    const forumsSubpage = this.props.forumsSubpage === '' ? 'recentActivity' : this.props.forumsSubpage;
    if (study) {
      switch(forumsSubpage) {
        case 'recentActivity':
          getPosts(this.state.getPostsSearch);
          break;
        case 'uncategorized':
          getUncategorizedPosts();
          break;
        case 'flagged':
          getFlaggedPosts();
          break;
        default:
          throw `Bad forumSubpage (${forumsSubpage})`;
      }
      loadDashboardData();
    }
  }
  handleSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { getPosts, resetRecentActivityPostIds } = this.props;
    const { getPostsSearch } = this.state;
    const value = event.target.value.toString();

    resetRecentActivityPostIds();

    this.setState({
      getPostsSearch: Object.assign(getPostsSearch, {searchText: value, pageNumber: 0})
    });
    if(value.length > 0) {
      Object.assign(getPostsSearch, {searchText: value});
      getPosts(getPostsSearch);
    } else {
      Object.assign(getPostsSearch, {searchText: undefined})
      getPosts(getPostsSearch);
    }
  };
  handleSearchSubmit = (value: string) => {
    const { getPosts } = this.props;
    const { getPostsSearch } = this.state;
    Object.assign(getPostsSearch, {searchText: value})
    getPosts(getPostsSearch);
  }
  handleSearchCollapseChange = (openPanels:string[]) => {
    const searchIsCollapsed = _.isEmpty(openPanels);
    this.setState( {searchIsCollapsed})
  }
  handleShowDeletedChange = (checked: boolean) => {
    this.setState({
      showDeleted: checked,
      getPostsSearch: Object.assign(this.state.getPostsSearch, { includeDeleted: checked })
    }, () => {
      this.props.getPosts(this.state.getPostsSearch);
    });
  };
  renderSearchCriteriaSection = () => {
    const { arms } = this.props;
    const internalArmFilterChange = (event: RadioChangeEvent) => {
      const studyArmIds: number[] = event.target.value === -1 ? arms ? arms.map(arm => arm.id) : [] : [event.target.value];
      const newGetPostsSearch = Object.assign(this.state.getPostsSearch, {studyArmIds: studyArmIds, pageNumber: 0});
      this.setState({getPostsSearch: newGetPostsSearch, selectedPost: undefined});
      this.props.resetRecentActivityPostIds();
      this.props.updateRecentActivityScroll(0);
      this.props.getPosts(newGetPostsSearch);
    };
    const { getPostsSearch, showDeleted } = this.state;
    const selectedValue = getPostsSearch.studyArmIds.length === 1 ? getPostsSearch.studyArmIds[0] : -1;
    return <div id="search-container">
      <Collapse defaultActiveKey={['search']} expandIconPosition="right" onChange={this.handleSearchCollapseChange}>
        <Panel key="search" header="Search Criteria">
          <div>
            <SearchInput
              id="search-input"
              onChangeHandler={this.handleSearchStringChange}
              onSubmitHandler={this.handleSearchSubmit}
              placeholder="Search Forum"
              value={this.state.getPostsSearch.searchText}
            />
            <br/>
            <Radio.Group id="arm-filter" value={selectedValue} onChange={internalArmFilterChange}>
              <Radio.Button key='arm-radio--1' value={-1}>All</Radio.Button>
              {arms?.map(arm => <Radio.Button key={`arm-radio-${arm.id}`} value={arm.id}>{arm.name}</Radio.Button>)}
            </Radio.Group>
            {/* <span className='filter'>Show Deleted <Switch checked={showDeleted} onChange={this.handleShowDeletedChange} /></span> */}
          </div>
        </Panel>
      </Collapse>
    </div>
  };
  handleTabChange = (activeKey:string) => {
    const { history, getPosts, getUncategorizedPosts, getFlaggedPosts, studyId } = this.props;
    const { getPostsSearch } = this.state;
    switch (activeKey) {
      case 'recentActivity':
        getPosts(getPostsSearch);
        break;
      case 'uncategorized':
        getUncategorizedPosts();
        break;
      case 'flagged':
        getFlaggedPosts();
        break;
    }
    history.push(`/study/${studyId}/forums/${activeKey}`);
  }

  onRecentActivityScroll = (scrollTop:number, event: UIEvent) => {
   this.props.updateRecentActivityScroll(scrollTop);
   const { scrollHeight, clientHeight } = event.target;

   // Adding this to help debug why infinite scrolling doesn't work in some situations.
   console.log(` Math.round(scrollHeight - scrollTop) === clientHeight ... Math.round(${scrollHeight} - ${scrollTop}) === ${clientHeight} -> ${Math.round(scrollHeight - scrollTop)} === ${clientHeight} -> ${Math.round(scrollHeight - scrollTop) === clientHeight}`);

   if (Math.round(scrollHeight - scrollTop) === clientHeight) {
     const { getPosts } = this.props;
     const { getPostsSearch } = this.state;
     let nextPageSearch = _.cloneDeep(getPostsSearch);
     nextPageSearch.pageNumber++;
     this.setState({getPostsSearch: nextPageSearch});
     getPosts(nextPageSearch);
   }
  }

  onFlaggedScroll = (scrollTop:number, event: UIEvent) => {
    this.props.updateFlaggedScroll(scrollTop);
  }
  onUncategorizedScroll = (scrollTop:number, event: UIEvent) => {
    this.props.updateUncategorizedScroll(scrollTop);
  }
  onSelectRecentActivityPost = (post: PostType) => {
    this.props.updateRecentActivitySelectedPostId(post.id);
  }
  onSelectFlaggedPost = (post: PostType) => {
    this.props.updateFlaggedSelectedPostId(post.id);
  }
  onSelectUncategorizedPost = (post: PostType) => {
    this.props.updateUncategorizedSelectedPostId(post.id);
  }
  handleShowAddPostModal = () => {
    this.setState({postCreationModalVisible: true, addPostError: false});
  }
  closePostCreationModal = () => {
    this.setState({
      postCreationModalVisible: false,
    });
  };
  render() {
    const {
      getPostsStatus, posts = [],
      uncategorizedPosts,
      flaggedPosts,
      dashboardData,
      loadDashboardDataStatus,
      recentActivityScrollTop,
      flaggedPostsScrollTop,
      uncategorizedPostsScrollTop,
      recentActivitySelectedPostId,
      flaggedPostsSelectedPostId,
      uncategorizedPostsSelectedPostId,
      saveForumPostStatus,
      clearStatus
    } = this.props;
    const {
      postCreationModalVisible
    } = this.state;
    const forumsSubpage = this.props.forumsSubpage === '' ? 'recentActivity' : this.props.forumsSubpage;
    if (getPostsStatus.isError || loadDashboardDataStatus.isError) {
      return <div>{`Error: ${getPostsStatus.isError ? getPostsStatus.errorMessage : loadDashboardDataStatus.isError}`}</div>;
    }
    if(saveForumPostStatus.isError) {
      message.error(`There was an error saving posts:\n${saveForumPostStatus.errorMessage}`, 3);
      clearStatus(getType(savePostAsync.failure));
    }
    if(saveForumPostStatus.isSuccess) {
      message.success(`Forum posts created successfully.`);
      clearStatus(getType(savePostAsync.success));
    }
    const addPost = <Button type='primary' onClick={this.handleShowAddPostModal}>+ Add Post</Button>;
    const expandedeHeight = 375;
    const listHeight = window.innerHeight - (this.state.searchIsCollapsed ? (expandedeHeight - 118): expandedeHeight);
    return (
      <div id="hmp-forum-page">
        <ForumPostCreationModal visible={postCreationModalVisible} closeHandler={this.closePostCreationModal} />
        <Tabs defaultActiveKey={forumsSubpage} tabBarExtraContent={addPost} onChange={this.handleTabChange}>
          <TabPane key="recentActivity" tab="Forum Posts">
            <div className="hmp-forum-page-tab">
              <div className="hmp-forum-page-tab-search">
                {this.renderSearchCriteriaSection()}
              </div>
              <PostList height={listHeight}
                        onScroll={this.onRecentActivityScroll}
                        scrollTop={recentActivityScrollTop}
                        selectedPostId={recentActivitySelectedPostId}
                        onSelectPost={this.onSelectRecentActivityPost}
                        posts={posts}
              />
            </div>
          </TabPane>
          <TabPane key="flagged" tab={`${dashboardData && dashboardData.forum.flagged} Flagged`}>
            <div className="hmp-forum-page-tab">
              <PostList height={listHeight}
                        onScroll={this.onFlaggedScroll}
                        scrollTop={flaggedPostsScrollTop}
                        selectedPostId={flaggedPostsSelectedPostId}
                        onSelectPost={this.onSelectFlaggedPost}
                        posts={flaggedPosts}
              />
            </div>
          </TabPane>
          <TabPane key="uncategorized" tab={`${dashboardData && dashboardData.forum.uncategorized} Uncategorized`}>
            <div className="hmp-forum-page-tab">
              <PostList height={listHeight}
                        scrollTop={uncategorizedPostsScrollTop}
                        onScroll={this.onUncategorizedScroll}
                        selectedPostId={uncategorizedPostsSelectedPostId}
                        onSelectPost={this.onSelectUncategorizedPost}
                        posts={uncategorizedPosts}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState): StateProps => {
  const study = selectors.getRequestedStudy(state);
  const posts = selectors.getLastSearchResultPosts(state);
  const uncategorizedPosts = selectors.getUncategorizedPosts(state);
  const flaggedPosts = selectors.getFlaggedPosts(state);
  const flags = selectors.getFlags(state);
  const comments = selectors.getComments(state);
  const thumbsups = selectors.getThumbsups(state);
  const favorites = selectors.getFavorites(state);
  const getPostsStatus = selectors.loadPostsStatus(state);
  const dashboardData = selectors.getDashboardData(state);
  const loadDashboardDataStatus = selectors.loadDashboardStatus(state);
  const forumsSubpage = selectors.getRequestedForumsTab(state);
  const recentActivityScrollTop = state.ui.forums.recentActivity.scrollTop;
  const flaggedPostsScrollTop = state.ui.forums.flaggedPosts.scrollTop;
  const uncategorizedPostsScrollTop = state.ui.forums.uncategorizedPosts.scrollTop;
  const recentActivitySelectedPostId = state.ui.forums.recentActivity.selectedPostId;
  const flaggedPostsSelectedPostId = state.ui.forums.flaggedPosts.selectedPostId;
  const uncategorizedPostsSelectedPostId = state.ui.forums.uncategorizedPosts.selectedPostId;
  const pseudoParticipants = selectors.getCurrentUserPseudoParticipants(state);
  const topics = selectors.getForumTopics(state);
  const arms = selectors.getRequestedStudyStudyArms(state);
  const studyId = selectors.getRequestedStudyId(state);
  const saveForumPostStatus = selectors.saveForumPostStatus(state);
  return {
    study,
    getPostsStatus,
    posts,
    flags,
    comments,
    uncategorizedPosts,
    thumbsups,
    favorites,
    dashboardData,
    loadDashboardDataStatus,
    flaggedPosts,
    forumsSubpage,
    recentActivityScrollTop,
    flaggedPostsScrollTop,
    uncategorizedPostsScrollTop,
    recentActivitySelectedPostId,
    flaggedPostsSelectedPostId,
    uncategorizedPostsSelectedPostId,
    pseudoParticipants,
    topics,
    arms,
    studyId,
    saveForumPostStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    savePost: (newPost: PostType) => dispatch(savePostAsync.request(newPost)),
    getPosts: (postsSearch: GetPostsSearchType) => dispatch(getPostsAsync.request(postsSearch)),
    getUncategorizedPosts: () => dispatch(getUncategorizedPostsAsync.request()),
    getFlaggedPosts: () => dispatch(getFlaggedPostsAsync.request()),
    loadDashboardData: () => dispatch(loadDashboardDataAsync.request()),
    updateRecentActivityScroll: (scrollTop:number) => dispatch(updateRecentActivityScroll(scrollTop)),
    updateFlaggedScroll: (scrollTop:number) => dispatch(updateFlaggedScroll(scrollTop)),
    updateUncategorizedScroll: (scrollTop:number) => dispatch(updateUncategorizedScroll(scrollTop)),
    updateRecentActivitySelectedPostId: (selectedPostId:number) => dispatch(updateRecentActivitySelectedPostId(selectedPostId)),
    updateFlaggedSelectedPostId: (selectedPostId:number) => dispatch(updateFlaggedSelectedPostId(selectedPostId)),
    updateUncategorizedSelectedPostId: (selectedPostId:number) => dispatch(updateUncategorizedSelectedPostId(selectedPostId)),
    resetRecentActivityPostIds: () => dispatch(resetRecentActivityPostIds()),
    clearStatus: (type: string) => dispatch(clearStatus(type))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForumPage);
