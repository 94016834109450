import {
  DatePicker, Checkbox, Radio, Button, Collapse, Cascader
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import * as _ from 'lodash';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import FormContextProvider from './FormContextProvider';
import { FormType, ParticipantType } from '../../types/entity.types';
import { saveFormAsync, updateFormAsync } from '../../redux/form/form.types';

import './editForm.scss';
import { RadioChangeEvent } from 'antd/lib/radio';

const { TextArea } = Input;
const { confirm } = Modal;
const { Panel } = Collapse;

interface StateProps {
  participants: ParticipantType[]
}

interface DispatchProps {
  saveForm: typeof saveFormAsync.request;
  editForm: typeof updateFormAsync.request;
}

interface ComponentProps extends DispatchProps, StateProps {
  closeHandler: () => {},
  visible: boolean,
  editable: boolean,
  data: FormType,
  editing: boolean
}

const textAreaRows = 4;
const footerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%'
};
const footerActionStyle = {
  textAlign: 'center',
  width: '50%'
};
const radioStyle = {
  display: 'block',
  height: 'auto',
  lineHeight: '30px'
};

function filter(inputValue: any, path: any): boolean {
  return path.some((option: any) => option.label ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 : false);
}

const requiredText = 'This response is required.';

const severityOptions = ['Mild', 'Moderate', 'Severe', 'Potentially life-threatening', 'Death'];
const reportedOptions = ['Yes', 'No'];
const relatedOptions = ['Possibly related or related', 'Not related'];
const criteriaOptions = ['Resulted in death', 'Is life threatening', 'Requires inpatient hospitalization or prolongation of existing hospitalization', 'Is a congenital anomaly/birth defect', 'Results in persistent or significant disability/incapacity', 'None apply'];
const statusOptions = ['Ongoing', 'Unable to resolve; no further action will be taken', 'Resolved'];

class AdverseEventForm extends Component<ComponentProps & FormComponentProps, {}> {

  readonly state = {
    participantId: -1,
    formType: 'ADVERSE_EVENT',
    Q3R: new Date(),
    Q4R: undefined,
    Q5R: new Date(),
    Q6R: undefined,
    Q7R: -1,
    Q8R: undefined,
    Q9R: undefined,
    Q10R: undefined,
    Q11R: undefined,
    Q12R: new Array<number>(0),
    Q13R: -1,
    Q13aR: undefined,
    Q14R: undefined,
    isResolved: false
  };
  componentDidMount() {
    const { editing, data } = this.props;
    if(editing && data.id) {
      this.setState({
        participantId: data.participantId,
        ...JSON.parse(data.formData)
      });
    }
  }
  questionMap = {
    Q1: 'Q1R', // adminId
    Q2: 'Q2R', // participantId
    Q3: 'Q3R', // date
    Q4: 'Q4R', // boolean
    Q5: 'Q5R', // date or null
    Q6: 'Q6R', // string
    Q7: 'Q7R', // number [0-4]
    Q8: 'Q8R', // string
    Q9: 'Q9R', // string
    Q10: 'Q10R', // boolean
    Q11: 'Q11R', // string
    Q12: 'Q12R', // number[] [0-5][] all that apply
    Q13: 'Q13R', // number [0-2]
    Q13a: 'Q13aR', // date or null (required if Q13 in [1,2])
    Q14: 'Q14R' // string (required if Q13 = 0)
  };

  formatDate = (d: Date | undefined) => {
    return moment(d).calendar();
  }

  onChange = (state: any) => {
    this.setState(state);
  };

  onStatusChange = (e: RadioChangeEvent) => {
    const isResolved = statusOptions[e.target.value] !== 'Ongoing';
    this.setState({
      Q13R: e.target.value,
      isResolved
    });
  }

  showCancel = () => {
    const { closeHandler, editable } = this.props;
    if (editable) {
      confirm({
        title: 'Are you sure you want to leave this form?',
        content: 'You will lose all changes.',
        okText: 'Leave',
        okType: 'danger',
        onOk() {
          closeHandler();
        },
        onCancel() {}
      });
    } else {
      closeHandler();
    }
  }

  handleSubmit = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.showSave();
      }
    });
  }

  showSave = () => {
    const { editable, editing } = this.props;
    if (editable || editing) {
      const handleSaveForm = this.handleSaveForm.bind(this);
      confirm({
        title: 'Are you sure you want to submit this form?',
        content: '',
        okText: 'Save',
        onOk() {
          handleSaveForm();
        },
        onCancel() {}
      });
    }
  }

  handleSaveForm = () => {
    const { saveForm, closeHandler, editable, editing, data, editForm } = this.props;
    const { participantId, formType, isResolved } = this.state;
    const form = {
      participantId,
      formType,
      isResolved,
      formData: _.omit(this.state, ['participantId', 'formType', 'visible', 'isResolved'])
    };
    if (editable || editing) {
      const { formData } = form;
      if (formData.Q12R && formData.Q12R.indexOf(5) !== -1) {
        formData.Q12R = [5];
      }
      if (formData.Q13aR && formData.Q13R !== 0) {
        formData.Q13aR = undefined;
      }
      if(editing && data.id) {
        form.id = data.id;
        editForm(form)
      } else {
        saveForm(form);
      }
    }
    closeHandler();
  }


  render() {
    const {
      form, visible, editable, data, editing, participants
    } = this.props;
    const { getFieldDecorator } = form;
    const { formType, Q12R, Q13R } = this.state;
    let formData;
    if (data) formData = JSON.parse(data.formData);
    const dateFormat = 'MM/DD/YYYY';
    const displayDate = this.formatDate(new Date());
    const type = formType.split('_').map((s: string) => s.charAt(0) + s.substring(1).toLowerCase()).join(' ');

    const options = _.map(participants, participant => _.pick(participant, ['username', 'id']))
      .map(option => { return { label: option.username, value: option.id.toString() }; });

    return (
      <Modal
        key={data?.id ? `form-${data.id}` : 'form-adverse'}
        title={editable ? `${type}` : `${type} created: ${this.formatDate(data.createDate)}`}
        visible={visible}
        onCancel={this.showCancel}
        width="45%"
        style={{ minWidth: '45vw' }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button type={editable || editing ? 'danger' : 'default'} onClick={e => { e.stopPropagation(); this.showCancel(); }}>Close</Button>,
          <Button type="default" disabled={!editable && !editing} onClick={e => { e.stopPropagation(); this.handleSubmit(); }}>Save</Button>
        ]}
        destroyOnClose
      >
        <FormContextProvider>
          <a href="https://irb.upenn.edu/reportable-event" target="_blank">UPenn IRB Guidelines for Reportable Events</a>
          <Form key="form" layout="vertical" colon={false}>
            <Form.Item label="Participant:">
              {getFieldDecorator('participantId', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : data.participantId
              })( <Cascader style={{marginBottom: '5px'}}
                            placeholder="Enter participant's username"
                            options={options}
                            onChange={(value) => this.setState({participantId: value[0]})}
                            showSearch={{ filter }}/>
              )}
            </Form.Item>
            <Form.Item label="Date Adverse Event (AE) reported to staff:">
              {getFieldDecorator('Q3', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : moment(formData[this.questionMap.Q3])
              })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q3R: dateString })} format={dateFormat} />)}
            </Form.Item>
            <Form.Item label="Has or will this AE been reported to IRB?">
              {getFieldDecorator('Q4', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : (formData[this.questionMap.Q4] ? 0 : 1)
              })(<Radio.Group disabled={!editable && !editing} onChange={(e) => this.onChange({ Q4R: e.target.value })}>
                {reportedOptions.map(opt => <Radio value={reportedOptions.indexOf(opt)}>{opt}</Radio>)}
              </Radio.Group>)}
            </Form.Item>
            <Form.Item label="AE onset date:">
              {getFieldDecorator('Q5', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : moment(formData[this.questionMap.Q5])
              })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q5R: dateString })} format={dateFormat} />)}
            </Form.Item>
            <Form.Item label="Who reported the AE to staff?">
              {getFieldDecorator('Q6', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q6]
              })(<Input disabled={!editable && !editing} onChange={e => this.onChange({ Q6R: e.target.value })} type="textarea" />)}
            </Form.Item>
            <Form.Item label="Severity:">
              {getFieldDecorator('Q7', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : formData[this.questionMap.Q7]
              })(<Radio.Group disabled={!editable && !editing} onChange={(e) => this.onChange({ Q7R: e.target.value })}>
                {severityOptions.map(opt => <Radio style={radioStyle} value={severityOptions.indexOf(opt)}>{opt}</Radio>)}
              </Radio.Group>)}
            </Form.Item>
            <Form.Item label="Description of the AE:">
              {getFieldDecorator('Q8', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q8]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q8R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
            <Form.Item label="Staff follow-up actions taken in response to the AE:">
              {getFieldDecorator('Q9', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q9]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q9R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
            <Form.Item label="Is this AE related to the study?">
              {getFieldDecorator('Q10', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : (formData[this.questionMap.Q10] ? 0 : 1)
              })(<Radio.Group disabled={!editable && !editing} onChange={(e) => this.onChange({ Q10R: e.target.value })}>
                {relatedOptions.map(opt => <Radio id={relatedOptions.indexOf(opt).toString()} value={relatedOptions.indexOf(opt)}>{opt}</Radio>)}
              </Radio.Group>)}
            </Form.Item>
            <Form.Item label="What is the rationale for your assessment of relatedness?">
              {getFieldDecorator('Q11', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q11]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q11R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
            <Form.Item label="Do any of these criteria apply to this AE?">
              {getFieldDecorator('Q12', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? Q12R : formData[this.questionMap.Q12]
              })(<CheckboxGroup
                disabled={!editable && !editing}
                onChange={(vals: any[]) => this.onChange({ Q12R: vals })}
              >
                <ul style={{ listStyle: 'none', paddingLeft: 0 }}>{criteriaOptions.map(opt => <li style={radioStyle}><Checkbox style={{ display: 'block', marginRight: '0px' }} value={criteriaOptions.indexOf(opt)}>{opt}</Checkbox></li>)}</ul>
              </CheckboxGroup>)}
            </Form.Item>
            <Collapse bordered={false} activeKey={editable ? (this.state.Q13R !== 0 && this.state.Q13R !== -1 ? '0' : '-1') : (formData[this.questionMap.Q13] !== 0 ? '0' : '-1')}>
              <Panel
                id="collapse-header"
                showArrow={false}
                key="0"
                header={(
                  <Form.Item label="Based on your understanding of the AE, what is its current status?">
                    {getFieldDecorator('Q13', {
                      rules: [{ required: true, message: requiredText }],
                      initialValue: editable ? undefined : formData[this.questionMap.Q13]
                    })(<Radio.Group disabled={!editable && !editing} onChange={this.onStatusChange}>
                      {statusOptions.map(opt => <Radio value={statusOptions.indexOf(opt)}>{opt}</Radio>)}
                    </Radio.Group>)}
                  </Form.Item>
)}
              >
                <Form.Item label="Closure or resolution date:">
                  {getFieldDecorator('Q13a', {
                    rules: [{ required: !editable || Q13R !== 0, message: requiredText }],
                    initialValue: editable ? undefined : moment(formData[this.questionMap.Q13a])
                  })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q13aR: dateString })} format={dateFormat} />)}
                </Form.Item>
              </Panel>
            </Collapse>
            <Form.Item label="Additional comments or context:">
              {getFieldDecorator('Q14', {
                initialValue: editable ? '' : formData[this.questionMap.Q14]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q14R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
          </Form>
        </FormContextProvider>
      </Modal>
    );
  }
}


const mapStateToProps =  createStructuredSelector<IApplicationState, StateProps>({
  participants: selectors.getRequestedStudyParticipantsWithUsernames
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    saveForm: (form: FormType) => dispatch(saveFormAsync.request(form)),
    editForm: (form: FormType) => dispatch(updateFormAsync.request(form))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'form' })(AdverseEventForm));
