import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  BadgesActionTypes, getBadgesAsync
} from './badges.types';
import { BadgeType, ParticipantBadgeType } from '../../types/entity.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {
  awardParticipantBadgeAsync,
  getParticipantBadgesAsync,
  ParticipantBadgeActionTypes
} from './participantBadges.types';

const updateParticipantBadgesReducer = produce((draft, badges) => {
  if (!badges) {
    return;
  }
  // fully resetting each time, so this will only hold the badges for the most recently selected participant
  draft.byId = [];
  draft.allIds = map(keys(badges), Number);
  const ids = Object.keys(badges);
  for (const id of ids) {
    draft.byId[id] = badges[id];
  }
});

export const participantBadgesReducer = (state: NormalizedState<ParticipantBadgeType> = initialNormalizedState, action: ParticipantBadgeActionTypes) => {

  switch (action.type) {
    case getType(getParticipantBadgesAsync.success): return updateParticipantBadgesReducer(state, action.payload);
    case getType(awardParticipantBadgeAsync.success): return updateParticipantBadgesReducer(state, action.payload);
    default: return state;
  }
};

export default participantBadgesReducer;
