import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { NormalizerResult, FormType } from '../../types/entity.types';
import axios from '../api';
import { entitySchema } from '../schema';
import { 
  getFormsAsync,
  saveFormAsync,
  getParticipantFormsAsync,
  updateFormAsync
} from './form.types';

const getForms = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/form/study/${studyId}`
  });
};

function* getFormsHandler(action: ReturnType<typeof getFormsAsync.request>): Generator {
  try {
    const studyId: number = action.payload;
    const response: AxiosResponse = (yield call(getForms, studyId)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.forms) as NormalizerResult;
    const { forms } = entities;

    yield put(getFormsAsync.success(forms));
  } catch (error) {
    yield put(getFormsAsync.failure(error));
  }
}

function* getFormsWatcher() {
  yield takeLatest(getType(getFormsAsync.request), getFormsHandler);
}

const getParticipantForms = (participantId: number) => {
  return axios({
    method: 'get',
    url: `/a/form/participant/${participantId}`
  });
};

function* getParticipantFormsHandler(action: ReturnType<typeof getFormsAsync.request>): Generator {
  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = (yield call(getParticipantForms, participantId)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.forms) as NormalizerResult;
    const { forms } = entities;

    yield put(getParticipantFormsAsync.success(forms));
  } catch (error) {
    yield put(getParticipantFormsAsync.failure(error));
  }
}

function* getParticipantFormsWatcher() {
  yield takeLatest(getType(getParticipantFormsAsync.request), getParticipantFormsHandler);
}

const saveForm = (form: FormType) => {
  return axios({
    method: 'put',
    url: `/a/form/${form.formType}?participantId=${form.participantId}`,
    data: {
      isResolved: form.isResolved,
      ...form.formData
    }
  });
};

function* saveFormHandler(action: ReturnType<typeof saveFormAsync.request>): Generator {
  try {
    const form: FormType = action.payload;
    const response: AxiosResponse = (yield call(saveForm, form)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.forms) as NormalizerResult;
    const { forms } = entities;

    yield put(saveFormAsync.success(forms));
  } catch (error) {
    yield put(saveFormAsync.failure(error));
  }
}

function* saveFormWatcher() {
  yield takeLatest(getType(saveFormAsync.request), saveFormHandler);
}

const editForm = (form: FormType) => {
  return axios({
    method: 'put',
    url: `/a/form/${form.formType}/${form.id}/?participantId=${form.participantId}`,
    data: {
      isResolved: form.isResolved,
      ...form.formData
    }
  });
};

function* editFormHandler(action: ReturnType<typeof updateFormAsync.request>): Generator {
  try {
    const form: FormType = action.payload;
    const response: AxiosResponse = (yield call(editForm, form)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.forms) as NormalizerResult;
    const { forms } = entities;

    yield put(updateFormAsync.success(forms));
  } catch (error) {
    yield put(updateFormAsync.failure(error));
  }
}

function* editFormWatcher() {
  yield takeLatest(getType(updateFormAsync.request), editFormHandler);
}

export default function* formsSaga() {
  yield all([
    fork(getFormsWatcher),
    fork(getParticipantFormsWatcher),
    fork(saveFormWatcher),
    fork(editFormWatcher)
  ]);
}
