import React from 'react';
import AntdIcon from '../../antdIcon/AntdIcon';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import './fieldErrorMessage.scss'

interface ComponentProps {
  isVisible: boolean,
  message: string
}

const FieldErrorMessage = (props: ComponentProps) => {

  const { isVisible, message } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div className="hmp-field-error-message">
      <AntdIcon fontAwesomeIcon={faExclamationCircle} /> {message}
    </div>
  )
}

export default FieldErrorMessage;