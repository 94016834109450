import { Dropdown, Menu, Popover } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  archiveThreadAsync,
  assignThreadAsync,
  markThreadImportantAsync,
  markThreadUnreadAsync,
  unarchiveThreadAsync,
  unassignThreadAsync,
  unmarkThreadImportantAsync
} from '../../redux/messages/messages.types';
import { MessageThreadType } from '../../types/entity.types';
import { renderDateWithTime } from '../util/Util';
import './thread.scss';
import ThreadMembers from './ThreadMembers';
import ThreadOwnerBubbles from './ThreadOwnerBubbles';

interface StateProps {
  thread: MessageThreadType;
  setActiveThread: (threadId: number) => void;
  activeThreadId: number;
}

interface DispatchProps {
  archiveThread: typeof archiveThreadAsync.request;
  unarchiveThread: typeof unarchiveThreadAsync.request;
  markImportant: typeof markThreadImportantAsync.request;
  unmarkImportant: typeof unmarkThreadImportantAsync.request;
  assignThread: typeof assignThreadAsync.request;
  unassignThread: typeof unassignThreadAsync.request;
  markUnread: typeof markThreadUnreadAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {}

class ThreadListItem extends Component<ComponentProps, {}> {
  readonly state = {
    showMembers: false
  };

  renderTitle = (title: string) => {
    return title.length > 27 ? title.substring(0, 27) + '...' : title;
  };

  renderLastMessageDate = (date: Date | string) => {
    const fifteenMinutesAgo = moment().subtract(15, 'minutes');
    return <Popover content={renderDateWithTime(date)}>
      {moment(date).calendar(undefined, {
        sameDay: (now) => {
          if(moment(date).isAfter(fifteenMinutesAgo)) {
            if(moment(date).isSame(moment(), 'minute')) {
              return '[Now]';
            } else if (moment().diff(date, 'seconds') > 60) {
              return `[${moment().diff(date, 'minutes').toString() + ' min'}]`
            }
            return `[< 1 min]`
          }
          return 'h:mm A';
        },
        lastDay: 'ddd',
        lastWeek: '[Last] ddd',
        sameElse: 'MMM Do'
      })}
    </Popover>
  }

  handleClick = (action: string) => {
    const { archiveThread, unarchiveThread, thread, markImportant, unmarkImportant, markUnread } = this.props;
    switch(action) {
      case 'archive':
        archiveThread(thread.id);
      break;
      case 'unarchive':
        unarchiveThread(thread.id);
      break;
      case 'important':
        markImportant(thread.id);
      break;
      case 'unimportant':
        unmarkImportant(thread.id);
      break;
      case 'unread':
        markUnread(thread.id);
      break;
      case 'members':
        this.showMembers();
      break;
    }
  };

  handleAssignThread = async (assignUserIds: number[], unassignUserIds: number[]): Promise<void> => {
    const { assignThread, unassignThread, thread } = this.props;
    const threadId = thread.id;
    await Promise.all(assignUserIds.map(id => assignThread({ threadId, userId: id })))
    await Promise.all(unassignUserIds.map(id => unassignThread({ threadId, userId: id })))
    return;
  };

  showMembers = () => {
    this.setState({
      showMembers: true
    });
  };

  closeMembers = () => {
    this.setState({
      showMembers: false
    });
  };

  render() {
    const { thread, setActiveThread, activeThreadId } = this.props;
    const { showMembers } = this.state;
    const { participant } = thread;

    const menuOptions = (
      <Menu>
        <Menu.Item key="4" onClick={(e) => {this.handleClick('members')}}>
          <a>View Members</a>
        </Menu.Item>
        {!thread.isImportant ?
          <Menu.Item key="2" onClick={(e) => this.handleClick('important')}>
            <a>Mark as Important</a>
          </Menu.Item> :
          <Menu.Item key="3" onClick={(e) => this.handleClick('unimportant')}>
            <a>Mark as Not-Important</a>
          </Menu.Item>
        }
        {!thread.markedUnread &&
          <Menu.Item key="5" onClick={(e) => this.handleClick('unread')}>
            <a>Mark as Unread</a>
          </Menu.Item>
        }
        {!thread.isArchived ?
          <Menu.Item key="0" onClick={(e) => this.handleClick('archive')}>
            <a>Archive</a>
          </Menu.Item> :
          <Menu.Item key="1" onClick={(e) => this.handleClick('unarchive')}>
            <a>Mark as Active</a>
          </Menu.Item>
        }
      </Menu>
    );
    const unread = thread.unreadMessageCount > 0 || thread.markedUnread;
    const activeClass = 'thread-list-item ' + (activeThreadId === thread.id  && 'thread-list-item-selected ');

    return (
      <div className={activeClass} onClick={(e) => { e.preventDefault(); setActiveThread(thread.id)}}>
        {showMembers && <ThreadMembers members={thread.members} handleAssign={this.handleAssignThread} close={this.closeMembers} visible={showMembers}/>}
        <div className='thread-avatar-container'>
          <i style={{visibility: thread.isImportant ? "visible" : "hidden"}} className="important fas fa-exclamation-circle fa-lg"></i>
          <img className='thread-avatar' alt='thread' src={`data:image/jpeg;base64, ${participant.avatar? participant.avatar.avatar : ''}`} style={{ backgroundColor: participant.avatarBackground ? `#${participant.avatarBackground}` : '#FFB100' }} />
        </div>
        <div className='thread-item-content'>
          <span className={['thread-from', `${unread ? 'unread' : ''}`].join(' ')}>{thread.participant.username}</span>
          <span className={['thread-title', `${unread ? 'unread' : ''}`].join(' ')}>{this.renderTitle(thread.title)}</span>
          <span className={['thread-preview', `${unread ? 'unread' : ''}`].join(' ')}>{thread.preview}</span>
          <ThreadOwnerBubbles members={thread.members} />
        </div>
        <div className='thread-item-info'>
          <span className={['thread-date', `${unread ? 'unread' : ''}`].join(' ')}>{this.renderLastMessageDate(thread.lastMessageDate)}</span>
          <div className='thread-item-options' onClick={(e) => e.stopPropagation()}>
            <Dropdown overlay={ menuOptions } trigger={['click']} placement='bottomRight'>
              <div className='thread-item-option-hitbox'><a><i className="far fa-ellipsis-v fa-lg"></i></a></div>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    archiveThread: (threadId: number) => dispatch(archiveThreadAsync.request(threadId)),
    unarchiveThread: (threadId: number) => dispatch(unarchiveThreadAsync.request(threadId)),
    markImportant: (threadId: number) => dispatch(markThreadImportantAsync.request(threadId)),
    unmarkImportant: (threadId: number) => dispatch(unmarkThreadImportantAsync.request(threadId)),
    assignThread: ({ threadId, userId }) => dispatch(assignThreadAsync.request({ threadId, userId })),
    unassignThread: ({ threadId, userId }) => dispatch(unassignThreadAsync.request({ threadId, userId })),
    markUnread: (threadId: number) => dispatch(markThreadUnreadAsync.request(threadId))
  };
};


export default connect(null, mapDispatchToProps)(ThreadListItem);