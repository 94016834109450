import { Alert } from 'antd';
import Layout from 'antd/lib/layout';
import Spin from 'antd/lib/spin';
import Typography from "antd/lib/typography";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import Header from '../components/header/Header';
import StudySideBar from '../components/sidebar/StudySideBar';
import StudyNotFound from '../pages/404/StudyNotFound';
import AnalyticsReport from '../pages/reports/AnalyticsReport';
import IncentivesReport from '../pages/reports/IncentivesReport';
import ActivitiesLandingPage from '../pages/study/activity/ActivitiesLandingPage';
import AdHocQueryLandingPage from '../pages/study/adHocQuery/AdHocQueryLandingPage';
import ArmsPage from '../pages/study/arm/ArmsPage';
import DashboardLandingPage from '../pages/study/dashboard/DashboardLandingPage';
import Form from '../pages/study/form/Form';
import ForumPage from '../components/forum/ForumPage';
import Study from '../pages/study/home/Study';
import PaymentsLandingPage from '../pages/study/payment/PaymentsLandingPage';
import Participant from '../pages/study/participant/detail/Participant';
import ParticipantSearch from '../pages/study/participant/search/ParticipantSearch';
import ResourceEditPage from '../pages/study/resource/ResourceEditPage';
import ResourcesLandingPage from '../pages/study/resource/ResourcesLandingPage';
import SettingsLandingPage from '../pages/study/settings/SettingsLandingPage';
import SurveysLandingPage from '../pages/study/survey/SurveysLandingPage';
import TestKitsLandingPage from '../pages/study/testkit/TestKitsLandingPage';
import { loadForumTopicsAsync } from '../redux/topics/topic.types';
import * as selectors from '../redux/selectors';
import { loadStudyAsync } from '../redux/study/study.types';
import { IApiRequestState } from '../types/api.types';
import { StudyType, TopicType } from '../types/entity.types';
import IApplicationState from '../types/state.types';
import Badges from "../components/badge/Badges";
import Avatars from "../components/avatar/Avatars";
import Inbox from '../pages/study/inbox/Inbox';
import AnnouncementLandingPage from '../pages/study/announcement/AnnouncementLandingPage';
import QnaLandingPage from '../pages/study/qna/QnaLandingPage';
import UserCreationPage from '../pages/study/user/UserCreationPage';
import TestkitCompletedReport from "../components/testkit/TestkitCompletedReport";

const {
  Content,
  Footer
} = Layout;

const {
  Title
} = Typography;

interface StateProps {
  requestedStudyId: number,
  study: Optional<StudyType>,
  status: IApiRequestState,
  resourceTopics: Optional<TopicType[]>,
  isNonYAB: boolean,
  hasDataAnalystRole: boolean
}

interface DispatchProps {
  loadStudy: typeof loadStudyAsync.request,
  loadForumTopics: typeof loadForumTopicsAsync.request
}

interface ComponentProps extends StateProps, DispatchProps {}

class StudyRouter extends Component<ComponentProps, {}> {

  componentDidMount(): void {

    const { loadStudy, loadForumTopics, requestedStudyId } = this.props;
    loadStudy(requestedStudyId);
    loadForumTopics(1);
    loadForumTopics(3);
  }

  render() {
    const { requestedStudyId, study, status, isNonYAB, hasDataAnalystRole } = this.props;

    // TODO componentize the Spinner and Error Message

    if (status.isLoading) {
      return (
        <Layout id="main">
          <Header />
          <Content id="content">
            <Spin size="large" />
          </Content>
        </Layout>
      );
    }

    if (status.isError) {
      return (
        <Layout id="main">
          <Header />
          <Content id="content">
            Error:
            {' '}
            {status.errorMessage}
          </Content>
        </Layout>
      );
    }

    if (requestedStudyId === -1) {
      return (
        <Layout id="main">
          <Header />
          <Content id="content">
            An invalid or unknown Study ID was specified in the URL.
          </Content>
        </Layout>
      );
    }

    if (!study) {
      return (
        <Layout id="main">
          <Header />
          <Content id="content">
            The Study could not be loaded.
          </Content>
        </Layout>
      );
    }

    const fullPrivilegeRoutes:JSX.Element[] = [];
    const dataAnalystRoutes:JSX.Element[] = [];

    if (isNonYAB) {
      fullPrivilegeRoutes.push( <Route key="dashboard" exact path="/study/:studyId" component={DashboardLandingPage} /> );
      fullPrivilegeRoutes.push( <Route key="study" exact path="/study/:studyId/study" component={Study} /> );
      fullPrivilegeRoutes.push( <Route key="activities" exact path="/study/:studyId/activities" component={ActivitiesLandingPage} /> );
      fullPrivilegeRoutes.push( <Route key="announcements" exact path="/study/:studyId/announcements" component={AnnouncementLandingPage} /> );
      fullPrivilegeRoutes.push( <Route key="arms" exact path="/study/:studyId/arms" component={ArmsPage} /> );
      fullPrivilegeRoutes.push( <Route key="avatars" exact path="/study/:studyId/avatars" component={Avatars} /> );
      fullPrivilegeRoutes.push( <Route key="badges" exact path="/study/:studyId/badges" component={Badges} /> );
      fullPrivilegeRoutes.push( <Route key="forms" exact path="/study/:studyId/form" component={Form}/> );
      fullPrivilegeRoutes.push( <Route key="inbox" exact path="/study/:studyId/inbox" component={Inbox}/> );
      fullPrivilegeRoutes.push( <Route key="participants" exact path="/study/:studyId/participants" component={ParticipantSearch}/> );
      fullPrivilegeRoutes.push( <Route key="particiant" path="/study/:studyId/participants/:participantId" component={Participant}/> );
      fullPrivilegeRoutes.push( <Route key="payments" exact path="/study/:studyId/payments" component={PaymentsLandingPage}/> );
      fullPrivilegeRoutes.push( <Route key="qna" exact path="/study/:studyId/qna" component={QnaLandingPage}/> );
      fullPrivilegeRoutes.push( <Route key="resources" exact path="/study/:studyId/resources" component={ResourcesLandingPage}/> );
      fullPrivilegeRoutes.push( <Route key="newResource" exact path="/study/:studyId/resources/new" component={ResourceEditPage}/> );
      fullPrivilegeRoutes.push( <Route key="editResource" exact path="/study/:studyId/resources/edit/:articleId" component={ResourceEditPage}/> );
      fullPrivilegeRoutes.push( <Route key="surveys" exact path="/study/:studyId/surveys" component={SurveysLandingPage}/> );
      fullPrivilegeRoutes.push( <Route key="testkits" path="/study/:studyId/testkits" component={TestKitsLandingPage}/> );
      fullPrivilegeRoutes.push( <Route key="user creation" exact path="/study/:studyId/user" component={UserCreationPage} /> );
    }

    if (hasDataAnalystRole) {
      dataAnalystRoutes.push( <Route key="incentives_report" exact path="/study/:studyId/reports/incentives" component={IncentivesReport}/> );
      dataAnalystRoutes.push( <Route key="analytics_report" exact path="/study/:studyId/reports/analytics" component={AnalyticsReport}/> ); //todo this report is broken, removed from StudySideBar currently
      dataAnalystRoutes.push( <Route key="ad hoc query" exact path="/study/:studyId/adHocQuery" component={AdHocQueryLandingPage} /> );
      dataAnalystRoutes.push( <Route key="testkit report" exact path="/study/:studyId/testkitReport" component={TestkitCompletedReport} /> );
    }

    fullPrivilegeRoutes.push( <Route key="settings" exact path="/study/:studyId/settings" component={SettingsLandingPage}/> );

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <StudySideBar />
        <Layout id="main">
          <Header>
            <span>{study?.name}</span>
          </Header>

          <Content id="content" style={{ height: '100vh - 70px'}}>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <div style={{ flex: '1 1 auto', height: '100%'}}>
                <Switch>
                  {...fullPrivilegeRoutes}
                  {...dataAnalystRoutes}
                  <Route key="forums"      path="/study/:studyId/forums" component={ForumPage} />
                  <Route key="notFound" component={StudyNotFound} />
                </Switch>
              </div>
            </div>
          </Content>
          {/*<Footer>Footer</Footer>*/}
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  requestedStudyId: selectors.getRequestedStudyId,
  study: selectors.getRequestedStudy,
  status: selectors.loadStudyStatus,
  resourceTopics: selectors.getResourceTopics,
  isNonYAB: selectors.isNonYAB,
  hasDataAnalystRole: selectors.hasDataAnalystRole
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadStudy: (studyId: number) => dispatch(loadStudyAsync.request(studyId)),
    loadForumTopics: (forumId: number) => dispatch(loadForumTopicsAsync.request(forumId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyRouter);
