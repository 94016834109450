import { getType } from 'typesafe-actions';
import { DashboardDataType } from '../../types/entity.types';
import { DashboardActionTypes, loadDashboardDataAsync } from './dashboard.types';

const initialDashboardData: DashboardDataType = {
  testkits: {
    requested: -1, needsReview: -1, seroconversions: -1
  },
  payments: { unpaid: -1 },
  forum: { uncategorized: -1, flagged: -1 },
  inbox: {  unreadThreads: -1, unreadMemberThreads: -1, unreadImportantThreads: -1, unreadExpertThreads: -1},
  qnas: { unansweredCount: -1 },
  forms: { openFormsCount: -1 }
};

export const dashboardReducer = (state: DashboardDataType  = initialDashboardData, action: DashboardActionTypes) => {

  switch (action.type) {
    case getType(loadDashboardDataAsync.success): return action.payload;
    default: return state;
  }
};

export default dashboardReducer;
