import React, { useRef, ChangeEvent, RefObject } from 'react';
import Button from 'antd/lib/button';
import Cascader, { CascaderOptionType } from 'antd/lib/cascader';
import PageHeader from 'antd/lib/page-header';
import Input from 'antd/lib/input';

import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/char_counter.min';
import 'froala-editor/js/plugins/code_beautifier.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/inline_class.min';
import 'froala-editor/js/plugins/inline_style.min';
import 'froala-editor/js/plugins/line_height.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/paragraph_style.min';
import 'froala-editor/js/plugins/print.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/save.min';
import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/url.min';
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/char_counter.min.css';
import 'froala-editor/css/plugins/code_view.min.css';
import 'froala-editor/css/plugins/colors.min.css';
import 'froala-editor/css/plugins/draggable.min.css';
import 'froala-editor/css/plugins/emoticons.min.css';
import 'froala-editor/css/plugins/fullscreen.min.css';
import 'froala-editor/css/plugins/help.min.css';
import 'froala-editor/css/plugins/image.min.css';
import 'froala-editor/css/plugins/special_characters.min.css';
import 'froala-editor/css/plugins/table.min.css';
import 'froala-editor/css/plugins/video.min.css';
import FroalaEditor from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';

import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { connect } from 'react-redux';
import { UserState } from 'redux-oidc';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../redux/selectors';
import { ArticleType } from '../../../types/entity.types';
import './resourceEditor.scss';
import IApplicationState from '../../../types/state.types';
import ActivityTooltip from '../../activity/ActivityTooltip';
import CoverImageEditor from '../../image/cover/CoverImageEditor';
import FieldErrorMessage from './FieldErrorMessage';
import moment, { Moment } from 'moment';
import { DatePicker, Modal } from 'antd';
import { updateCmsEditorBeforeSave } from '../../util/Util';

const { confirm } = Modal;
const { TextArea } = Input;

const countCharacters = (value: string) => {
  if (!value) {
    return 0;
  }
  return value.length;
};

interface StateProps {
  oidc: UserState
}

interface ComponentProps extends StateProps {
  resourceTopics: CascaderOptionType[],
  originalArticle?: Optional<ArticleType>,
  saveArticle: Function,
}

interface ComponentState extends ArticleType {
  imageChoices: string[],
  isDirty: boolean,
  selectedTopicId: string,
  selectedSubTopicId: string,
  showTitleError: boolean,
  showSummaryError: boolean,
  showTopicError: boolean,
  showContentError: boolean,
}

const parseForImageUrls = (body: string = '') => {
  let imageUrls = (body.match(/src=("\/api\/u\/upload\/view\/.*?)"/g) || []).map( (imageUrl: string) => {
    return imageUrl.substring(5, imageUrl.length-1);
  });

  const youtubeRegex = /src=("https:\/\/www\.(youtube\.com\/embed\/)(.*?))"/g;
  let m;
  while ((m = youtubeRegex.exec(body)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === youtubeRegex.lastIndex) {
        youtubeRegex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
        // console.log(`Found match, group ${groupIndex}: ${match}`);
        if(groupIndex === 3)
        {
          const path = "https://img.youtube.com/vi/" + match + "/0.jpg";
          imageUrls.push(path)
        }
    });
  }
  return imageUrls;
};

const mergeImageOptions = (imageUrl: string, images: string[] = []) => {

  const duplicates = images.concat(imageUrl);
  return duplicates.filter((url, index) => {
    return url && url.trim() != '' ? duplicates.indexOf(url) === index : false;
  });
};

const isBlank = (value: string) => {
  return (!value || 0 === value.trim().length);
}

class ResourceEditor extends React.Component<ComponentProps,ComponentState> {

  private froalaRef: RefObject<any>;

  componentDidMount() {
    const editor = this.froalaRef.current;

    Froalaeditor.DefineIcon('armTag', { NAME: 'eye-slash' });
    Froalaeditor.RegisterCommand('armTag', {
      title: 'Limit Content to Arms 2 and 3',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        const cur = editor.getEditor();
        const insertion = cur.selection.text() ? `<arms ids="2,3">${cur.selection.text()}</arms>` : '<arms ids="2,3"><p>Insert Content Here</p></arms> '
        cur.html.insert(insertion);
      },
    });

  }

  constructor(props: ComponentProps) {
    super(props);
    this.froalaRef = React.createRef();
    if (props.originalArticle) {
      this.state = {
        imageChoices: mergeImageOptions(props.originalArticle.cover, parseForImageUrls(props.originalArticle.body)),
        isDirty: false,
        selectedTopicId: this.findSelectedTopicId(props.originalArticle.topicId),
        selectedSubTopicId: `${props.originalArticle.topicId}`,
        showTitleError: false,
        showSummaryError: false,
        showTopicError: false,
        showContentError: false,
        ...props.originalArticle
      }
    }
    else {
      // If there is no original article, then this is a new article, so
      // the article must be initialized properly.
      this.state = {
        imageChoices: [],
        isDirty: false,
        selectedTopicId: '',
        selectedSubTopicId: '',
        showTitleError: false,
        showSummaryError: false,
        showTopicError: false,
        showContentError: false,
        // article properties, flattened out, because React does shallow state comparisons
        id: undefined,
        body: '',
        cover: '',
        deleteDate: null,
        previewUrl: '',
        summary: '',
        title: '',
        topicId: -1,
        videoUrl: '',
        publishDate: null
      }
    }
  }

  // The topicId assigned to an article is actually a Subtopic ID. This is a
  // helper function to find the matching Topic ID for the Subtopic ID supplied.
  findSelectedTopicId = (subTopicId: number): string => {
    if (this.props.resourceTopics) {
      for (const topic of this.props.resourceTopics) {
        if (topic.children) {
          for (const subTopic of topic.children) {
            if (subTopic.value === `${subTopicId}`) {
              return topic.value ? topic.value : '';
            }
          }
        }
      }
    }
    return '';
  };

  // TODO: Modify the code in the handleSaveClick to pass the article back out to the
  //  ResourceEditPage. Then add Redux event/saga/etc to save the updated Article.
  //  Dispatching of all actions should be handled by the ResourceEditPage.

  handleSaveClick = () => {

    const { showTitleError, showSummaryError, showTopicError, showContentError } = this.validateArticle();
    if (showTitleError || showSummaryError || showTopicError || showContentError) {
      this.setState({
        showTitleError,
        showSummaryError,
        showTopicError,
        showContentError
      });
      return;
    }

    const article = {
      id: this.state.id != -1 ? this.state.id : -1,
      title: this.state.title,
      summary: this.state.summary,
      topicId: this.state.selectedSubTopicId,
      cover: this.state.cover ? this.state.cover.trim() : '',
      body: updateCmsEditorBeforeSave(this.state.body),
      publishDate: this.state.publishDate,
      createdByUserId: 1001
    };

    this.props.saveArticle(article);
    // TODO: this notification would need to be triggered elsewhere.  But for now I'm doing it here.
    alert('Article Saved');

  };

  validateArticle = () => {
    return {
      showTitleError: isBlank(this.state.title),
      showSummaryError: isBlank(this.state.summary),
      showTopicError: isBlank(this.state.selectedSubTopicId),
      showContentError: isBlank(this.state.body),
    }
  }

  handleTopicChange = (values: string[]) => {
    const selectedTopicId = values[0];
    const selectedSubTopicId = values[1];
    this.setState({ selectedTopicId, selectedSubTopicId, showTopicError: isBlank(selectedSubTopicId) });
  };

  handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ title: value, showTitleError: isBlank(value) });
  };

  handleSummaryChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    this.setState({ summary: value, showSummaryError: isBlank(value) });
  };

  handleCoverImageChange = (imageUrl: string) => {
    this.setState({cover: imageUrl, imageChoices: mergeImageOptions(imageUrl, this.state.imageChoices)});
  };

  handleBodyChange = (body: string) => {
    const images = parseForImageUrls(body) || [];
    this.setState({ body, imageChoices: mergeImageOptions(this.state.cover, images.concat(this.state.imageChoices)), showContentError: isBlank(body) });
  };

  handlePublishDateChange = (date: Moment | null, datestring: string) => {
    this.setState({ publishDate: datestring ? moment(datestring).toDate() : null });
  };

  handleBackClick = () => {
    confirm({
      title: `Are you sure you want to leave this resource?`,
      content: '',
      okText: 'Leave',
      okType: 'danger',
      onOk() {
        window.history.back();
      },
      onCancel() {}
    });
  }

  render() {

    const { oidc } = this.props;

    const config = {
      charCounterCount: true,
      fontSizeSelection: true,
      fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
      iconsTemplate: 'font_awesome_5r',
      imageDefaultAlign: 'left',
      imageMaxSize: 16 * 1024 * 1024, // 16MB
      imageUploadParam: 'file',
      imageUploadMethod: 'POST',
      imageUploadURL: '/api/a/cms/uploads/image',
      placeholderText: 'Edit Your Content Here!',
      htmlAllowedAttrs: ['ids', 'accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap'],
      htmlAllowedTags: ['arms', 'a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source', 'span', 'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr'],
      htmlDoNotWrapTags: ['arms'],
      pluginsEnabled: [
        'align',
        'charCounter',
        'codeBeautifier',
        'codeView',
        'colors',
        'draggable',
        'emoticons',
        'entities',
        'fontSize',
        'fullscreen',
        'help',
        'image',
        'inlineClass',
        'inlineStyle',
        'lineHeight',
        'link',
        'lists',
        'paragraphFormat',
        'paragraphStyle',
        'print',
        'quote',
        'save',
        'specialCharacters',
        'table',
        'url',
        'video',
        'wordPaste'
      ],
      // TODO Figure out why this works.  The backend seems to only be validating that the token can be decoded; not that it has expired or anything like that.
      requestHeaders: {
        "Authorization": `Bearer ${oidc.user?.access_token}`
      },
      toolbarButtons: {
        moreText: {
          buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
          buttonsVisible: 3
        },
        moreParagraph: {
          buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'outdent', 'indent', 'formatOL', 'formatUL', 'lineHeight', 'paragraphFormat', 'paragraphStyle', 'quote'],
          buttonsVisible: 6
        },
        moreRich: {
          buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'specialCharacters', 'insertHR', 'armTag'],
          buttonsVisible: 4
        },
        moreMisc: {
          buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'selectAll', 'html', 'help', 'save'],
          align: 'right',
          buttonsVisible: 2
        }
      },
      linkAutoPrefix: ''
    };


    const { resourceTopics } = this.props;
    const { showTitleError, showSummaryError, showTopicError, showContentError } = this.state;
    const { selectedTopicId, selectedSubTopicId, title, summary, body, cover, imageChoices, publishDate } = this.state;

    return (
      <div className="hmp-resource-editor">
        <PageHeader title="Return to Resources" subTitle="" onBack={this.handleBackClick} extra={[
          <Button key="save" type="primary" onClick={this.handleSaveClick}>Save</Button>,
        ]}/>
        <div className="hmp-resource-editor-title">
          <label>Title</label>
          <Input className={showTitleError ? "error" : ""} maxLength={300} onChange={this.handleTitleChange} value={title}/>
          <label className="counter">Characters: {countCharacters(title)}/300</label>
          <FieldErrorMessage isVisible={showTitleError} message="You must enter a title for the article." />
        </div>
        <div className="hmp-resource-editor-summary">
          <label>Summary</label>
          <TextArea className={showSummaryError ? "error" : ""} rows={3} value={summary} maxLength={1000} onChange={this.handleSummaryChange} />
          <label className="counter">Characters: {countCharacters(summary)}/1000</label>
          <FieldErrorMessage isVisible={showSummaryError} message="You must enter a summary for the article." />
        </div>
        <div className="hmp-resource-editor-topic">
          <label>Topic</label>
          <Cascader className={`hmp-resource-editor-topic-selector ${showTopicError ? "error" : ""}`}
                    options={resourceTopics}
                    defaultValue={[`${selectedTopicId}`, `${selectedSubTopicId}`]}
                    popupClassName="hmp-resource-editor-topic-selector-popup-menu"
                    changeOnSelect
                    onChange={this.handleTopicChange}
          />
          <FieldErrorMessage isVisible={showTopicError} message="You must select a valid Topic -> Subtopic" />
        </div>
        <div className="hmp-resource-editor-publish-date">
          <label>Publish Date <ActivityTooltip text="This is when this article will be available to the participants.  The exact moment it will be published is the first second of the day Eastern Standard Time" /></label>
          <DatePicker value={publishDate ? moment(publishDate) : undefined} onChange={this.handlePublishDateChange}/>
        </div>
        <div className="hmp-resource-editor-cover">
          <label>Cover</label>
          <CoverImageEditor currentCoverImageUrl={cover} coverImageUrlChoices={imageChoices} coverImageChangeHandler={this.handleCoverImageChange} />
        </div>
        <div className="hmp-resource-editor-body">
          <label>Content</label>
          <FieldErrorMessage isVisible={showContentError} message="You must enter body copy for the article." />
          <FroalaEditor
            ref={this.froalaRef}
            tag="textarea"
            config={config}
            model={body}
            onModelChange={this.handleBodyChange}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  oidc: selectors.selectOidc
});

export default connect(mapStateToProps, null) (ResourceEditor);