import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { BadgeType, ParticipantBadgeType } from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';

export const getParticipantBadgesAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANT_BADGES_REQUEST',
  'hmp/admin/GET_PARTICIPANT_BADGES_SUCCESS',
  'hmp/admin/GET_PARTICIPANT_BADGES_FAILURE'
)<number, Optional<NormalizedType<BadgeType>>, Error>();

export const awardParticipantBadgeAsync = createAsyncAction(
  'hmp/admin/AWARD_PARTICIPANT_BADGE_REQUEST',
  'hmp/admin/AWARD_PARTICIPANT_BADGE_SUCCESS',
  'hmp/admin/AWARD_PARTICIPANT_BADGE_FAILURE'
)<{participantId: number, badgeCollectionId: number}, Optional<NormalizedType<ParticipantBadgeType>>, Error> ();


const participantBadgeActions = {
  getParticipantBadgesAsync,
  awardParticipantBadgeAsync
};

export type ParticipantBadgeActionTypes = ActionType<typeof participantBadgeActions>;
