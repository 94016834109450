import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from './redux/selectors';
import IApplicationState from './types/state.types';
import LoginPage from './pages/auth/LoginPage';
import Router from './router/Router';

interface StateProps {
  authenticatedUserId: Optional<number>,
  isAuthenticated: boolean,
  isAuthenticating: boolean
}

interface ComponentProps extends StateProps {}

class App extends React.Component<ComponentProps, {}> {

  render() {

    const { isAuthenticated, isAuthenticating } = this.props;

    if (!isAuthenticated && !isAuthenticating) {
      return <LoginPage />;
    }

    if (isAuthenticating) {
      return <div>Authenticating...</div>;
    }

    if (!isAuthenticated) {
      return <div>Error...</div>;
    }

    return (
      <Router />
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  authenticatedUserId: selectors.getAuthenticatedUserId,
  isAuthenticated: selectors.isAuthenticated,
  isAuthenticating: selectors.isAuthenticating
});

export default connect(mapStateToProps)(App);
