import { Button, Table } from 'antd';
import Column from 'antd/es/table/Column';
import { _ } from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { requestAdHocPaymentAsync } from '../../redux/payment/payment.types';
import { PaymentType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { renderPopoverDate } from '../util/Util';

interface StateProps {
  participantId: number
}

interface DispatchProps {
  requestAdHocPayment: typeof requestAdHocPaymentAsync.request
}

interface ComponentProps extends DispatchProps, StateProps {
  participantIncentives: PaymentType[]
}

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class IncentivesTable extends PureComponent<ComponentProps, {}> {

  createGenericAdHoc = () => {
    const { requestAdHocPayment, participantId } = this.props;
    const incentiveId = 16;
    requestAdHocPayment({participantId, incentiveId});
  };

  createPrEPAdHoc = () => {
    const { requestAdHocPayment, participantId } = this.props;
    const incentiveId = 17;
    requestAdHocPayment({participantId, incentiveId});
  };

  render() {
    const { participantIncentives } = this.props;
    return (
      <div>
        <Button style={{marginRight: '10px' }} onClick={this.createGenericAdHoc}>Create Ad Hoc Payment Request</Button>
        <Button                                onClick={this.createPrEPAdHoc}>Create PrEP Test Kit Payment Request</Button>
        <br/> <br/>
        <Table
          dataSource={participantIncentives}
          pagination={{pageSize: 20}}
          rowClassName={rowClassName}
          rowKey="incentivePaymentId"
        >
          <Column title='ID' dataIndex='incentivePaymentId' key='incentivePaymentId'
                  sorter={(a: any, b: any) => a.incentivePaymentId - b.incentivePaymentId} />
          <Column title='Label' dataIndex='label' key='label'
                  sorter={(a: any, b: any) => `${a.label}`.localeCompare(b.label)}
                  filters={_.map(_.sortedUniq(_.uniq(_.sortBy(_.map(participantIncentives, 'label')))), x => { return {text: x, value: x}} )}
                  onFilter={(value, record) => record.label === value} />
          <Column title='Amount' dataIndex='amount' key='amount'
                  render={(amount:any) => `$${amount}` }
                  sorter={(a: any, b: any) => a.amount - b.amount} />
          <Column title='Create Date' dataIndex='createDate' key='createDate'
                  render={renderPopoverDate}
                  sorter={(a: any, b: any) => moment(a.createDate).unix() - moment(b.createDate).unix()} />
          <Column title='Paid' dataIndex='paidDate' key='paidDate'
                  render={(paidDate:any) => `${paidDate ? moment(paidDate).calendar() : ''}` }
                  defaultSortOrder='descend'
                  sorter={(a: any, b: any) => {
                    if (!a.paidDate && !b.paidDate) return 0;
                    if (!a.paidDate && b.paidDate) return 1;
                    if (a.paidDate && !b.paidDate) return -1;
                    else return moment(a.paidDate).unix() - moment(b.paidDate).unix()
                  }}
                  filters={[{text: 'unpaid', value: 'unpaid'}]}
                  onFilter={(value, record) => value === 'unpaid' && !record.paidDate}
          />
          <Column title='Order Number' dataIndex="trackingInfo" key="trackingInfo"
                  sorter={(a:any, b: any) =>  `${a.trackingInfo}`.localeCompare(b.trackingInfo)}
          />
        </Table>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    requestAdHocPayment: (param: {participantId: number, incentiveId: number}) => dispatch(requestAdHocPaymentAsync.request(param)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncentivesTable);
