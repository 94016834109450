import {AxiosResponse} from 'axios';
import {normalize} from 'normalizr';
import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {getType} from 'typesafe-actions';
import {NormalizerResult, NoteType, ParticipantType} from 'types/entity.types';
import axios from '../api';
import {entitySchema} from '../schema';
import {getParticipantNotesAsync, saveParticipantNoteAsync} from './notes.types';
import cloneDeep from 'lodash/cloneDeep';

const getParticipantNotes = (participantId: number) => {
  return axios({
    method: 'get',
    url: `/a/note/${participantId}`
  });
};

function* getParticipantNotesHandler(action: ReturnType<typeof getParticipantNotesAsync.request>): Generator {

  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = yield call(getParticipantNotes, participantId);
    const { entities } = normalize(response.data, entitySchema.notes) as NormalizerResult;
    const { notes } = entities;

    yield put(getParticipantNotesAsync.success(notes));
  } catch (error) {
    yield put(getParticipantNotesAsync.failure(error));
  }
}

function* getParticipantNotesWatcher() {
  yield takeLatest(getType(getParticipantNotesAsync.request), getParticipantNotesHandler);
}

const saveParticipantNote = (note: NoteType) => {
  const data = cloneDeep(note);

  return axios({
    method: 'put',
    url: `/a/note/${note.participantId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data
  });
};

function* saveParticipantNoteHandler(action: ReturnType<typeof saveParticipantNoteAsync.request>): Generator {

  try {
    const note: NoteType = action.payload;

    const response: AxiosResponse = yield call(saveParticipantNote, note);
    const { entities } = normalize(response.data, entitySchema.notes) as NormalizerResult;
    const { notes } = entities;

    yield put(saveParticipantNoteAsync.success(notes));
  } catch (error) {
    yield put(saveParticipantNoteAsync.failure(error));
  }
}

function* saveParticipantNoteWatcher() {
  yield takeLatest(getType(saveParticipantNoteAsync.request), saveParticipantNoteHandler);
}

export default function* notesSaga() {
  yield all([
    fork(getParticipantNotesWatcher),
    fork(saveParticipantNoteWatcher)
  ]);
}
