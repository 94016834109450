import React from 'react';
import {
  Icon, Button
} from 'antd';
import Form from 'antd/lib/form';
import QuestionItem from './QuestionItem';
import { ActivityQuestionType, ActivityOptionType } from '../../../types/entity.types';
const { Item } = Form;
import '../activity.scss';

interface ComponentProps {
  form: any;
  type: number;
  creative: boolean;
  editable: boolean;
  questions: ActivityQuestionType[];
  options: ActivityOptionType[];
  setStatus: (status: string) => void;
  updateQuestion: (question: ActivityQuestionType, index: number) => void;
  addQuestion: () => void;
  removeQuestion: (index: number) => void;
}

const DynamicQuestionItems = (props: ComponentProps) => {
  const { 
    questions, 
    form, 
    type, 
    creative,
    editable,
    updateQuestion, 
    addQuestion, 
    removeQuestion, 
    options, 
    setStatus 
  } = props;
  const onAddClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    addQuestion();
  };
  const items = questions.map((q: ActivityQuestionType, index: number) => {
    return (<li className='question-list-container'>
      <QuestionItem 
        form={form} 
        type={type} 
        creative={creative}
        editable={editable}
        question={q} 
        options={options} 
        setStatus={setStatus}
        updateQuestion={(q) => updateQuestion(q, index)}
      />
      {questions.length > 1 && (creative || editable) && (
      <button disabled={!creative && !editable} className='dashed-danger-button' onClick={() => removeQuestion(index)}>
        <Icon type='minus' /> Remove Question
      </button>)}
    </li>
  )});
  return (
    <ul className='dynamic-question-items'>
      {items}
      {(creative || editable) && <Item>
        <Button type='dashed' onClick={onAddClick} style={{width: '100%'}}>
          <Icon type='plus' /> Add question
        </Button>
      </Item>}
    </ul>
  );
};

export default DynamicQuestionItems;