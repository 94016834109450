import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AdminUserType, UserType, NewUserType, RoleType } from '../../types/entity.types';
import { NormalizedType } from 'types/state.types';

export interface PasswordArguments {
  password: string;
  currentPassword: string;
  updatePseudoParticipants?: boolean;
}

export const updatePasswordAsync = createAsyncAction(
  'hmp/admin/UPDATE_PASSWORD_REQUEST',
  'hmp/admin/UPDATE_PASSWORD_SUCCESS',
  'hmp/admin/UPDATE_PASSWORD_FAILURE'
)<PasswordArguments, Optional<UserType>, Error>();

export const updateEmailAsync = createAsyncAction(
  'hmp/admin/UPDATE_EMAIL_REQUEST',
  'hmp/admin/UPDATE_EMAIL_SUCCESS',
  'hmp/admin/UPDATE_EMAIL_FAILURE'
)<Optional<string>, Optional<UserType>, Error>();

export const updateMobileAsync = createAsyncAction(
  'hmp/admin/UPDATE_PASSWORD_EMAIL_MOBILE_REQUEST',
  'hmp/admin/UPDATE_PASSWORD_EMAIL_MOBILE_SUCCESS',
  'hmp/admin/UPDATE_PASSWORD_EMAIL_MOBILE_FAILURE'
)<Optional<string>, Optional<UserType>, Error>();

export const getUserAsync = createAsyncAction(
  'hmp/admin/USER_REQUEST',
  'hmp/admin/USER_SUCCESS',
  'hmp/admin/USER_FAILURE'
)<number, Optional<UserType>, Error>();

export const getAdminAsync = createAsyncAction(
  'hmp/admin/GET_ADMIN_REQUEST',
  'hmp/admin/GET_ADMIN_SUCCESS',
  'hmp/admin/GET_ADMIN_FAILURE'
)<void, Optional<NormalizedType<AdminUserType>>, Error>();

export const createUserAsync = createAsyncAction(
  'hmp/admin/CREATE_USER_REQUEST',
  'hmp/admin/CREATE_USER_SUCCESS',
  'hmp/admin/CREATE_USER_FAILURE'
)<NewUserType, void, Error>();

export const getAdminRolesAsync = createAsyncAction(
  'hmp/admin/GET_ADMIN_ROLES_REQUEST',
  'hmp/admin/GET_ADMIN_ROLES_SUCCESS',
  'hmp/admin/GET_ADMIN_ROLES_FAILURE'
)<void, Optional<NormalizedType<RoleType>>, Error>();

const userActions = {
  getUserAsync,
  getAdminAsync,
  updatePasswordAsync,
  updateEmailAsync,
  updateMobileAsync,
  createUserAsync,
  getAdminRolesAsync
};

export type UserActionTypes = ActionType<typeof userActions>;
