import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  getForumDetailsByStudyIdAsync,
  ForumActionTypes
} from './forum.types';
import { ForumDetailType } from '../../types/entity.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';

const updateForumsReducer = produce((draft, forums) => {
  if (!forums) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(forums), Number));
  const ids = Object.keys(forums);
  for (const id of ids) {
    draft.byId[id] = forums[id];
  }
});

export const forumReducer = (state: NormalizedState<ForumDetailType> = initialNormalizedState, action: ForumActionTypes) => {
  switch (action.type) {
    case getType(getForumDetailsByStudyIdAsync.success): return updateForumsReducer(state, action.payload);
    default: return state;
  }
};

export default forumReducer;