import { Table } from 'antd';
import React, { Component } from 'react';
import moment from 'moment';
import { FormType } from '../../types/entity.types';
import { renderPopoverDate } from '../util/Util';
import AdverseEventForm from './AdverseEventForm';
import EarlyTerminationForm from './EarlyTerminationForm';
import ProtocolDeviationForm from './ProtocolDeviationForm';
import SocialHarmForm from './SocialHarmForm';
import Username from '../username/Username';
import './editForm.scss';

const { Column } = Table;

const tableStyle = {
  background: '#FFF'
};

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

const typeFilters = [
  { text: 'Adverse Event', value: 'ADVERSE_EVENT' },
  { text: 'Early Termination', value: 'EARLY_TERMINATION' },
  { text: 'Protocol Deviation', value: 'PROTOCOL_DEVIATION' },
  { text: 'Social Harm', value: 'SOCIAL_HARM' }
];

interface StateProps {
  forms: Optional<FormType[]>
}

interface DispatchProps {
}

interface ComponentProps extends StateProps {
}

class FormTable extends Component<ComponentProps, {}> {
  readonly state = {
    viewForm: false,
    editing: false,
    currentForm: null,
  }
  handleViewForm = (id: number) => {
    if(this.props.forms) {
      this.setState({
        viewForm: true,
        currentForm: this.props.forms.find(f => f.id === id)
      });
    }
  }
  handleEditForm = (id: number) => {
    if(this.props.forms) {
      this.setState({
        viewForm: true,
        editing: true,
        currentForm: this.props.forms.find(f => f.id === id)
      });
    }
  }
  renderFormModal = (form: FormType, visible: boolean, type: string) => {
    const { editing } = this.state;
    if (!visible) return (<div />);
    switch (type) {
      case 'ADVERSE_EVENT':
        return <AdverseEventForm editable={false} editing={editing} data={form} closeHandler={this.handleClose} visible={visible} />;
      case 'EARLY_TERMINATION':
        return <EarlyTerminationForm editable={false} editing={editing} data={form} closeHandler={this.handleClose} visible={visible} />;
      case 'PROTOCOL_DEVIATION':
        return <ProtocolDeviationForm editable={false} editing={editing} data={form} closeHandler={this.handleClose} visible={visible} />;
      case 'SOCIAL_HARM':
        return <SocialHarmForm editable={false} editing={editing} data={form} closeHandler={this.handleClose} visible={visible} />;
      default:
        return <div />;
    }
  }
  handleClose = () => {
    this.setState({
      viewForm: false,
      editing: false,
      currentForm: null
    });
  }
  renderFormLink = (id: number) => {
    return <div className='form-action-container' >
      <a title='View' className='form-action' onClick={e => this.handleViewForm(id)}><i className="far fa-eye fa-lg" /></a>,
      <a title='Edit' className='form-action' onClick={(e) => { e.stopPropagation(); this.handleEditForm(id); }}>
        <i className="far fa-edit fa-lg"></i>
      </a>
    </div>;
  }
  sorterCreateDate = (a: any, b: any) => moment(a.createDate).unix() - moment(b.createDate).unix();
  renderParticipant = (id: number) => {
    if(id) {
      return <Username participantId={id} />;
    }
    return 'N/a';
  };
  renderBoolean = (bool: number) => {
    return bool ? 'True' : 'False';
  };
  onFilterResolved = (value: any, record: any) => {
    if(value) {
      return record.isResolved === parseInt(value);
    }
    return false;
  };
  handleRowClick = (record: FormType, index: number, event: Event) => {
    if(record && record.id && this.props.forms) {
      this.setState({
        viewForm: true,
        currentForm: this.props.forms.find(f => f.id === record.id)
      });
    }
  };
  render() {
    const { forms: filteredForms } = this.props;
    const { currentForm, viewForm } = this.state;
    const resolvedFilters = [{ text: 'Resolved', value: '1' }, { text: 'Open', value: '0' }];
    return (
      <div>
        {currentForm ? this.renderFormModal(currentForm, viewForm, currentForm.formType) : <div />}
        <Table
          pagination={false}
          dataSource={filteredForms}
          rowKey="id"
          rowClassName={rowClassName}
          style={tableStyle}
          onRowClick={this.handleRowClick}
        >
          <Column
            title="Username"
            dataIndex="participantId"
            key="username"
            render={(value: number) => <Username participantId={value} />}
            sorter={(a: FormType, b: FormType) => (a.participantId && b.participantId ? a.participantId - b.participantId : 0)}
          />
          <Column title="Form Type" dataIndex="formType" key="formType" render={type => type.split('_').map((s: string) => s.charAt(0) + s.substring(1).toLowerCase()).join(' ')} filters={typeFilters} onFilter={(value, record: FormType) => record.formType === value} />
          <Column title="Date" dataIndex="createDate" key="createDate" defaultSortOrder='descend' sorter={this.sorterCreateDate} render={renderPopoverDate}/>
          <Column title="Admin ID" dataIndex="adminId" key="adminId" />
          <Column title="Resolved?" dataIndex="isResolved" key="isResolved" render={this.renderBoolean} filters={resolvedFilters} onFilter={this.onFilterResolved} />
          <Column
            title="View"
            dataIndex="id"
            key="id"
            render={(text, row, id: number) => this.renderFormLink(id)}
          />
        </Table>
      </div>
    );
  }
}

export default (FormTable);
