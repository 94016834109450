import React, { Component } from 'react';
import axios from '../../redux/api';
import IApplicationState from 'types/state.types';
import { connect } from 'react-redux';

interface StateProps {
  accessToken: string
}

interface ComponentProps extends StateProps {
  url: string,
  alt: string
}

class SecureImage extends Component<ComponentProps, {}> {
  readonly state = {
    source: ''
  }

  componentDidMount() {
    const { url, accessToken } = this.props;
    this.getBase64(url, accessToken);
  }

  getBase64(url: string, accessToken: string) {
    return axios
      .get(url, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then(response => {
        const prefix = "data:image/jpg;base64,";
        const base64 = Buffer.from(response.data, 'binary').toString('base64');
        this.setState({
          source: prefix + base64
        });
      });
  }

  render() {
    const { source } = this.state;
    const { alt } = this.props;
    if(!source || source.length === 0) {
    return <div>No image found for the following url: {this.props.url}</div>
    }
    return (
      <img style={{ width: 'inherit', height: 'inherit' }} alt={alt} src={source}></img>
    );
  }
}

const mapStateToProps = (state: IApplicationState): StateProps => {
  return {
    accessToken: state.oidc.user? state.oidc.user.access_token : ''
  };
};

export default connect(mapStateToProps, null)(SecureImage);
