import { Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import Username from '../../components/username/Username';
import * as selectors from '../../redux/selectors';
import { getRequestedTestkitsAsync } from '../../redux/testkit/testkit.types';
import { TestkitType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { renderPopoverDate } from '../util/Util';
import TestkitRequestedCard from './TestkitRequestedCard';

interface StateProps {
  testkits: Optional<TestkitType[]>
}

interface DispatchProps {
  loadRequestedTestkits: typeof getRequestedTestkitsAsync.request
}

interface ComponentProps extends DispatchProps, StateProps {
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a: any, b: any) => a.id - b.id
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: (a: any, b: any) => `${a.type}`.localeCompare(b.type),
    filters: [{text:'OraQuick', value: 'oraquick'}, {text:'HEMASPOT', value: 'hemaspot'}],
    onFilter: (value:number, record:any) =>  record.type.toLowerCase() === value,
  },
  {
    title: 'Request Date',
    dataIndex: 'requestDate',
    key: 'requestDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.requestDate).unix() - moment(b.requestDate).unix()
  },
  {
    title: 'Username',
    dataIndex: 'participant.id',
    key: 'username',
    render: (value: any, row: any) => <Username participantId={value} />,
    sorter: (a: any, b: any) => `${a.participant.username}`.localeCompare(b.participant.username)
  },
  {
    title: 'Participant ID',
    dataIndex: 'participant.id',
    key: 'id',
    sorter: (a: any, b: any) => a.participant.id - b.participant.id
  },
];

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class TestkitRequestedTable extends Component<ComponentProps, {}> {
  readonly state = {
    studyId: 1
  };

  componentDidMount() {
    const { studyId } = this.state;
    const { loadRequestedTestkits } = this.props;
    loadRequestedTestkits({ studyId });
  }

  rowRender = (record: any) => <TestkitRequestedCard testkit={record} />;

  render() {
    const { testkits } = this.props;
    return (
      <div style={{height: '100$', border: '1px solid blue'}}>
      <Table
        columns={columns}
        dataSource={testkits}
        scroll={{y: `calc(100vh - 200px)`}}
        pagination={false}
        rowClassName={rowClassName}
        rowKey="id"
        expandedRowRender={this.rowRender}
      />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  testkits: selectors.getRequestedTestkits
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadRequestedTestkits: (args: {studyId: number}) => dispatch(getRequestedTestkitsAsync.request(args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestkitRequestedTable);
