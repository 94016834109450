import { createSelector } from 'reselect';
import _ from 'lodash';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import { selectParticipants } from '../participants/participants.selectors';
import entitySchema from '../schema';

export const selectAuth = (state: IApplicationState) => {
  return state.auth;
};

export const selectAdmins = (state: IApplicationState) => {
  return state.entities.admin;
}

export const selectAdminRoles = (state: IApplicationState) => {
  return state.entities.adminRoles;
}

export const getAdmins = createSelector(
  [selectAdmins],
  (admin) => {
    if (admin && admin.allIds && admin.allIds.length > 0) {
      const { admin: denormalizedAdmin } = denormalize({ admin: admin.allIds }, entitySchema, { admin: admin.byId });
      return _.toArray(denormalizedAdmin);
    }
    return undefined;
  }
)

export const getAdminRoles = createSelector(
  [selectAdminRoles],
  (adminRoles) => {
    if (adminRoles && adminRoles.allIds && adminRoles.allIds.length > 0) {
      const { adminRoles: denormalizedAdminRoles } = denormalize({ adminRoles: adminRoles.allIds }, entitySchema, { adminRoles: adminRoles.byId });
      return _.toArray(denormalizedAdminRoles);
    }
    return undefined;
  }
)

export const getExpertAdmins = createSelector(
  [getAdmins],
  (admins) => {
    if (admins) {
      const experts =  _.filter(admins, a => _.find(a.roles, r => r.role.role === 'Expert'));
      return experts;
    }
    return [];
  }
)

export const selectUser = createSelector(
  [selectAuth],
  (auth) => {
    return auth ? auth.user : undefined;
  }
);

// TODO: See if we need to add a custom function to determine if the user changed. I'm not sure all of the restructuring
//  has eliminated the un-necessary component re-rendering. The re-rendering could also have been a result of the OIDC
//  silent token renewals.
export const getUser = createSelector(
  [selectUser, selectParticipants],
  (user, participants) => {

    if (user && user.id != -1) {
      return user;
    }

    return undefined;
  }
);

export const getCurrentUserPseudoParticipants = createSelector(
  [selectUser, selectParticipants],
  (user, participants) => {

    if (user && user.id != -1) {
      if (participants?.allIds?.length && user.pseudoParticipantIds.length) {
        const { participants: pseudoParticipants } = denormalize({ participants: user.pseudoParticipantIds  }, entitySchema, { participants: participants.byId });
        return pseudoParticipants;
      }
    }

    return [];
  }
)

// YABs don't get to do much
export const isNonYAB = createSelector(
  [getUser],
  (user) => {
    if (!user) {
      return false;
    }

    if (user.roles.length === 1 && user.roles[0].role.role === 'YAB') {
      return false;
    }

    return true;
  }
)

export const hasDataAnalystRole = createSelector(
  [getUser],
  (user) => {
    if (!user) {
      return false;
    }

    return !!_.find(user.roles, r => r.role.role === 'Data Analyst');
  }
)

export const hasCareNavigatorRole = createSelector(
  [getUser],
  (user) => {
    if (!user) {
      return false;
    }

    return !!_.find(user.roles, r => r.role.role === 'Care Navigator');
  }
)


export default { getUser, isNonYAB };
