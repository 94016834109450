import React, { ChangeEvent } from 'react';
import {
  Input, Select
} from 'antd';
import '../activity.scss';
import { ActivityQuestionType, ActivityOptionType} from '../../../types/entity.types';
const { Option } = Select;

interface ComponentProps {
  form: any;
  creative: boolean;
  editable: boolean;
  question: ActivityQuestionType;
  options: ActivityOptionType[];
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  updateQuestion: (q: ActivityQuestionType) => void;
}

enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener
}

const CategoryQuestion = (props: ComponentProps) => {
  const { 
    question,
    onTextChange, 
    updateQuestion, 
    options, 
    creative, 
    editable 
  } = props;
  let { text, correctOptionId } = question;
  const onCorrectOptionChange = (value: string) => {
    question.correctOptionId = value;
    updateQuestion(question);
  };
  return (
    <div style={{ width: '100%' }} >
      <Input spellCheck disabled={!creative && !editable} placeholder='Question text' defaultValue={text} onChange={onTextChange} style={{ width: '100%' }} />
      Correct Option: <Select disabled={!creative && !editable} defaultValue={correctOptionId ? `${correctOptionId}` : undefined} onChange={onCorrectOptionChange} style={{width: 'auto', minWidth: '200px'}}>
        {options?.map(option => <Option value={`${option.id ? option.id : option.uuid}`}>{option.text}</Option>)}
      </Select>
    </div>
  );
};

export default CategoryQuestion;