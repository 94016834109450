import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  loadConsentStatusesAsync,
  ParticipantActionTypes,
  saveParticipantActualHivStatusAsync,
  saveParticipantAsync, saveParticipantInterviewSelectedAsync,
  updateParticipants
} from "./participants.types";
import { ConsentStatusType, ParticipantType } from "../../types/entity.types";
import { initialNormalizedState, NormalizedState } from '../../types/state.types';

const updateConsentStatuses = produce((draft, consentStatuses) => {
  if (!consentStatuses) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(consentStatuses), Number));
  const ids = Object.keys(consentStatuses);
  for (const id of ids) {
    draft.byId[id] = consentStatuses[id];
  }
});

export const consentStatusesReducer = (state: NormalizedState<ConsentStatusType> = initialNormalizedState, action: ParticipantActionTypes) => {

  switch (action.type) {
    case getType(loadConsentStatusesAsync.success): return updateConsentStatuses(state, action.payload);
    default: return state;
  }
};

export default consentStatusesReducer;
