import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { ThumbsupType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { selectParticipants } from '../participants/participants.selectors';
import { entitySchema } from '../schema';

export const selectThumbsups = (state: IApplicationState) => {
  return state.entities.thumbsups;
};

export const getThumbsups = createSelector(
  [selectThumbsups, selectParticipants],
  (thumbsups, participants): Optional<ThumbsupType[]> => {
    if (thumbsups && thumbsups.allIds && thumbsups.allIds.length > 0) {
      const { thumbsups: denormalizedThumbsups } = denormalize({ thumbsups: thumbsups.allIds }, entitySchema, { thumbsups: thumbsups.byId, participants: participants.byId});
      return toArray(denormalizedThumbsups);
    }
    return undefined;
  }
);

export const getQnaThumbsups = createSelector(
  [selectThumbsups, selectParticipants],
  (thumbsups, participants): Optional<ThumbsupType[]> => {
    if (thumbsups && thumbsups.allIds && thumbsups.allIds.length > 0) {
      const { thumbsups: denormalizedThumbsups } = denormalize({ thumbsups: thumbsups.allIds }, entitySchema, { thumbsups: thumbsups.byId, participants: participants.byId});
      const qnaThumbsups = toArray(denormalizedThumbsups).filter(t => t.type === 'qna');
      return qnaThumbsups;
    }
    return undefined;
  }
);

export const getCommentThumbsups = createSelector(
  [selectThumbsups, selectParticipants],
  (thumbsups, participants): Optional<ThumbsupType[]> => {
    if (thumbsups && thumbsups.allIds && thumbsups.allIds.length > 0) {
      const { thumbsups: denormalizedThumbsups } = denormalize({ thumbsups: thumbsups.allIds }, entitySchema, { thumbsups: thumbsups.byId, participants: participants.byId});
      const qnaThumbsups = toArray(denormalizedThumbsups).filter(t => t.type === 'comment');
      return qnaThumbsups;
    }
    return undefined;
  }
);

export default { getThumbsups };
