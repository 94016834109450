import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import { transliterate as tr } from 'transliteration';
import * as selectors from '../../redux/selectors';
import FormContextProvider from '../form/FormContextProvider';
import IApplicationState from '../../types/state.types';
import { createAnnouncementAsync, updateAnnouncementAsync } from '../../redux/announcements/announcements.types';
import { AnnouncementType, StudyArmType } from '../../types/entity.types';
import { Modal, message, Button, Select, Input, DatePicker } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import FroalaCustomUpload from '../activity/FroalaCustomUpload';
import { updateCmsEditorBeforeSave } from '../util/Util';
import ActivityTooltip from '../activity/ActivityTooltip';
const { confirm } = Modal;
const { Item } = Form;
const { Option } = Select;

interface StateProps {
  studyId: number,
  arms: Optional<StudyArmType[]>
}

interface DispatchProps {
  createAnnouncement: typeof createAnnouncementAsync.request,
  updateAnnouncement: typeof updateAnnouncementAsync.request
}

interface ComponentProps extends StateProps, DispatchProps, FormComponentProps {
  announcement: AnnouncementType;
  visible: boolean;
  closeHandler: () => {};
}
const defaultState = {
  id: undefined,
  type: 'study',
  typeId: -1,
  title: '',
  body: '',
  effectiveStartDate: undefined,
  effectiveEndDate: undefined
};
const itemStyle = {
  marginBottom: '10px',
  marginTop: '10px'
}
const cleanstring = (dirty: string) => {
  if (dirty) {
    return tr(dirty
      .replace(/‘/g, "'")
      .replace(/’/g, "'")
      .replace(/“/g, '"')
      .replace(/”/g, '"')
    );
  }
  return '';
};

class AnnouncementForm extends Component<ComponentProps, {}> {
  readonly state = _.cloneDeep(defaultState);
  componentDidMount() {
    const { announcement, studyId } = this.props;
    if(announcement && announcement.id) {
      const mergedAnnouncement = _.merge(_.cloneDeep(defaultState), announcement);
      this.setState({ ...mergedAnnouncement });
    } else {
      // Default state is set to create an annoucement
      // on the study level.
      const modifiedAnnouncement = _.cloneDeep(this.state);
      modifiedAnnouncement.typeId = studyId;
      this.setState({ ...modifiedAnnouncement });
    }
  }
  handleSave = (e: any) => {
    const { form } = this.props;
    const { id } = this.state;
    if(form) {
      form.validateFields((err: any, values: any) => {
        if (!err) {
          const handleSaveAnnouncement = this.handleSaveAnnouncement.bind(this);
          confirm({
            title: `Are you sure you want to ${id ? 'edit' : 'create'} this announcement?`,
            content: '',
            okText: 'Confirm',
            onOk() {
              handleSaveAnnouncement();
            },
            onCancel() {}
          });
        }
      });
    }
  };
  handleSaveAnnouncement = async () => {
    const {
      closeHandler,
      createAnnouncement,
      updateAnnouncement
    } = this.props;
    const {
      id,
      title,
      body,
      type,
      typeId,
      effectiveStartDate,
      effectiveEndDate
    } = this.state;
    const announcement = {
      id,
      title,
      body,
      type,
      typeId,
      effectiveStartDate,
      effectiveEndDate
    }
    announcement.body = cleanstring(body);
    if(id) {
      updateAnnouncement(announcement);
    } else {
      createAnnouncement(announcement);
    }
    this.resetState();
    closeHandler();
  };
  handleCancel = (e: any) => {
    const { closeHandler } = this.props;
    const resetState = this.resetState.bind(this);
    confirm({
      title: 'Are you sure you want to leave this announcement?',
      content: 'You will lose all changes.',
      okText: 'Leave',
      okType: 'danger',
      onOk() {
        resetState();
        closeHandler();
      },
      onCancel() {}
    });
  };
  resetState = () => {
    const resetState = _.cloneDeep(defaultState);
    this.setState(resetState);
  };
  onTypeTypeIdChange = (value: string) => {
    const typeTypeId = value.split('-');
    if(typeTypeId.length === 2) {
      this.setState({
        type: typeTypeId[0],
        typeId: parseInt(typeTypeId[1])
      });
    }
  };
  onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: e.target.value });
  };
  onBodyChange = (value: string) => {
    this.setState({ body: updateCmsEditorBeforeSave(value) });
  };
  validateBody = (rule, value, cb) => {
    const { body } = this.state;
    try {
      if (body.length === 0){
        throw new Error('Body is required.');
      }
      cb()
    } catch (err) {
      cb(err);
    }
  };
  onStartDateChange = (date: Moment | null, dateString: string) => {
    this.setState({ effectiveStartDate: dateString.length ? dateString : undefined });
  };
  onEndDateChange = (date: Moment | null, dateString: string) => {
    this.setState({ effectiveEndDate: dateString.length ? dateString : undefined });
  };
  disabledDate = (current: Moment | null): boolean => {
    return current && current < moment().startOf('day') ? true : false;
  };
  validateStartDate = (rule, value, cb) => {
    try {
      const { effectiveEndDate } = this.state;
      if(effectiveEndDate && moment(value).isSameOrAfter(effectiveEndDate)) {
        throw new Error('An announcement\'s start date must proceed its end date.');
      }
      cb();
    } catch (err) {
      cb(err);
    }
  }
  validateEndDate = (rule, value, cb) => {
    try {
      const { effectiveStartDate } = this.state;
      if(value && moment(effectiveStartDate).isSameOrAfter(value)) {
        throw new Error('An announcement\'s end date must follow its start date.');
      }
      cb();
    } catch (err) {
      cb(err);
    }
  }
  render() {
    const {
      id,
      type,
      typeId,
      title,
      body,
      effectiveStartDate,
      effectiveEndDate
    } = this.state;
    const { visible, form, arms, studyId } = this.props;
    const { getFieldDecorator } = form;
    const footerActions = [
      <Button type='primary' onClick={this.handleSave}>Save</Button>,
      <Button type='default' onClick={this.handleCancel}>Cancel</Button>
    ];
    return (
      <Modal
        title='Add/Edit Announcement'
        visible={visible}
        onOk={this.handleSave}
        onCancel={this.handleCancel}
        footer={footerActions}
        closable={false}
        destroyOnClose={true}
        width='50%'
        style={{minWidth: '400px'}}
      >
        <FormContextProvider>
          <Form key="announcement-form" layout="horizontal" colon={false}>
            <Item label="Type:" style={itemStyle} >
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Type is required.' }],
                initialValue: `${type}-${typeId}`
              })(
                <Select onChange={this.onTypeTypeIdChange} style={{width: 120 }}>
                  <Option value={`study-${studyId}`}>Study</Option>
                  {arms && arms.map(arm => <Option value={`arm-${arm.id}`}>Arm {arm.id}</Option>)}
                </Select>
              )}
            </Item>
            <Item label="Title:" style={itemStyle} >
              {getFieldDecorator('title', {
                rules: [{ required: true, message: 'Title is required.' }],
                initialValue: title
              })(<Input placeholder='Title' onChange={this.onTitleChange} />)}
            </Item>
            <Item label="Body:" style={itemStyle} >
              {getFieldDecorator('body', {
                rules: [{ required: true, validator: this.validateBody }]
              })(<FroalaCustomUpload
                model={body}
                onChange={this.onBodyChange}
              />)}
            </Item>
            <Item label={<span>Start Date: <ActivityTooltip text="If no start date is provided this will start now. Otherwise it will start on the hour you specify in the user's local timezone." /></span>} style={itemStyle} >
              {getFieldDecorator('startDate', {
                rules: [{ required: false, validator: this.validateStartDate }],
                validateTrigger: ['onChange'],
                initialValue: effectiveStartDate ? moment(effectiveStartDate) : undefined
              })(<DatePicker disabledDate={this.disabledDate} showTime placeholder='Select start date' onChange={this.onStartDateChange} format='YYYY-MM-DD HH:mm' />)}
            </Item>
            <Item label={<span>End Date: <ActivityTooltip text="If no end date is provided, then the announcement will be active until the next announcement start date. Otherwise it will end on the hour you specify in the user's local timezone" /></span>} style={itemStyle} >
              {getFieldDecorator('endDate', {
                rules: [{ required: false, validator: this.validateEndDate }],
                validateTrigger: ['onChange'],
                initialValue: effectiveEndDate ? moment(effectiveEndDate) : undefined
              })(<DatePicker disabledDate={this.disabledDate} showTime placeholder='Select end date' onChange={this.onEndDateChange} format='YYYY-MM-DD HH:mm' />)}
            </Item>
          </Form>
        </FormContextProvider>
      </Modal>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    studyId: selectors.getRequestedStudyId(state),
    arms: selectors.getRequestedStudyStudyArms(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    createAnnouncement: (announcement: AnnouncementType) => dispatch(createAnnouncementAsync.request(announcement)),
    updateAnnouncement: (announcement: AnnouncementType) => dispatch(updateAnnouncementAsync.request(announcement))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'announcement-form' })(AnnouncementForm));