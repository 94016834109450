import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { ArticleType } from '../../types/entity.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {
  ArticleActionTypes,
  IArticleUIState,
  loadArticlesAsync,
  updateSavedArticleId,
  updateSelectedArticleIds,
} from "./article.types";

export const initialArticleUIState = {
  articleIds: [],
  savedArticleId: undefined,
};
export const articleUIReducer: Reducer<IArticleUIState, ArticleActionTypes> = (state: IArticleUIState = initialArticleUIState, action: ArticleActionTypes) => {

  switch (action.type) {
    case getType(updateSelectedArticleIds):
      return {
        ...state,
        articleIds: action.payload,
      };
    case getType(updateSavedArticleId):
      return {
        ...state,
        savedArticleId: action.payload,
      };
    default:
      return { ...state };
  }
};

export default articleUIReducer;
