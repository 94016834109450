import React, { Component } from 'react';
import {
  Input, Form, Checkbox
} from 'antd';
import FroalaCustomUpload from '../FroalaCustomUpload';
import '../activity.scss';
import { ActivityOptionType, ActivityFeedbackType } from '../../../types/entity.types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { updateCmsEditorBeforeSave } from '../../util/Util';
const { Item } = Form;

interface ComponentProps {
  form: any;
  type: number;
  creative: boolean;
  editable: boolean;
  feedback: ActivityFeedbackType;
  options: ActivityOptionType[];
  setStatus: (status: string) => void;
  updateFeedback: (f: ActivityFeedbackType) => void;
}
enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener,
  question
}
const itemStyle = {
  width: '100%',
  marginBottom: '10px', 
  marginTop: '10px'
};

class FeedbackItem extends Component<ComponentProps, {}> {
  onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { feedback, updateFeedback } = this.props;
    e.preventDefault(); 
    feedback.title = e.target.value;
    updateFeedback(feedback);
  };
  onMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { feedback, updateFeedback } = this.props;
    const { value } = e.target;
    const reg = /[0-9]*(\.[0-9]*)?$/;
    if (value.length && (reg.test(value)) || value === '' || value === '-') {
      const min = parseInt(value);
      if(!isNaN(min)) {
        feedback.min = min;
      } else {
        feedback.min = undefined;
      }
    }
    updateFeedback(feedback);
  };
  onMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { feedback, updateFeedback } = this.props;
    const { value } = e.target;
    const reg = /[0-9]*(\.[0-9]*)?$/;
    if (value.length && (reg.test(value)) || value === '' || value === '-') {
      const max = parseInt(value);
      if(!isNaN(max)) {
        feedback.max = max;
      } else {
        feedback.max = undefined;
      }
    }
    updateFeedback(feedback);
  };
  onTextChange = (value: string) => {
    const { feedback, updateFeedback } = this.props;
    feedback.text = updateCmsEditorBeforeSave(value);
    updateFeedback(feedback);
  };
  onOverallChange = (e: CheckboxChangeEvent) => {
    const { feedback, updateFeedback } = this.props;
    feedback.min = 0;
    feedback.max = undefined;
    feedback.isOverallFeedback = e.target.checked;
    updateFeedback(feedback);
  };
  validateFeedbackTitle = (rule, value, cb) => {
    const { feedback, setStatus } = this.props;
    try {
      if (feedback && feedback.title && feedback.title.length === 0){ 
        throw new Error('Feedback title is required.');
      }
      setStatus('finish');
      cb();
    } catch (err) {
      cb(err);
    }
  };
  validateFeedbackText = (rule, value, cb) => {
    const { feedback, setStatus } = this.props;
    try {
      if (feedback && feedback.text.length === 0){ 
        throw new Error('Feedback text is required.');
      }
      setStatus('finish');
      cb();
    } catch (err) {
      cb(err);
    }
  };
  render() {
    // Including options in the props so that in the future we could 
    // display which options would lead to the feedback based on the
    // feedback's min/max and the option's points.
    const { 
      type, 
      form, 
      creative,
      editable,
      feedback
    } = this.props;
    const { text, title, min, max, uuid, isOverallFeedback, id } = feedback;
    const { getFieldDecorator } = form;
    // Only display min/max options for activity-level feedback,
    // not option or question level.
    const displayMinMax = (Type.zero === type && !isOverallFeedback) || Type.question === type;
    const isOverall = Type.zero === type && (isOverallFeedback || (min === 0 && max === null));
    return (<div className='feedback-container'>
      <Item
        required={false}
        key={`feedback-title-${id ? id : uuid}`}
        style={itemStyle}
      >
      {getFieldDecorator(`feedback-title-${id ? id : uuid}`, {
        validateTrigger: ['onChange', 'onBlur', 'onFocus'],
        rules: [
          {
            required: false,
            message: 'Please input feedback title or delete this feedback.',
            validator: this.validateFeedbackTitle
          }
        ],
        initialValue: title
      })(<Input 
        spellCheck
        disabled={!creative && !editable}
        key={`feedback-title-input-${id ? id : uuid}`}
        placeholder='Feedback title'
        onChange={this.onTitleChange} 
        style={{width: '100%'}}
      />)}
      </Item>
      {Type.zero === type && <Checkbox disabled={!creative && !editable} checked={isOverall} onChange={this.onOverallChange}>Overall Feedback</Checkbox>}
      <Item
        required={false}
        key={`feedback-text-${id ? id : uuid}`}
        style={itemStyle}
      >
      {getFieldDecorator(`feedback-text-${id ? id : uuid}`, {
        validateTriggers: ['onChange', 'onBlur', 'onFocus'],
        rules: [
          {
            required: true,
            message: 'Please input feedback text or delete this feedback.',
            validator: this.validateFeedbackText
          }
        ]
      })(<FroalaCustomUpload 
        disabled={!creative && !editable}
        model={text}
        onChange={this.onTextChange}
      />)}
      </Item>
      {displayMinMax && 
      <span className='feedback-prop'>
        Point Range: <Input spellCheck disabled={!creative && !editable} size='small' value={min} min={0} onChange={this.onMinChange} style={{width: '20%'}}/> to <Input disabled={!creative && !editable} size='small' value={max} min={0} onChange={this.onMaxChange} style={{width: '20%'}}/>
      </span>}
    </div>);
  }
};

export default FeedbackItem;