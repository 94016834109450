import {
  faCommentAlt,
  faFlag as farFaFlag,
  faStar as farStar,
  faThumbsUp as farThumbsUp
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCommentAltLines,
  faFlag as fasFaFlag,
  faStar as fasStar,
  faThumbsUp as fasThumbsUp,
  faTrashAlt
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Button, Cascader, Col, Menu, message, Modal, Popover, Row, Tooltip, Typography} from 'antd';
import Comment from 'antd/lib/comment';
import Dropdown from 'antd/lib/dropdown';
import Icon from 'antd/lib/icon';
import update from 'immutability-helper';
import * as _ from 'lodash';
import moment from 'moment';
import React, {ChangeEvent, Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Dispatch} from 'redux';
import {createStructuredSelector} from 'reselect';
import uuid from 'uuid';
import SecureImage from '../../../components/image/SecureImage';
import {getStudyArmLabelFromId, renderPopoverDateFromNow} from '../../../components/util/Util';
import {
  hideContentAsync,
  HideContentPayloadType,
  resolveFlagsAsync,
  unhideContentAsync
} from '../../../redux/flags/flags.types';
import {deletePostAsync, savePostAsync} from '../../../redux/posts/posts.types';
import * as selectors from '../../../redux/selectors';
import {
  AvatarType,
  CommentType, CommentTypeWithReplies,
  FavoriteType,
  FlagType,
  ParticipantType,
  PostType,
  StudyArmType,
  ThumbsupType,
  TopicType
} from '../../../types/entity.types';
import IApplicationState from '../../../types/state.types';
import Username from '../../../components/username/Username';
import './forumPage.scss'
import {CascaderOptionType} from "antd/es/cascader";
import HMPTextArea from "../../../components/textarea/HMPTextArea";
import {CreateCommentParam} from "../../../redux/user/socialUtil";
import {createCommentAsync} from "../../../redux/comments/comments.types";

const { confirm } = Modal;
const { Paragraph } = Typography;

interface DispatchProps {
  resolveFlags: typeof resolveFlagsAsync.request,
  hideContent: typeof hideContentAsync.request,
  unhideContent: typeof unhideContentAsync.request,
  savePost: typeof savePostAsync.request,
  deletePost: typeof deletePostAsync.request,
  createComment: typeof createCommentAsync.request
}

const initialState = {
  posts: [] as Optional<PostType[]>,
  editedPost: undefined as Optional<PostType>,
  comments: [] as Optional<CommentType[]>,
  flags: [] as Optional<FlagType[]>,
  thumbsups: [] as Optional<ThumbsupType[]>,
  favorites: [] as Optional<FavoriteType[]>,
  avatars: [] as Optional<AvatarType[]>,
  topics: [] as Optional<TopicType[]>,
  selectedStyle: undefined as any,
  isEditing: false as Boolean,
  visibleFlagResolutionPopups: {'post': [], 'comment': []},
  visibleAddCommentPopups: {'post': [], 'comment': []},
  showTopicSelector: false as Boolean,
  studyArms: [] as StudyArmType[],
  studyId: -1 as number,
  selectedParticipantId: -1 as number,
  newCommentText: '' as string
};

interface StateProps {
  hasCareNavigatorRole: Optional<Boolean>;
  pseudoParticipants: Optional<ParticipantType[]>;
  participants: Optional<ParticipantType[]>;
  posts: Optional<PostType[]>,
  comments: Optional<CommentType[]>,
  flags: Optional<FlagType[]>,
  thumbsups: Optional<ThumbsupType[]>,
  favorites: Optional<FavoriteType[]>,
  avatars: Optional<AvatarType[]>,
  topics: Optional<TopicType[]>
  studyArms: Optional<StudyArmType[]>
  studyId: Optional<number>;
}

interface ComponentProps extends StateProps, DispatchProps {
  post: Optional<PostType>;
  mode: string;
}

type ComponentState = Readonly<typeof initialState>

function filter(inputValue: any, path: any): boolean {
  return path.some((option: any) => option.label ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 : false);
}

class Post extends Component<ComponentProps, ComponentState> {

  readonly state: ComponentState = initialState;

  handleTitleChange = ({ target: { value } }: {target: any}) => {
    this.setState(update(this.state, { editedPost: { title: { $set: value } } }));
  };

  handleBodyChange = ({ target: { value } }: {target: any}) => {
    this.setState(update(this.state, { editedPost: { body: { $set: value } } }));
  };

  handleCancelPost = () => {
    this.setState({ isEditing: false, editedPost: undefined });
  };

  getFlags = (type:string, typeId: number) => {
    return _.filter(this.props.flags, {'typeId': typeId, 'type': type});
  }

  commentCount = (type:string, typeId: number) => {
    const comments = _.filter(this.props.comments, {'typeId': typeId, 'type': type});
    return comments ? comments.length : 0;
  }

  renderParticipantLink = (value: any, row: any) => {
    const { studyId } = this.props;
    return <Link to={`/study/${studyId}/participants/${row.id}`}>{value}</Link>;
  };

  private makeUsernamesHover = (participants: ParticipantType[]) => {
    const { studyId } = this.props;
    const content:JSX.Element[] = [];
    if (!participants.length) {
      return <div key={uuid()}>none</div>
    }
    for (let i=0; i< participants.length; i++) {
      let p:ParticipantType = participants[i];
      content.push(<span key={p.id}><a href={`/study/${studyId}/participants/${p.id}/`}>{p.username}</a></span>);
      if (i !== participants.length-1) {
        content.push(<br key={p.id+'_br'}/>);
      }
    }
    return <div key={uuid()}>{...content}</div>
  };

  commentHover = (type:string, typeId: number) => {
    const comments = _.filter(this.props.comments, {'typeId': typeId, 'type': type});
    const participants:ParticipantType[] =  _.uniqBy(_.map(comments, 'participant'), 'id') as ParticipantType[];
    return this.makeUsernamesHover(participants);
  };

  thumbsupHover = (type: string, typeId: number) => {
    const thumbsups = _.filter(this.props.thumbsups, {'typeId': typeId, 'type': type});
    const participants:ParticipantType[] = _.uniqBy(_.map(thumbsups, 'participant'), 'id') as ParticipantType[];
    return this.makeUsernamesHover(participants);
  };

  thumbsupCount = (type: string, typeId: number) => {
    const thumbsups = _.filter(this.props.thumbsups, {'typeId': typeId, 'type': type});
    return thumbsups ? thumbsups.length : 0;
  }

  favoriteCount = (type: string, typeId: number) => {
    const favorites = _.filter(this.props.favorites, {'typeId': typeId, 'type': type});
    return favorites ? favorites.length : 0;
  }

  favoriteHover = (type: string, typeId: number) => {
    const favorites = _.filter(this.props.favorites, {'typeId': typeId, 'type': type});
    const participants = _.uniqBy(_.map(favorites, 'participant'), 'id') as ParticipantType[];
    return this.makeUsernamesHover(participants);
  };

  postFavoriteCount = (type: string, typeId: number) => {
    const favorites = _.filter(this.props.favorites, {'typeId': typeId, 'type': type});
    return favorites ? favorites.length : 0;
  }

  flagCount = (type: string, typeId: number) => {
    const flags = _.filter(this.props.flags, {'typeId': typeId, 'type': type});
    return flags ? flags.length : 0;
  }

  flagHover = (type: string, typeId: number) => {
    const flags = _.filter(this.props.flags, {'typeId': typeId, 'type': type});
    const participants = _.uniqBy(_.map(flags, 'participant'), 'id') as ParticipantType[];
    return this.makeUsernamesHover(participants);
  }

  handleDelete = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const { post, deletePost } = this.props;
    if(post) {
      const { id } = post;
      confirm({
        title: 'Are you sure you want to delete this post?',
        okText: 'Delete',
        okType: 'danger',
        onOk() {
          deletePost(id);
        },
        onCancel() {}
      });
    }
  }

  iconFlagSolid = (): any => {
    return <FontAwesomeIcon icon={fasFaFlag} />;
  };

  iconFlagOutline = (): any => {
    return <FontAwesomeIcon icon={farFaFlag} />;
  };

  iconThumbsupSolid = (): any => {
    return <FontAwesomeIcon icon={fasThumbsUp} />;
  };

  iconThumbsupOutline = (): any => {
    return <FontAwesomeIcon icon={farThumbsUp} />;
  };

  iconFavoriteSolid = (): any => {
    return <FontAwesomeIcon icon={fasStar} />;
  };

  iconFavoriteOutline = (): any => {
    return <FontAwesomeIcon icon={farStar} />;
  };

  iconCommentSolid = (): any => {
    return <FontAwesomeIcon icon={faCommentAltLines} />;
  };

  iconCommentOutline = (): any => {
    return <FontAwesomeIcon icon={faCommentAlt} />;
  };

  iconDelete = (): any => {
    return <FontAwesomeIcon icon={faTrashAlt} />;
  }

  copyDeepLink = (id: number) => {
    const deepLink = `hmp://forum/${id}`
    navigator.clipboard.writeText(deepLink).then(function() {
      message.success('Copied to clipboard!');
    }, function() {
      message.error('Failed to copy to clipboard.');
    });
  };

  handleSelectedParticipantChange = (value: string[]) => {
    this.setState( {selectedParticipantId: parseInt(value[0])});
  }

  renderPostItemHeader = (post: PostType) => {
    const { mode, topics, studyArms } = this.props;

    const menuClick = (event:any) => {
      post.topicId = event.key;
      this.props.savePost(post);
      this.setState({showTopicSelector: false});
    };
    const currentTopic:TopicType|undefined = _.find(topics, t => t.id == post.topicId);

    if (!currentTopic) return <div/>;

    const topicsInSameCollection = _.filter(topics, t => t.topicCollectionId === currentTopic.topicCollectionId);
    // @ts-ignore
    const menu = <Menu onClick={menuClick}>
      {_.map(topicsInSameCollection, (topic:TopicType) => {
        return <Menu.Item key={topic.id}>
          <a>
            {topic.title}
          </a>
        </Menu.Item>
      })}
    </Menu>;

    const avatarObj = post?.participant?.avatarId ? this.getAvatar(post.participant?.avatarId) : undefined;

    const avatar = avatarObj ?
      <Avatar src={`data:image/png;base64,${avatarObj.avatar}`} style={{backgroundColor: '#'+post?.participant?.avatarBackground}} />
      : undefined;

    const redFlagCssStyle = this.hasUnresolvedFlags('post', post.id) ? 'red-flag' : '';

    let tags = [];
    if(post.deleteDate !== null) {
      tags.push(<span title={moment(post.deleteDate).calendar()} className='post-tag-bubble deleted'>DELETED</span>);
    }
    if(post.isHidden) {
      tags.push(<span title='hidden' className='post-tag-bubble hidden'>HIDDEN</span>);
    }
    // @ts-ignore
    return (
      <div id="post-header-container">
        <div className='title-container'>
          <p id="title" className={'full' !== mode ? 'title-truncate' : 'title-no-truncate'}>
            {post.title}
          </p>
          <div className='title-extra'>
            <a title='Copy deep link to clipboard' className='forum-post-link' onClick={(e) => { e.stopPropagation(); this.copyDeepLink(post.id); }}>
              <i className="far fa-link fa-sm"></i>
            </a>
            {tags}
          </div>
        </div>
        <span id="arm" className="small-font">
          {getStudyArmLabelFromId(studyArms, post.studyArmId)}
        </span>
        <div id="after-title-container">
          <span id="avatar">
            {avatar}
          </span>
          <p id="username" className="small-font">
            <Username participantId={post.participant?.id} />
          </p>
          <hr id="horizontal-rule" />
          <p id="create-date" className="small-font">
            {renderPopoverDateFromNow(post.createDate)}
          </p>
          <p id="category" className="small-font">
            <span
              // @ts-ignore
              onClick={(evt:MouseEvent) => {
                evt.preventDefault();
                if ('edit' === this.props.mode)
                  this.setState({showTopicSelector: false})
              }}>
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button>{post.topic}</Button>
            </Dropdown>
          </span>
          </p>

          <div id="social-container">
            <Tooltip placement="bottom" title={this.flagHover('post', post.id)}>
              <Popover
                content={this.flagResolveMenu('post', post)}
                title={`Resolve Flag`}
                trigger="click"
                // @ts-ignore
                visible={this.isFlagResolutionPopupVisible('post', post.id)}
                onVisibleChange={(visible:boolean) => this.handleFlagResolutionPopupVisibleChange(visible, 'post', post.id)}
              >
                <span><span className={`${redFlagCssStyle}`}>{ this.flagCount('post', post.id) } <Icon style={{ color: '#8185B3', marginRight: 15 }} component={this.iconFlagOutline}/></span></span>
              </Popover>
            </Tooltip>

            <Tooltip placement="bottom" title={this.commentHover('post', post.id)}>
              <Popover
                content={this.createCommentMenu('post', post)}
                title={`Add Comment`}
                trigger="click"
                // @ts-ignore
                visible={this.isAddCommentPopupVisible('post', post.id)}
                onVisibleChange={(visible:boolean) => this.handleAddCommentPopupVisibleChange(visible, 'post', post.id)}
              >
                {this.commentCount('post', post.id).toString()}  <Icon style={{ color: '#8185B3', marginRight: '10px'}} component={this.iconCommentOutline}/>
              </Popover>
            </Tooltip>
            <Tooltip placement="bottom" title={this.thumbsupHover('post', post.id)}> {this.thumbsupCount('post', post.id).toString()} <Icon style={{ color: '#8185B3', marginRight: '8px' }} component={this.iconThumbsupOutline}/> </Tooltip>
            <Tooltip placement="bottom" title={this.favoriteHover('post', post.id)}> {this.favoriteCount('post', post.id).toString()} <Icon style={{ color: '#8185B3', marginRight: '10px' }} component={this.iconFavoriteOutline}/> </Tooltip>
            <Tooltip placement="bottom" title='Delete'><Icon style={{ color: '#8185B3', marginRight: '10px' }} component={this.iconDelete} onClick={this.handleDelete} /></Tooltip>
          </div>
        </div>
      </div>
    );
  };

  // renderPollBody = (post: PostType) => {
  //   if (post && post.poll) {
  //     @ts-ignore
      // return  <div>
      //   <p id="body-text" className={`${fadeCssClass} ${hiddenCssClass}`}>
      //     {post.body}
      // </div>
      //   <PollChart height={200} width={200} data={post.poll.options} />
      // </div>
    // }
    // else return <div>Wierd error: no post.poll.options</div>
  // }

  getAvatar = (avatarId: number) => {
    return _.find(this.props.avatars, a => a.id === avatarId);
  }

  hasUnresolvedFlags = (type:string, typeId: number) => {
    const flags = this.getFlags(type, typeId);
    return _.find(flags, f => !f.resolution);
  }

  resolveFlags = (type:string, typeId: number, resolution: string) => {
    const flags = this.getFlags(type, typeId);
    if (flags?.length) {
      const ids = _.map(flags, 'id');
      this.props.resolveFlags({ids, resolution});
    }
  }

  hideContent = (type:string, typeId: number) => {
    this.props.hideContent({type, typeId});
    this.resolveFlags(type, typeId, 'confirmed')
  }

  unhideContent = (type:string, typeId: number) => {
    this.props.unhideContent({type, typeId});
  }

  onCommentTextChange = (e: ChangeEvent<HTMLAreaElement>) => {
    // @ts-ignore
    this.setState({newCommentText: e.target.value});
  }

  createComment = (type: string, typeId: number, comment: string, parentCommentId: number) => {
    const { createComment, pseudoParticipants } = this.props;
    let { selectedParticipantId:participantId } = this.state;
    if (participantId === -1 && pseudoParticipants?.length) {
      participantId = pseudoParticipants[0].id;
    }
    createComment({type, typeId, comment, parentCommentId, participantId });
    this.setState({newCommentText: '', selectedParticipantId: -1});
    this.handleAddCommentPopupVisibleChange(false, type, typeId);
  }

  createCommentMenu: any = (type:string, postOrComment: PostType | CommentType) => {
    const {
      hasCareNavigatorRole,
      pseudoParticipants,
      participants
    } = this.props;

    const {
      newCommentText,
      selectedParticipantId
    } = this.state;

    const studyArmId = type === 'post' ? postOrComment.studyArmId : -1;

    const pseudosThisArm = _.filter(pseudoParticipants, p => !!p.username && p.studyArmId === studyArmId );

    if (!pseudosThisArm?.length && !hasCareNavigatorRole) {
      return <div>There are no pseudo participants associated with your account</div>
    }
    const participantSelectorOptions:CascaderOptionType[] =
      hasCareNavigatorRole ?
        _.map(_.filter(participants, p => !!p.username && p.studyArmId === studyArmId && p.type === 'PSEUDO PARTICIPANT'), participant => {
          return {label: participant.username, value: participant.id.toString()};
        })
      :
         _.map(pseudosThisArm, participant => {
          return {label: participant.username, value: participant.id.toString()};
        });

    return (
      <div id="create-comment-popover">
        <div id="button-row">
          <p>Add As</p>
          {hasCareNavigatorRole || pseudosThisArm.length > 1 ?
            <Cascader
              placeholder="Enter participant's username"
              options={participantSelectorOptions}
              // @ts-ignore
              onChange={this.handleSelectedParticipantChange}
              showSearch={{ filter }}
            />
            :
            <p>{pseudosThisArm[0].username}</p>
          }
          <Button
            disabled={!newCommentText || !selectedParticipantId}
            onClick={() =>
              this.createComment(
                type,
                postOrComment.id,
                newCommentText,
                // @ts-ignore
                type === "comment" ? postOrComment?.parentCommentId : undefined
              )}>
            Submit
          </Button>
        </div>
        <HMPTextArea value={newCommentText}
                     minRows={15}
                     // @ts-ignore
                     onChange={this.onCommentTextChange}
                     spellCheck={true}
                     placeholder="Enter your comment"></HMPTextArea>
      </div> );
  }

  flagResolveMenu: any = (type:string, postOrComment:PostType | CommentType) => {
    const {flags, posts, comments, studyId} = this.props;

    const theirPosts = _.filter(posts, (p:PostType) => p.createdByParticipantId === postOrComment.participant?.id);
    const theirFlaggedPosts = _.filter(theirPosts, (p:PostType) => _.find(flags, f => f.flaggedParticipantId === p.createdByParticipantId));
    const theirHiddenPosts = _.filter(theirPosts, (p:PostType) => p.isHidden);
    const theirComments = _.filter(comments, (c:CommentType) => c.participantId === postOrComment.participant?.id);
    const theirFlaggedComments = _.filter(theirComments, (c:CommentType) => _.find(flags, f => f.flaggedParticipantId === c.participantId));
    const theirHiddenComments = _.filter(theirComments, (c:CommentType) => c.isHidden);

    const flagsForThisContent = _.filter(flags, (f:FlagType) => f.type === type && f.typeId === postOrComment.id);

    return <div id="flag-resolve-popover">
      <div id="button-row">
        {postOrComment.isHidden ?
          <Button onClick={() => this.unhideContent(type, postOrComment.id)}> Unhide Content </Button> :
          <Button onClick={() => this.hideContent(type, postOrComment.id)}> Hide Content </Button>
        }
        {flagsForThisContent.length ?
          <Button onClick={() => this.resolveFlags(type, postOrComment.id, 'ignored')}>Ignore Flags</Button>
          : undefined
        }
      </div>

      <div id="title-row">
        <p><a href={`/study/${studyId}/participants/${postOrComment.participant?.id}/forum`}>{postOrComment.participant?.username}'s Flag Stats</a></p>
      </div>
      <div id="table-row">
        <table id="table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className='center'>flagged</th>
              <th className='center'>hidden</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Posts</td>
              <td>{theirPosts.length}</td>
              <td className='center'>{theirFlaggedPosts.length}</td>
              <td className='center'>{theirHiddenPosts.length}</td>
            </tr>
            <tr>
              <td>Comments</td>
              <td>{theirComments.length}</td>
              <td className='center'>{theirFlaggedComments.length}</td>
              <td className='center'>{theirHiddenComments.length}</td>
            </tr>
            <tr>
              <td className='bottom-row'></td>
              <td className='bottom-row'>{theirPosts.length + theirComments.length}</td>
              <td className='center bottom-row'>{theirFlaggedPosts.length + theirFlaggedComments.length}</td>
              <td className='center bottom-row'>{theirHiddenPosts.length + theirHiddenComments.length}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>;
  }

  renderCommentActions = (comment: CommentType) => {
    return ([
      <Tooltip placement="bottom" title={this.flagHover('comment', comment.id)}>
        <Popover
          content={this.flagResolveMenu('comment', comment)}
          title={`Resolve Flag`}
          trigger="click"
          // @ts-ignore
          visible={this.isFlagResolutionPopupVisible('comment', comment.id)}
          onVisibleChange={(visible:boolean) => this.handleFlagResolutionPopupVisibleChange(visible, 'comment', comment.id)}
          >
           {this.flagCount('comment', comment.id).toString()}  <Icon style={{ color: '#8185B3', marginRight: 15 }} component={this.iconFlagOutline}/>
          </Popover>
      </Tooltip>,
      <Tooltip placement="bottom" title={this.thumbsupHover('comment', comment.id)}>
        {this.thumbsupCount('comment', comment.id).toString()}  <Icon style={{ color: '#8185B3', marginRight: 15 }} component={this.iconThumbsupOutline}/>
      </Tooltip>,
      <Tooltip placement="bottom" title={this.favoriteHover('comment', comment.id)}>
        {this.favoriteCount('comment', comment.id).toString()}  <Icon style={{ color: '#8185B3', marginRight: 15 }} component={this.iconFavoriteOutline}/>
      </Tooltip>
    ]);
  }

  renderCommentsSection = (post:PostType) => {
    const {comments, studyId} = this.props;
    const postComments: CommentType[] = _.filter(comments, { 'type': 'post', 'typeId': post.id });
    const sortedComments: CommentType[] = _.reverse(_.sortBy(postComments, 'createDate'));
    sortedComments.forEach((c:CommentTypeWithReplies) => c.replies = []);
    const orderedComments = _.reduceRight(sortedComments, (arr:any[], comment: CommentType) => {
      if (comment.parentCommentId) {
        const parentComment = _.find(arr, c => c.id === comment.parentCommentId);
        parentComment.replies.splice(0, 0, comment);
      }
      else {
        arr.splice(0, 0, comment);
      }
      return arr;
    }, []);
    const renderContent = (comment:CommentType) => {
      return <span>{comment.body}</span>
    }

    const renderComments = (comments:any[]) => {
      return <div>
        {_.map(comments, comment => {
          const commentThumbsUpCount = this.thumbsupCount('comment', comment.id)
          const avatarObject = comment.participant?.avatarId ? this.getAvatar(comment.participant.avatarId) : undefined;
          const avatar = <Avatar src={"data:image/png;base64,"+avatarObject?.avatar} style={{backgroundColor: '#'+comment.participant.avatarBackground}} />;
          let tags = [];
          if(comment.isHidden) {
            tags.push(<span title='hidden' className='post-tag-bubble hidden'>HIDDEN</span>);
          }
          return <span key={comment.id}>
                <Comment key={comment.id}
                         avatar={avatar}
                         author={<span style={{color: '#ccc'}}><a href={`/study/${studyId}/participants/${comment.participant?.id}/`}>{comment.username}</a>{tags}</span>}
                         content={renderContent(comment)}
                         datetime={ <span>{renderPopoverDateFromNow(comment.createDate)}</span>}
                         actions={this.renderCommentActions(comment)}
                >
                  {renderComments(comment.replies)}
                </Comment>
      </span>
        })}
      </div>
    };

    return renderComments(orderedComments);

  };

  isFlagResolutionPopupVisible = (type:string, typeId: number) => {
    const { visibleFlagResolutionPopups } = this.state;
    return -1 !== visibleFlagResolutionPopups[type].indexOf(typeId);
  }

  handleFlagResolutionPopupVisibleChange = (visible:boolean, type:string, typeId:number) => {
    let updated:any = _.cloneDeep(this.state.visibleFlagResolutionPopups);
    if (visible) {
      updated[type].push(typeId);
    }
    else {
      updated[type] = _.without(updated[type], typeId);
    }
    this.setState({visibleFlagResolutionPopups: updated});
  }

  isAddCommentPopupVisible = (type:string, typeId: number) => {
    const { visibleAddCommentPopups } = this.state;
    return -1 !== visibleAddCommentPopups[type].indexOf(typeId);
  }

  handleAddCommentPopupVisibleChange = (visible:boolean, type:string, typeId:number) => {
    let updated:any = _.cloneDeep(this.state.visibleAddCommentPopups);
    if (visible) {
      updated[type].push(typeId);
    }
    else {
      updated[type] = _.without(updated[type], typeId);
    }
    this.setState({visibleAddCommentPopups: updated});
  }

  render() {
    const { post, selectedStyle, mode } = this.props;

    if (!post) return <div>error</div>

    const avatar = post.participant?.avatarId ? this.getAvatar(post.participant.avatarId) : undefined;

    const fadeCssClass = 'partial' === mode ? 'three-line-fade' : '';
    const mediaCssClass = `partial` === mode ? 'body-media-partial' : '';
    const alertBoxCss = post.isHidden ? 'alert-box' : '';

    let body = undefined;
    if (post.poll) {
      const onelinefade = 'partial' === mode ? 'one-line-fade' : '';
      const options = 'partial' === mode ? _.slice(post.poll.options, 0, 2) : post.poll.options;
      body = <div>
        <p id="body-text" className={`${onelinefade}`}>
          {post.body}
        </p>
        <div>
          {_.map(options, item => {
            return <span key={item.option}> {item.option}: {item.count}<br/></span>
          })}
          {options.length === post.poll.options.length ? undefined :
            <span> {post.poll.options.length - options.length} more </span>
          }
        </div>
      </div>
    }
    else if (post.postLinks) {

      // there can only be one entry in the array postLinks per the current design
      const postLink = post.postLinks[0];

      if (postLink.type === 'web')
      {
        body = <div>
            <p id="body-text" className={`${fadeCssClass}`}>
              {post.body}
            </p>
            <a href={postLink.url}>{postLink.url}</a>
          </div>;
      }
      else if (postLink.type === 'image') {
        const url = _.replace(postLink.url, '/c/upload/', '/a/upload/') + `?participantId=${post.createdByParticipantId}`;
        const imageStyle = 'partial' === mode ? {height: '100%'} : {height: 'auto', width: '100%'};
        body = <div id='body-media' className={`${mediaCssClass}`}>
          <div style={imageStyle} className={`${alertBoxCss}`}>
              <SecureImage url={url}  />
            </div>
            <p id="body-text" className={`${fadeCssClass}`}>
              {post.body}
            </p>
        </div>
      }
      else if (postLink.type === 'video')
      {
        const url = _.replace(postLink.url, 'https://youtu.be/','https://www.youtube.com/embed/' )
        // @ts-ignore
        body = (
          <div id="body-media">
            <div id="ytContainer" className={`${'partial' === mode ? 'partial-container' : 'full-container'}`}>
              <iframe id="ytplayer" className={`${alertBoxCss} ${'partial' === mode ? 'partial-player' : 'full-player'}`} type="text/html"  src={url} frameBorder="0"></iframe>
            </div>
            <p id="body-text" className={`${fadeCssClass}`}>
              {post.body}
            </p>
          </div>);
      }
      else {
        body = <p>Unhandled Post Link Type: {postLink.type}</p>
      }
    } else {
      body = <p id="body-text" className={`${fadeCssClass}`}>
        {post.body}
      </p>
    }

    return <div id="post-container" key={post.id} style={{ ...selectedStyle }} >
      <Row>
        <Col>
          {this.renderPostItemHeader(post)}
        </Col>
      </Row>
      <Row>
       <Col>
         <div id="post-body-container">
           {body}
         </div>
       </Col>
      </Row>
      <Col>
        <div>
          {this.props.mode === 'full' ? this.renderCommentsSection(post) : <div/> }
        </div>
      </Col>
    </div>
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
    hasCareNavigatorRole: selectors.hasCareNavigatorRole,
    pseudoParticipants: selectors.getCurrentUserPseudoParticipants,
    participants: selectors.getParticipants,
    posts: selectors.getPosts,
    comments: selectors.getComments,
    flags: selectors.getFlags,
    favorites: selectors.getFavorites,
    thumbsups: selectors.getThumbsups,
    avatars: selectors.getAvatars,
    topics: selectors.getFlattenedForumTopics,
    studyArms: selectors.getStudyArms,
    studyId: selectors.getRequestedStudyId
});

const mapDispatchToProps = (dispatch: Dispatch) : DispatchProps => {
  return {
    resolveFlags: (flagResolution: {ids: number[], resolution: string}) => dispatch(resolveFlagsAsync.request(flagResolution)),
    hideContent: (payload: HideContentPayloadType) => dispatch(hideContentAsync.request(payload)),
    unhideContent: (payload: HideContentPayloadType) => dispatch(unhideContentAsync.request(payload)),
    savePost: (post: PostType) => dispatch(savePostAsync.request(post)),
    deletePost: (id: number) => dispatch(deletePostAsync.request(id)),
    createComment: (param: CreateCommentParam) => dispatch(createCommentAsync.request(param))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Post);
