import Card from 'antd/lib/card';
import Progress from 'antd/lib/progress';
import React from 'react';
import './progressStatCard.scss';

interface ProgressStatCardProps {
  color?: string,
  count: number,
  subTitle?: string,
  title?: string,
  total: number,
}

const ProgressStatCard = (props: ProgressStatCardProps) => {

  const { color, count, subTitle, title, total } = props;

  const percentage = Math.round((count / total) * 100);

  return (
    <Card className="hmp-progress-stat-card">
      <div className="header">
        <span className="title">{title}</span>
        <span className="count" style={{color: color}}>{count}</span>
      </div>
      <div className="sub-title">{subTitle}</div>
      <Progress className="progress-bar" percent={percentage} strokeColor={color} />
    </Card>
  )
};

export default ProgressStatCard;