import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';
import { renderDateWithTime, renderDateWithTimeTwelveHourPeriod } from '../../../components/util/Util';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import { getAnnouncementsAsync, createAnnouncementAsync, updateAnnouncementAsync } from '../../../redux/announcements/announcements.types';
import { AnnouncementType, StudyArmType } from '../../../types/entity.types';
import './announcement.scss';
import { IApiRequestStatus } from 'types/api.types';
import { Card, Table, Button, message, Input } from 'antd';
import AnnouncementForm from '../../../components/announcement/AnnouncementForm';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { clearStatus } from '../../../redux/api/api.types';
import { getType } from 'typesafe-actions';
const { Column } = Table;
const { Search } = Input;

interface StateProps {
  studyId: number,
  announcements: Optional<AnnouncementType[]>,
  arms: Optional<StudyArmType[]>,
  loadAnnouncementsStatus: IApiRequestStatus,
  createAnnouncementStatus: IApiRequestStatus,
  updateAnnouncementStatus: IApiRequestStatus
}

interface DispatchProps {
  loadAnnouncements: typeof getAnnouncementsAsync.request,
  clearLoadStatus: typeof clearStatus
}

interface ComponentProps extends StateProps, DispatchProps {}

class AnnouncementLandingPage extends Component<ComponentProps, {}> {
  readonly state = {
    currentId: -1,
    viewForm: false,
    searchTerm: ''
  };
  private statusFilters = [
    { text: 'Past', value: 'Past'},
    { text: 'Current', value: 'Current'},
    { text: 'Future', value: 'Future'},
  ];
  componentDidMount() {
    const { studyId, loadAnnouncements } = this.props;
    loadAnnouncements(studyId);
  }
  rowClassName = (record: any, index: number): string => index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  sorterAlphabetical = (a: AnnouncementType, b: AnnouncementType) => a.title.localeCompare(b.title);
  sorterStartDate = (a: any, b: any) => moment(a.effectiveStartDate).unix() - moment(b.effectiveStartDate).unix();
  sorterEndDate = (a: any, b: any) => {
    if(!a.effectiveEndDate && !b.effectiveEndDate) {
      return 0;
    } else if(!a.effectiveEndDate) {
      return -1;
    } else if(!b.effectiveEndDate) {
      return 1;
    } else {
      return moment(a.effectiveEndDate).unix() - moment(b.effectiveEndDate).unix();
    }
  };
  renderStatus = (text, record, index) => {
    const { announcements } = this.props;
    if(moment(record?.effectiveStartDate).isAfter() && moment(record?.effectiveEndDate).isAfter()) {
      return 'Future';
    } else if(moment(record?.effectiveStartDate).isSameOrBefore() && moment(record?.effectiveEndDate).isSameOrAfter()) {
      return 'Current';
    } else {
      let isLatest = false;
      if(!record?.effectiveEndDate) {
        isLatest = !announcements?.some(a => moment(a.effectiveStartDate).isAfter(record?.effectiveStartDate));
      }
      return isLatest ? 'Current' : 'Past';
    }
  };
  renderActions = (value: number) => {
    return <div className='announcement-action-container'>
      <a title='Edit' className='announcement-action' onClick={(e) => { e.stopPropagation(); this.openForm(value); }}>
        <i className="far fa-edit fa-lg"></i>
      </a>
    </div>;
  };
  openForm = (value?: number) => {
    this.setState({
      currentId: value ? value : this.state.currentId,
      viewForm: true
    });
  };
  expandedRowRenderer = (record: any) => <FroalaEditorView model={record.body}/>;
  onFilterStatus = (value: any, record: any) => {
    const { announcements } = this.props;
    if(record) {
      switch(value) {
        case 'Past':
          return moment(record.effectiveStartDate).isBefore() && (moment(record.effectiveEndDate).isBefore() || announcements?.some(a => moment(a.effectiveStartDate).isAfter(record.effectiveStartDate)));
        case 'Current':
          return record.effectiveEndDate ? moment(record.effectiveStartDate).isSameOrBefore() && moment(record.effectiveEndDate).isSameOrAfter() : !announcements?.some(a => moment(a.effectiveStartDate).isAfter(record.effectiveStartDate));
        case 'Future':
          return moment(record.effectiveStartDate).isAfter() && moment(record.effectiveEndDate).isAfter();
        default:
          return false;
      }
    }
    return false;
  };
  onFilterArms = (value: any, record: any) => {
    if(record) {
      return record.type + '-' + record.typeId === value;
    }
    return false;
  };
  closeForm = () => {
    this.setState({
      viewForm: false,
      currentId: -1,
    });
  };
  capitalize = (s: string): string => s ? s.split(' ').map(word => word[0].toUpperCase() + word.substring(1)).join(' ') : s;
  renderTypeTypeId = (text, record, index) => {
    return <span>{this.capitalize(record.type) + ' ' + record.typeId}</span>;
  };
  search = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: e.target.value });
  };
  handleRowClick = (record: AnnouncementType, index: number, event: Event) => {
    if(record && record.id) {
      this.setState({
        currentId: record.id,
        viewForm: true
      });
    }
  };
  render() {
    const {
      announcements,
      arms,
      studyId,
      createAnnouncementStatus,
      updateAnnouncementStatus,
      clearLoadStatus
    } = this.props;
    const { currentId, viewForm, searchTerm } = this.state;
    const currentAnnouncement = announcements?.find(a => a.id === currentId);
    const regex = new RegExp(searchTerm, 'i');
    const displayedAnnouncements = searchTerm && searchTerm.length ? _.filter(announcements, a => regex.test(a.title) || regex.test(a.body)) : announcements;
    const armsFilters = arms ? arms.map(arm => {
      return { text: `Arm ${arm.id}`, value: `arm-${arm.id}` };
    }) : [];
    armsFilters.push({ text: `Study ${studyId}`, value: `study-${studyId}` });
    if(createAnnouncementStatus.isError || updateAnnouncementStatus.isError) {
      message.error('Announcement conflicts with another existing announcement start and end date.');
      clearLoadStatus(getType(createAnnouncementAsync.failure));
      clearLoadStatus(getType(updateAnnouncementAsync.failure));
    }
    if(createAnnouncementStatus.isSuccess || updateAnnouncementStatus.isSuccess) {
      message.success('Announcement successfully created.');
      clearLoadStatus(getType(createAnnouncementAsync.success));
      clearLoadStatus(getType(updateAnnouncementAsync.success));
    }
    return (
    <Card
      title={
        <div className='announcement-title'>
          <h1>Announcements</h1>
          <Button type='primary' onClick={(e) => { e.stopPropagation(); this.openForm(); }}>+ Add Announcement</Button>
        </div>
      }>
        {viewForm &&
        <AnnouncementForm
          announcement={currentAnnouncement}
          visible={viewForm}
          closeHandler={this.closeForm}
        />}
        <Search
          placeholder="Search text"
          onChange={this.search}
          style={{ width: 200, marginBottom: '10px' }}
        />
        <Table
          dataSource={displayedAnnouncements}
          rowKey="id"
          rowClassName={this.rowClassName}
          expandedRowRender={this.expandedRowRenderer}
          scroll={{ y: 500 }}
          pagination={false}
          onRowClick={this.handleRowClick}
        >
          <Column title="Type" dataIndex="type" key="type" render={this.renderTypeTypeId} filters={armsFilters} onFilter={this.onFilterArms} />
          <Column title="Title" dataIndex="title" key="title" sorter={this.sorterAlphabetical} />
          <Column title="Start Date" dataIndex="effectiveStartDate" key="effectiveStartDate" render={renderDateWithTimeTwelveHourPeriod} sorter={this.sorterStartDate} defaultSortOrder='descend' />
          <Column title="End Date" dataIndex="effectiveEndDate" key="effectiveEndDate" render={renderDateWithTimeTwelveHourPeriod} sorter={this.sorterEndDate} />
          <Column title="Status" dataIndex="typeId" key="typeId" render={this.renderStatus} filters={this.statusFilters} onFilter={this.onFilterStatus} />
          <Column title="" dataIndex="id" key="id" render={this.renderActions} />
        </Table>
      </Card>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    studyId: selectors.getRequestedStudyId(state),
    arms: selectors.getRequestedStudyStudyArms(state),
    announcements: selectors.getAnnouncements(state),
    loadAnnouncementsStatus: selectors.loadAnnouncementsStatus(state),
    createAnnouncementStatus: selectors.createAnnouncementStatus(state),
    updateAnnouncementStatus: selectors.updateAnnouncementStatus(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadAnnouncements: (studyId: number) => dispatch(getAnnouncementsAsync.request(studyId)),
    clearLoadStatus: (type: string) => dispatch(clearStatus(type))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementLandingPage);