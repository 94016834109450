import { ActionType, createAsyncAction } from 'typesafe-actions';
import {FavoriteType} from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';
import { SocialJsonParamType } from '../user/socialUtil';

export const getFavoritesAsync = createAsyncAction(
  'hmp/admin/GET_FAVORITES_REQUEST',
  'hmp/admin/GET_FAVORITES_SUCCESS',
  'hmp/admin/GET_FAVORITES_FAILURE'
)<SocialJsonParamType[], Optional<NormalizedType<FavoriteType>>, Error>();


const thumbsupsActions = {
  getFavoritesAsync
};

export type FavoritesActionTypes = ActionType<typeof thumbsupsActions>;
