import axios from "../api";
import {AxiosResponse} from "axios";
import {all, call, fork, put, takeEvery} from "@redux-saga/core/effects";
import {normalize} from "normalizr";
import {entitySchema} from "../schema";
import {NormalizerResult} from "../../types/entity.types";
import { SocialJsonParamType } from '../user/socialUtil';
import {getThumbsupsAsync} from "./thumbsups.types";
import {getType} from "typesafe-actions";

const getThumbsups = (jsonThumbups:SocialJsonParamType[]) => {
  const param = JSON.stringify(jsonThumbups);
  return axios({
    method: 'get',
    url: `/a/social/thumbsups?thumbsupsJson=${encodeURIComponent(param)}`
  });
};

function* getThumbsupsHandler(action: ReturnType<typeof getThumbsupsAsync.request>): Generator {

  try {
    const { payload } = action;
    const response: AxiosResponse = <AxiosResponse> (yield call(getThumbsups, payload));
    const { entities } = normalize(response.data, entitySchema.thumbsups) as NormalizerResult;
    const { thumbsups } = entities;

    yield put(getThumbsupsAsync.success(thumbsups));
  } catch (error) {
    yield put(getThumbsupsAsync.failure(error));
  }
}

function* getThumbsupsWatcher() {
  yield takeEvery(getType(getThumbsupsAsync.request), getThumbsupsHandler);
}

export default function* thumbsupSaga() {
  yield all([fork(getThumbsupsWatcher)]);
}
