import { AxiosRequestConfig } from 'axios';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import {
  applyMiddleware, compose, createStore, Store
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { loadUser } from 'redux-oidc';
import createSagaMiddleware from 'redux-saga';
import userManager from '../oidc/UserManager';
import axios from './api';
import rootReducer from './reducers';
import rootSaga from './sagas';
import IApplicationState from '../types/state.types';

export default function configureStore(history: History): Store<any> {

  let composeEnhancers = compose;

  if (process.env.NODE_ENV !== 'production') {
    composeEnhancers = composeWithDevTools({});
  }

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer(history),
    {},
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);
  loadUser(store, userManager);

  // create axios interceptor to add the Access Token to every request header
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const { user } = (store.getState() as IApplicationState).oidc;
    if (user) {
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  return store;
}
