import { AxiosResponse } from 'axios';
import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import { NormalizerResult } from '../../types/entity.types';
import { awardParticipantBadgeAsync, getParticipantBadgesAsync } from './participantBadges.types';

const getParticipantBadges = (participantId:number) => {
  return axios({
    method: 'get',
    url: `/a/badge/getBadgesForParticipant?participantId=${participantId}`
  });
};

const awardParticipantBadge = (participantId:number, badgeCollectionId: number) => {
  return axios({
    method: 'put',
    url: `/a/badge/awardBadgeToParticipant/${badgeCollectionId}/${participantId}`
  });
};

function* getParticipantBadgesHandler(action: ReturnType<typeof getParticipantBadgesAsync.request>): Generator {

  try {
    const participantId = action.payload;
    const response: AxiosResponse = (yield call(getParticipantBadges, participantId)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.badges) as NormalizerResult;
    const { badges } = entities;

    yield put(getParticipantBadgesAsync.success(badges));
  } catch (error) {
    yield put(getParticipantBadgesAsync.failure(error));
  }
}

function* awardParticipantBadgeHandler(action: ReturnType<typeof awardParticipantBadgeAsync.request>): Generator {

  try {
    const { participantId, badgeCollectionId} = action.payload;
    const response: AxiosResponse = (yield call(awardParticipantBadge, participantId, badgeCollectionId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.badges) as NormalizerResult;
    const { badges } = entities;

   // yield put(awardParticipantBadgeAsync.success(badges));

    yield put(getParticipantBadgesAsync.request(participantId))

  } catch (error) {
    yield put(awardParticipantBadgeAsync.failure(error));
  }
}

function* getParticipantBadgesWatcher() {
  yield takeLatest(getType(getParticipantBadgesAsync.request), getParticipantBadgesHandler);
}

function* awardParticipantBadgeWatcher() {
  yield takeLatest(getType(awardParticipantBadgeAsync.request), awardParticipantBadgeHandler);
}

export default function* participantBadgeSaga() {
  yield all([fork(getParticipantBadgesWatcher),
                    fork(awardParticipantBadgeWatcher)]);
}
