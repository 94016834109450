import React, { Component } from "react"
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AdminUserType, ThreadMemberType } from "types/entity.types";
import IApplicationState from 'types/state.types';
import uuid from 'uuid';
import * as selectors from '../../redux/selectors';

interface StateProps {
  admin: Optional<AdminUserType[]>;
}

interface ComponentProps extends StateProps {
  members: ThreadMemberType[];
}

class ThreadOwnerBubbles extends Component<ComponentProps,{}> {
  render() {
    const { admin, members } = this.props;
    const assignedUsers = members ? members.filter(member => member.isAssigned).map(member => admin?.find(a => a.id == member.userId)): [];
    if(!assignedUsers) {
      return <div />;
    }
    return <div className='thread-assignee-container'>
      {assignedUsers.map(user => {
        if(!user) {
          return <div key={uuid()} />;
        }
        const {
          firstName,
          lastName
        } = user;
        const initials: string = firstName && lastName ? firstName[0] + lastName[0] + lastName[1] : firstName ? firstName.substring(0,3) : lastName ? lastName.substring(0,3) : 'N/A';
        return <div key={`thread_owner_bubble_${user.id}`} className='thread-assignee-bubble'><span>{initials.toUpperCase()}</span></div>
      })}
    </div>;
  }
}

const mapStateToProps =  createStructuredSelector<IApplicationState, StateProps>({
  admin: selectors.getAdmins
});

export default connect(mapStateToProps)(ThreadOwnerBubbles);