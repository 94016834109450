import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Radio from 'antd/lib/radio';
import Form, { FormComponentProps } from 'antd/lib/form';
import Input from 'antd/lib/input';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { Component } from 'react';
import { DatePicker } from 'antd';
import FormContextProvider from '../../../../components/form/FormContextProvider';
import { ConsentStatusType, ParticipantType, StudyArmType } from "../../../../types/entity.types";
import Select from "antd/lib/select";

interface ComponentProps extends FormComponentProps {
  studyArm: StudyArmType,
  consentStatuses: ConsentStatusType[],
  initialParticipant: ParticipantType,
  saveClickHandler: (participant: ParticipantType) => void,
  cancelClickHandler: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

interface ComponentState {
  participant: ParticipantType
}

class ParticipantEditCard extends Component<ComponentProps, ComponentState> {

  readonly state = {
    participant: cloneDeep(this.props.initialParticipant)
  };

  handleSubmit = (e: any )=> {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.saveClickHandler(this.state.participant);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      studyArm, saveClickHandler, cancelClickHandler, consentStatuses
    } = this.props;

    const { participant } = this.state;

    const dateFormat = 'MM/DD/YYYY';

    const { TextArea } = Input;

    const { Option } = Select;

    return (
      <Card key="edit">
        <FormContextProvider>
          <Form key={participant.id} onSubmit={this.handleSubmit}>
            <div className="profile">
              <div className="profile-container">
               <div className="demo">
                  <div className="demo-section">
                    <Form.Item label="First Name">
                      {getFieldDecorator('firstName', {
                        rules: [{required: true, message: 'First name is required!'}],
                        initialValue: participant.firstName
                      })(<Input onChange={v => { participant.firstName = v.target.value; }} />)}
                    </Form.Item>
                    <Form.Item label="Last Name">
                      <Input defaultValue={participant.lastName} onChange={v => { participant.lastName = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Username">
                      <label>{participant.username}</label>
                    </Form.Item>
                    <Form.Item label="Email">
                      {getFieldDecorator('email', {
                        rules: [{required: true, message: 'Email is required!'}],
                        initialValue: participant.email
                      })(<Input onChange={v => { participant.email = v.target.value; }} />)}
                    </Form.Item>
                    <Form.Item label="Mobile">
                      <Input defaultValue={participant.mobile} onChange={(v) => { participant.mobile = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Address">
                      <Input defaultValue={participant.address1} onChange={(v) => { participant.address1 = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Line 2">
                      <Input defaultValue={participant.address2} onChange={(v) => { participant.address2 = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="City">
                      <Input defaultValue={participant.city} onChange={(v) => { participant.city = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="State">
                      <Input defaultValue={participant.state} onChange={(v) => { participant.state = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Postal Code">
                      <Input defaultValue={participant.postalCode} onChange={(v) => { participant.postalCode = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Date of Birth">
                      <DatePicker defaultValue={moment(participant.dateOfBirth)} format={dateFormat} onChange={(date, dateString) => { participant.dateOfBirth = date ? date.toDate() : undefined; }} />
                    </Form.Item>
                    <Form.Item label="Age">
                      <label>{moment().diff(participant.dateOfBirth, 'years')}</label>
                    </Form.Item>
                    <Form.Item label="Sex at Birth">
                      <Radio.Group defaultValue={participant.genderAtBirth} onChange={(v) => {participant.genderAtBirth = v.target.value}}>
                        <Radio value={"F"}>FEMALE</Radio>
                        <Radio value={"M"}>MALE</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Gender Identity">
                      <Input defaultValue={participant.genderIdentity} onChange={(v) => { participant.genderIdentity = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Ethnicity">
                      <Input defaultValue={participant.ethnicity} onChange={(v) => { participant.ethnicity = v.target.value; }} />
                    </Form.Item>
                  </div>
                  <div className="demo-section">
                    <Form.Item label="Created Date">
                      <label>{moment(participant.createDate).format('MM/DD/YYYY')}</label>
                    </Form.Item>
                    <Form.Item label="Study Arm">
                      <label>{studyArm.name}</label>
                    </Form.Item>
                    <Form.Item label="Status">
                      <Select style={{ width: 200}} defaultValue={participant.status} onChange={(value: string) => {participant.status = value}}>
                        <Option value="APPLIED">APPLIED</Option>
                        <Option value="DISQUALIFIED">DISQUALIFIED</Option>
                        <Option value="ACCEPTED">ACCEPTED</Option>
                        <Option value="ACTIVE">ACTIVE</Option>
                        <Option value="DROPPED">DROPPED</Option>
                        <Option value="DISMISSED">DISMISSED</Option>
                        <Option value="FINISHED">FINISHED</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Actual HIV Status">
                      <Radio.Group defaultValue={participant.actualHivStatus} onChange={(v) => {participant.actualHivStatus = v.target.value}}>
                        <Radio value={"NEGATIVE"}>NEGATIVE</Radio>
                        <Radio value={"POSITIVE"}>POSITIVE</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Reported HIV Status">
                      <Radio.Group defaultValue={participant.reportedHivStatus} onChange={(v) => {participant.reportedHivStatus = v.target.value}}>
                        <Radio value={"NEGATIVE"}>NEGATIVE</Radio>
                        <Radio value={"POSITIVE"}>POSITIVE</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {/*<Form.Item label="Eligible">*/}
                    {/*  <Radio.Group defaultValue={participant.isEligible} onChange={(v) => {participant.isEligible = v.target.value}}>*/}
                    {/*    <Radio value={1}>YES</Radio>*/}
                    {/*    <Radio value={0}>NO</Radio>*/}
                    {/*  </Radio.Group>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="Email Verified">*/}
                    {/*  <Radio.Group defaultValue={participant.isEmailVerified} onChange={(v) => {participant.isEmailVerified = v.target.value}}>*/}
                    {/*    <Radio value={1}>YES</Radio>*/}
                    {/*    <Radio value={0}>NO</Radio>*/}
                    {/*  </Radio.Group>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="Signed Consent">*/}
                    {/*  <Radio.Group defaultValue={participant.hasSignedConsent} onChange={(v) => {participant.hasSignedConsent = v.target.value}}>*/}
                    {/*    <Radio value={1}>YES</Radio>*/}
                    {/*    <Radio value={0}>NO</Radio>*/}
                    {/*  </Radio.Group>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="Access Code">
                      <Input defaultValue={participant.accessCode} onChange={(v) => { participant.accessCode = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Referral Code">
                      <Input defaultValue={participant.referralCode} onChange={(v) => { participant.referralCode = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Pin">
                      <Input defaultValue={participant.pin} onChange={(v) => { participant.pin = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Consent Version">
                      <Select style={{ width: 200}} defaultValue={participant.consentStatusVersion} onChange={(value: string) => {participant.consentStatusVersion = value}}>
                        {consentStatuses.map(cs => {
                          return <Option value={cs.version}>{`${cs.version} - ${cs.description}`}</Option>
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="PrEP Status">
                      <Select style={{ width: 200}} defaultValue={participant.prepStatus} onChange={(value: string) => {participant.prepStatus = value}}>
                        { participant.actualHivStatus === "POSITIVE" && <Option value='NA'>          NA</Option> }
                        { participant.actualHivStatus === "NEGATIVE" && <Option value='DK'>          DK</Option>  }
                        { participant.actualHivStatus === "NEGATIVE" && <Option value='YesNow'>  YesNow</Option> }
                        { participant.actualHivStatus === "NEGATIVE" && <Option value='YesPast'>YesPast</Option> }
                        { participant.actualHivStatus === "NEGATIVE" && <Option value='No'>          No</Option> }
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="demo-section">
                    <Form.Item label="Applied Date">
                      <DatePicker defaultValue={participant.appliedDate?moment(participant.appliedDate):undefined} format={dateFormat} onChange={(date, dateString) => { participant.appliedDate = date ? date.toDate() : undefined; }} />
                    </Form.Item>
                    <Form.Item label="Accepted Date">
                      <DatePicker defaultValue={participant.acceptedDate?moment(participant.acceptedDate):undefined} format={dateFormat} onChange={(date, dateString) => { participant.acceptedDate = date ? date.toDate() : undefined; }} />
                    </Form.Item>
                    <Form.Item label="Start Date">
                      <DatePicker defaultValue={participant.startDate?moment(participant.startDate):undefined} format={dateFormat} onChange={(date, dateString) => { participant.startDate = date ? date.toDate() : undefined; }} />
                    </Form.Item>
                    <Form.Item label="End Date">
                      <DatePicker defaultValue={participant.endDate?moment(participant.endDate):undefined} format={dateFormat} onChange={(date, dateString) => { participant.endDate = date ? date.toDate() : undefined; }} />
                    </Form.Item>
                    <Form.Item label="Mobile Enroll Date">
                      <DatePicker defaultValue={participant.mobileEnrollmentDate?moment(participant.mobileEnrollmentDate):undefined} format={dateFormat} onChange={(date, dateString) => { participant.mobileEnrollmentDate = date ? date.toDate() : undefined; }} />
                    </Form.Item>
                    <Form.Item label="Graduation Date">
                      <DatePicker defaultValue={participant.graduationDate?moment(participant.graduationDate):undefined} format={dateFormat} onChange={(date, dateString) => { participant.graduationDate = date ? date.toDate() : undefined; }} />
                    </Form.Item>
                    <Form.Item label="Disqualified Date">
                      <DatePicker defaultValue={participant.disqualifiedDate?moment(participant.disqualifiedDate):undefined} format={dateFormat} onChange={(date, dateString) => { participant.disqualifiedDate = date ? date.toDate() : undefined; }} />
                    </Form.Item>
                    <Form.Item label="Disqualified Reason">
                      <Input defaultValue={participant.disqualifiedReason} onChange={(v) => { participant.disqualifiedReason = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Drop Date">
                      <DatePicker defaultValue={participant.dropDate?moment(participant.dropDate):undefined} format={dateFormat} onChange={(date, dateString) => { participant.dropDate = date ? date.toDate() : undefined; }} />
                    </Form.Item>
                    <Form.Item label="Drop Reason">
                      <Input defaultValue={participant.dropReason} onChange={(v) => { participant.dropReason = v.target.value; }} />
                    </Form.Item>
                    <Form.Item label="Dismissed Date">
                      <DatePicker defaultValue={participant.dismissedDate?moment(participant.dismissedDate):undefined} format={dateFormat} onChange={(date, dateString) => { participant.dismissedDate = date ? date.toDate() : undefined; }} />
                    </Form.Item>
                    <Form.Item label="Dismissed Reason">
                      <Input defaultValue={participant.dismissedReason} onChange={(v) => { participant.dismissedReason = v.target.value; }} />
                    </Form.Item>
                  </div>
                  <div className="demo-section">
                    <Form.Item label="About Me" style={{alignItems: "flex-start"}}>
                      <TextArea rows={3} defaultValue={participant.aboutMe} onChange={(v) => { participant.aboutMe = v.target.value; }} style={{width: "250px"}}/>
                    </Form.Item>
                  </div>
                </div>
                <div className="actions">
                  <Button htmlType="submit">Save</Button>
                  <br />
                  <Button onClick={cancelClickHandler}>Cancel</Button>
                </div>
              </div>
            </div>
          </Form>
        </FormContextProvider>
      </Card>
    );
  }
}

export default (Form.create<ComponentProps>())(ParticipantEditCard);
