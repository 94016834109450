import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import { selectParticipants } from '../participants/participants.selectors';
import entitySchema from '../schema';
import { NoteType, UserSurveyType } from '../../types/entity.types';

export const selectUserSurveys = (state: IApplicationState) => {
  return state.entities.userSurveys;
};

export const getUserSurveys = createSelector(
  [selectUserSurveys, selectParticipants],
  (userSurveys, participants): Optional<UserSurveyType[]> => {
    if (userSurveys && userSurveys.allIds && userSurveys.allIds.length > 0) {
      const { userSurveys: denormalizedUserSurveys } = denormalize({ userSurveys: userSurveys.allIds }, entitySchema, { userSurveys: userSurveys.byId, participants: participants.byId });
      return toArray(denormalizedUserSurveys);
    }
    return undefined;
  }
);

export default { getUserSurveys };
