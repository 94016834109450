import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { NormalizerResult, TestkitType } from '../../types/entity.types';
import axios from '../api';
import { loadDashboardDataAsync } from '../dashboard/dashboard.types';
import { entitySchema } from '../schema';
import {
  FulfillTestkitArgumentType,
  fulfillTestkitAsync,
  getAwaitingResultTestkitsAsync,
  getCompletedTestkitsAsync,
  getNeedsReviewTestkitsAsync,
  getParticipantsTestkitsAsync,
  getRequestedTestkitsAsync, markTestkitReportedAsync,
  ReviewTestkitArgumentType,
  reviewTestkitAsync
} from './testkit.types';


const getParticipantsTestkits = (participantId: number) => {
  return axios({
    method: 'get',
    url: `/a/testkit/${participantId}/participantTestKits`
  });
};

function* getParticipantsTestkitsHandler(action: ReturnType<typeof getParticipantsTestkitsAsync.request>): Generator {
  try {
    const studyId: number = action.payload;
    const response: AxiosResponse = yield call(getParticipantsTestkits, studyId);
    const { entities } = normalize(response.data, entitySchema.testkits) as NormalizerResult;
    const { testkits } = entities;

    yield put(getParticipantsTestkitsAsync.success(testkits));
  } catch (error) {
    yield put(getParticipantsTestkitsAsync.failure(error));
  }
}

function* getParticipantsTestkitsWatcher() {
  yield takeLatest(getType(getParticipantsTestkitsAsync.request), getParticipantsTestkitsHandler);
}


const getRequestedTestkits = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/testkit/${studyId}/requested`
  });
};

function* getRequestedTestkitsHandler(action: ReturnType<typeof getRequestedTestkitsAsync.request>): Generator {
  try {
    const { studyId } = action.payload;
    const response: AxiosResponse = yield call(getRequestedTestkits, studyId);
    const { entities } = normalize(response.data, entitySchema.testkits) as NormalizerResult;
    const { testkits } = entities;

    yield put(getRequestedTestkitsAsync.success(testkits));
  } catch (error) {
    yield put(getRequestedTestkitsAsync.failure(error));
  }
}

function* getRequestedTestkitsWatcher() {
  yield takeLatest(getType(getRequestedTestkitsAsync.request), getRequestedTestkitsHandler);
}

const getNeedsReviewTestkits = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/testkit/${studyId}/needsReview`
  });
};

function* getNeedsReviewTestkitsHandler(action: ReturnType<typeof getNeedsReviewTestkitsAsync.request>): Generator {
  try {
    const { studyId } = action.payload;
    const response: AxiosResponse = yield call(getNeedsReviewTestkits, studyId);
    const { entities } = normalize(response.data, entitySchema.testkits) as NormalizerResult;
    const { testkits } = entities;

    yield put(getNeedsReviewTestkitsAsync.success(testkits));
  } catch (error) {
    yield put(getNeedsReviewTestkitsAsync.failure(error));
  }
}

function* getNeedsReviewTestkitsWatcher() {
  yield takeLatest(getType(getNeedsReviewTestkitsAsync.request), getNeedsReviewTestkitsHandler);
}

const getAwaitingResultTestkits = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/testkit/${studyId}/awaitingResult`
  });
};

function* getAwaitingResultTestkitsHandler(action: ReturnType<typeof getAwaitingResultTestkitsAsync.request>): Generator {
  try {
    const { studyId } = action.payload;
    const response: AxiosResponse = yield call(getAwaitingResultTestkits, studyId);
    const { entities } = normalize(response.data, entitySchema.testkits) as NormalizerResult;
    const { testkits } = entities;

    yield put(getAwaitingResultTestkitsAsync.success(testkits));
  } catch (error) {
    yield put(getAwaitingResultTestkitsAsync.failure(error));
  }
}

function* getAwaitingResultTestkitsWatcher() {
  yield takeLatest(getType(getAwaitingResultTestkitsAsync.request), getAwaitingResultTestkitsHandler);
}

const getCompletedTestkits = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/testkit/${studyId}/completed`
  });
};

function* getCompletedTestkitsHandler(action: ReturnType<typeof getCompletedTestkitsAsync.request>): Generator {
  try {
    const { studyId } = action.payload;
    const response: AxiosResponse = yield call(getCompletedTestkits, studyId);
    const { entities } = normalize(response.data, entitySchema.testkits) as NormalizerResult;
    const { testkits } = entities;

    yield put(getCompletedTestkitsAsync.success(testkits));
  } catch (error) {
    yield put(getCompletedTestkitsAsync.failure(error));
  }
}

function* getCompletedTestkitsWatcher() {
  yield takeLatest(getType(getCompletedTestkitsAsync.request), getCompletedTestkitsHandler);
}

const fulfillTestkit = (testkit: FulfillTestkitArgumentType) => {
  return axios({
    method: 'put',
    url: `/a/testkit/${testkit.id}/fulfill`,
    data: {
      trackingNumber: testkit.trackingNumber,
      returnTrackingNumber: testkit.returnTrackingNumber,
      estimatedDeliveryDate: testkit.estimatedDeliveryDate
    }
  });
};

function* fulfillTestkitHandler(action: ReturnType<typeof fulfillTestkitAsync.request>): Generator {
  try {
    const testkit: FulfillTestkitArgumentType = action.payload;
    const response: AxiosResponse = yield call(fulfillTestkit, testkit);
    const { entities } = normalize([response.data], entitySchema.testkits) as NormalizerResult;
    const { testkits } = entities;

    yield put(fulfillTestkitAsync.success(testkits));
    yield put(loadDashboardDataAsync.request());
  } catch (error) {
    yield put(fulfillTestkitAsync.failure(error));
  }
}

function* fulfillTestkitWatcher() {
  yield takeLatest(getType(fulfillTestkitAsync.request), fulfillTestkitHandler);
}

const reviewTestkit = (testkit: ReviewTestkitArgumentType) => {
  let url = `/a/testkit/${testkit.id}/review`;
  if (testkit.actualResult && testkit.reportDate) {
    url += `?reportDate=${testkit.reportDate}&actualResult=${testkit.actualResult}`;
  }
  else if (testkit.actualResult) {
    url += `?actualResult=${testkit.actualResult}`;
  }
  else if (testkit.reportDate) {
    url += `?reportDate=${testkit.reportDate}`;
  }

  return axios({
    method: 'put',
    url
  });
};

function* reviewTestkitHandler(action: ReturnType<typeof reviewTestkitAsync.request>): Generator {
  try {
    const testkit: ReviewTestkitArgumentType = action.payload;
    const response: AxiosResponse = yield call(reviewTestkit, testkit);
    const { entities } = normalize([response.data], entitySchema.testkits) as NormalizerResult;
    const { testkits } = entities;

    yield put(reviewTestkitAsync.success(testkits));
    yield put(loadDashboardDataAsync.request());
  } catch (error) {
    yield put(reviewTestkitAsync.failure(error));
  }
}
function* reviewTestkitWatcher() {
  yield takeLatest(getType(reviewTestkitAsync.request), reviewTestkitHandler);
}

const markTestkitReported = (testkit: TestkitType) => {
  return axios({
    method: 'put',
    url: `/a/testkit/${testkit.id}/markReported`
  });
};

function* markTestkitReportedHandler(action: ReturnType<typeof markTestkitReportedAsync.request>): Generator {
  try {
    const testkit: TestkitType = action.payload;
    const response: AxiosResponse = (yield call(markTestkitReported, testkit)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.testkits) as NormalizerResult;
    const { testkits } = entities;

    yield put(markTestkitReportedAsync.success(testkits));
    yield put(loadDashboardDataAsync.request());
  } catch (error) {
    yield put(reviewTestkitAsync.failure(error));
  }
}
function* markTestkitReportedWatcher() {
  yield takeLatest(getType(markTestkitReportedAsync.request), markTestkitReportedHandler);
}

export default function* studySaga() {
  yield all([
    fork(getParticipantsTestkitsWatcher),
    fork(getRequestedTestkitsWatcher),
    fork(getAwaitingResultTestkitsWatcher),
    fork(getNeedsReviewTestkitsWatcher),
    fork(getCompletedTestkitsWatcher),
    fork(fulfillTestkitWatcher),
    fork(reviewTestkitWatcher),
    fork(markTestkitReportedWatcher),
  ]);
}
