import React, { Component } from 'react';
import * as _ from 'lodash';
import {
  Icon, Button
} from 'antd';
import Form from 'antd/lib/form';
import { ActivityOptionType } from '../../../types/entity.types';
const { Item } = Form;
import '../activity.scss';
import OptionItem from './OptionItem';

interface ComponentProps {
  form: any;
  type: number;
  creative: boolean;
  editable: boolean;
  options: ActivityOptionType[];
  setStatus: (status: string) => void;
  updateOption: (option: ActivityOptionType, index: number) => void;
  addOption: () => void;
  removeOption: (index: number) => void;
  swapOptionPosition?: (indexA: number, indexB: number) => void;
}

class DynamicOptionItems extends Component<ComponentProps, {}> {
  onAddClick = (e: React.MouseEvent<HTMLElement>) => {
    const { addOption } = this.props;
    e.preventDefault();
    addOption();
  };
  moveUp = (index: number) => {
    const { swapOptionPosition } = this.props;
    if(swapOptionPosition) {
      swapOptionPosition(index, index - 1);
    }
  }
  moveDown = (index: number) => {
    const { swapOptionPosition } = this.props;
    if(swapOptionPosition) {
      swapOptionPosition(index, index + 1);
    }
  }
  render() {
    const { 
      creative,
      editable,
      options, 
      form, 
      type, 
      setStatus,
      updateOption, 
      removeOption
    } = this.props;
    const items = options.map((o: ActivityOptionType, index: number) => {
      return (
        <li className='option-list-container'>
          <OptionItem
              form={form} 
              type={type} 
              option={o}
              creative={creative}
              editable={editable}
              setStatus={setStatus}
              updateOption={(o: ActivityOptionType) => updateOption(o, index)}
              moveUp={() => this.moveUp(index)}
              moveDown={() => this.moveDown(index)}
            />
          {options.length > 1 && (creative || editable) && (
          <button disabled={!creative && !editable} className='dashed-danger-button' onClick={() => removeOption(index)}>
            <Icon type='minus' /> Remove Option
          </button>)}
        </li>);
    });
    return (
      <ul className='dynamic-option-items'>
        {items}
        <Item>
          <Button disabled={!creative && !editable} type='dashed' onClick={this.onAddClick} style={{width: '100%'}}>
            <Icon type='plus' /> Add option
          </Button>
        </Item>
      </ul>
    );
  }
};

export default DynamicOptionItems;