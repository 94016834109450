import {
  DatePicker, Checkbox, Radio, Button, Popover, Collapse, Tooltip, Cascader
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import * as _ from 'lodash';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import FormContextProvider from './FormContextProvider';
import { FormType, ParticipantType } from '../../types/entity.types';
import { saveFormAsync, updateFormAsync } from '../../redux/form/form.types';

import './editForm.scss';
import { RadioChangeEvent } from 'antd/lib/radio';

const { TextArea } = Input;
const { confirm } = Modal;
const { Panel } = Collapse;

interface StateProps {
  participants: ParticipantType[]
}

interface DispatchProps {
  saveForm: typeof saveFormAsync.request,
  editForm: typeof updateFormAsync.request
}

interface ComponentProps extends DispatchProps, StateProps {
  closeHandler: () => {},
  visible: boolean,
  editable: boolean,
  data: FormType,
  editing: boolean
}

const textAreaRows = 4;
const footerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%'
};
const footerActionStyle = {
  textAlign: 'center',
  width: '50%'
};
const radioStyle = {
  display: 'block',
  height: 'auto',
  lineHeight: '30px'
};

function filter(inputValue: any, path: any): boolean {
  return path.some((option: any) => option.label ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 : false);
}

const requiredText = 'This response is required.';

const physicalHarmOptions = ['Yes', 'No', 'Don\'t know'];
const qualityOptions = ['Minimal disturbance', 'Moderate disturbance; no significant impact', 'Major disturbance with significant impact'];
const statusOptions = ['Ongoing', 'Unable to resolve; no further action taken', 'Resolved'];
const typeOptions = ['Family relationship',
  'Partner relationship',
  'Other personal relationship',
  'Intervention relationship',
  'Employment',
  'Education',
  'Medical/dental',
  'Housing',
  'Other, specify:'];
const typeTips = ['negative experience(s) with family, excluding partner',
  'negative experience(s) with significant other, spouse, or sex partner',
  'negative experiences with friends, neighbors, community members',
  'verbal mistreatment from another participant',
  'turned down for a job, lost a job, other work problems',
  'turned down by educational program, told to leave a program, other school problems',
  'refused medical or dental treatment, treated negatively by a provider',
  'trouble getting or keeping housing, negative experiences with proprietor, other housing problems'];

class AdverseEventForm extends Component<ComponentProps & FormComponentProps, {}> {

  readonly state = {
    participantId: -1,
    formType: 'SOCIAL_HARM',
    Q3R: undefined,
    Q4R: undefined,
    Q5R: undefined,
    Q6R: new Array<number>(0),
    Q6aR: undefined,
    Q7R: undefined,
    Q8R: undefined,
    Q9R: -1,
    Q9aR: undefined,
    Q10R: undefined,
    Q11aR: undefined,
    Q11bR: undefined,
    Q12R: -1,
    Q12aR: undefined,
    Q13R: undefined,
    isResolved: false
  };
  componentDidMount() {
    const { editing, data } = this.props;
    if(editing && data.id) {
      this.setState({
        participantId: data.participantId,
        ...JSON.parse(data.formData)
      });
    }
  }
  questionMap = {
    Q1: 'Q1R', // adminId
    Q2: 'Q2R', // participantId
    Q3: 'Q3R', // date
    Q4: 'Q4R', // date
    Q5: 'Q5R', // string
    Q6: 'Q6R', // number[][0-8]
    Q6a: 'Q6aR', // string (other)
    Q7: 'Q7R', // string
    Q8: 'Q8R', // number [0-2]
    Q9: 'Q9R', // number [0-2]
    Q9a: 'Q9aR', // if Q9R = 0, string
    Q10: 'Q10R', // number [0-2]
    Q11a: 'Q11aR', // string
    Q11b: 'Q11bR', // string
    Q12: 'Q12R', // number [0-2]
    Q12a: 'Q12aR', // if Q11R in [1,2], date
    Q13: 'Q13R' // string
  };
  formatDate = (d: Date | undefined) => {
    return moment(d).calendar();
  }
  onChange = (state: any) => {
    this.setState(state);
  }
  onStatusChange = (e: RadioChangeEvent) => {
    const isResolved = statusOptions[e.target.value] !== 'Ongoing';
    this.setState({
      Q12R: e.target.value,
      isResolved
    });
  }
  showCancel = () => {
    const { closeHandler, editable } = this.props;
    if (editable) {
      confirm({
        title: 'Are you sure you want to leave this form?',
        content: 'You will lose all changes.',
        okText: 'Leave',
        okType: 'danger',
        onOk() {
          closeHandler();
        },
        onCancel() { }
      });
    } else {
      closeHandler();
    }
  }
  handleSubmit = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.showSave();
      }
    });
  }
  showSave = () => {
    const { editable, editing } = this.props;
    if (editable || editing) {
      const handleSaveForm = this.handleSaveForm.bind(this);
      confirm({
        title: 'Are you sure you want to submit this form?',
        content: '',
        okText: 'Save',
        onOk() {
          handleSaveForm();
        },
        onCancel() { }
      });
    }
  }
  handleSaveForm = () => {
    const { saveForm, closeHandler, editable, data, editing, editForm } = this.props;
    const { participantId, formType, isResolved } = this.state;
    const form = {
      participantId,
      formType,
      formData: _.omit(this.state, ['participantId', 'formType', 'visible']),
      isResolved
    };
    if (editable || editing) {
      const { formData } = form;
      if (formData.Q6R && formData.Q6aR && formData.Q6R.indexOf(8) === -1) {
        formData.Q6aR = undefined;
      }
      if (formData.Q9aR && formData.Q9R !== 0) {
        formData.Q9aR = undefined;
      }
      if (formData.Q12aR && formData.Q12R === 0) {
        formData.Q12aR = undefined;
      }
      if(editing && data.id) {
        form.id = data.id;
        editForm(form);
      } else {
        saveForm(form);
      }
    }
    closeHandler();
  }
  render() {
    const {
      form, visible, editable, data, editing, participants
    } = this.props;
    const { getFieldDecorator } = form;
    const { formType } = this.state;
    let formData: any;
    if (data) formData = JSON.parse(data.formData);
    const dateFormat = 'MM/DD/YYYY';
    const displayDate = this.formatDate(new Date());
    const type = formType.split('_').map((s: string) => s.charAt(0) + s.substring(1).toLowerCase()).join(' ');

    const options = _.map(participants, participant => _.pick(participant, ['username', 'id']))
      .map(option => { return { label: option.username, value: option.id.toString() }; });

    return (
      <Modal
        key={data?.id ? `form-${data.id}` : 'form-social'}
        title={editable ? `${type}` : `${type} created: ${this.formatDate(data.createDate)}`}
        visible={visible}
        onCancel={this.showCancel}
        width="45%"
        style={{ minWidth: '45vw' }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button type={editable || editing ? 'danger' : 'default'} onClick={e => { e.stopPropagation(); this.showCancel(); }}>Close</Button>,
          <Button type="primary" disabled={!editable && !editing} onClick={e => { e.stopPropagation(); this.handleSubmit(); }}>Save</Button>,
        ]}
        destroyOnClose
      >
        <FormContextProvider>
          <Form key="form" layout="vertical" colon={false}>
            <Form.Item label="Participant ID:">
              {getFieldDecorator('participantId', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : data.participantId
              })(<Cascader style={{marginBottom: '5px'}}
                           placeholder="Enter participant's username"
                           options={options}
                           onChange={(value) => this.setState({participantId: value[0]})}
                           showSearch={{ filter }}/>)}
            </Form.Item>
            <Form.Item label="Date social harm reported to staff:">
              {getFieldDecorator('Q3', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : moment(formData[this.questionMap.Q3])
              })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q3R: dateString })} format={dateFormat} />)}
            </Form.Item>
            <Form.Item label="Date of social harm onset:">
              {getFieldDecorator('Q4', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : moment(formData[this.questionMap.Q4])
              })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q4R: dateString })} format={dateFormat} />)}
            </Form.Item>
            <Form.Item label="Who reported the social harm to staff?">
              {getFieldDecorator('Q5', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : formData[this.questionMap.Q5]
              })(<Input disabled={!editable && !editing} onChange={e => this.onChange({ Q5R: e.target.value })} type="textarea" />)}
            </Form.Item>
            <Form.Item label="Do any of these criteria apply to this AE?">
              {getFieldDecorator('Q6', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? [] : formData[this.questionMap.Q6]
              })(<CheckboxGroup
                disabled={!editable && !editing}
                onChange={(vals: any[]) => this.onChange({ Q6R: vals })}
              >
                <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                  {typeOptions.map(opt => {
                    switch (typeOptions.indexOf(opt)) {
                      case 8:
                        return (
                          <li style={radioStyle}>
                            <Checkbox value={typeOptions.indexOf(opt)}>{opt}</Checkbox>
                            <Input disabled={!editable && !editing} onChange={(e) => this.onChange({ Q6aR: e.target.value })} style={{ width: 200, marginLeft: 10 }} defaultValue={editable ? '' : formData[this.questionMap.Q6a]} />
                          </li>
                        );
                      default:
                        return (
                          <li style={radioStyle}>
                            <Tooltip placement="right" title={typeTips[typeOptions.indexOf(opt)]}>
                              <Checkbox value={typeOptions.indexOf(opt)}>{opt}</Checkbox>
                            </Tooltip>
                          </li>
                        );
                    }
                  })}
                </ul>
              </CheckboxGroup>)}
            </Form.Item>
            <Form.Item label="Describe the social harm resulting from study participation:">
              {getFieldDecorator('Q7', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q7]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q7R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
            <Form.Item label="Did the social harm involve physical harm to the participant?">
              {getFieldDecorator('Q8', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? this.state.Q8R : parseInt(formData[this.questionMap.Q8])
              })(<Radio.Group disabled={!editable && !editing} onChange={e => this.onChange({ Q8R: e.target.value })}>
                {physicalHarmOptions.map(opt => {
                  switch (physicalHarmOptions.indexOf(opt)) {
                    case 0:
                      return (
                        <Popover placement="leftTop" content="Be sure to complete Adverse Event CRF." trigger="click">
                          <Radio value={physicalHarmOptions.indexOf(opt)}>{opt}</Radio>
                        </Popover>
                      );
                    default:
                      return <Radio value={physicalHarmOptions.indexOf(opt)}>{opt}</Radio>;
                  }
                })}
              </Radio.Group>)}
            </Form.Item>
            <Collapse bordered={false} activeKey={editable ? this.state.Q9R.toString() : formData[this.questionMap.Q9].toString()}>
              <Panel
                id="collapse-header"
                showArrow={false}
                key="0"
                header={(
                  <Form.Item label="In this situation, did the participant's involvement in the study become known to others?">
                    {getFieldDecorator('Q9', {
                      rules: [{ required: true, message: requiredText }],
                      initialValue: editable ? this.state.Q9R : parseInt(formData[this.questionMap.Q9])
                    })(<Radio.Group disabled={!editable && !editing} onChange={e => this.onChange({ Q9R: e.target.value })}>
                      {physicalHarmOptions.map(opt => {
                        return <Radio value={physicalHarmOptions.indexOf(opt)}>{opt}</Radio>;
                      })}
                    </Radio.Group>)}
                  </Form.Item>
)}
              >
                <Form.Item label="How did study participation become known?">
                  {getFieldDecorator('Q9a', {
                    rules: [{ required: this.state.Q9R === 0, message: requiredText }],
                    initialValue: editable ? undefined : formData[this.questionMap.Q9a]
                  })(<Input disabled={!editable && !editing} onChange={e => this.onChange({ Q9aR: e.target.value })} type="textarea" />)}
                </Form.Item>
              </Panel>
            </Collapse>
            <Form.Item label="Did the social harm involve physical harm to the participant?">
              {getFieldDecorator('Q10', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? this.state.Q10R : parseInt(formData[this.questionMap.Q10])
              })(<Radio.Group disabled={!editable && !editing} onChange={e => this.onChange({ Q10R: e.target.value })}>
                {qualityOptions.map(opt => {
                  return <Radio style={radioStyle} value={qualityOptions.indexOf(opt)}>{opt}</Radio>;
                })}
              </Radio.Group>)}
            </Form.Item>
            <Form.Item label="Describe how the social harm was addressed:">
              <i>Participant actions:</i>
              {getFieldDecorator('Q10a', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q11a]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q11aR: e.target.value })} rows={textAreaRows} />)}
              <i>Staff actions:</i>
              {getFieldDecorator('Q10b', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q11b]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q11bR: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
            <Collapse bordered={false} activeKey={editable ? (this.state.Q12R !== 0 && this.state.Q12R !== -1 ? '0' : '-1') : (formData[this.questionMap.Q12] !== 0 ? '0' : '-1')}>
              <Panel
                id="collapse-header"
                showArrow={false}
                key="0"
                header={(
                  <Form.Item label="Current Status:">
                    {getFieldDecorator('Q12', {
                      rules: [{ required: true, message: requiredText }],
                      initialValue: editable ? this.state.Q12R : parseInt(formData[this.questionMap.Q12])
                    })(<Radio.Group disabled={!editable && !editing} onChange={this.onStatusChange}>
                      {statusOptions.map(opt => {
                        return <Radio value={statusOptions.indexOf(opt)}>{opt}</Radio>;
                      })}
                    </Radio.Group>)}
                  </Form.Item>
)}
              >
                <Form.Item label="Closure or resolution date:">
                  {getFieldDecorator('Q12a', {
                    rules: [{ required: this.state.Q12R !== 0, message: requiredText }],
                    initialValue: editable ? undefined : moment(formData[this.questionMap.Q12a])
                  })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q12aR: dateString })} format={dateFormat} />)}
                </Form.Item>
              </Panel>
            </Collapse>
            <Form.Item label="Additional comments or context:">
              {getFieldDecorator('Q13', {
                initialValue: editable ? '' : formData[this.questionMap.Q13]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q13R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
          </Form>
        </FormContextProvider>
      </Modal>
    );
  }
}

const mapStateToProps =  createStructuredSelector<IApplicationState, StateProps>({
  participants: selectors.getRequestedStudyParticipantsWithUsernames
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    saveForm: (form: FormType) => dispatch(saveFormAsync.request(form)),
    editForm: (form: FormType) => dispatch(updateFormAsync.request(form))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'form' })(AdverseEventForm));
