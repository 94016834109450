import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { Card, Col, Row } from 'antd';
import { connect } from 'react-redux';
import { getBadgesAsync } from '../../redux/badges/badges.types';
import { BadgeType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import { IApiRequestState } from '../../types/api.types';

interface StateProps {
  badges: Optional<BadgeType[]>,
  status: IApiRequestState
}

interface DispatchProps {
  getBadges: typeof getBadgesAsync.request
}

interface ComponentProps extends StateProps, DispatchProps {}

class Badges extends Component<ComponentProps, {}> {

  async componentDidMount() {
    this.props.getBadges();
  }

  render() {

    const { badges } = this.props;
    const badgeCols: any[] = [];
    if (badges) {
      badges.forEach((badge: BadgeType) => {
        badgeCols.push(
          <Col id={`col${badge.badgeCollectionId.toString()}`} span={4}>
            <Card id={badge.badgeCollectionId.toString()} bordered={false} style={{ height: 155, textAlign: 'center' }}>
              <img src={badge.badge} style={{ width: 50, alignSelf: 'center' }} />
              <div>{badge.label}</div>
              <div style={{ fontSize: 10 }}>{badge.description}</div>
            </Card>
          </Col>
        );
      });
    }

    return (
      <div>
        <Row>{badgeCols}</Row>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState): StateProps => {
  const badges = selectors.getBadges(state);
  const status = selectors.loadBadgesStatus(state);

  return {
    badges,
    status
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getBadges: () => dispatch(getBadgesAsync.request())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Badges);
