import React from 'react';
import uniqBy from 'lodash/uniqBy';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../redux/selectors';
import { UserType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';

interface StateProps {
  user: Optional<UserType>,
  isNonYAB: boolean
}

interface ComponentProps extends StateProps {}

class LandingPage extends React.Component<ComponentProps, {}> {

  render() {

    const { user, isNonYAB } = this.props;

    if (!user) {
      return <Redirect to="/auth/login" />;
    }

    // The user did not go to a specific /admin or /study URL, so let's take a look at their permissions and try to
    // redirect them to the best place possible.

    // If Admin redirect to /admin
    if (isAdmin(user)) {
      return <Redirect to="/admin" />;
    }

    const authorizedStudies = getAuthorizedStudies(user);

    // If == 1 Study redirect to /study/id
    if (authorizedStudies && authorizedStudies.length === 1) {
      if (isNonYAB ) {
        return <Redirect to={`/study/${authorizedStudies[ 0 ].studyId}`}/>;
      }
      else {
        return <Redirect to={`/study/${authorizedStudies[ 0 ].studyId}/forums`}/>;
      }
    }

    // If > Study display landing page and let them pick
    return (
      <div>
        <h1>
Welcome
          {user.firstName}
        </h1>
        {authorizedStudies.map(study => {
          return <div key={study.studyId}><Link to={`/study/${study.studyId}`}>{study.studyName}</Link></div>;
        })}
      </div>
    );
  }
}

const isAdmin = (user: UserType) => {

  for (let i = 0; i < user.roles.length; i++) {

    if (user.roles[i].role.type === 'ADMIN') {
      return true;
    }
  }
  return false;
};

const getAuthorizedStudies = (user: UserType): {studyId: number, studyName: string}[] => {

  const studyRoles = user.roles.filter(role => { return role.studyId != undefined; });
  const uniqueStudyRoles = uniqBy(studyRoles, 'studyId').map(role => {
    return {
      studyId: role.studyId,
      studyName: role.studyName
    };
  });
  return uniqueStudyRoles as {studyId: number, studyName: string}[];
};

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  user: selectors.getUser,
  isNonYAB: selectors.isNonYAB
});

export default connect(mapStateToProps)(LandingPage);
