import { createSelector } from 'reselect';
import { AnalyticsType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';

export const selectAnalytics = (state: IApplicationState) => {
  return state.ui.reports.analytics.reportRows;
};

export const getAnalytics = createSelector(
  [selectAnalytics],
  (analytics, ): Optional<AnalyticsType[]> => {
    return analytics;
  }
);

export default { getAnalytics };
