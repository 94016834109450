import React from 'react';
import {
  Icon, Button
} from 'antd';
import Form from 'antd/lib/form';
import { ActivityFeedbackType, ActivityOptionType } from '../../../types/entity.types';
const { Item } = Form;
import FeedbackItem from './FeedbackItem';
import '../activity.scss';

interface ComponentProps {
  form: any;
  type: number;
  creative: boolean;
  editable: boolean;
  feedback: ActivityFeedbackType[];
  options: ActivityOptionType[];
  setStatus: (status: string) => void;
  updateFeedback: (feedback: ActivityFeedbackType, index: number) => void;
  addFeedback: () => void;
  removeFeedback: (index: number) => void;
}

const DynamicFeedbackItems = (props: ComponentProps) => {
  const { 
    form, 
    type, 
    creative,
    editable,
    feedback, 
    options, 
    setStatus, 
    updateFeedback, 
    addFeedback, 
    removeFeedback 
  } = props;
  const onAddClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    addFeedback();
  };
  const itemStyle = {
    width: '100%',
    marginBottom: '10px', 
    marginTop: '10px'
  };
  const items = feedback.map((f: ActivityFeedbackType, index: number) => {
    return (<li className='feedback-list-item-container'>
      <FeedbackItem 
        form={form} 
        type={type} 
        creative={creative}
        editable={editable}
        feedback={f} 
        options={options} 
        setStatus={setStatus}
        updateFeedback={(fb: ActivityFeedbackType) => updateFeedback(fb, index)}
      />
      {(creative || editable) && 
      <button disabled={!creative && !editable} className='dashed-danger-button' onClick={() => removeFeedback(index)}>
        <Icon type='minus' /> Remove Feedback
      </button>}
    </li>)
  });
  return (
    <ul className='dynamic-feedback-items'>
      {items}
      <Item>
        <Button disabled={!creative && !editable} type='dashed' onClick={onAddClick} style={{width: '100%'}}>
          <Icon type='plus' /> Add feedback
        </Button>
      </Item>
    </ul>
  );
};

export default DynamicFeedbackItems;