import React, { Component, CSSProperties } from 'react';
import TextArea from 'react-textarea-autosize'
import uuid from 'uuid';
import './HMPTextArea.scss';

interface ComponentProps {
  value: string;
  key?: string | undefined;
  rows?: number | undefined;
  maxRows?: number;
  minRows?: number | undefined;
  onChange?: ((event: React.ChangeEvent<HTMLTextAreaElement>) => void) | undefined;
  onHeightChange?: ((height: number) => void) | undefined;
  placeholder?: string | undefined;
  characterLimit?: number | undefined;
  spellCheck: boolean | "true" | "false" | undefined;
  style?: CSSProperties | undefined;
  className?: string | undefined;
  useCacheForDOMMeasurements?: boolean | undefined;
  disabled?: boolean | undefined;
}

class HMPTextArea extends Component<ComponentProps, {}> {
  private key: string;
  constructor(props: ComponentProps) {
    super(props);
    this.key = uuid();
  }
  onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.stopPropagation();
    const { onChange } = this.props;
    if(onChange) {
      onChange(event);
    }
  };
  characterLimit = (s: string | null, limit: number): string => {
    if(s) {
      if(s.length <= limit) {
        return `(${limit - s.length} characters left.)`
      }
      return `(${s.length - limit} characters over character limit.)`
    }
    return `(${limit} characters left)`;
  };
  render() {
    const {
      value,
      key,
      rows,
      maxRows,
      minRows,
      onHeightChange,
      placeholder,
      characterLimit,
      spellCheck,
      style,
      className,
      useCacheForDOMMeasurements,
      disabled
    } = this.props;
    const displayCharacterLimit = characterLimit !== undefined && characterLimit > 0;
    let exceedsCharacterLimit = false;
    let characterLimitMessage = '';
    if(displayCharacterLimit) {
      if(value && value.length > characterLimit) {
        exceedsCharacterLimit = true;
      }
      characterLimitMessage = `${value.length}/${characterLimit}`;
    }
    return (
    <div className='textarea-container'>
      <TextArea
        key={key ? key : this.key} 
        value={value}
        rows={rows}
        maxRows={maxRows}
        minRows={minRows}
        onChange={this.onChange}
        onHeightChange={onHeightChange}
        placeholder={placeholder}
        spellCheck={spellCheck}
        style={style}
        className={[className ? className  : '', 'textarea', `${exceedsCharacterLimit ? 'error' : ''}`].join(' ')}
        useCacheForDOMMeasurements={useCacheForDOMMeasurements}
        disabled={disabled}
      />
      {displayCharacterLimit && <span className={['textarea-after', `${exceedsCharacterLimit ? 'error' : ''}`].join(' ')}>{characterLimitMessage}</span>}
    </div>);
    
  }
}

export default HMPTextArea;