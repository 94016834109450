import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import { NewUserType, RoleType, StudyArmType } from '../../../types/entity.types';
import { Card, Button, message, Input, Select, Collapse, DatePicker, Modal } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import FormContextProvider from '../../../components/form/FormContextProvider';
import { createUserAsync } from '../../../redux/user/user.types';
import axios from '../../../redux/api';
import { IApiRequestStatus } from '../../../types/api.types';
import { clearStatus } from '../../../redux/api/api.types';
import { getType } from 'typesafe-actions';
const { Item } = Form;
const { Option } = Select;
const { Panel } = Collapse;
const { Password } = Input;
const { confirm } = Modal;
import './userCreation.scss';
import Config from '../../../components/util/Config';

interface StateProps {
  adminRoles: Optional<RoleType[]>;
  studyId: number;
  arms: Optional<StudyArmType[]>;
  isHMPStigma: boolean;
  createUserStatus: IApiRequestStatus;
}

interface DispatchProps {
  createUser: typeof createUserAsync.request;
  clearStatus: typeof clearStatus;
}

interface ComponentProps extends StateProps, DispatchProps, FormComponentProps {}

enum UserType {
  PARTICIPANT = 'PARTICIPANT',
  ADMIN = 'ADMIN'
}

const itemStyle = {
  width: 'auto',
  marginBottom: '10px', 
  marginTop: '10px',
};
const statesAndTerritories = [
  {name: 'Alabama', code: 'AL'},
  {name: 'Alaska', code: 'AK'},
  {name: 'American Samoa', code: 'AS'},
  {name: 'Arizona', code: 'AZ'},
  {name: 'Arkansas', code: 'AR'},
  {name: 'California', code: 'CA'},
  {name: 'Colorado', code: 'CO'},
  {name: 'Connecticut', code: 'CT'},
  {name: 'Delaware', code: 'DE'},
  {name: 'District of Columbia', code: 'DC'},
  {name: 'Florida', code: 'FL'},
  {name: 'Georgia', code: 'GA'},
  {name: 'Guam', code: 'GU'},
  {name: 'Hawaii', code: 'HI'},
  {name: 'Idaho', code: 'ID'},
  {name: 'Illinois', code: 'IL'},
  {name: 'Indiana', code: 'IN'},
  {name: 'Iowa', code: 'IA'},
  {name: 'Kansas', code: 'KS'},
  {name: 'Kentucky', code: 'KY'},
  {name: 'Louisiana', code: 'LA'},
  {name: 'Maine', code: 'ME'},
  {name: 'Maryland', code: 'MD'},
  {name: 'Massachusetts', code: 'MA'},
  {name: 'Michigan', code: 'MI'},
  {name: 'Minnesota', code: 'MN'},
  {name: 'Mississippi', code: 'MS'},
  {name: 'Missouri', code: 'MO'},
  {name: 'Montana', code: 'MT'},
  {name: 'Nebraska', code: 'NE'},
  {name: 'Nevada', code: 'NV'},
  {name: 'New Hampshire', code: 'NH'},
  {name: 'New Jersey', code: 'NJ'},
  {name: 'New Mexico', code: 'NM'},
  {name: 'New York', code: 'NY'},
  {name: 'North Carolina', code: 'NC'},
  {name: 'North Dakota', code: 'ND'},
  {name: 'Northern Mariana Islands', code: 'NI'},
  {name: 'Ohio', code: 'OH'},
  {name: 'Oklahoma', code: 'OK'},
  {name: 'Oregon', code: 'OR'},
  {name: 'Pennsylvania', code: 'PA'},
  {name: 'Puerto Rico', code: 'PR'},
  {name: 'Rhode Island', code: 'RI'},
  {name: 'South Carolina', code: 'SC'},
  {name: 'South Dakota', code: 'SD'},
  {name: 'Tennessee', code: 'TN'},
  {name: 'Texas', code: 'TX'},
  {name: 'U.S. Virgin Islands', code: 'UI'},
  {name: 'Utah', code: 'UT'},
  {name: 'Vermont', code: 'VT'},
  {name: 'Virginia', code: 'VA'},
  {name: 'Washington', code: 'WA'},
  {name: 'West Virginia', code: 'WV'},
  {name: 'Wisconsin', code: 'WI'},
  {name: 'Wyoming', code: 'WY'},
];

class UserCreationPage extends Component<ComponentProps, {}> {
  readonly state = {
    userType: UserType.ADMIN,
    email: undefined,
    studyArmId: undefined,
    studyId: undefined,
    firstName: undefined,
    hivStatus: undefined,
    dateOfBirth: undefined,
    genderIdentity: undefined,
    city: undefined,
    state: undefined,
    mobile: undefined,
    postalCode: undefined,
    referralCode: undefined,
    username: undefined,
    password: undefined,
    roleId: undefined,
    confirmPassword: undefined,
    usernameStatus: undefined
  };
  private availableUserTypes: string[] = [];
  constructor(props: ComponentProps) {
    super(props);
    this.availableUserTypes.push(UserType.ADMIN);
    if(!this.props.isHMPStigma) {
      this.availableUserTypes.push(UserType.PARTICIPANT);
    }
  }
  componentDidMount() {
  }
  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };
  emailValidator = (rule, value: string, cb: any) => {
    try {
      // RF5322 standard email regex
      const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      if(!emailRegex.test(value)) {
        throw new Error('Please enter a valid email address.');
      }
      cb()
    } catch (err) {
      cb(err);
    }
  };
  onUserTypeChange = (value: string) => {
    this.setState({ userType: value });
  };
  onStudyArmChange = (value: string) => {
    this.setState({ studyArmId: value });
  };
  onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: event.target.value });
  };
  onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: event.target.value });
  };
  usernameValidator = async (rule, value: string, cb: any) => {
    const { studyId } = this.props;
    const { userType } = this.state;
    try {
      if(userType === UserType.ADMIN) {
        if(!value || value.length === 0) {
          throw new Error('Username is a required field.')
        }
        this.setState({ usernameStatus: 'validating' });
        const isUsernameAvailable = await axios({
          method: 'get',
          url: `/a/usmg/availability/username/${value}?isAdminUser=true&studyId=${studyId}`
        });
        if(!isUsernameAvailable.data) {
          this.setState({ username: undefined, usernameStatus: 'error' });
          throw new Error('This username is already in-use.');
        }
        this.setState({ username: value, usernameStatus: 'success' });
        cb();
      } else {
        cb();
      }
    } catch (err) {
      cb(err);
    }
  };
  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };
  onConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmPassword: event.target.value });
  };
  passwordValidator = (rule, value: string, cb: any) => {
    try {
      if(value && value.length < 8) {
        throw new Error('Please enter a password that\'s at least 8 characters long.');
      }
      cb();
    } catch (err) {
      cb(err);
    }
  };
  confirmPasswordValidator = (rule, value: string, cb: any) => {
    try {
      if(value !== this.state.password) {
        throw new Error('Passwords do not match.');
      }
      cb()
    } catch (err) {
      cb(err);
    }
  };
  onRoleChange = (value: string) => {
    this.setState({ roleId: value });
  };
  onBirthdayChange = (date: Moment | null, dateString: string) => {
    this.setState({ dateOfBirth: dateString });
  }
  onGenderIdentityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ genderIdentity: event.target.value });
  };
  onCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ city: event.target.value });
  };
  onStateChange = (value: string) => {
    this.setState({ state: value });
  };
  onPostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ postalCode: event.target.value });
  };
  onMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ mobile: event.target.value });
  };
  onReferralCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ referralCode: event.target.value });
  };
  handleCreateUser = () => {
    const { createUser, studyId } = this.props;
    const {
      userType
    } = this.state;
    let user: NewUserType = this.state;
    user.hivStatus = -1;
    user.studyId = studyId;
    if(userType === UserType.PARTICIPANT) {
      user.username = undefined;
      user.password = undefined;
      user.roleId = undefined;
    }
    if(userType === UserType.ADMIN) {
      user.studyArmId = -1;
    }
    createUser(user);
    this.resetState();
  };
  resetState = () => {
    this.props.form.resetFields();
    this.setState({
      userType: UserType.PARTICIPANT,
      email: undefined,
      studyArmId: undefined,
      studyId: undefined,
      firstName: undefined,
      hivStatus: undefined,
      dateOfBirth: undefined,
      genderIdentity: undefined,
      city: undefined,
      state: undefined,
      mobile: undefined,
      postalCode: undefined,
      referralCode: undefined,
      username: undefined,
      password: undefined,
      roleId: undefined,
      confirmPassword: undefined
    });
  };
  handleReset = () => {
    const resetState = this.resetState.bind(this);
    confirm({
      title: 'Are you sure you want to reset this form?',
      content: 'You will lose all changes.',
      okText: 'Reset',
      okType: 'danger',
      onOk() {
        resetState();
      },
      onCancel() {}
    });
  };
  handleSave = () => {
    const { form } = this.props;
    if(form) {
      form.validateFields((err: any, values: any) => {
        if (!err) {
          const handleCreateUser = this.handleCreateUser.bind(this);
          confirm({
            title: `Are you sure you want to create this user?`,
            content: '',
            okText: 'Confirm',
            onOk() {
              handleCreateUser();
              message.success('Success! Please refresh screen to load new data.');
            },
            onCancel() {}
          });
        }
      });
    }
  }
  capitalize = (s: string): string => s ? s.split(' ').map(word => word[0].toUpperCase() + word.substring(1)).join(' ') : s;
  render() {
    const { form, arms, adminRoles, createUserStatus, clearStatus } = this.props;
    const { getFieldDecorator } = form;
    const {
      userType,
      email,
      studyArmId,
      studyId,
      firstName,
      hivStatus,
      dateOfBirth,
      genderIdentity,
      city,
      state,
      mobile,
      postalCode,
      referralCode,
      username,
      password,
      roleId,
      confirmPassword,
      usernameStatus
    } = this.state;
    if(createUserStatus.isError) {
      message.error('Error creating user.');
      clearStatus(getType(createUserAsync.failure));
    } else if(createUserStatus.isSuccess) {
      message.success('User created successfully.');
      clearStatus(getType(createUserAsync.success));
    }
    return (
    <div className='form-container'>
      <Card className='user-creation-form' 
        title={
          <div className='announcement-title'>
            <h1>User Creation</h1>
          </div>
        }>
          <FormContextProvider>
              <Form key="user-creation-form" layout="vertical" colon={false}>
                <Collapse bordered={false} activeKey={userType}>
                  <Panel
                    className="collapse-header"
                    showArrow={false}
                    key={UserType.ADMIN}
                    header={(
                      <Item key='type-select' label="Select the type of user:" style={itemStyle}>
                        {getFieldDecorator('type', {
                          rules: [{ required: true }],
                          initialValue: userType
                        })(<Select onChange={this.onUserTypeChange}>
                          {this.availableUserTypes.map(option => <Option value={option}>{this.capitalize(option.toLowerCase())}</Option>)}
                        </Select>)}
                      </Item>)}
                  >
                    <Item key='role-select' label="Role:" style={itemStyle}>
                      {getFieldDecorator('roleId', {
                        rules: [{ required: userType === UserType.ADMIN }],
                        initialValue: roleId
                      })(<Select onChange={this.onRoleChange}>
                        {adminRoles?.map(role => <Option value={role.id}>{role.role}</Option>)}
                      </Select>)}
                    </Item>
                    <Item 
                      key='username-input'
                      label="Username:" 
                      validateStatus={usernameStatus}
                      style={itemStyle}>
                      {getFieldDecorator('username', {
                        rules: [{ required: userType === UserType.ADMIN, validator: this.usernameValidator }],
                        validateTrigger: ['onBlur', 'onSubmit'],
                        initialValue: username
                      })(<Input placeholder='Username' onChange={this.onUsernameChange} />)}
                    </Item>
                    <Item key='password-input' label="Password:" style={itemStyle}>
                      {getFieldDecorator('password', {
                        rules: [{ required: userType === UserType.ADMIN, validator: this.passwordValidator }],
                        validateTrigger: ['onBlur', 'onChange'],
                        initialValue: password
                      })(<Password visibilityToggle={true} placeholder='Password' onChange={this.onPasswordChange} />)}
                    </Item>
                    <Item key='confirm-password-input' label="Confirm Password:" style={itemStyle}>
                      {getFieldDecorator('confirmPassword', {
                        rules: [{ required: userType === UserType.ADMIN, validator: this.confirmPasswordValidator }],
                        validateTrigger: ['onBlur', 'onChange'],
                        initialValue: confirmPassword
                      })(<Password visibilityToggle={true} placeholder='Confirm Password' onChange={this.onConfirmPasswordChange} />)}
                    </Item>
                  </Panel>
                </Collapse>
                {userType !== UserType.ADMIN && <Item key='arm-select' label="Select the study arm:" style={itemStyle}>
                  {getFieldDecorator('study arm', {
                    rules: [{ required: userType !== UserType.ADMIN }],
                    initialValue: studyArmId
                  })(<Select onChange={this.onStudyArmChange}>
                    {arms?.map(arm => <Option value={arm.id}>{arm.name}</Option>)}
                  </Select>)}
                </Item>}
                <Item key='email-input' label="Email:" style={itemStyle}>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, validator: this.emailValidator }],
                    validateTrigger: ['onSubmit', 'onBlur'],
                    initialValue: email
                  })(<Input placeholder='email@email.com' onChange={this.onEmailChange} />)}
                </Item>
                <Item key='first-name-input' label="First Name:" style={itemStyle}>
                  {getFieldDecorator('firstName', {
                    rules: [{ required: true }],
                    initialValue: firstName
                  })(<Input placeholder='First name' onChange={this.onFirstNameChange} />)}
                </Item>
                <Collapse>
                  <Panel header='Optional' key='0'>
                    <Item key='dob-input' label="Date of Birth:" style={itemStyle}>
                      {getFieldDecorator('dateOfBirth', {
                        rules: [{ required: false }],
                        initialValue: dateOfBirth ? moment(dateOfBirth) : undefined
                    })(<DatePicker onChange={this.onBirthdayChange} />)}
                    </Item>
                    <Item key='gender-identity-input' label="Gender Identity:" style={itemStyle}>
                      {getFieldDecorator('genderIdentity', {
                        rules: [{ required: false }],
                        initialValue: genderIdentity
                      })(<Input placeholder='Gender Identity' onChange={this.onGenderIdentityChange} />)}
                    </Item>
                    <Item key='city-input' label="City:" style={itemStyle}>
                      {getFieldDecorator('city', {
                        rules: [{ required: false }],
                        initialValue: city
                      })(<Input placeholder='City' onChange={this.onCityChange} />)}
                    </Item>
                    <Item key='state-input' label="State:" style={itemStyle}>
                      {getFieldDecorator('state', {
                        rules: [{ required: false }],
                        initialValue: state
                      })(<Select onChange={this.onStateChange}>
                        {statesAndTerritories?.map(state => <Option value={state.code}>{state.name}</Option>)}
                      </Select>)}
                    </Item>
                    <Item key='postal-code-input' label="Postal Code:" style={itemStyle}>
                      {getFieldDecorator('postalCode', {
                        rules: [{ required: false }],
                        initialValue: postalCode
                      })(<Input placeholder='Postal Code' onChange={this.onPostalCodeChange} />)}
                    </Item>
                    <Item key='mobile-input' label="Mobile Phone:" style={itemStyle}>
                      {getFieldDecorator('mobile', {
                        rules: [{ required: false }],
                        initialValue: mobile
                      })(<Input placeholder='Mobile Phone' onChange={this.onMobileChange} />)}
                    </Item>
                    <Item key='referral-input' label="Referral Code:" style={itemStyle}>
                      {getFieldDecorator('referralCode', {
                        rules: [{ required: false }],
                        initialValue: referralCode
                      })(<Input placeholder='Referral Code' onChange={this.onReferralCodeChange} />)}
                    </Item>
                  </Panel>
                </Collapse>
                <div className='actions'>
                  <Button className='action' type='default' onClick={this.handleReset}>Reset</Button>
                  <Button className='action' type='primary' onClick={this.handleSave}>Save</Button>
                </div>
              </Form>
            </FormContextProvider>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    adminRoles: selectors.getAdminRoles(state),
    studyId: selectors.getRequestedStudyId(state),
    arms: selectors.getRequestedStudyStudyArms(state),
    createUserStatus: selectors.createUserStatus(state),
    isHMPStigma: Config.isHMPStigma()
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    createUser: (user: NewUserType) => dispatch(createUserAsync.request(user)),
    clearStatus: (type: string) => dispatch(clearStatus(type))
  };
};

export default Form.create({ name: 'user-creation-form' })(connect(mapStateToProps, mapDispatchToProps)(UserCreationPage));