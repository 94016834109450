import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { NormalizerResult, NotificationSubscription } from '../../types/entity.types';
import axios from '../api';
import { getQnaPostAsync } from '../qna/qna.types';
import { entitySchema } from '../schema';
import { getUserAsync } from '../user/user.types';
import {
  getMyAdminNotificationSubscriptionsAsync,
  getParticipantNotificationsAsync, sendAdHocAdminQnaNotificationAsync,
  updateNotificationSubscriptionAsync
} from './notification.types';

const updateNotificationSubscription = (subscription:NotificationSubscription) => {
  const emailOn = subscription.emailOn ? "true": "false";
  const smsOn = subscription.smsOn ? "true": "false";

  return axios({
    method: 'put',
    url: `/a/notification/${subscription.label}/subscribe?emailOn=${emailOn}&smsOn=${smsOn}`
  });
}


function* updateNotificationSubscriptionHandler(action: ReturnType<typeof updateNotificationSubscriptionAsync.request>): Generator {
  try {
    const subscription = action.payload;
    const response: AxiosResponse = (yield call(updateNotificationSubscription, subscription )) as AxiosResponse;

    yield put(getUserAsync.request(subscription.userId));
  } catch (error) {
    yield put(updateNotificationSubscriptionAsync.failure(error));
  }
}

function* updateNotificationSubscriptionWatcher() {
  yield takeLatest(getType(updateNotificationSubscriptionAsync.request), updateNotificationSubscriptionHandler);
}

const getMyAdminNotificationSubscriptions = () => {
  return axios({
    method: 'get',
    url: `/a/notification/myAdminNotificationSubscriptions`
  });
}

function* getMyAdminNotificationSubscriptionsHandler(action: ReturnType<typeof getParticipantNotificationsAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getMyAdminNotificationSubscriptions )) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.notifications) as NormalizerResult;
    const { notifications } = entities;

    yield put(getMyAdminNotificationSubscriptionsAsync.success(notifications));
  } catch (error) {
    yield put(getMyAdminNotificationSubscriptionsAsync.failure(error));
  }
}

function* getMyAdminNotificationSubscriptionsWatcher() {
  yield takeLatest(getType(getMyAdminNotificationSubscriptionsAsync.request), getMyAdminNotificationSubscriptionsHandler);
}

const getParticipantNotifications = (participantId: number) => {
  return axios({
    method: 'get',
    url: `/a/notification/participantNotifications?participantId=${participantId}`
  });
};

function* getParticipantNotificationsHandler(action: ReturnType<typeof getParticipantNotificationsAsync.request>): Generator {
  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = (yield call(getParticipantNotifications, participantId)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.notifications) as NormalizerResult;
    const { notifications } = entities;

    yield put(getParticipantNotificationsAsync.success(notifications));
  } catch (error) {
    yield put(getParticipantNotificationsAsync.failure(error));
  }
}

function* getParticipantNotificationsWatcher() {
  yield takeLatest(getType(getParticipantNotificationsAsync.request), getParticipantNotificationsHandler);
}

const sendAdHocAdminQnaNotification = (userId: number, qnaPostId: number) => {
  return axios({
    method: 'put',
    url: `/a/notification/${userId}/${qnaPostId}/sendUserAdHocQnaEmail`
  });
};

function* sendAdHocAdminQnaNotificationHandler(action: ReturnType<typeof sendAdHocAdminQnaNotificationAsync.request>): Generator {
  try {
    const { userId, qnaPostId } = action.payload;
    const response: AxiosResponse = (yield call(sendAdHocAdminQnaNotification, userId, qnaPostId)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.notifications) as NormalizerResult;
    const { notifications } = entities;

    yield put(sendAdHocAdminQnaNotificationAsync.success(notifications));

    yield put(getQnaPostAsync.request(qnaPostId));

  } catch (error) {
    yield put(sendAdHocAdminQnaNotificationAsync.failure(error));
  }
}

function* sendAdHocAdminQnaNotificationWatcher() {
  yield takeLatest(getType(sendAdHocAdminQnaNotificationAsync.request), sendAdHocAdminQnaNotificationHandler);
}

export default function* notificationsSaga() {
  yield all([
    fork(getParticipantNotificationsWatcher),
    fork(getMyAdminNotificationSubscriptionsWatcher),
    fork(updateNotificationSubscriptionWatcher),
    fork(sendAdHocAdminQnaNotificationWatcher)
  ]);
}
