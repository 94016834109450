import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { ArticleType, AvatarType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { entitySchema } from '../schema';
import { selectTopics } from '../topics/topic.selectors';

export const selectArticles = (state: IApplicationState) => {
  return state.entities.articles;
};

export const selectedArticleIds = (state: IApplicationState) => {
  return state.ui.articles.articleIds;
};

export const selectSavedArticleId = (state: IApplicationState) => {
  return state.ui.articles.savedArticleId;
};

export const getSelectedArticles = createSelector(
  [selectArticles, selectedArticleIds, selectTopics],
  (articles, articleIds, selectTopics): Optional<ArticleType[]> => {
    if (articles && articles.allIds && articles.allIds.length > 0) {
      const { articles: denormalizedArticles } = denormalize({ articles: articleIds }, entitySchema, { articles: articles.byId, topics: selectTopics.byId });
      return toArray(denormalizedArticles);
    }
    return undefined;
  }
);

export default { selectArticles, selectedArticleIds, getSelectedArticles };
