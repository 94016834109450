import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import {
  all, call, fork, put, takeLatest, takeEvery
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { NormalizerResult, PostType } from 'types/entity.types';
import axios from '../api';
import { getCommentsAsync } from '../comments/comments.types';
import { loadDashboardDataAsync } from '../dashboard/dashboard.types';
import { getFavoritesAsync } from '../favorites/favorites.types';
import { getFlagsAsync } from '../flags/flags.types';
import { updateFlaggedPostIds, updateRecentActivityPostIds, updateUncategorizedPostIds } from '../forum/forum.types';
import { entitySchema } from '../schema';
import { getThumbsupsAsync } from '../thumbsups/thumbsups.types';
import createJsonParamFromPosts, { createJsonParam } from '../user/socialUtil';
import {
  getParticipantPostsAsync,
  GetPostsSearchType,
  getPostsAsync,
  getUncategorizedPostsAsync,
  savePostAsync,
  getFlaggedPostsAsync,
  deletePostAsync
} from './posts.types';


let lastPostParams: GetPostsSearchType;

const getFlaggedPosts = () => {
  return axios( {
    method: 'get',
    url: `/a/forum/flagged`
  });
}
const savePost = (post:PostType) => {
  return axios({
    method: 'put',
    url: `/a/forum/post`,
    data: post
  });
}
const getPosts = (postsSearch: GetPostsSearchType) => {
  const url = `/a/forum/posts`
  return axios({
    method: 'post',
    url,
    data: postsSearch
  });
};

const getParticipantPosts = (participantId: number) => {
  return axios({
    method: 'post',
    url: `/a/forum/posts`,
    data: {participantId}
  });
};

const getUncategorizedPosts = () => {
  return axios({
    method: 'get',
    url: `/a/forum/uncategorized/1`
  });
};

const deletePost = (id: number) => {
  return axios({
    method: 'delete',
    url: `/a/forum/post/${id}`
  });
};

function* refreshPostsHandler() {
  const refreshPostsParams = {...lastPostParams, pageNumber:0, pageSize: (lastPostParams.pageNumber+1) * lastPostParams.pageSize};
  yield put(getPostsAsync.request(refreshPostsParams));
}

function* getPostsHandler(action: ReturnType<typeof getPostsAsync.request>): Generator {

  try {
    lastPostParams = action.payload;
    const postsSearch: GetPostsSearchType = action.payload;
    const response: AxiosResponse = (yield call(getPosts, postsSearch)) as AxiosResponse;
    const { entities, result } = normalize(response.data, entitySchema.posts) as NormalizerResult;
    const { posts } = entities;

    if (result?.length) {
      const jsonParam = [createJsonParam('post', result)];
      yield put(getFlagsAsync.request(jsonParam));
      yield put(getCommentsAsync.request(jsonParam));
      yield put(getThumbsupsAsync.request(jsonParam));
      yield put(getFavoritesAsync.request(jsonParam));
    }

    yield put(getPostsAsync.success(posts));
    yield put(updateRecentActivityPostIds(result));
  } catch (error) {
    yield put(getPostsAsync.failure(error));
  }
}

function* getPostsWatcher() {
  yield takeLatest(getType(getPostsAsync.request), getPostsHandler);
}

function* getParticipantPostsHandler(action: ReturnType<typeof getParticipantPostsAsync.request>): Generator {

  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = (yield call(getParticipantPosts, participantId)) as AxiosResponse;
    const { entities, result } = normalize(response.data, entitySchema.posts) as NormalizerResult;
    const { posts } = entities;

    if (result?.length) {
      const jsonParam = [createJsonParam('post', result)];
      yield put(getFlagsAsync.request(jsonParam));
      yield put(getCommentsAsync.request(jsonParam));
      yield put(getThumbsupsAsync.request(jsonParam));
      yield put(getFavoritesAsync.request(jsonParam));
    }

    yield put(getParticipantPostsAsync.success(posts));
  } catch (error) {
    yield put(getParticipantPostsAsync.failure(error));
  }
}

function* getParticipantPostsWatcher() {
  yield takeLatest(getType(getParticipantPostsAsync.request), getParticipantPostsHandler);
}

function* getUncategorizedPostsHandler(action: ReturnType<typeof getUncategorizedPostsAsync.request>): Generator {

  try {
    const response: AxiosResponse = (yield call(getUncategorizedPosts)) as AxiosResponse;
    const { entities, result } = normalize(response.data, entitySchema.posts) as NormalizerResult;
    const { posts } = entities;

    if (result?.length) {
      const jsonParam = [createJsonParam('post', result)];
      yield put(getFlagsAsync.request(jsonParam));
      yield put(getCommentsAsync.request(jsonParam));
      yield put(getThumbsupsAsync.request(jsonParam));
      yield put(getFavoritesAsync.request(jsonParam));
    }

    yield put(updateUncategorizedPostIds(result));
    yield put(getUncategorizedPostsAsync.success(posts));
  } catch (error) {
    yield put(getUncategorizedPostsAsync.failure(error));
  }
}


function* savePostHandler(action: ReturnType<typeof savePostAsync.request>): Generator {
  try {
    const { payload: post } = action;
    const response: AxiosResponse = (yield call(savePost, post)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.posts) as NormalizerResult;
    const { posts } = entities;

    yield put(savePostAsync.success(posts));
    yield put(loadDashboardDataAsync.request());
    // yield put(getUncategorizedPostsAsync.request());
    yield call(refreshPostsHandler);
  } catch (error) {
    yield put(savePostAsync.failure(error));
  }
}

function* getFlaggedPostsHandler(action: ReturnType<typeof getFlaggedPostsAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getFlaggedPosts)) as AxiosResponse;

    const { entities, result } = normalize(response.data, entitySchema.posts) as NormalizerResult;
    const { posts } = entities;

    if (result?.length) {
      const jsonParam = [createJsonParam('post', result)];
      yield put(getFlagsAsync.request(jsonParam));
      yield put(getCommentsAsync.request(jsonParam));
      yield put(getThumbsupsAsync.request(jsonParam));
      yield put(getFavoritesAsync.request(jsonParam));
    }

    yield put(getFlaggedPostsAsync.success(posts));
    yield put(updateFlaggedPostIds(result));
  } catch (error) {
    yield put(getFlaggedPostsAsync.failure(error));
  }
}

function* deletePostHandler(action: ReturnType<typeof deletePostAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(deletePost, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.posts) as NormalizerResult;
    const { posts } = entities;

    yield put(loadDashboardDataAsync.request());
    yield put(getUncategorizedPostsAsync.request());
    yield put(deletePostAsync.success(posts));
  } catch (error) {
    yield put(deletePostAsync.failure(error));
  }
}

function* getUncategorizedPostsWatcher() {
  yield takeLatest(getType(getUncategorizedPostsAsync.request), getUncategorizedPostsHandler);
}

function* savePostWatcher() {
  yield takeEvery(getType(savePostAsync.request), savePostHandler);
}

function* getFlaggedPostsWatcher() {
  yield takeLatest(getType(getFlaggedPostsAsync.request), getFlaggedPostsHandler);
}

function* deletePostWatcher() {
  yield takeLatest(getType(deletePostAsync.request), deletePostHandler);
}

export default function* postsSaga() {
  yield all([
    fork(getParticipantPostsWatcher),
    fork(getPostsWatcher),
    fork(getUncategorizedPostsWatcher),
    fork(savePostWatcher),
    fork(getFlaggedPostsWatcher),
    fork(deletePostWatcher)
  ]);
}
