import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { StudyType } from '../../../types/entity.types';
import IApplicationState from '../../../types/state.types';
import * as selectors from '../../../redux/selectors';

interface StateProps {
  studies: Optional<StudyType[]>
}

interface ComponentProps extends StateProps{
}

class Admin extends Component<ComponentProps, {}> {

  render() {

    const { studies } = this.props;

    return (

      <div>
        <h1>Admin</h1>
        {studies ? studies.map(study => {
          return <div key={study.id}><Link to={`/study/${study.id}`}>{study.name}</Link></div>;
        }) : null}
      </div>

    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  studies: selectors.getStudies
});

export default connect(mapStateToProps)(Admin);
