import React, { Component } from 'react';
import { connect } from 'react-redux';
import IApplicationState from '../../types/state.types';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import _ from 'lodash';
import * as selectors from '../../redux/selectors';
import { Popover } from 'antd';
import { ParticipantType, StudyArmType } from '../../types/entity.types';
import './username.scss';

interface StateProps {
  participants: Optional<ParticipantType[]>;
  studyArms: Optional<StudyArmType[]>;
  studyId: number;
}

interface ComponentProps extends StateProps {
  participantId: number,
  style?: any;
  link?: string;
}

class Username extends Component<ComponentProps, {}> {
  readonly state = {
  }
  renderPopover = () => {
    const { participantId, participants, studyArms } = this.props;
    const currentParticipant = participants?.find(p => p.id === participantId);
    const currentStudyArm = studyArms?.find(sa => sa.id === currentParticipant?.studyArmId);
    if(!currentParticipant) {
      return <span>No Participant Found.</span>
    }
    return (<div className='username-popover'>
      <span><b>ID:</b> {currentParticipant.id}</span>
      <span><b>Name:</b> {`${currentParticipant.firstName} ${currentParticipant.lastName}`}</span>
      <span><b>Study Arm:</b> {currentStudyArm?.name}</span>
      <span><b>HIV Status:</b> {currentParticipant.actualHivStatus}</span>
    </div>);
  };
  render() {
    const {
      participantId,
      participants,
      style,
      studyId,
      link
    } = this.props;
    const currentParticipant = participants?.find(p => p.id === participantId);
    if(!currentParticipant) {
      return <span>No Participant Found.</span>
    }
    return (<Popover content={this.renderPopover()}>
      <Link style={style ? style : {}} to={link ? link : `/study/${studyId}/participants/${participantId}`}>{currentParticipant.username}</Link>
    </Popover>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  participants: selectors.getParticipants,
  studyArms: selectors.getStudyArms,
  studyId: selectors.getRequestedStudyId
});

export default connect(mapStateToProps, null)(Username);