import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import IApplicationState from '../../types/state.types';

export const selectRouter = (state: IApplicationState) => {
  return state.router;
};

export const selectRouterLocation = createSelector(
  [selectRouter],
  (router) => {
    return router ? router.location : undefined;
  }
);

export const selectRouterLocationPathname = createSelector(
  [selectRouterLocation],
  (location) => {
    return location ? location.pathname : undefined;
  }
);

export const getRequestedStudyId = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return -1;
    }

    const match = matchPath<{studyId: string}>(pathname, {
      path: '/study/:studyId'
    });

    if (match && match.params && match.params.studyId) {

      if (!isNaN(parseInt(match.params.studyId, 10))) {
        return parseInt(match.params.studyId, 10);
      }
    }
    return -1;
  }
);

export const getRequestedTestkitTab = createSelector(
 [selectRouterLocationPathname],
  (pathname) => {
   if (!pathname) {
     return '';
   }

   const match = matchPath<{testkitSubpage: string}> (pathname, {
     path: '/study/:studyId/testkits/:testkitSubpage'
   });

   if (match && match.params && match.params.testkitSubpage) {
     return match.params.testkitSubpage;
   }

   return '';
  }
);


export const getRequestedForumPostId = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return undefined;
    }

    const match = matchPath<{postId: string}> (pathname, {
      path: '/study/:studyId/forums/:forumSubpage/:postId'
    });

    if (match && match.params && match.params.postId) {
      return parseInt(match.params.postId);
    }

    return undefined;
  }
);

export const getRequestedParticipantTab = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return '';
    }

    const match = matchPath<{participantSubpage: string}> (pathname, {
      path: '/study/:studyId/participants/:participantId/:participantSubpage'
    });

    if (match && match.params && match.params.participantSubpage) {
      return match.params.participantSubpage;
    }

    return '';
  }
);

export const getRequestedForumsTab = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return '';
    }

    const match = matchPath<{forumsSubpage: string}> (pathname, {
      path: '/study/:studyId/forums/:forumsSubpage'
    });

    if (match && match.params && match.params.forumsSubpage) {
      return match.params.forumsSubpage;
    }

    return '';
  }
);


export const getRequestedTestkitId = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return -1;
    }

    const match = matchPath<{testkitId: string}>(pathname, {
      path: '/testkit/:testkitId'
    });

    if (match && match.params && match.params.testkitId) {

      if (!isNaN(parseInt(match.params.testkitId, 10))) {
        return parseInt(match.params.testkitId, 10);
      }
    }
    return -1;
  }
);

export const getRequestedParticipantId = (state: IApplicationState) => {
  const { pathname } = state.router.location;
  const match = matchPath<{studyId: string, participantId: string}>(pathname, {
    path: '/study/:studyId/participants/:participantId'
  });

  if (match && match.params && match.params.participantId) {

    if (!isNaN(parseInt(match.params.participantId, 10))) {
      return parseInt(match.params.participantId, 10);
    }
  }
  return -1;
};

export const getRequestedResourceId = (state: IApplicationState) => {
  const { pathname } = state.router.location;
  const match = matchPath<{studyId: string, resourceId: string}>(pathname, {
    path: '/study/:studyId/resources/:resourceId'
  });

  if (match && match.params && match.params.resourceId) {

    if (!isNaN(parseInt(match.params.resourceId, 10))) {
      return parseInt(match.params.resourceId, 10);
    }
  }
  return -1;
};

export const getRequestedEditResourceId = (state: IApplicationState) => {
  const { pathname } = state.router.location;
  const match = matchPath<{studyId: string, resourceId: string}>(pathname, {
    path: '/study/:studyId/resources/edit/:resourceId'
  });

  if (match && match.params && match.params.resourceId) {

    if (!isNaN(parseInt(match.params.resourceId, 10))) {
      return parseInt(match.params.resourceId, 10);
    }
  }
  return -1;
};
