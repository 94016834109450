import React, { Component } from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import {
  Input, Form, Icon, Tree
} from 'antd';
import '../activity.scss';
import { ActivityTreeNodeType, ActivityTreeOptionType } from '../../../types/entity.types';
import TreeOptionItems from './TreeOptionItems';
const { Item } = Form;
const { TreeNode } = Tree;

interface ComponentProps {
  form: any;
  creative: boolean;
  editable: boolean;
  node: ActivityTreeNodeType;
  level: number;
  setStatus: (status: string) => void;
  updateNode: (node: ActivityTreeNodeType) => void;
  removeNode: () => void;
}
const defaultNode = {
  uuid: '',
  text: '',
  options: []
};
const defaultOption = {
  uuid: '',
  text: ''
};
const itemStyle = {
  width: '100%',
  marginBottom: '10px', 
  marginTop: '10px'
};
enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener
}

class ActivityTreeNode extends Component<ComponentProps, {}> {
  readonly state = {
    opened: true
  };
  onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { node, updateNode } = this.props;
    e.preventDefault();
    node.text = e.target.value;
    updateNode(node);
  };
  validateNode = (rule, value, cb) => {
    const { node } = this.props;
    const { text } = node;
    try {
      if (node && text.length === 0){ 
        throw new Error('Tree question text is required.');
      }
      cb();
    } catch (err) {
      cb(err);
    }
  };
  onRemoveNode = (e: React.MouseEvent<HTMLElement>) => {
    const { removeNode } = this.props;
    e.preventDefault();
    removeNode();
  };
  updateOptions = (newOptions: ActivityTreeOptionType[]) => {
    const { node, updateNode } = this.props;
    if(newOptions) {
      node.options = newOptions;
      updateNode(node);
    }
  };
  addOption = () => {
    const { node, updateNode } = this.props;
    const { options } = node;
    const newOption = _.cloneDeep(defaultOption);
    newOption.uuid = generateUUID();
    if(options.length === 0) {
      node.options = [newOption]
    } else {
      options[options.length] = newOption;
    }
    updateNode(node);
  };
  removeOption = (index: number) => {
    const { node, updateNode } = this.props;
    const { options } = node;
    let i = 0;
    node.options = options ? options.filter(o => { return index === i++ ? false : true; }) : [];
    updateNode(node);
  };
  toggleOpen = () => {
    this.setState({ opened: !this.state.opened });
  }
  render () {
    const { opened } = this.state;
    const {
      form,
      creative,
      editable,
      node,
      level,
      setStatus,
    } = this.props;
    const { text, options, uuid, id } = node;
    const { getFieldDecorator } = form;
    return (
    <div className='activity-tree-node'>
      {level > 0 && <div className='tree-option-icon'>
        <Icon type={opened ? 'minus-square' : 'plus-square'} onClick={() => this.toggleOpen()} />
      </div>}
      <div className='tree-node-content'>
        <Item
          required={false}
          key={`node-question-${id ? id : uuid}`}
          style={itemStyle}
        >
          {getFieldDecorator(`node-question-${id ? id : uuid}`, {
            validateTriggers: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                message: 'Please input question text or delete this question.',
                validator: this.validateNode
              }
            ],
            initialValue: text
          })(<Input spellCheck disabled={!creative && !editable} placeholder='Question text' onChange={this.onTextChange} style={{ width: '100%' }} />)}
        </Item>
        {opened && <ul className='tree-node-list'>
          <TreeOptionItems 
            form={form}
            creative={creative}
            editable={editable}
            options={options ? options : []} 
            level={level+1}
            setStatus={setStatus}
            updateOptions={this.updateOptions} 
            addOption={this.addOption} 
            removeOption={this.removeOption}
          />
        </ul>}
      </div>
      {level > 0 && (creative || editable) && 
      <div className='tree-option-icon'>
        <Icon
          className='dynamic-delete-button'
          type='minus-circle-o' 
          onClick={this.onRemoveNode}
        />
      </div>}
    </div>);
  }
};

export default ActivityTreeNode;