import { Button, Card, Dropdown, Icon, Menu, Tabs } from "antd";
import Steps from "antd/lib/steps";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import BadgesTable from "../../../../components/badge/BadgesTable";
import AdverseEventForm from "../../../../components/form/AdverseEventForm";
import EarlyTerminationForm from "../../../../components/form/EarlyTerminationForm";
import FormTable from "../../../../components/form/FormTable";
import ProtocolDeviationForm from "../../../../components/form/ProtocolDeviationForm";
import SocialHarmForm from "../../../../components/form/SocialHarmForm";
import CommentsTable from "../../../../components/forum/CommentsTable";
import PostsTable from "../../../../components/forum/PostsTable";
import ThreadsTable from "../../../../components/forum/ThreadsTable";
import IncentivesTable from "../../../../components/incentives/IncentivesTable";
import NotificationLogTable from "../../../../components/notification/NotificationLogTable";
import UserSurveyTable from "../../../../components/survey/UserSurveyTable";
import TestkitTable from "../../../../components/testkit/TestkitTable";
import Config from "../../../../components/util/Config";
import { saveParticipantNoteAsync } from "../../../../redux/notes/notes.types";
import {
  loadConsentStatusesAsync,
  loadRequestedParticipantAsync,
  saveParticipantAsync
} from "../../../../redux/participants/participants.types";
import { loadCommentsStatus } from "../../../../redux/selectors";
import * as selectors from "../../../../redux/selectors";
import { IApiRequestState } from "../../../../types/api.types";
import {
  AvatarType,
  CommentType, ConsentStatusType,
  FormType,
  MessageThreadType,
  NoteType,
  NotificationType,
  ParticipantBadgeType,
  ParticipantType,
  PaymentType,
  PostType,
  StudyArmType,
  TestkitType,
  UserSurveyType
} from "../../../../types/entity.types";
import IApplicationState from "../../../../types/state.types";
import "./participant.scss";
import ParticipantDemographicsCard from "./ParticipantDemographicsCard";
import ParticipantEditCard from "./ParticipantEditCard";
import ParticipantNoteCard from "./ParticipantNoteCard";
import ParticipantViewCard from "./ParticipantViewCard";

const Tab = Tabs;

const { Step } = Steps;

const footerActionStyle = {
  textAlign: 'right',
  width: '50%'
};

interface StateProps {
  avatar: Optional<AvatarType>,
  loadParticipantStatus: IApiRequestState,
  studyArm: Optional<StudyArmType>
  participant: Optional<ParticipantType>,
  notes: Optional<NoteType[]>,
  posts: Optional<PostType[]>,
  comments: Optional<CommentType[]>,
  threads: Optional<MessageThreadType[]>,
  forms: Optional<FormType[]>,
  notifications: Optional<NotificationType[]>,
  testkits: Optional<TestkitType[]>,
  participantSubpage: Optional<string>,
  userSurveys: Optional<UserSurveyType[]>,
  incentives: Optional<PaymentType[]>,
  participantBadges: Optional<ParticipantBadgeType[]>,
  consentStatuses: Optional<ConsentStatusType[]>
}

interface DispatchProps {
  loadParticipant: typeof loadRequestedParticipantAsync.request,
  saveParticipant: typeof saveParticipantAsync.request,
  saveParticipantNote: typeof saveParticipantNoteAsync.request
  loadConsentStatuses: typeof loadConsentStatusesAsync.request,
}

interface ComponentProps extends StateProps, DispatchProps {}

const initialState = {
  inEditMode: false as boolean,
  editedParticipant: {} as ParticipantType,
  selectedKey: 'posts' as string,
  activeForm: ''
};
type ComponentState = Readonly<typeof initialState>;

class Participant extends Component<ComponentProps, ComponentState> {

  readonly state: ComponentState = initialState;

  componentDidMount(): void {
    const { loadParticipant, loadConsentStatuses } = this.props;
    loadParticipant();
    loadConsentStatuses();
  }

  handleEditClick = () => {
    const { participant } = this.props;
    if (participant) {
      this.setState({ inEditMode: true, editedParticipant: participant });
    }
  };

  handleSaveClick = (participant: ParticipantType) => {
    const { saveParticipant } = this.props;
    saveParticipant(participant);
    console.log(participant);
    this.setState({ inEditMode: false });
  };

  handleSaveNoteClick = (note: NoteType) => {
    const { saveParticipantNote } = this.props;
    saveParticipantNote(note);
  };

  handleCancelClick = () => {
    this.setState({ inEditMode: false });
  };

  closeForm = () => {
    this.setState({
      activeForm: ''
    });
  };

  handleMenuClick(e: any) {
    this.setState({
      activeForm: e.key
    });
  }

  formOptions = (
    <Menu onClick={this.handleMenuClick.bind(this)}>
      <Menu.Item key="ADVERSE_EVENT">
        Adverse Event
      </Menu.Item>
      <Menu.Item key="EARLY_TERMINATION">
        Early Termination
      </Menu.Item>
      <Menu.Item key="PROTOCOL_DEVIATION">
        Protocol Deviation
      </Menu.Item>
      <Menu.Item key="SOCIAL_HARM">
        Social Harm
      </Menu.Item>
    </Menu>
  );

  renderFormModal = (type: string) => {
    switch (type) {
      case 'ADVERSE_EVENT':
        return <AdverseEventForm editable={true} closeHandler={this.closeForm} visible={this.state.activeForm === type} />;
      case 'EARLY_TERMINATION':
        return <EarlyTerminationForm editable={true} closeHandler={this.closeForm} visible={this.state.activeForm === type} />;
      case 'PROTOCOL_DEVIATION':
        return <ProtocolDeviationForm editable={true} closeHandler={this.closeForm} visible={this.state.activeForm === type} />;
      case 'SOCIAL_HARM':
        return <SocialHarmForm editable={true} closeHandler={this.closeForm} visible={this.state.activeForm === type} />;
      default:
        return <div />;
    }
  };

  render() {
    const { avatar, loadParticipantStatus, studyArm, participant, posts, notes, threads, forms, notifications, testkits, comments, userSurveys, incentives, participantBadges, consentStatuses } = this.props;
    const participantSubpage = this.props.participantSubpage === '' ? 'Timeline' : this.props.participantSubpage;
    const { editedParticipant, activeForm } = this.state;

    if (loadParticipantStatus.isLoading) {
      return <div>Loading Participant</div>;
    }

    if (loadParticipantStatus.isError) {
      return <div>Error loading the requested participant.</div>;
    }

    if (studyArm === undefined) {
      return (
        <div>The participant is assigned to an invalid study arm or you do not have permissions to view the study.</div>
      );
    }

    if (participant === undefined) {
      return (
        <div>The participant does not exist or you do not have permissions to view the participant.</div>
      );
    }

    const avatarBase64 = avatar ? avatar.avatar : undefined;

    const showSurveys = () => {
      return !Config.isHMPStyle();
    }
    const showForms = () => {
      return !Config.isHMPStyle();
    }
    const showTestKits = () => {
      return !Config.isHMPStyle();
    }
    const showIncentives = () => {
      return !Config.isHMPStyle();
    }

    return (
      <div id="participant">
        <Steps type="navigation" size="small" current={1}>
          <Step title="Accepted" />
          <Step title={`${_.capitalize(participant.status)}`} />
          <Step title="Finished" />
        </Steps>
        <ParticipantViewCard avatarBase64={avatarBase64} participant={participant} />
        <ParticipantNoteCard participant={participant} notes={notes} saveClickHandler={this.handleSaveNoteClick} />
        {this.renderFormModal(activeForm)}
        <div id="tab-bar">
          <Tab defaultActiveKey={participantSubpage}>
            {/*<Tab.TabPane tab="Timeline" key="1">*/}
            {/*  <div>*/}
            {/*    <ParticipantTimelineCard />*/}
            {/*  </div>*/}
            {/*</Tab.TabPane>*/}
            <Tab.TabPane tab="Demographics" key="2">
              <div>
                {this.state.inEditMode
                  ? <ParticipantEditCard studyArm={studyArm} consentStatuses={consentStatuses} initialParticipant={participant} saveClickHandler={this.handleSaveClick} cancelClickHandler={this.handleCancelClick} />
                  : <ParticipantDemographicsCard consentStatuses={consentStatuses} studyArm={studyArm} participant={participant} editClickHandler={this.handleEditClick} />
                }
              </div>
            </Tab.TabPane>
            {showSurveys() && <Tab.TabPane tab="User Surveys" key="userSurveys">
              <div>
                <UserSurveyTable userSurveys={userSurveys} />
              </div>
            </Tab.TabPane>}
            <Tab.TabPane tab="Posts" key="forum">
              <div>
                <PostsTable posts={posts} />
              </div>
            </Tab.TabPane>
            <Tab.TabPane tab="Comments" key="comments">
              <div>
                <CommentsTable comments={comments} />
              </div>
            </Tab.TabPane>
            <Tab.TabPane tab="Message Threads" key="4">
              <Card>
                <div>
                  <ThreadsTable threads={threads} />
                </div>
              </Card>
            </Tab.TabPane>
            {showForms() && <Tab.TabPane tab="Forms" key="5">
              <Card style={{background: 'white'}} title={
                <Dropdown style={footerActionStyle} overlay={this.formOptions}>
                  <Button type="default">New Form <Icon type="down"/></Button>
                </Dropdown>
              }>
                <FormTable forms={forms} />
              </Card>
            </Tab.TabPane>}
            <Tab.TabPane tab="Notifications" key="6">
              <div>
                <NotificationLogTable participantNotifications={notifications} />
              </div>
            </Tab.TabPane>
            {showTestKits() && <Tab.TabPane tab="Test Kits" key="7">
              <div>
                <TestkitTable participantTestkits={testkits} />
              </div>
            </Tab.TabPane>}
            {showIncentives() && <Tab.TabPane tab="Incentives" key="8">
              <div>
                <IncentivesTable participantId={participant.id} participantIncentives={incentives} />
              </div>
            </Tab.TabPane>}
            <Tab.TabPane tab="Badges" key="9">
              <div>
                <BadgesTable participantBadges={participantBadges}/>
              </div>
            </Tab.TabPane>
          </Tab>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  avatar: selectors.getRequestedStudyParticipantAvatar,
  loadParticipantStatus: selectors.loadRequestedParticipantStatus,
  studyArm: selectors.getRequestedStudyParticipantStudyArm,
  participant: selectors.getRequestedStudyParticipant,
  notes: selectors.getRequestedStudyParticipantNotes,
  posts: selectors.getRequestedStudyParticipantPosts,
  comments: selectors.getRequestedStudyParticipantComments,
  threads: selectors.getRequestedStudyParticipantThreads,
  forms: selectors.getRequestedParticipantForms,
  notifications: selectors.getRequestedParticipantNotifications,
  testkits: selectors.getRequestedParticipantTestkits,
  participantSubpage: selectors.getRequestedParticipantTab,
  userSurveys: selectors.getRequestedParticipantUserSurveys,
  incentives: selectors.getRequestedParticipantIncentives,
  participantBadges: selectors.getParticipantBadges,
  consentStatuses: selectors.getConsentStatuses
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadParticipant: () => dispatch(loadRequestedParticipantAsync.request()),
    saveParticipant: (participant: ParticipantType) => dispatch(saveParticipantAsync.request(participant)),
    saveParticipantNote: (note: NoteType) => dispatch(saveParticipantNoteAsync.request(note)),
    loadConsentStatuses: () => dispatch(loadConsentStatusesAsync.request())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Participant);
