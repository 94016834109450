import * as _ from 'lodash';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { getType } from 'typesafe-actions';
import { IApiRequestState, IApiRequestStatus } from '../types/api.types';
import {
  BadgeType,
  CommentType,
  FormType,
  MessageThreadType,
  NoteType,
  NotificationType,
  ParticipantType,
  PaymentType,
  PostType,
  StudyType,
  TestkitType,
  UserSurveyType
} from '../types/entity.types';
import IApplicationState from '../types/state.types';
import { getAnalyticsReportAsync } from './analitycs/analytics.types';
import {
  createAnnouncementAsync,
  getAnnouncementsAsync,
  updateAnnouncementAsync
} from './announcements/announcements.types';
import getStatus from './api/api.selectors';
import * as articleSelectors from './article/article.selectors';
import {
  getRequestedArticleAsync,
  saveArticleAsync,
} from "./article/article.types";
import * as avatarSelectors from './avatar/avatar.selectors';
import { getAvatarAsync } from './avatar/avatar.types';
import { getBadges } from './badges/badges.selectors';
import { getBadgesAsync } from './badges/badges.types';
import * as commentSelectors from './comments/comments.selectors';
import { getComments } from './comments/comments.selectors';
import { getParticipantCommentsAsync } from './comments/comments.types';
import { loadDashboardDataAsync } from './dashboard/dashboard.types';
import { getFavorites } from './favorites/favorites.selectors';
import { getFlags } from './flags/flags.selectors';
import * as formSelectors from './form/form.selectors';
import { loadRequestedFormsAsync } from './form/form.types';
import * as messageSelectors from './messages/messages.selectors';
import { createThreadAsync, getMessageInboxAsync, getMessagesAsync, getParticipantThreadsAsync } from './messages/messages.types';
import * as noteSelectors from './notes/notes.selectors';
import { getParticipantNotesAsync } from "./notes/notes.types";
import * as notificationSelectors from './notification/notification.selectors';
import * as participantSelectors from './participants/participants.selectors';
import { loadParticipantAsync, loadRequestedParticipantAsync } from './participants/participants.types';
import * as paymentSelectors from './payment/payment.selectors';
import { getPaymentsReportAsync } from './payment/payment.types';
import { getPosts } from './posts/posts.selectors';
import { getPostsAsync, savePostAsync } from './posts/posts.types';
import { getQnaPostsAsync } from './qna/qna.types';
import * as routerSelectors from './router/router.selectors';
import { entitySchema } from './schema';
import * as studySelectors from './study/study.selectors';
import { loadStudiesAsync, loadStudyAsync } from './study/study.types';
import * as testkitSelectors from './testkit/testkit.selectors';
import { getThumbsups } from './thumbsups/thumbsups.selectors';
import { loadResourceTopicsAsync } from './topics/topic.types';
import { getUserAsync, updatePasswordAsync, createUserAsync } from './user/user.types';
import * as userSurveySelectors from './userSurvey/userSurvey.selectors';

export const selectOidc = (state: IApplicationState) => {
  return state.oidc;
};

export const selectOidcUser = createSelector(
  [selectOidc],
  (oidc) => {
    return oidc ? oidc.user : undefined;
  }
);

export const selectOidcUserProfile = createSelector(
  [selectOidcUser],
  (user) => {
    return user ? user.profile : undefined;
  }
);

export const getAuthenticatedUserId = createSelector(
  [selectOidcUserProfile],
  (profile) => {
    return profile ? profile.sub : undefined;
  }
);

export const getRequestedStudy = createSelector(
  [routerSelectors.getRequestedStudyId, studySelectors.selectStudies, studySelectors.selectStudyArms, participantSelectors.selectParticipants],
  (id, studies, studyArms, participants) => {

    if (id != -1 && studies && studies.allIds && studies.allIds.length > 0) {

      const { studies: denormalizedStudies } = denormalize({ studies: [id] }, entitySchema, { studies: studies.byId, studyArms: studyArms.byId, participants: participants.byId });

      if (denormalizedStudies) {
        const studiesArray = toArray(denormalizedStudies);
        if (studiesArray && studiesArray.length === 1) {
          return studiesArray[0];
        }
      }
    }
    return undefined;
  }
);

export const getRequestedEditResource = createSelector(
  [routerSelectors.getRequestedEditResourceId, articleSelectors.selectArticles],
  (id, articles) => {

    if (id != -1 && articles && articles.allIds && articles.allIds.length > 0) {
      return articles.byId[id];
    }
    return undefined;
  }
);

export const getRequestedStudyParticipants = createSelector(
  [getRequestedStudy],
  (study) => {

    if (!study) {
      return [];
    }

    const { studyArms } = study;

    if (!studyArms) {
      return [];
    }

    let participants: ParticipantType[] = [];

    for (let i = 0; i < studyArms.length; i++) {
      participants = participants.concat(studyArms[i].participants);
    }
    return participants;
  }
);

export const getRequestedStudyStudyArms = createSelector(
  [getRequestedStudy],
  (study) => {
    if (!study) {
      return [];
    }
    const { studyArms } = study;
    if (!studyArms) {
      return [];
    }
    return studyArms;
  }
);

export const getRequestedStudyParticipantsWithUsernames = createSelector(
  [getRequestedStudyParticipants],
  (participants ) => {
    if (!participants) {
      return [];
    }
    let participantsWithUsernames: ParticipantType[] = [];
    for (let i = 0; i < participants.length; i++) {
      if(participants[i].username) {
        participantsWithUsernames.push(participants[i]);
      }
    }
    return participantsWithUsernames;
  }
);

export const getRequestedStudyParticipantUsernames = createSelector(
  [getRequestedStudyParticipants],
  (participants ) => {
    return _.sortBy(_.map(participants, 'username'));
  }
);

export const getRequestedStudyParticipant = createSelector(
  [routerSelectors.getRequestedParticipantId, getRequestedStudyParticipants],
  (participantId, participants) => {
    if (!participants) {
      return undefined;
    }

    const matchingParticipants: ParticipantType[] = participants.filter(participant => participant.id === participantId);

    if (!matchingParticipants.length) {
      return undefined;
    }

    const requestedParticipant = matchingParticipants[0];

    requestedParticipant.referredParticipants = [];
    _.forEach(requestedParticipant.referredParticipantIds?.split(','), refId => {
      const referredParticipant = _.find(participants, p => p.id === parseInt(refId));
      if (referredParticipant) {
        requestedParticipant.referredParticipants?.push(referredParticipant);
      }
    });

    if (requestedParticipant.referredByParticipantId) {
      requestedParticipant.referredByParticipant  = _.find(participants, p => p.id === requestedParticipant.referredByParticipantId);
    }
    return requestedParticipant;
  }
);

export const getRequestedStudyParticipantStudyArm = createSelector(
  [getRequestedStudy, getRequestedStudyParticipant],
  (study, participant) => {

    if (!study || !participant) {
      return undefined;
    }

    const { studyArmId } = participant;
    const { studyArms } = study;

    for (let i = 0; i < studyArms.length; i++) {
      if (studyArms[i].id === studyArmId) {
        return studyArms[i];
      }
    }
    return undefined;
  }
);

export const getRequestedStudyParticipantAvatar = createSelector(
  [avatarSelectors.getAvatars, getRequestedStudyParticipant],
  (avatars, participant) => {

    if (!avatars || !participant) {
      return undefined;
    }

    const { avatarId } = participant;

    for (let i = 0; i < avatars.length; i++) {
      if (avatars[i].id === avatarId) {
        return avatars[i];
      }
    }
    return undefined;
  }

);

export const getRequestedStudyParticipantPosts = createSelector(
  [getPosts, getRequestedStudyParticipant, getComments, getFlags, getThumbsups, getFavorites],
  (posts, participant, comments, flags, thumbsups, favorites) => {

    if (!posts || !participant) {
      return undefined;
    }

    const { id } = participant;

    const participantPosts: PostType[] = [];
    for (let i = 0; i < posts.length; i++) {
      if (posts[i].createdByParticipantId === id) {
        let decoratedPost = posts[i];

        const postComments = _.filter(comments, c => 'post' === c.type && c.typeId === decoratedPost.id);
        const commentCount = postComments.length;

        const postFlags = _.filter(flags, f => 'post' === f.type && f.typeId === decoratedPost.id);
        const flagCount = postFlags.length;

        const postThumbsups = _.filter(thumbsups, t => 'post' === t.type && t.typeId === decoratedPost.id);
        const thumbsupsCount  = postThumbsups.length;

        const postFavorites = _.filter(favorites, f => 'post' === f.type && f.typeId === decoratedPost.id);
        const favoritesCount = postFavorites.length;

        Object.assign(decoratedPost, {commentCount, flagCount, thumbsupsCount, favoritesCount});
        participantPosts.push(decoratedPost);
      }
    }
    return participantPosts;
  }
);

export const getRequestedForms = createSelector(
  [formSelectors.getForms, getRequestedStudy],
  (forms, study: StudyType) => {
    if (!forms || !study) {
      return undefined;
    }

    const { id } = study;
    const requestedForms: FormType[] = [];
    for (let i = 0; i < forms.length; i++) {
      requestedForms.push(forms[i]);
    }
    return requestedForms;
  }
);

export const getRequestedParticipantForms = createSelector(
  [formSelectors.getForms, getRequestedStudyParticipant],
  (forms, participant: Optional<ParticipantType>) => {
    if (!forms || !participant) {
      return undefined;
    }

    const { id } = participant;
    const requestedForms: FormType[] = [];
    for (let i = 0; i < forms.length; i++) {
      if (forms[i].participantId === id) {
        requestedForms.push(forms[i]);
      }
    }
    return requestedForms;
  }
);

export const getRequestedParticipantNotifications = createSelector(
  [notificationSelectors.getNotifications, getRequestedStudyParticipant],
  (notifications, participant: Optional<ParticipantType>) => {
    if (!notifications || !participant) {
      return undefined;
    }

    const { id } = participant;
    const requestedNotifications: NotificationType[] = [];
    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].participantId === id) {
        requestedNotifications.push(notifications[i]);
      }
    }
    return requestedNotifications;
  }
);


export const getRequestedParticipantTestkits = createSelector(
  [testkitSelectors.getTestkits, getRequestedStudyParticipant],
  (testkits, participant: Optional<ParticipantType>) => {
    if (!testkits || !participant) {
      return undefined;
    }

    const { id } = participant;
    const participantTestkits: TestkitType[] = [];
    for (let i = 0; i < testkits.length; i++) {
      if (testkits[i].participantId === id) {
        participantTestkits.push(testkits[i]);
      }
    }
    return participantTestkits;
  }
);


export const getRequestedParticipantUserSurveys = createSelector(
  [userSurveySelectors.getUserSurveys, getRequestedStudyParticipant],
  (userSurveys, participant: Optional<ParticipantType>) => {
    if (!userSurveys?.length || !participant) {
      return undefined;
    }

    const { id } = participant;
    const requestedUserSurveys: UserSurveyType[] = [];
    for (let i = 0; i < userSurveys.length; i++) {
      if (userSurveys[i].participantId === id) {
        requestedUserSurveys.push(userSurveys[i]);
      }
    }
    return requestedUserSurveys;
  }
);

export const getRequestedParticipantIncentives = createSelector(
  [paymentSelectors.getPayments, getRequestedStudyParticipant],
  (payments, participant: Optional<ParticipantType>) => {
    if (!payments?.length || !participant) {
      return undefined;
    }

    const { id } = participant;
    const userPayments: PaymentType[] = [];
    for (let i = 0; i < payments.length; i++) {
      if (payments[i].participantId === id) {
        userPayments.push(payments[i]);
      }
    }
    return userPayments;
  }
);
//
// export const getRequestedParticipantBadges = createSelector(
//   [getBadges, getRequestedStudyParticipant],
//
//   (badges, participant: Optional<ParticipantType>) => {
//     if (!badges?.length || !participant) {
//       return undefined;
//     }
//
//     const { id } = participant;
//     const userBadges: BadgeType[] = [];
//     for (let i = 0; i < badges.length; i++) {
//       if (badges[i].participantId === id) {
//         userPayments.push(payments[i]);
//       }
//     }
//     return userPayments;
//   }
// );

export const getRequestedParticipantExitInterviewPayment = createSelector(
  [paymentSelectors.getPayments, getRequestedStudyParticipant],
  (payments, participant: Optional<ParticipantType>) => {
    if (!payments?.length || !participant) {
      return undefined;
    }

    const { id } = participant;
    const payment = _.find(payments, p => p.incentiveId === 9 && p.participantId === id)
    return payment;
  }
);

export const getRequestedStudyParticipantComments = createSelector(
  [commentSelectors.getComments, getRequestedStudyParticipant],
  (comments, participant) => {

    if (!comments || !participant) {
      return undefined;
    }

    const { id } = participant;

    const participantComments: CommentType[] = [];
    for (let i = 0; i < comments.length; i++) {
      if (comments[i].participantId === id) {
        participantComments.push(comments[i]);
      }
    }
    return participantComments;
  }
);

export const getRequestedStudyParticipantThreads = createSelector(
  [messageSelectors.getMessageThreads, getRequestedStudyParticipant],
  (threads, participant) => {

    if (!threads || !participant) {
      return undefined;
    }

    const { id } = participant;

    const participantThreads: MessageThreadType[] = [];
    for (let i = 0; i < threads.length; i++) {
      if (threads[i].participantId === id) {
        participantThreads.push(threads[i]);
      }
    }
    return participantThreads;
  }
);

export const getRequestedStudyParticipantNotes = createSelector(
  [noteSelectors.getNotes, getRequestedStudyParticipant],
  (notes, participant) => {

    if (!notes || !participant) {
      return undefined;
    }

    const { id } = participant;

    const participantNotes: NoteType[] = [];
    for (let i = 0; i < notes.length; i++) {
      if (notes[i].participantId === id) {
        participantNotes.push(notes[i]);
      }
    }
    return participantNotes;
  }
);

const getLoadingStatus = (state: IApplicationState, type: string): IApiRequestState => {
  const status: Optional<IApiRequestState> = getStatus(state, type);

  if (status) {
    return status;
  }
  return {
    isLoading: false,
    isError: false,
    isSuccess: false
  };
};

/**
 * Since the individual actions catch their own errors, the loadStudies wrapper must check the status of each individual
 * action to determine if the studies are still being loaded or have failed to load.
 *
 * @param state the application state
 */
export const loadStudiesStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(loadStudiesAsync.request));
};

/**
 * Since the individual actions catch their own errors, the loadStudy wrapper must check the status of each individual
 * action to determine if the study is still being loaded or has failed to load.
 *
 * @param state the application state
 */
export const loadStudyStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(loadStudyAsync.request));
};

export const loadDashboardStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(loadDashboardDataAsync.request));
};

export const loadParticipantStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(loadParticipantAsync.request));
};

export const loadRequestedParticipantStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(loadRequestedParticipantAsync.request));
};

export const getAvatarLoadingStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getAvatarAsync.request));
};

export const getUserLoadingStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getUserAsync.request));
};

export const getStudyPostsLoadingStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getPostsAsync.request));
};

export const loadRequestedFormsStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(loadRequestedFormsAsync.request));
};

export const getRequestedEditResourceStatus = (state: IApplicationState): IApiRequestStatus => {
  return getLoadingStatus(state, getType(getRequestedArticleAsync.request));
}

export const loadPostsStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getPostsAsync.request));
};

export const loadBadgesStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getBadgesAsync.request));
};

export const loadResourceTopicsStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(loadResourceTopicsAsync.request));
};

export const loadCommentsStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getParticipantCommentsAsync.request));
};

export const loadMessagesStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getParticipantThreadsAsync.request));
};

export const loadMessageInboxStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getMessageInboxAsync.request));
};

export const getMessagesStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getMessagesAsync.request));
};

export const loadNotesStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getParticipantNotesAsync.request));
};

export const loadAnnouncementsStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getAnnouncementsAsync.request));
};

export const createAnnouncementStatus = (state: IApplicationState): IApiRequestStatus => {
  return getLoadingStatus(state, getType(createAnnouncementAsync.request));
};

export const updateAnnouncementStatus = (state: IApplicationState): IApiRequestStatus => {
  return getLoadingStatus(state, getType(updateAnnouncementAsync.request));
};

export const loadQnaPostsStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getQnaPostsAsync.request));
};

export const getAnalyticsReportStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getAnalyticsReportAsync.request));
};

export const getPaymentsReportStatus = (state: IApplicationState): IApiRequestState => {
  return getLoadingStatus(state, getType(getPaymentsReportAsync.request));
};

export const updatePasswordStatus = (state: IApplicationState): IApiRequestStatus => {
  return getLoadingStatus(state, getType(updatePasswordAsync.request));
};

export const createUserStatus = (state: IApplicationState): IApiRequestStatus => {
  return getLoadingStatus(state, getType(createUserAsync.request));
};

export const saveForumPostStatus = (state: IApplicationState): IApiRequestStatus => {
  return getLoadingStatus(state, getType(savePostAsync.request));
};

export const createMessageThreadStatus = (state: IApplicationState): IApiRequestStatus => {
  return getLoadingStatus(state, getType(createThreadAsync.request));
};

export const saveArticleStatus = (
  state: IApplicationState
): IApiRequestStatus => {
  return getLoadingStatus(state, getType(saveArticleAsync.request));
};

export const isAuthenticated = createSelector(
  [selectOidcUser],
  (user) => {
    return user ? !user.expired : false;
  }
);

export const isAuthenticating = createSelector(
  [selectOidc],
  (oidc) => {
    return oidc ? oidc.isLoadingUser : false;
  }
);

export * from './api/api.selectors';
export * from './analitycs/analytics.selectors';
export * from './avatar/avatar.selectors';
export * from './article/article.selectors';
export * from './badges/badges.selectors';
export * from './badges/participantBadges.selectors';
export * from './comments/comments.selectors';
export * from './participants/participants.selectors';
export * from './posts/posts.selectors';
export * from './flags/flags.selectors';
export * from './thumbsups/thumbsups.selectors';
export * from './favorites/favorites.selectors';
export * from './router/router.selectors';
export * from './sidebar/sidebar.selectors';
export * from './study/study.selectors';
export * from './user/user.selectors';
export * from './topics/topic.selectors';
export * from './testkit/testkit.selectors';
export * from './comments/comments.selectors';
export * from './messages/messages.selectors';
export * from './notes/notes.selectors';
export * from './activities/activities.selectors';
export * from './notification/notification.selectors';
export * from './payment/payment.selectors';
export * from './dashboard/dashboard.selectors';
export * from './announcements/announcements.selectors';
export * from './qna/qna.selectors';
export * from './userSurvey/userSurvey.selectors';
export * from './forum/forum.selectors';

