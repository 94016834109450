import React, { Component } from 'react';import {
  Button
} from 'antd';
import './activity.scss';

interface ComponentProps {
  creative: boolean;
  editable: boolean;
  gradient: any | undefined;
  setStatus: (status: string) => void;
  setGradient: (start: string, end: string) => void;
}

const colorOptions = [
  { start: 'FC4548', end: 'FFB100' },
  { start: 'FFB100', end: '00D5B6' },
  { start: '00D5B6', end: '924DFC' },
  { start: '924DFC', end: 'FC4548' },
  { start: 'FFB100', end: '924DFC' },
  { start: '00D5B6', end: 'FC4548' }
]

class ActivityGradientSelector extends Component<ComponentProps, {}> {
  readonly state = {
    start: null,
    end: null,
    bothSet: false
  };
  componentDidMount() {
    const { gradient } = this.props;
    if(gradient) {
      this.setState({
        start: gradient.start,
        end: gradient.end
      });
    }
  }
  setGradient = (grad: any) => {
    const { setGradient, setStatus } = this.props;
    const { start, end } = this.state;
    if(grad.start !== start || grad.end !== end) {
      setGradient(grad.start, grad.end);
      this.setState({
        start: grad.start,
        end: grad.end
      });
      setStatus('finish');
    }
  };
  render() {
    const { creative, editable } = this.props;
    const { start, end } = this.state;
    let key:number = 0;
    return (
      <div className='activity-gradient'>
        <div className='color-panel'>
          {colorOptions.map(grad => {
            return <Button
                    key={key++}
                    disabled={!creative && !editable}
                    type='link'
                    style={{background: `linear-gradient(#${grad.start}, #${grad.end})`}}
                    className={['color-option', grad.start === start && grad.end === end ? 'selected' : ''].join(' ')}
                    onClick={(e) => { e.stopPropagation(); this.setGradient(grad); }}/>
          })}
        </div>
      </div>
    );
  }
}

export default ActivityGradientSelector;