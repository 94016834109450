import { Button, Card, Col, Modal, Popover, Progress, Row, Table } from 'antd';
import Column from 'antd/es/table/Column';
import { _ } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getBadgesAsync } from '../../redux/badges/badges.types';
import { awardParticipantBadgeAsync } from '../../redux/badges/participantBadges.types';
import * as selectors from '../../redux/selectors';
import { IApiRequestState } from '../../types/api.types';
import { BadgeType, ParticipantBadgeType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { renderPopoverDate } from '../util/Util';
import './badgeTable.scss';

interface StateProps {
  participantId: Optional<number>,
  participantBadges: Optional<ParticipantBadgeType[]>
  badges: Optional<BadgeType[]>,
  status: IApiRequestState
}

interface DispatchProps {
  getBadges: typeof getBadgesAsync.request,
  awardParticipantBadge: any
}

interface ComponentProps extends DispatchProps, StateProps {
}

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

const initialState = {
  isAwardBadgePopupVisible: false,
  awardBadgeCollectionId: -1,
  participantBadges: []
};

type ComponentState = Readonly<typeof initialState>;

class BadgesTable extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = initialState;

  componentDidMount() {
    this.props.getBadges();
  }

  renderBadgeProgress(progressCount: number, badge: ParticipantBadgeType) {
    if (badge.earnedDate)
      return renderPopoverDate(badge.earnedDate);
    else
      return (
        <Popover content={`${progressCount} of ${badge.quota}`}>
          <Progress style={{width:'400px'}} percent={parseFloat((100.0*(progressCount/badge.quota)).toFixed(0))} />
        </Popover>
      )
  }

  showAwardBadgeModal = () => {
    this.setState({isAwardBadgePopupVisible: true});
  }

  hideAwardBadgeModal = () => {
    this.setState({isAwardBadgePopupVisible: false});
  }

  awardBadge = () => {
    const { participantId, awardParticipantBadge } = this.props;
    const { awardBadgeCollectionId } = this.state;
    awardParticipantBadge(participantId, awardBadgeCollectionId);
    this.setState({isAwardBadgePopupVisible: false});
  }

  createBadgeCardGrid = () => {
    const { badges } = this.props;
    const { isAwardBadgePopupVisible, participantBadges, awardBadgeCollectionId} = this.state;

    const badgeCols:any[] = [];
    if (badges && isAwardBadgePopupVisible) {
      const unearnedBadges = _.filter(badges, (b:BadgeType) => {
        const workingOn = _.find(participantBadges, (pb:ParticipantBadgeType) => pb.badgeCollectionId === b.badgeCollectionId);
        return !workingOn || !workingOn.earnedDate;
      });
      unearnedBadges.forEach((badge: BadgeType) => {
        const selectedCss = awardBadgeCollectionId === badge.badgeCollectionId ? 'selected' : undefined;
        badgeCols.push(
          <Col key={`col${badge.badgeCollectionId.toString()}`} span={4}>
            <Card className={[
                    'badge-card',
                    `${selectedCss ? 'selected' : ''}`
                  ].join(' ')}
                  key={badge.badgeCollectionId.toString()}
                  bordered={false}
                  onClick={(e) => {this.setState({awardBadgeCollectionId: badge.badgeCollectionId})}} >
              <img src={badge.badge}/>
              <div>{badge.label}</div>
              <div style={{fontSize: 10}}>{badge.description}</div>
            </Card>
          </Col>
        );
      });
    }

    return <Row className='badge-grid-row'>{badgeCols}</Row>
  }

  render() {
    const { participantBadges, badges } = this.props;
    const { isAwardBadgePopupVisible, awardBadgeCollectionId } = this.state;
    const badgeGrid = this.createBadgeCardGrid();

    return (
      <div>
        <Button onClick={this.showAwardBadgeModal}>Award Badge</Button>
        <br/> <br/>
        <Table
          dataSource={participantBadges}
          pagination={{pageSize: 10}}
          rowClassName={rowClassName}
          rowKey="badgeCollectionId"
        >
          <Column title='ID' dataIndex='badgeCollectionId' key='badgeCollectionId'
                  width='70px'
                  defaultSortOrder='ascend'
                  sorter={(a: any, b: any) => a.badgeCollectionId - b.badgeCollectionId} />
          <Column title='Label' dataIndex='label' key='label'
                  width='200px'
                  sorter={(a: any, b: any) => `${a.label}`.localeCompare(b.label)}
                  filters={_.map(_.sortedUniq(_.uniq(_.sortBy(_.map(participantBadges, 'label')))), x => { return {text: x, value: x}} )}
                  onFilter={(value, record) => record.label === value} />
          <Column title='Description' dataIndex='description' key='description'
                  width='600px'
                  sorter={(a: any, b: any) => `${a.description}`.localeCompare(b.description)} />
          <Column title='Progress' dataIndex="progressCount"
                  width='600px'
                  sorter={(a:any, b: any) =>  a.progressCountt - b.progressCount}
                  render={this.renderBadgeProgress}
          />
        </Table>
        <Modal visible={isAwardBadgePopupVisible}
               width='800px'
               title="Select a Badge to Award"
               onCancel={this.hideAwardBadgeModal}
               onOk={this.awardBadge}>
          {badgeGrid}
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getBadges: () => dispatch(getBadgesAsync.request()),
    awardParticipantBadge: (participantId:number, badgeCollectionId: number) => dispatch(awardParticipantBadgeAsync.request({participantId, badgeCollectionId}))
  };
};

const mapStateToProps = (state: IApplicationState): StateProps => {
  const participantId = selectors.getRequestedParticipantId(state);
  const badges = selectors.getBadges(state);
  const status = selectors.loadBadgesStatus(state);
  const participantBadges = selectors.getParticipantBadges(state);

  return {
    participantId,
    badges,
    status,
    participantBadges
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgesTable);
