import React, { ChangeEvent } from 'react';
import {
  Input, InputNumber, Checkbox
} from 'antd';
import '../activity.scss';
import { ActivityOptionType, ActivityFeedbackType } from 'types/entity.types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import OptionFeedbackItem from '../feedback/OptionFeedbackItem';

interface ComponentProps {
  form: any;
  creative: boolean;
  editable: boolean;
  option: ActivityOptionType;
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPointsChange: (value: number | undefined) => void;
  onCorrectChange: (e: CheckboxChangeEvent) => void;
  setStatus: (status: string) => void;
  updateFeedback: (f: ActivityFeedbackType) => void;
  addFeedback: () => void;
  removeFeedback: () => void;
}

enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener
}

const QuizOption = (props: ComponentProps) => {
  const { 
    option,
    creative,
    editable, 
    onTextChange, 
    onPointsChange, 
    onCorrectChange, 
    form, 
    setStatus,
    updateFeedback, 
    removeFeedback, 
    addFeedback 
  } = props;
  const { text, points, isCorrect, feedback } = option;
  return (<div className='option-container'>
    <Input spellCheck disabled={!creative && !editable} placeholder='Option text' value={text} onChange={onTextChange} style={{width: '100%'}}/>
    <div className='option-props'>
      <span className='option-prop'>
        Correct Answer <Checkbox disabled={!creative && !editable} checked={isCorrect} onChange={onCorrectChange}/>
      </span>
      <span className='option-prop'>
        Point Value <InputNumber disabled={!creative && !editable} value={points} min={0} onChange={onPointsChange}/>
      </span>
    </div>
    <h4>Option Feedback:</h4>
    <OptionFeedbackItem 
      form={form}
      type={Type.quiz}
      creative={creative}
      editable={editable}
      feedback={feedback}
      option={option}
      setStatus={setStatus}
      updateFeedback={updateFeedback}
      addFeedback={addFeedback}
      removeFeedback={removeFeedback}
    />
  </div>);
};

export default QuizOption;