import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { MarkPaidType, NormalizerResult } from '../../types/entity.types';
import axios from '../api';
import { entitySchema } from '../schema';
import {
  getParticipantPaymentsAsync,
  getPaymentsAsync, getPaymentsReportAsync,
  markPaidAsync, requestAdHocPaymentAsync,
  requestExitInterviewPaymentAsync
} from './payment.types';

const EXIT_INTERVIEW_PAYMENT_ID: number = 9;

const requestPayment = (participantId: number, incentiveId: number) => {
  return axios({
    method: 'put',
    url: `/a/incentive/request/${participantId}/${incentiveId}`,
  });
};

function* requestExitInterviewPaymentHandler(action: ReturnType<typeof requestExitInterviewPaymentAsync.request>): Generator {

  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = (yield call(requestPayment, participantId, EXIT_INTERVIEW_PAYMENT_ID)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.payments) as NormalizerResult;
    const { payments } = entities;

    yield put(requestExitInterviewPaymentAsync.success(payments));
  } catch (error) {
    yield put(requestExitInterviewPaymentAsync.failure(error));
  }
}

function* requestExitInterviewPaymentWatcher() {
  yield takeLatest(getType(requestExitInterviewPaymentAsync.request), requestExitInterviewPaymentHandler);
}

function* requestAdHocPaymentHandler(action: ReturnType<typeof requestAdHocPaymentAsync.request>): Generator {

  try {
    const { participantId, incentiveId} = action.payload;
    const response: AxiosResponse = (yield call(requestPayment, participantId, incentiveId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.payments) as NormalizerResult;
    const { payments } = entities;

    yield put(requestAdHocPaymentAsync.success(payments));
  } catch (error) {
    yield put(requestAdHocPaymentAsync.failure(error));
  }
}

function* requestAdHocPaymentWatcher() {
  yield takeLatest(getType(requestAdHocPaymentAsync.request), requestAdHocPaymentHandler);
}

const getParticipantPayments = (participantId: number) => {
  return axios({
    method: 'get',
    url: `/a/incentive/getPayments?participantId=${participantId}`,
  });
};

function* getParticipantPaymentsHandler(action: ReturnType<typeof getParticipantPaymentsAsync.request>): Generator {

  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = (yield call(getParticipantPayments, participantId)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.payments) as NormalizerResult;
    const { payments } = entities;

    yield put(getParticipantPaymentsAsync.success(payments));
  } catch (error) {
    yield put(getParticipantPaymentsAsync.failure(error));
  }
}

function* getParticipantPaymentsWatcher() {
  yield takeLatest(getType(getParticipantPaymentsAsync.request), getParticipantPaymentsHandler);
}

const getPayments = () => {
  return axios({
    method: 'get',
    url: `/a/incentive/getPayments`,
  });
};

function* getPaymentsHandler(action: ReturnType<typeof getPaymentsAsync.request>): Generator {

  try {
    const response: AxiosResponse = (yield call(getPayments)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.payments) as NormalizerResult;
    const { payments } = entities;

    yield put(getPaymentsAsync.success(payments));
  } catch (error) {
    yield put(getPaymentsAsync.failure(error));
  }
}

function* getPaymentsWatcher() {
  yield takeLatest(getType(getPaymentsAsync.request), getPaymentsHandler);
}

const getPaymentsReport = () => {
  return axios({
    method: 'get',
    url: `/a/incentive/getPayments?includeStudyIds=true`,
  });
};

function* getPaymentsReportHandler(action: ReturnType<typeof getPaymentsReportAsync.request>): Generator {

  try {
    const response: AxiosResponse = (yield call(getPaymentsReport)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.payments) as NormalizerResult;
    const { payments } = entities;

    yield put(getPaymentsReportAsync.success(payments));
  } catch (error) {
    yield put(getPaymentsReportAsync.failure(error));
  }
}

function* getPaymentsReportWatcher() {
  yield takeLatest(getType(getPaymentsReportAsync.request), getPaymentsReportHandler);
}

const markPaid = (markPaidObj: MarkPaidType) => {
  return axios({
    method: 'put',
    url: `/a/incentive/markPaid`,
    data: markPaidObj
  });
};

function* markPaidHandler(action: ReturnType<typeof markPaidAsync.request>): Generator {

  try {
    const markPaidObj: MarkPaidType = action.payload;
    const response: AxiosResponse = (yield call(markPaid, markPaidObj)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.payments) as NormalizerResult;
    const { payments } = entities;

    yield put(markPaidAsync.success(payments));
  } catch (error) {
    yield put(markPaidAsync.failure(error));
  }
}

function* markPaidWatcher() {
  yield takeLatest(getType(markPaidAsync.request), markPaidHandler);
}

export default function* paymentSaga() {
  yield all([
    fork(getPaymentsWatcher),
    fork(getPaymentsReportWatcher),
    fork(getParticipantPaymentsWatcher),
    fork(requestExitInterviewPaymentWatcher),
    fork(requestAdHocPaymentWatcher),
    fork(markPaidWatcher)
  ]);
}
