import React from 'react';
import Radio from 'antd/lib/radio';
import Dropzone, { FileWithPath } from 'react-dropzone';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons';
import './coverImageEditor.scss';
import AntdIcon from '../../antdIcon/AntdIcon';

import axios from '../../../redux/api';

interface ComponentProps {
  coverImageChangeHandler: (imageUrl: string) => void,
  currentCoverImageUrl?: string,
  coverImageUrlChoices?: string[]
}

interface ComponentState {
  isCoverUploading: boolean,
  uploadPercentComplete: number
}

class CoverImageEditor extends React.Component<ComponentProps,ComponentState> {

  readonly state: ComponentState = {
    isCoverUploading: false,
    uploadPercentComplete: 0
  };

  updateProgress = (progressEvent: ProgressEvent) => {
    const uploadPercentComplete = progressEvent.lengthComputable ? Math.round(100*(progressEvent.loaded / progressEvent.total)) : 0;
    this.setState({uploadPercentComplete});
  };

  onDrop = (files: FileWithPath[]) => {
    if (files && files.length === 1) {

      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.setState({isCoverUploading: true, uploadPercentComplete: 0});

      // TODO I should really separate out the upload from the component, but 1) make it work.
      axios.post('/a/cms/uploads/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: this.updateProgress,
        timeout: 100000
      })
        .then((res) => {
          if (res?.data?.link) {
            if (this.props.coverImageChangeHandler) {
              this.props.coverImageChangeHandler(res.data.link);
            }
            this.setState({
              isCoverUploading: false
            });
          }
          else {
            this.setState({isCoverUploading: false});
          }
        })
        .catch((err) => {
          this.setState({isCoverUploading: false});
        });
    }
  };

  coverImageChangeHandler = (event: any) => {
    const value = event.target.value;
    this.props.coverImageChangeHandler(value);
  };

  render() {

    const { currentCoverImageUrl, coverImageUrlChoices } = this.props;
    const { isCoverUploading, uploadPercentComplete } = this.state;

    const renderDropzone = (isDragActive: boolean, isDragAccept: boolean, isDragReject: boolean) => {

      if (isCoverUploading) {
        return (
          <div className="hmp-cover-image-editor-dropzone-active">
            <CircularProgressbar value={uploadPercentComplete} text={`${uploadPercentComplete}%`} />
          </div>
        )
      }

      if (isDragAccept) {
        return (
          <div className="hmp-cover-image-editor-dropzone-accept">
            Drop files now
          </div>
        )
      }

      if (isDragReject) {
        return (
          <div className="hmp-cover-image-editor-dropzone-reject">
            Reject Drag
          </div>
        )
      }

      if (isDragActive) {
        return (
          <div className="hmp-cover-image-editor-dropzone-active">
            Drop files here...
          </div>
        )
      }

      if (currentCoverImageUrl === '') {
        return (
          <div className="hmp-cover-image-editor-dropzone-inactive">
            <AntdIcon fontAwesomeIcon={faCloudUpload} />
            <span>Click or Drag &amp; Drop to</span>
            <span>Upload</span>
          </div>
        )
      }

      return (
        <div className="hmp-cover-image-editor-dropzone-inactive">
          <img src={currentCoverImageUrl} alt="Cover Image" />
        </div>
      )
    };

    return (
      <div className="hmp-cover-image-editor">
        <Dropzone
          accept={['image/jpeg','image/png','image/gif']}
          multiple={false}
          onDrop={this.onDrop}
          preventDropOnDocument={true}
        >
          {({getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject}) => (
            <div className="hmp-cover-image-editor-dropzone-container">
              <div {...getRootProps({className: 'hmp-cover-image-editor-dropzone'})} >
                <input {...getInputProps()} />
                {renderDropzone(isDragActive, isDragAccept, isDragReject)}
              </div>
            </div>
          )}
        </Dropzone>
        <div className="hmp-cover-image-editor-image-list">
          <Radio.Group value={currentCoverImageUrl} onChange={this.coverImageChangeHandler}>
            <Radio className="hmp-cover-image-editor-choice" value={''}>None</Radio>
            {coverImageUrlChoices && coverImageUrlChoices.map((imageUrl) => {
              return (
                <Radio value={imageUrl} key={`choice-${imageUrl}`} className="hmp-cover-image-editor-choice">
                  <img src={imageUrl} alt={imageUrl}/>
                </Radio>
              )
            })}
          </Radio.Group>
        </div>
      </div>
    )
  }
}

export default CoverImageEditor;