import React, { Component } from 'react';
import './thread.scss';
import { Empty } from 'antd';

class EmptyMessageList extends Component<{},{}> {
  render() {
    return (
      <div className='thread-list-empty'>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }
}

export default EmptyMessageList;