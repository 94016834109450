import { RouterState } from 'connected-react-router';
import { UserState as OidcUserState } from 'redux-oidc';
import { initialAnalyticsUIState } from '../redux/analitycs/analytics.ui.reducer';
import { initialArticleUIState } from '../redux/article/article.ui.reducer';
import { initialForumUIState } from '../redux/forum/forum.ui.reducer';
import { ISideBarState } from '../redux/sidebar/sidebar.types';
import { initialStudyUIState } from '../redux/study/study.ui.reducer';
import IApiState from './api.types';
import { ArticleType, ConsentStatusType, ParticipantBadgeType, RoleType } from "./entity.types";
import {
  AvatarType,
  CommentType,
  MessageResponseType,
  MessageThreadType,
  MessageType,
  ParticipantType,
  PostType,
  StudyArmType,
  StudyType,
  UserType,
  FormType,
  BadgeType,
  TestkitType,
  TopicType,
  FlagType,
  ThumbsupType,
  FavoriteType,
  NoteType,
  ActivityType,
  ActivityCategoryType,
  ActivityTypeType,
  AdminUserType,
  UserRoleType,
  PaymentType,
  AnnouncementType,
  NotificationType,
  UserSurveyType,
  AnalyticsType,
  QnaPostType,
  ForumDetailType
} from './entity.types';
import { IQnaUIState } from '../redux/qna/qna.types';
import { IMessageUIState } from '../redux/messages/messages.types';

export type NormalizedType<T> = {
  [key: string]: T
}

export const initialNormalizedState = {
  allIds: [],
  byId: {}
};

export const initialUserState = {
  id: -1,
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  roles: []
};

export type NormalizedState<T> = {
  allIds: number[],
  byId: {
    [key: string]: T
  }
}


export interface IApplicationState {
  dashboard: any,
  api: IApiState,
  auth: {
    user: Optional<UserType>
  },
  entities: {
    activities: NormalizedState<ActivityType>,
    activityCategories: NormalizedState<ActivityCategoryType>,
    activityTypes: NormalizedState<ActivityTypeType>,
    admin: NormalizedState<AdminUserType>,
    articles: NormalizedState<ArticleType>,
    avatars: NormalizedState<AvatarType>,
    badges: NormalizedState<BadgeType>,
    participantBadges: NormalizedState<ParticipantBadgeType>,
    comments: NormalizedState<CommentType>,
    favorites: NormalizedState<FavoriteType>,
    flags: NormalizedState<FlagType>,
    forms: NormalizedState<FormType>,
    messages: NormalizedState<MessageType>,
    messageResponses: NormalizedState<MessageResponseType>,
    threads: NormalizedState<MessageThreadType>,
    participants: NormalizedState<ParticipantType>,
    notes: NormalizedState<NoteType>,
    notifications: NormalizedState<NotificationType>,
    posts: NormalizedState<PostType>,
    studies: NormalizedState<StudyType>,
    studyArms: NormalizedState<StudyArmType>,
    testkits: NormalizedState<TestkitType>,
    topics: NormalizedState<TopicType>,
    thumbsups: NormalizedState<ThumbsupType>,
    uncategorizedPostIds: NormalizedState<PostType>,
    payments: NormalizedType<PaymentType>,
    announcements: NormalizedType<AnnouncementType>,
    userSurveys: NormalizedType<UserSurveyType>,
    qnaPosts: NormalizedType<QnaPostType>,
    adminRoles: NormalizedType<RoleType>,
    forums: NormalizedType<ForumDetailType>,
    consentStatuses: NormalizedType<ConsentStatusType>
  },
  oidc: OidcUserState,
  router: RouterState,
  ui: {
    forums: typeof initialForumUIState,
    study: typeof initialStudyUIState,
    sidebar: ISideBarState,
    articles: typeof initialArticleUIState,
    qnas: IQnaUIState,
    messages: IMessageUIState,
    reports: {
      analytics: typeof initialAnalyticsUIState
    }
  }
}

export default IApplicationState;
