import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import {
  MessagesActionTypes,
  updateMessageThreadCount,
  IMessageUIState
} from './messages.types';

export const initialMessageUIState: IMessageUIState = {
  threadCount: 0
}

export const messageUIReducer: Reducer<IMessageUIState, MessagesActionTypes> = (state: IMessageUIState = initialMessageUIState, action: MessagesActionTypes) => {
  switch (action.type) {
    case getType(updateMessageThreadCount):
      return {
        ...state,
        threadCount: action.payload,
      };
    default:
      return state;
  }
};

export default messageUIReducer;
