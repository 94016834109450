import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ActivityType, ActivityCategoryType, ActivityOptionType, ActivityQuestionType, ActivityFeedbackType, ActivityTypeType } from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';

export interface ActivitySummaryArguments {
  pageNumber?: number;
  pageSize?: number;
  includeUnpublished?: boolean;
  includeDeleted?: boolean;
  healthAssessmentsOnly?: boolean;
  participantId?: number;
}

export interface ActivityArguments {
  id: number;
  participantId?: number;
}

export interface ActivityComponentArguments {
  type: string;
  typeId: number;
  component: ActivityOptionType | ActivityQuestionType | ActivityFeedbackType | ActivityType;
}

export interface DeleteActivityArguments {
  id: number;
  deleteDate?: Date;
}

export const getActivitySummariesAsync = createAsyncAction(
  'hmp/admin/GET_ACTIVITY_SUMMARIES_REQUEST',
  'hmp/admin/GET_ACTIVITY_SUMMARIES_SUCCESS',
  'hmp/admin/GET_ACTIVITY_SUMMARIES_FAILURE'
)<ActivitySummaryArguments, Optional<NormalizedType<ActivityType>>, Error>();

export const getActivityAsync = createAsyncAction(
  'hmp/admin/GET_ACTIVITY_REQUEST',
  'hmp/admin/GET_ACTIVITY_SUCCESS',
  'hmp/admin/GET_ACTIVITY_FAILURE'
)<ActivityArguments, Optional<NormalizedType<ActivityType>>, Error>();

export const getActivityCategoriesAsync = createAsyncAction(
  'hmp/admin/GET_ACTIVITY_CATEGORIES_REQUEST',
  'hmp/admin/GET_ACTIVITY_CATEGORIES_SUCCESS',
  'hmp/admin/GET_ACTIVITY_CATEGORIES_FAILURE'
)<void, Optional<NormalizedType<ActivityCategoryType>>, Error>();

export const getActivityTypesAsync = createAsyncAction(
  'hmp/admin/GET_ACTIVITY_TYPES_REQUEST',
  'hmp/admin/GET_ACTIVITY_TYPES_SUCCESS',
  'hmp/admin/GET_ACTIVITY_TYPES_FAILURE'
)<void, Optional<NormalizedType<ActivityTypeType>>, Error>();

export const upsertActivityAsync = createAsyncAction(
  'hmp/admin/UPSERT_ACTIVITY_REQUEST',
  'hmp/admin/UPSERT_ACTIVITY_SUCCESS',
  'hmp/admin/UPSERT_ACTIVITY_FAILURE'
)<ActivityType, Optional<NormalizedType<ActivityType>>, Error>();

export const updateActivityComponentAsync = createAsyncAction(
  'hmp/admin/UPDATE_ACTIVITY_COMPONENT_REQUEST',
  'hmp/admin/UPDATE_ACTIVITY_COMPONENT_SUCCESS',
  'hmp/admin/UPDATE_ACTIVITY_COMPONENT_FAILURE'
)<ActivityComponentArguments, void, Error>();

export const deleteActivityAsync = createAsyncAction(
  'hmp/admin/DELETE_ACTIVITY_REQUEST',
  'hmp/admin/DELETE_ACTIVITY_SUCCESS',
  'hmp/admin/DELETE_ACTIVITY_FAILURE'
)<DeleteActivityArguments, Optional<NormalizedType<ActivityType>>, Error>();

export const publishActivityAsync = createAsyncAction(
  'hmp/admin/PUBLISH_ACTIVITY_REQUEST',
  'hmp/admin/PUBLISH_ACTIVITY_SUCCESS',
  'hmp/admin/PUBLISH_ACTIVITY_FAILURE'
)<number, Optional<NormalizedType<ActivityType>>, Error>();

export const unpublishActivityAsync = createAsyncAction(
  'hmp/admin/UNPUBLISH_ACTIVITY_REQUEST',
  'hmp/admin/UNPUBLISH_ACTIVITY_SUCCESS',
  'hmp/admin/UNPUBLISH_ACTIVITY_FAILURE'
)<number, Optional<NormalizedType<ActivityType>>, Error>();

export const uploadActivityImageAsync = createAsyncAction(
  'hmp/admin/UPLOAD_ACTIVITY_IMAGE_REQUEST',
  'hmp/admin/UPLOAD_ACTIVITY_IMAGE_SUCCESS',
  'hmp/admin/UPLOAD_ACTIVITY_IMAGE_FAILURE'
)<File, Optional<string>, Error>();

export const updateActivities = createStandardAction('hmp/admin/UPDATE_ACTIVITIES')<Optional<NormalizedType<ActivityType>>>();

const activitiesActions = {
  getActivitySummariesAsync,
  getActivityAsync,
  upsertActivityAsync,
  deleteActivityAsync,
  publishActivityAsync,
  unpublishActivityAsync,
  activityImageUploadAsync: uploadActivityImageAsync,
  getActivityCategoriesAsync,
  getActivityTypesAsync,
  updateActivities
};

export type ActivitiesActionTypes = ActionType<typeof activitiesActions>;
