import React, { Component } from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import {
  Input, Form, Button, Icon
} from 'antd';
import '../activity.scss';
import { ActivityTreeOptionType, ActivityTreeNodeType } from 'types/entity.types';
import ActivityTreeNode from './ActivityTreeNode';
const { Item } = Form;

interface ComponentProps {
  form: any;
  creative: boolean;
  editable: boolean;
  option: ActivityTreeOptionType;
  level: number;
  setStatus: (status: string) => void;
  updateOption: (option: ActivityTreeOptionType) => void;
}
const defaultNode = {
  uuid: '',
  text: '',
  options: []
};
const itemStyle = {
  width: '100%',
  marginBottom: '10px', 
  marginTop: '10px'
};

class TreeOptionItem extends Component<ComponentProps, {}> {
  readonly state = {
    opened: true
  };
  onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { option, updateOption } = this.props;
    e.preventDefault();
    option.text = e.target.value;
    updateOption(option);
  }
  updateNode = (node: ActivityTreeNodeType) => {
    const { option, updateOption } = this.props;
    option.question = node;
    updateOption(option);
  }
  onAddClick = (e: React.MouseEvent<HTMLElement>) => {
    const { option, updateOption } = this.props;
    e.preventDefault();
    option.question = _.cloneDeep(defaultNode);
    option.question.uuid = generateUUID();
    updateOption(option);
  };
  validateOption = (rule, value, cb) => {
    const { option } = this.props;
    const { text } = option;
    try {
      if (option && text.length === 0){ 
        throw new Error('Tree option text is required.');
      }
      cb();
    } catch (err) {
      cb(err);
    }
  };
  removeNode = () => {
    const { option, updateOption } = this.props;
    option.question = undefined;
    updateOption(option);
  };
  toggleOpen = () => {
    this.setState({ opened: !this.state.opened });
  }
  render () {
    const { 
      form,
      creative,
      editable,
      option, 
      level,
      setStatus
    } = this.props;
    const { text, question, uuid, id } = option;
    const { getFieldDecorator } = form;
    const { opened } = this.state;
    return (<div className='tree-option-item'>
      <div className='tree-option-icon'>
        <Icon type={opened ? 'minus-square' : 'plus-square'} onClick={() => this.toggleOpen()} />
      </div>
      <div className='tree-option-container'>
        <Item
          required={false}
          key={`node-option-${id ? id : uuid}`}
          style={itemStyle}
        >
          {getFieldDecorator(`node-option-${id ? id : uuid}`, {
            validateTriggers: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                message: 'Please input option text or delete this option.',
                validator: this.validateOption
              }
            ],
            initialValue: text
          })(<Input spellCheck disabled={!creative && !editable} placeholder='Option text' onChange={this.onTextChange} style={{width: '100%'}}/>)}
        </Item>
        {opened && question ? 
          <ul className='tree-node-list'>
            <div className='tree-node-item'>
              <ActivityTreeNode
                form={form}
                creative={creative}
                editable={editable}
                node={question ? question : _.cloneDeep(defaultNode)}
                level={level+1}
                setStatus={setStatus}
                updateNode={this.updateNode}
                removeNode={this.removeNode}
              />
            </div>
          </ul> : 
          <Item>
            <Button disabled={!creative && !editable} type='dashed' onClick={this.onAddClick} style={{width: '100%'}}>
              <Icon type='plus' /> Add question
            </Button>
          </Item>
        }
      </div>
    </div>);
  }
};

export default TreeOptionItem;