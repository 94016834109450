import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { ActivityType, ActivityCategoryType, ActivityTypeType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';

export const selectActivities = (state: IApplicationState) => {
  return state.entities.activities;
};

export const selectCategories = (state: IApplicationState) => {
  return state.entities.activityCategories;
}

export const selectTypes = (state: IApplicationState) => {
  return state.entities.activityTypes;
}

export const getActivities = createSelector(
  [selectActivities],
  (activities): Optional<ActivityType[]> => {
    if (activities && activities.allIds && activities.allIds.length > 0) {
      const { activities: denormalizedActivities } = denormalize({ activities: activities.allIds }, entitySchema, { activities: activities.byId });
      return toArray(denormalizedActivities);
    }
    return undefined;
  }
);

export const getCategories = createSelector(
  [selectCategories],
  (categories): Optional<ActivityCategoryType[]> => {
    if (categories && categories.allIds && categories.allIds.length > 0) {
      const { activityCategories: denormalizedCategories } = denormalize({ activityCategories: categories.allIds }, entitySchema, { activityCategories: categories.byId });
      return toArray(denormalizedCategories);
    }
    return undefined;
  }
);

export const getTypes = createSelector(
  [selectTypes],
  (types): Optional<ActivityTypeType[]> => {
    if (types && types.allIds && types.allIds.length > 0) {
      const { activityTypes: denormalizedTypes } = denormalize({ activityTypes: types.allIds }, entitySchema, { activityTypes: types.byId });
      return toArray(denormalizedTypes);
    }
    return undefined;
  }
)

export default { getActivities };
