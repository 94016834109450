import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { AnnouncementType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';

export const selectAnnouncements = (state: IApplicationState) => {
  return state.entities.announcements;
};

export const getAnnouncements = createSelector(
  [selectAnnouncements],
  (announcements): Optional<AnnouncementType[]> => {
    if (announcements && announcements.allIds && announcements.allIds.length > 0) {
      const { announcements: denormalizedAnnouncements } = denormalize({ announcements: announcements.allIds }, entitySchema, { announcements: announcements.byId });
      return toArray(denormalizedAnnouncements);
    }
    return undefined;
  }
);

export default { getAnnouncements };
