import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Icon, { CustomIconComponentProps } from 'antd/lib/icon';

interface ComponentProps {
  fontAwesomeIcon: IconDefinition,
  classes?: string
}

export const AntdIcon = (props: ComponentProps) => {

  const { fontAwesomeIcon, classes } = props;

  const FaIcon = (icon: IconDefinition): React.FunctionComponent<CustomIconComponentProps> => {
    return () => <FontAwesomeIcon icon={icon} className={classes} />;
  };

  return <Icon component={FaIcon(fontAwesomeIcon)} />;
};

export default AntdIcon;
