import React, { Component } from "react";
import TextArea from 'react-textarea-autosize';
import './thread.scss';
import { Button } from "antd";

interface DispatchProps {
}

interface ComponentProps extends DispatchProps {
  getMessages: () => void;
  sendMessage: (input: string) => void;
  threadId: number;
}

class ComposeMessage extends Component<ComponentProps, {}> {
  readonly state = {
    input: ''
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    if(!event || !event.target) {
      return '';
    }

    const input = event.target.value;
    this.setState({ input });
  };

  handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const trimmedInput = this.state.input.trim();
    const isEmptyMessage =
      trimmedInput === '' ||
      trimmedInput === '>' ||
      trimmedInput === '``````' ||
      trimmedInput === '``' ||
      trimmedInput === '**' ||
      trimmedInput === '____' ||
      trimmedInput === '__' ||
      trimmedInput === '****';
    if(isEmptyMessage) {
      return;
    }
    const input = this.state.input;
    const { sendMessage, getMessages } = this.props;
    if(input.length > 0 && /\S/.test(input)) {
      sendMessage(input);
      getMessages();
      this.setState({ input: '' });
    }
  };

  render() {
    const { input } = this.state;
    return (
      <div className="compose-message">
        <div className="compose-message-container">
          <TextArea
            spellCheck
            maxRows={3}
            onChange={this.handleChange}
            value={input}
            placeholder="Type a message"
            className='compose-message-input'
          />
          <Button type='primary' onClick={this.handleSubmit}>Send</Button>
        </div>
      </div>
    );
  }
}

export default ComposeMessage;