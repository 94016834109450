import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { ArticleType } from '../../types/entity.types';
import { initialNormalizedState, NormalizedState, NormalizedType } from '../../types/state.types';
import {
  ArticleActionTypes,
  loadArticlesAsync,
  getRequestedArticleAsync,
  updateSelectedArticleIds,
  saveArticleAsync,
  deleteArticleAsync, 
  publishArticleAsync,
  unpublishArticleAsync
} from './article.types';

const updateArticlesReducer = produce((draft, articles: Optional<NormalizedType<ArticleType>>) => {
  if (!articles) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(articles), Number));
  const ids = Object.keys(articles);
  for (const id of ids) {
    draft.byId[id] = articles[parseInt(id)];
  }
});

export const articleReducer = (state: NormalizedState<ArticleType> = initialNormalizedState, action: ArticleActionTypes) => {

  switch (action.type) {
    case getType(loadArticlesAsync.success): return updateArticlesReducer(state, action.payload);
    case getType(getRequestedArticleAsync.success): return updateArticlesReducer(state, action.payload);
    case getType(saveArticleAsync.success): return updateArticlesReducer(state, action.payload);
    case getType(deleteArticleAsync.success): return updateArticlesReducer(state, action.payload);
    case getType(publishArticleAsync.success): return updateArticlesReducer(state, action.payload);
    case getType(unpublishArticleAsync.success): return updateArticlesReducer(state, action.payload);
    default: return state;
  }
};

export default articleReducer;
