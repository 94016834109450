import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ParticipantType } from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';

export const loadDashboardDataAsync = createAsyncAction(
  'hmp/admin/LOAD_DASHBOARD_REQUEST',
  'hmp/admin/LOAD_DASHBOARD_SUCCESS',
  'hmp/admin/LOAD_DASHBOARD_FAILURE'
)<void, void, Error>();

const dashboardActions = {
  loadDashboardDataAsync
};

export type DashboardActionTypes = ActionType<typeof dashboardActions>;
