import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';
import { NoteType } from '../../types/entity.types';

export const selectNotes = (state: IApplicationState) => {
  return state.entities.notes;
};

export const getNotes = createSelector(
  [selectNotes],
  (notes): Optional<NoteType[]> => {
    if (notes && notes.allIds && notes.allIds.length > 0) {
      const { notes: denormalizedNotes } = denormalize({ notes: notes.allIds }, entitySchema, { notes: notes.byId });
      return toArray(denormalizedNotes);
    }
    return undefined;
  }
);

export default { getNotes };
