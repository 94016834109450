import { ActionType, createAsyncAction } from 'typesafe-actions';
import { TopicType } from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';

export const loadResourceTopicsAsync = createAsyncAction(
  'hmp/admin/LOAD_RESOURCE_TOPICS_REQUEST',
  'hmp/admin/LOAD_RESOURCE_TOPICS_SUCCESS',
  'hmp/admin/LOAD_RESOURCE_TOPICS_FAILURE'
)<void, Optional<NormalizedType<TopicType>>, Error>();

export const loadForumTopicsAsync = createAsyncAction(
  'hmp/admin/LOAD_FORUM_TOPICS_REQUEST',
  'hmp/admin/LOAD_FORUM_TOPICS_SUCCESS',
  'hmp/admin/LOAD_FORUM_TOPICS_FAILURE'
)<number, Optional<NormalizedType<TopicType>>, Error>();

export const loadQnaTopicsAsync = createAsyncAction(
  'hmp/admin/LOAD_QNA_TOPICS_REQUEST',
  'hmp/admin/LOAD_QNA_TOPICS_SUCCESS',
  'hmp/admin/LOAD_QNA_TOPICS_FAILURE'
)<void, Optional<NormalizedType<TopicType>>, Error>();

const topicActions = {
  loadResourceTopicsAsync,
  loadForumTopicsAsync,
  loadQnaTopicsAsync,
};

export type TopicActionTypes = ActionType<typeof topicActions>;
