import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { QnaPostType } from '../../types/entity.types';
import { NormalizedType } from '../../types/state.types';

export interface GetQnaPostsArguments {
  studyId: number;
  pageNumber?: number;
  pageSize: number;
  previewLength?: number;
  includeDeleted: boolean;
  answers: string; // all, unansweredOnly or answeredOnly
  query?: string;
}

export interface IQnaUIState {
  postCount: number;
}

export const getQnaPostsAsync = createAsyncAction(
  'hmp/admin/GET_QNA_POSTS_REQUEST',
  'hmp/admin/GET_QNA_POSTS_SUCCESS',
  'hmp/admin/GET_QNA_POSTS_FAILURE'
)<GetQnaPostsArguments, Optional<NormalizedType<QnaPostType>>, Error>();

export const getQnaPostAsync = createAsyncAction(
  'hmp/admin/GET_QNA_POST_REQUEST',
  'hmp/admin/GET_QNA_POST_SUCCESS',
  'hmp/admin/GET_QNA_POST_FAILURE'
)<number, Optional<NormalizedType<QnaPostType>>, Error>();

export const getFlaggedQnaContentAsync = createAsyncAction(
  'hmp/admin/GET_FLAGGED_QNA_CONTENT_REQUEST',
  'hmp/admin/GET_FLAGGED_QNA_CONTENT_SUCCESS',
  'hmp/admin/GET_FLAGGED_QNA_CONTENT_FAILURE'
)<number, Optional<NormalizedType<QnaPostType>>, Error>();

export const createQnaPostAsync = createAsyncAction(
  'hmp/admin/CREATE_QNA_POST_REQUEST',
  'hmp/admin/CREATE_QNA_POST_SUCCESS',
  'hmp/admin/CREATE_QNA_POST_FAILURE'
)<QnaPostType, Optional<NormalizedType<QnaPostType>>, Error>();

export const updateQnaPostAsync = createAsyncAction(
  'hmp/admin/UPDATE_QNA_POST_REQUEST',
  'hmp/admin/UPDATE_QNA_POST_SUCCESS',
  'hmp/admin/UPDATE_QNA_POST_FAILURE'
)<QnaPostType, Optional<NormalizedType<QnaPostType>>, Error>();

export const deleteQnaPostAsync = createAsyncAction(
  'hmp/admin/DELETE_QNA_POST_REQUEST',
  'hmp/admin/DELETE_QNA_POST_SUCCESS',
  'hmp/admin/DELETE_QNA_POST_FAILURE'
)<number, Optional<NormalizedType<QnaPostType>>, Error>();

export const getQnaPostCountAsync = createAsyncAction(
  'hmp/admin/GET_QNA_POST_COUNT_REQUEST',
  'hmp/admin/GET_QNA_POST_COUNT_SUCCESS',
  'hmp/admin/GET_QNA_POST_COUNT_FAILURE'
)<number, number, Error>();

export const updateQnaPosts = createStandardAction('hmp/admin/UPDATE_QNA_POSTS')<Optional<NormalizedType<QnaPostType>>>();
export const clearQnaPosts = createStandardAction('hmp/admin/CLEAR_QNA_POSTS')<void>();

const qnaActions = {
  getQnaPostsAsync,
  getQnaPostAsync,
  getFlaggedQnaContentAsync,
  createQnaPostAsync,
  updateQnaPostAsync,
  deleteQnaPostAsync,
  getQnaPostCountAsync,
  updateQnaPosts
};

export type QnaActionTypes = ActionType<typeof qnaActions>;
