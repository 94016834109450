import React, { Component } from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import {
  Input, Icon, Button, InputNumber, Form
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import '../activity.scss';
import { ActivityOptionType, ActivityFeedbackType } from '../../../types/entity.types';
import CategoryOption from './CategoryOption';
import QuizOption from './QuizOption';
import OptionFeedbackItem from '../feedback/OptionFeedbackItem';
const { Item } = Form;

interface ComponentProps {
  form: any;
  type: number;
  creative: boolean;
  editable: boolean;
  option: ActivityOptionType;
  setStatus: (status: string) => void;
  updateOption: (q: ActivityOptionType) => void;
  moveUp: () => void;
  moveDown: () => void;
}

enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener
}
const defaultFeedback = {
  uuid: '',
  title: '',
  text: '',
  min: undefined,
  max: undefined,
  isOverallFeedback: false
};
const itemStyle = {
  width: '100%',
  marginBottom: '10px', 
  marginTop: '10px'
};

class OptionItem extends Component<ComponentProps, {}> {
  onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { option, updateOption } = this.props;
    e.preventDefault();
    option.text = e.target.value;
    updateOption(option);
  };
  onPointsChange = (value: number | undefined) => {
    const { option, updateOption } = this.props;
    option.points = value;
    updateOption(option);
  };
  onCorrectChange = (e: CheckboxChangeEvent) => {
    const { option, updateOption } = this.props;
    option.isCorrect = e.target.checked;
    updateOption(option);
  };
  setPoints = (value: number) => {
    const { option, updateOption } = this.props;
    option.points = value;
    updateOption(option);
  };
  onMoveUpClick = (e: React.MouseEvent<HTMLElement>) => {
    const { moveUp } = this.props;
    e.preventDefault();
    moveUp();
  };
  onMoveDownClick = (e: React.MouseEvent<HTMLElement>) => {
    const { moveDown } = this.props;
    e.preventDefault();
    moveDown();
  };
  updateFeedback = (f: ActivityFeedbackType) => {
    const { option, updateOption } = this.props;
    option.feedback = f;
    updateOption(option);
  }
  addFeedback = () => {
    const { option, updateOption } = this.props;
    option.feedback = _.cloneDeep(defaultFeedback);
    option.feedback.uuid = generateUUID();
    updateOption(option);
  }
  removeFeedback = () => {
    const { option, updateOption } = this.props;
    option.feedback = undefined;
    updateOption(option);
  }
  validateOption = (rule, value, cb) => {
    const { option, setStatus } = this.props;
    try {
      if (option && option.text.length === 0){ 
        throw new Error('Option text is required.');
      }
      setStatus('finish');
      cb();
    } catch (err) {
      cb(err);
    }
  };
  render() {
    const { 
      option, 
      type, 
      form, 
      setStatus,
      creative,
      editable
    } = this.props;
    const { text, points, uuid, feedback, id } = option;
    const { getFieldDecorator } = form;
    let item;
    switch(type) {
      case Type.quiz:
        item = <QuizOption 
          form={form}
          creative={creative}
          editable={editable}
          option={option} 
          onTextChange={this.onTextChange} 
          onPointsChange={this.onPointsChange}
          onCorrectChange={this.onCorrectChange}
          setStatus={setStatus}
          updateFeedback={this.updateFeedback}
          addFeedback={this.addFeedback}
          removeFeedback={this.removeFeedback}
        />
      break;
      case Type.category: 
        item = <CategoryOption 
          creative={creative}
          editable={editable}
          option={option} 
          onTextChange={this.onTextChange} 
          setPoints={this.setPoints} 
        />
      break;
      case Type.ranking:
        item = (<div className='option-container'>
          <Input spellCheck disabled={!creative && !editable} placeholder='Option text' value={text} onChange={this.onTextChange} style={{width: '100%'}}/>
          <div className='option-props'>
            <span className='option-prop'>Point Value <InputNumber disabled={!creative && !editable} value={points} min={0} onChange={this.onPointsChange} /></span>
            <Button disabled={!creative && !editable} onClick={this.onMoveUpClick}><Icon type='up' /></Button>
            <Button disabled={!creative && !editable} onClick={this.onMoveDownClick}><Icon type='down' /></Button>
          </div>
        </div>);
      break;
      case Type.category_no_answer:
      case Type.screener:
        item = (<div className='option-container'>
          <Input spellCheck disabled={!creative && !editable} placeholder='Option text' value={text} onChange={this.onTextChange} style={{width: '100%'}}/>
          <div className='option-props'>
            <Item key={`input-number-option-${id}`}>
              <span className='option-prop'>Point Value <InputNumber disabled={!creative && !editable} value={points} min={0} onChange={this.onPointsChange} /></span>
            </Item>
          </div>
        </div>);
      break;
      case Type.goals:
        item = (<div className='option-container'>
          <Input spellCheck disabled={!creative && !editable} placeholder='Option text' value={text} onChange={this.onTextChange} style={{width: '100%'}}/>
          <div className='option-props'>
            <OptionFeedbackItem 
              form={form}
              type={Type.goals}
              creative={creative}
              editable={editable}
              feedback={feedback}
              option={option}
              setStatus={setStatus}
              updateFeedback={this.updateFeedback}
              addFeedback={this.addFeedback}
              removeFeedback={this.removeFeedback}
            />
          </div>
        </div>);
      break;
      default:
        item = <Input 
          spellCheck
          disabled={!creative && !editable} 
          placeholder='Option text' 
          value={text} 
          onChange={this.onTextChange} 
          style={{width: '80%'}}
        />;
      break;
    }
    return (
    <Item
      required={false}
      key={`option-${id ? id : uuid}`}
      style={itemStyle}
    >
      {getFieldDecorator(`options-${id ? id : uuid}`, {
        validateTriggers: ['onChange', 'onBlur', 'onFocus'],
        rules: [
          {
            required: true,
            whitespace: true,
            message: 'Please input option text or delete this option.',
            validator: this.validateOption
          }
        ],
        initialValue: text
      })(item)}
    </Item>);
  }
};

export default OptionItem;