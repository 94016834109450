import {
  DatePicker, Radio, Button
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import * as _ from 'lodash';
import FormContextProvider from './FormContextProvider';
import { FormType } from '../../types/entity.types';
import { saveFormAsync, updateFormAsync } from '../../redux/form/form.types';

import './editForm.scss';

const { TextArea } = Input;
const { confirm } = Modal;

interface DispatchProps {
  saveForm: typeof saveFormAsync.request;
  editForm: typeof updateFormAsync.request;
}

interface ComponentProps extends DispatchProps {
  closeHandler: () => {},
  visible: boolean,
  editable: boolean,
  data: FormType,
  editing: boolean
}

const textAreaRows = 4;
const footerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%'
};
const footerActionStyle = {
  textAlign: 'center',
  width: '50%'
};
const radioStyle = {
  display: 'block',
  height: 'auto',
  lineHeight: '30px'
};

const requiredText = 'This response is required.';

const reportedOptions = ['Yes', 'No'];
const deviationOptions = ['Enrollment', 'Randomization', 'App', 'Survey instrument', 'Protocol implementation', 'Sample collection or laboratory issue', 'Breach of confidentiality', 'Other, specify:'];

class AdverseEventForm extends Component<ComponentProps & FormComponentProps, {}> {

  readonly state = {
    participantId: null, // TODO: participantId may need to be nullable since the protocol deviation form doesn't always map to a single participant
    formType: 'PROTOCOL_DEVIATION',
    Q3R: undefined,
    Q4R: undefined,
    Q5R: undefined,
    Q6R: -1,
    Q6aR: undefined,
    Q7R: undefined,
    Q8R: undefined,
    Q9R: undefined,
    Q10R: undefined,
    Q11R: undefined,
    Q12R: undefined,
    Q13R: undefined
  };
  componentDidMount() {
    const { editing, data } = this.props;
    if(editing && data.id) {
      this.setState({
        participantId: data.participantId,
        ...JSON.parse(data.formData)
      });
    }
  }
  questionMap = {
    Q1: 'Q1R', // adminId
    Q2: 'Q2R', // date
    Q3: 'Q3R', // date
    Q4: 'Q4R', // date
    Q5: 'Q5R', // boolean
    Q6: 'Q6R', // number [0-7]
    Q6a: 'Q6aR', // string
    Q7: 'Q7R', // string
    Q8: 'Q8R', // string
    Q9: 'Q9R', // string
    Q10: 'Q10R', // string
    Q11: 'Q11R', // string
    Q12: 'Q12R', // number
    Q13: 'Q13R' // string
  };
  formatDate = (d: Date | undefined) => {
    return moment(d).calendar();
  }
  onChange = (state: any) => {
    this.setState(state);
  }
  showCancel = () => {
    const { closeHandler, editable } = this.props;
    if (editable) {
      confirm({
        title: 'Are you sure you want to leave this form?',
        content: 'You will lose all changes.',
        okText: 'Leave',
        okType: 'danger',
        onOk() {
          closeHandler();
        },
        onCancel() { }
      });
    } else {
      closeHandler();
    }
  }
  handleSubmit = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.showSave();
      }
    });
  }
  showSave = () => {
    const { editable, editing } = this.props;
    if (editable || editing) {
      const handleSaveForm = this.handleSaveForm.bind(this);
      confirm({
        title: 'Are you sure you want to submit this form?',
        content: '',
        okText: 'Save',
        onOk() {
          handleSaveForm();
        },
        onCancel() { }
      });
    }
  }
  handleSaveForm = () => {
    const { saveForm, closeHandler, editable, data, editing, editForm } = this.props;
    const { participantId, formType } = this.state;
    const form = {
      participantId,
      formType,
      formData: _.omit(this.state, ['participantId', 'formType', 'visible']),
      isResolved: true
    };
    if (editable || editing) {
      const { formData } = form;
      if (formData.Q6R && formData.Q6aR && formData.Q6R !== 7) {
        formData.Q6aR = undefined;
      }
      if(editing && data.id) {
        form.id = data.id;
        editForm(form);
      } else {
        saveForm(form);
      }
    }
    closeHandler();
  }
  render() {
    const {
      form, visible, editable, data, editing
    } = this.props;
    const { getFieldDecorator } = form;
    const { formType } = this.state;
    let formData: any;
    if (data) formData = JSON.parse(data.formData);
    const dateFormat = 'MM/DD/YYYY';
    const type = formType.split('_').map((s: string) => s.charAt(0) + s.substring(1).toLowerCase()).join(' ');

    return (
      <Modal
        key={data?.id ? `form-${data.id}` : 'form-deviation'}
        title={editable ? `${type}` : `${type} created: ${this.formatDate(data.createDate)}`}
        visible={visible}
        onCancel={this.showCancel}
        width="45%"
        style={{ minWidth: '45vw' }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button type={editable ? 'danger' : 'default'} onClick={e => { e.stopPropagation(); this.showCancel(); }}>Close</Button>,
          <Button type="primary" disabled={!editable && !editing} onClick={e => { e.stopPropagation(); this.handleSubmit(); }}>Save</Button>,
        ]}
        destroyOnClose
      >
        <FormContextProvider>
          <Form key="form" layout="vertical" colon={false}>
            <a href="https://irb.upenn.edu/Deviation" target="_blank">UPenn Protocol Deviation Information</a>
            <Form.Item label="Date deviation was identified:">
              {getFieldDecorator('Q2', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : moment(formData[this.questionMap.Q2])
              })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q2R: dateString })} format={dateFormat} />)}
            </Form.Item>
            <Form.Item label="Date deviation first occurred:">
              {getFieldDecorator('Q3', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : moment(formData[this.questionMap.Q3])
              })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q3R: dateString })} format={dateFormat} />)}
            </Form.Item>
            <Form.Item label="Date deviation ended:">
              {getFieldDecorator('Q4', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : moment(formData[this.questionMap.Q4])
              })(<DatePicker disabled={!editable && !editing} onChange={(date: Moment | null, dateString: string) => this.onChange({ Q4R: dateString })} format={dateFormat} />)}
            </Form.Item>
            <Form.Item label="Has or will this deviation been reported to IRB?">
              {getFieldDecorator('Q5', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : (formData[this.questionMap.Q5] ? 0 : 1)
              })(<Radio.Group disabled={!editable && !editing} onChange={(e) => this.onChange({ Q5R: e.target.value })}>
                {reportedOptions.map(opt => <Radio value={reportedOptions.indexOf(opt)}>{opt}</Radio>)}
              </Radio.Group>)}
            </Form.Item>
            <Form.Item label="Deviation type:">
              {getFieldDecorator('Q6', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? undefined : formData[this.questionMap.Q6]
              })(<Radio.Group disabled={!editable && !editing} onChange={(e) => this.onChange({ Q6R: e.target.value })}>
                {deviationOptions.map(opt => {
                  switch (deviationOptions.indexOf(opt)) {
                    case 7:
                      return (
                        <div style={radioStyle}>
                          <Radio value={deviationOptions.indexOf(opt)}>{opt}</Radio>
                          <Input disabled={!editable && !editing} onChange={(e) => this.onChange({ Q6aR: e.target.value })} style={{ width: 100, marginLeft: 10 }} defaultValue={editable ? '' : formData[this.questionMap.Q6a]} />
                        </div>
                      );
                    default:
                      return <Radio style={radioStyle} value={deviationOptions.indexOf(opt)}>{opt}</Radio>;
                  }
                })}
              </Radio.Group>)}
            </Form.Item>
            <Form.Item label="Description of deviation and how it was identified:">
              {getFieldDecorator('Q7', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q7]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q7R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
            <Form.Item label="Description of the root cause of the deviation (reason it occurred and the parties involved):">
              {getFieldDecorator('Q8', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q8]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q8R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
            <Form.Item label="Plans and/or actions taken to address the deviation:">
              {getFieldDecorator('Q9', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q9]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q9R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
            <Form.Item label="Plans and/or actions taken to prevent future occurrences of the deviation:">
              {getFieldDecorator('Q10', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q10]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q10R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
            <Form.Item label="Plans for communication information about this deviation to participant(s), if applicable:">
              {getFieldDecorator('Q11', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q11]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q11R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
            <Form.Item label="Number of participants affected by the deviation:">
              {getFieldDecorator('Q12', {
                rules: [{ required: true, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q12]
              })(<Input disabled={!editable && !editing} onChange={e => this.onChange({ Q12R: e.target.value })} type="number" />)}
            </Form.Item>
            <Form.Item label="Current status (e.g. active/completed) of affected participant(s):">
              {getFieldDecorator('Q13', {
                rules: [{ required: false, message: requiredText }],
                initialValue: editable ? '' : formData[this.questionMap.Q13]
              })(<TextArea disabled={!editable && !editing} onChange={e => this.onChange({ Q13R: e.target.value })} rows={textAreaRows} />)}
            </Form.Item>
          </Form>
        </FormContextProvider>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    saveForm: (form: FormType) => dispatch(saveFormAsync.request(form)),
    editForm: (form: FormType) => dispatch(updateFormAsync.request(form))
  };
};

export default connect(() => {}, mapDispatchToProps)(Form.create({ name: 'form' })(AdverseEventForm));
