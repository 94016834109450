import Icon from 'antd/lib/icon';
import PropTypes from 'prop-types';
import React from 'react';

interface ComponentProps {
  collapsed: boolean,
  toggleCallback: () => void
}

const SideBarToggleButton = (props: ComponentProps) => {

  const { collapsed, toggleCallback } = props;

  return <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={toggleCallback} style={{ fontSize: '24px', cursor: 'hand', color: 'white' }} />;
};

SideBarToggleButton.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggleCallback: PropTypes.func.isRequired
};

export default SideBarToggleButton;
