import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { FormType, NotificationType } from '../../types/entity.types';
import IApplicationState from '../../types/state.types';
import { selectParticipants } from '../participants/participants.selectors';
import entitySchema from '../schema';

export const selectNotifications = (state: IApplicationState) => {
  return state.entities.notifications;
};

export const getNotifications = createSelector(
  [selectNotifications, selectParticipants],
  (notifications, participants): Optional<NotificationType[]> => {
    if (notifications && notifications.allIds && notifications.allIds.length > 0) {
      const { notifications: denormalizedNotifications } = denormalize({ notifications: notifications.allIds }, entitySchema, { notifications: notifications.byId, participants: participants.byId  });
      return toArray(denormalizedNotifications);
    }
    return undefined;
  }
);

export default { getNotifications };
